import { BLOCK } from '../constants/typeConstants';
import { 
  SCENARIO_TRUCK,
  LOCATION_SCHEDULE_COMMENT,
  DELETE_SCENARIO_TRUCK,
  COPY_SCENARIO_MODAL,
  REMOVE_OBJECT,
  BULK_ACTIVATION_MODAL,
  ASSIGN_LOADER_MODAL,
  SEND_PDF_EMAIL_MODAL,
  PDF_PREVIEW_MODAL,
  SCENARIO_DELETE_MODAL,
  PUBLISH_SCENARIO_MODAL,
  SLEEPER_LOAD_MODAL,
  DRIVER_TO_BLOCK_RANKING,
  BLOCK_HISTORY_MODAL,
  DRIVER_HISTORY_MODAL,
  DESTINATION_MAX_CAPACITY_MODAL,
  ASSIGN_SLEEPER_YARD_MODAL,
  IMPORT_SLEEPERS_MODAL,
  SCENARIO_TRUCK_SHARE,
  SWAP_SCHEDULE_ACTIVITY_MODAL,
  DUPLICATE_OBJECT_NAMES_MODAL,
  ROLLUP_DAY_PICKER_MODAL,
  REOPEN_SCENARIO_PASSWORD_MODAL,
  SEND_CONTRACTOR_TIMECARDS_MODAL,
  TRAINEE_DRIVER_MODAL,
  PUBLISH_PARTIALLY_MODAL,
} from '../constants/modalTypes';

const OPEN_BLOCK_MODAL = 'OPEN_BLOCK_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const OPEN_SCENARIO_TRUCK_MODAL = 'OPEN_SCENARIO_TRUCK_MODAL';
const CLOSE_SCENARIO_TRUCK_MODAL = 'CLOSE_SCENARIO_TRUCK_MODAL';
const OPEN_LOCATION_SCHEDULE_ACTIVITY_MODAL = 'OPEN_LOCATION_SCHEDULE_ACTIVITY_MODAL';
const CLOSE_LOCATION_SCHEDULE_ACTIVITY_MODAL = 'CLOSE_LOCATION_SCHEDULE_ACTIVITY_MODAL';
const OPEN_SCENARIO_TRUCK_DELETE_MODAL = 'OPEN_SCENARIO_TRUCK_DELETE_MODAL';
const OPEN_COPY_SCENARIO_MODAL = 'OPEN_COPY_SCENARIO_MODAL';
const OPEN_REMOVE_LOCATION_MODAL = 'OPEN_REMOVE_LOCATION_MODAL';
const OPEN_BULK_ACTIVATION_MODAL = 'OPEN_BULK_ACTIVATION_MODAL';
const OPEN_ASSIGN_LOADER_MODAL = 'OPEN_ASSIGN_LOADER_MODAL';
const OPEN_SEND_PDF_EMAIL_MODAL = 'OPEN_SEND_PDF_EMAIL_MODAL';
const OPEN_PDF_PREVIEW_MODAL = 'OPEN_PDF_PREVIEW_MODAL';
const OPEN_SCENARIO_DELETE_MODAL = 'OPEN_SCENARIO_DELETE_MODAL';
const OPEN_PUBLISH_MODAL = 'OPEN_PUBLISH_MODAL';
const OPEN_SCENARIO_TRUCK_SHARE_MODAL = 'OPEN_SCENARIO_TRUCK_SHARE_MODAL';
const CLOSE_SCENARIO_TRUCK_SHARE_MODAL = 'CLOSE_SCENARIO_TRUCK_SHARE_MODAL';
const OPEN_DRIVER_TO_BLOCK_RANKING_MODAL = 'OPEN_DRIVER_TO_BLOCK_RANKING_MODAL';
const OPEN_SLEEPER_LOAD_INVENTORY_MODAL = 'OPEN_SLEEPER_LOAD_INVENTORY_MODAL';
const OPEN_DESTINATION_MAX_CAPACITY_MODAL = 'OPEN_DESTINATION_MAX_CAPACITY_MODAL';
const OPEN_SLEEPER_YARD_PROMPT_MODAL = 'OPEN_SLEEPER_YARD_PROMPT_MODAL';
const OPEN_BLOCK_HISTORY_MODAL = 'OPEN_BLOCK_HISTORY_MODAL';
const OPEN_DRIVER_HISTORY_MODAL = 'OPEN_DRIVER_HISTORY_MODAL';
const OPEN_IMPORT_SLEEPERS_MODAL = 'OPEN_IMPORT_SLEEPERS_MODAL';
const OPEN_SWAP_SCHEDULE_ACTIVITY_MODALL = 'OPEN_SWAP_SCHEDULE_ACTIVITY_MODAL';
const CLOSE_SWAP_SCHEDULE_ACTIVITY_MODAL = 'CLOSE_SWAP_SCHEDULE_ACTIVITY_MODAL';
const OPEN_DUPLICATE_OBJECT_NAMES_MODAL = 'OPEN_DUPLICATE_OBJECT_NAMES_MODAL';
const OPEN_ROLLUP_DAY_PICKER_MODAL = 'OPEN_ROLLUP_DAY_PICKER_MODAL';
const OPEN_REOPEN_SCENARIO_PASSWORD_MODAL = 'OPEN_REOPEN_SCENARIO_PASSWORD_MODAL';
const OPEN_SEND_CONTRACTOR_TIMECARDS_MODAL = 'OPEN_SEND_CONTRACTOR_TIMECARDS_MODAL';
const OPEN_TRAINEE_DRIVER_MODAL = 'OPEN_TRAINEE_DRIVER_MODAL';
const OPEN_PUBLISH_PARTIALLY_MODAL = 'OPEN_PUBLISH_PARTIALLY_MODAL';

export function openBlockModal(blockId, displayName) {
  return {
    type: OPEN_BLOCK_MODAL,
    payload: {
      blockId: blockId,
      name: displayName
    }
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  }
}

export function openDuplicateObjectNamesModal(object) {
  return {
    type: OPEN_DUPLICATE_OBJECT_NAMES_MODAL,
    payload: {
      isModalOpen: true,
      modalType: DUPLICATE_OBJECT_NAMES_MODAL,
      object: object
    }
  }
}


export function openRollupDayPickerModal() {
  return {
    type: OPEN_ROLLUP_DAY_PICKER_MODAL,
    payload: {
      isModalOpen: true,
      modalType: ROLLUP_DAY_PICKER_MODAL
    }
  }
}


export function openReopenScenarioPasswordModal() {
  return {
    type: OPEN_REOPEN_SCENARIO_PASSWORD_MODAL,
    payload: {
      isModalOpen: true,
      modalType: REOPEN_SCENARIO_PASSWORD_MODAL
    }
  }
}

export function openSendContractorTimeCardsModal() {
  return {
    type: OPEN_SEND_CONTRACTOR_TIMECARDS_MODAL,
    payload: {
      isModalOpen: true,
      modalType: SEND_CONTRACTOR_TIMECARDS_MODAL
    }
  }
}


export function openDestinationMaxCapacityModal(destination) {
  return {
    type: OPEN_DESTINATION_MAX_CAPACITY_MODAL,
    payload: {
      destination: destination,
      isModalOpen: true,
      modalType: DESTINATION_MAX_CAPACITY_MODAL
    }
  }
}

export function openBlockHistoryModal(driverIds) {
  return {
    type: OPEN_BLOCK_HISTORY_MODAL,
    payload: {
      driverIds: driverIds,
      isModalOpen: true,
      modalType: BLOCK_HISTORY_MODAL
    }
  }
}

export function openDriverHistoryModal(blockIds) {
  return {
    type: OPEN_DRIVER_HISTORY_MODAL,
    payload: {
      blockIds: blockIds,
      isModalOpen: true,
      modalType: DRIVER_HISTORY_MODAL
    }
  }
}

export function openImportSleepersModal() {
  return {
    type: OPEN_IMPORT_SLEEPERS_MODAL,
    payload: {
      isModalOpen: true,
      modalType: IMPORT_SLEEPERS_MODAL
    }
  }
}

export function openScenarioTruckModal(
  truckId,
  name,
  isAddingNewScenarioTruck = false,
  scenarioTruck = null,
  startTime,
  workDayMinutes, 
  comments) {
  return {
    type: OPEN_SCENARIO_TRUCK_MODAL,
    payload: {
      id: truckId,
      truckName: name,
      isAddingNewScenarioTruck: isAddingNewScenarioTruck,
      scenarioTruck: scenarioTruck,
      startTime: startTime,
      workDayMinutes: workDayMinutes,
      comments: comments
    }
  }
}

export function openDriverToBlockRankingModal(
  driverExperience,
  blockRanking) {
  return {
    type: OPEN_DRIVER_TO_BLOCK_RANKING_MODAL,
    payload: {
      isModalOpen: true,
      modalType: DRIVER_TO_BLOCK_RANKING,
      driverExperience: driverExperience,
      blockRanking: blockRanking
    }
  }
}

export function openTraineeDriverModal(traineeDriver, mentorDriver) {
  return {
    type: OPEN_TRAINEE_DRIVER_MODAL,
    payload: {
      isModalOpen: true,
      modalType: TRAINEE_DRIVER_MODAL,
      traineeDriver: traineeDriver,
      mentorDriver: mentorDriver
    }
  }
}

export function openPublishPartiallyModal(scenarioId, user) {
  return {
    type: OPEN_PUBLISH_PARTIALLY_MODAL,
    payload: {
      isModalOpen: true,
      modalType: PUBLISH_PARTIALLY_MODAL,
      scenarioId: scenarioId,
      user: user
    }
  }
}

export function closeScenarioTruckModal() {
  return {
    type: CLOSE_SCENARIO_TRUCK_MODAL
  }
}

export function openScenarioTruckShareModal(
  truckId,
  scenarioTruckId,
  scenarioTruck = null,
  name,
  isSharingScenarioTruck = false) {
    return {
      type: OPEN_SCENARIO_TRUCK_SHARE_MODAL,
      payload: {
        id: truckId,
        scenarioTruckId: scenarioTruckId,
        scenarioTruck: scenarioTruck,
        truckName: name,
        isSharingScenarioTruck: isSharingScenarioTruck
      }
    }
  }


export function closeScenarioTruckShareModal() {
  return {
    type: CLOSE_SCENARIO_TRUCK_SHARE_MODAL
  }
}


export function openLocationScheduleActivityModal(modalProps) {
  return {
    type: OPEN_LOCATION_SCHEDULE_ACTIVITY_MODAL,
    payload: {
      isModalOpen: true,
      modalType: LOCATION_SCHEDULE_COMMENT,
      modalProps: modalProps
    }
  }
}

export function closeLocationScheduleModal() {
  return {
    type: CLOSE_LOCATION_SCHEDULE_ACTIVITY_MODAL
  }
}

export function openSwapScheduleActivityModal(modalProps) {
  return {
    type: OPEN_SWAP_SCHEDULE_ACTIVITY_MODALL,
    payload: {
      isModalOpen: true,
      modalType: SWAP_SCHEDULE_ACTIVITY_MODAL,
      modalProps: modalProps
    }
  }
}

export function closeSwapScheduleActivityModal() {
  return {
    type: CLOSE_SWAP_SCHEDULE_ACTIVITY_MODAL
  }
}

export function openScenarioTruckDeleteModal(scenarioTruckId) {
  return {
    type: OPEN_SCENARIO_TRUCK_DELETE_MODAL,
    payload: {
      isModalOpen: true,
      modalType: DELETE_SCENARIO_TRUCK,
      scenarioTruckId: scenarioTruckId
    }
  }
}


export function openCopyScenarioModal() {
  return {
    type: OPEN_COPY_SCENARIO_MODAL,
    payload: {
      isModalOpen: true,
      modalType: COPY_SCENARIO_MODAL
    }
  }
}

export function openRemoveObjectModal(id) {
  return {
    type: OPEN_REMOVE_LOCATION_MODAL,
    payload: {
      isModalOpen: true,
      modalType: REMOVE_OBJECT,
      targetId: id
    }
  }
}

export function openBulkActivationModal() {
  return {
    type: OPEN_BULK_ACTIVATION_MODAL,
    payload: {
      isModalOpen: true,
      modalType: BULK_ACTIVATION_MODAL
    }
  }
}

export function openAssignLoaderModal(props) {
  return {
    type: OPEN_ASSIGN_LOADER_MODAL,
    payload: {
      isModalOpen: true,
      modalType: ASSIGN_LOADER_MODAL,
      ...props
    }
  }
}

export function openSendPdfConfirmationModal(props) {
  return {
    type: OPEN_SEND_PDF_EMAIL_MODAL,
    payload: {
      isModalOpen: true,
      modalType: SEND_PDF_EMAIL_MODAL,
      ...props
    }
  }
}

export function openPdfPreviewModal(previewUrl) {
  return {
    type: OPEN_PDF_PREVIEW_MODAL,
    payload: {
      isModalOpen: true,
      modalType: PDF_PREVIEW_MODAL,
      previewUrl: previewUrl
    }
  }
}

export function openScenarioDeleteModal(scenarioId) {
  return {
    type: OPEN_SCENARIO_DELETE_MODAL,
    payload: {
      isModalOpen: true,
      modalType: SCENARIO_DELETE_MODAL,
      scenarioId: scenarioId
    }
  }
}

export function openPublishModal(isPublishing, scenarioId, user) {
  return {
    type: OPEN_PUBLISH_MODAL,
    payload: {
      isModalOpen: true,
      modalType: PUBLISH_SCENARIO_MODAL,
      isPublishing: isPublishing,
      scenarioId: scenarioId,
      user: user
    }
  }
}

export function openSleeperLoadInventoryModal(sleeperInfo) {
  return {
    type: OPEN_SLEEPER_LOAD_INVENTORY_MODAL,
    payload: {
      isModalOpen: true,
      modalType: SLEEPER_LOAD_MODAL,
      sleeperInfo: sleeperInfo
    }
  }
}

export function openSleeperYardPromptModal(rowId, locationId, blockId) {
  return {
    type: OPEN_SLEEPER_YARD_PROMPT_MODAL,
    payload: {
      isModalOpen: true,
      modalType: ASSIGN_SLEEPER_YARD_MODAL,
      rowId: rowId,
      yardId: locationId,
      blockId: blockId
    }
  }
}

const defaultState = {
  isModalOpen: false,
  modalType: '',
  modalId: ''
}

const modalWindows = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_BLOCK_MODAL:
      return {
        isModalOpen: true,
        modalType: BLOCK,
        modalId: action.payload.blockId,
        name: action.payload.name
      };
    case CLOSE_MODAL:
      return defaultState;
    case OPEN_SCENARIO_TRUCK_MODAL:
      return _openScenarioTruckModal(state, action);
    case OPEN_DESTINATION_MAX_CAPACITY_MODAL:
      return _openDestinationMaxCapacityModal(state,action);
    case OPEN_DRIVER_TO_BLOCK_RANKING_MODAL:
      return _openDriverToBlockRankingModal(state, action);
    case CLOSE_SCENARIO_TRUCK_MODAL:
      return defaultState;
    case OPEN_SCENARIO_TRUCK_SHARE_MODAL:
      return _openScenarioTruckShareModal(state, action);
    case CLOSE_SCENARIO_TRUCK_SHARE_MODAL:
      return defaultState;
    case OPEN_LOCATION_SCHEDULE_ACTIVITY_MODAL:
      return _openLocationScheduleActivityModal(action);
    case CLOSE_LOCATION_SCHEDULE_ACTIVITY_MODAL:
      return defaultState;
    case OPEN_SCENARIO_TRUCK_DELETE_MODAL:
      return _openScenarioTruckDeleteModal(action);
    case OPEN_BLOCK_HISTORY_MODAL:
      return _openBlockHistoryModal(state, action);
    case OPEN_DRIVER_HISTORY_MODAL:
      return _openDriverHistoryModal(state, action);
    case OPEN_SWAP_SCHEDULE_ACTIVITY_MODALL:
      return _openSwapScheduleActivityModal(action);  
    case OPEN_TRAINEE_DRIVER_MODAL:
      return _openTraineeDriverModal(state, action);
    case CLOSE_SWAP_SCHEDULE_ACTIVITY_MODAL:
      return defaultState;
    case OPEN_IMPORT_SLEEPERS_MODAL:
    case OPEN_COPY_SCENARIO_MODAL:
    case OPEN_REMOVE_LOCATION_MODAL:
    case OPEN_BULK_ACTIVATION_MODAL:
    case OPEN_ASSIGN_LOADER_MODAL:
    case OPEN_SEND_PDF_EMAIL_MODAL:
    case OPEN_PDF_PREVIEW_MODAL:
    case OPEN_SCENARIO_DELETE_MODAL:
    case OPEN_PUBLISH_MODAL:
    case OPEN_SLEEPER_LOAD_INVENTORY_MODAL:
    case OPEN_SLEEPER_YARD_PROMPT_MODAL:
    case OPEN_DUPLICATE_OBJECT_NAMES_MODAL:
    case OPEN_ROLLUP_DAY_PICKER_MODAL:
    case OPEN_REOPEN_SCENARIO_PASSWORD_MODAL:
    case OPEN_SEND_CONTRACTOR_TIMECARDS_MODAL:
    case OPEN_PUBLISH_PARTIALLY_MODAL:
      return _openModal(action);
    default:
      return state;

  }
};

export default modalWindows;

function _openScenarioTruckDeleteModal(action) {
  return {
    ...action.payload
  }
}


function _openScenarioTruckModal(state, action) {
  return {
    isModalOpen: true,
    modalType: SCENARIO_TRUCK,
    truckId: action.payload.id,
    name: action.payload.truckName,
    isAddingNewScenarioTruck: action.payload.isAddingNewScenarioTruck,
    scenarioTruck: action.payload.scenarioTruck,
    startTime: action.payload.startTime,
    workDayMinutes: action.payload.workDayMinutes,
    comments: action.payload.comments
  };
}

function _openScenarioTruckShareModal(state, action) {
  return {
    isModalOpen: true,
    modalType: SCENARIO_TRUCK_SHARE,
    truckId: action.payload.id,
    scenarioTruckId: action.payload.scenarioTruckId,
    scenarioTruck: action.payload.scenarioTruck,
    name: action.payload.truckName,
    isSharingScenarioTruck: action.payload.isSharingScenarioTruck
  };
}


function _openLocationScheduleActivityModal(action) {
  return {
    ...action.payload
  }
}

function _openSwapScheduleActivityModal(action) {
  return {
    ...action.payload
  }
}

function _openModal(action) {
  return {
    ...action.payload
  }
}

function _openDriverToBlockRankingModal(state, action) {
  return {
    isModalOpen: true,
    modalType: DRIVER_TO_BLOCK_RANKING,
    driverExperience: action.payload.driverExperience,
    blockRanking: action.payload.blockRanking
  }
}

function _openDestinationMaxCapacityModal(state, action) {
  return {
    isModalOpen: true,
    modalType: DESTINATION_MAX_CAPACITY_MODAL,
    destination: action.payload.destination
  }
}

function _openBlockHistoryModal(state, action) {
  return {
    isModalOpen: true,
    modalType: BLOCK_HISTORY_MODAL,
    driverIds: action.payload.driverIds
  }
}

function _openDriverHistoryModal(state, action) {
  return {
    isModalOpen: true,
    modalType: DRIVER_HISTORY_MODAL,
    blockIds: action.payload.blockIds
  }
}

function _openTraineeDriverModal(state, action) {
  return {
    isModalOpen: true,
    modalType: TRAINEE_DRIVER_MODAL,
    traineeDriver: action.payload.traineeDriver,
    mentorDriver: action.payload.mentorDriver
  }
}
