import { setVendors } from '../reducers/vendors';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class VendorService {

  static getVendorsApi() {
    return axios.get('/api/vendors', { headers: getAuthService().header });
  }

  static getVendors() {
    return this.getVendorsApi()
      .then((vendors) => {
        store.dispatch(setVendors(vendors.data))
      })
      .catch(error => console.log(error));
  }
}

export default VendorService;