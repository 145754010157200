import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addYarderSuccess, updateYarderSuccess, removeYarderSuccess, ADD_YARDER, UPDATE_YARDER, REMOVE_YARDER, BULK_ACTIVATE_YARDERS, setYarders } from '../../reducers/yarders';
import { getAuthService } from '../AuthService';


const addYarderEpic = action$ => (
  action$.ofType(ADD_YARDER)
    .mergeMap(action => (
      ajax.post('/api/yarders', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addYarderSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateYarderEpic = action$ => (
  action$.ofType(UPDATE_YARDER)
    .mergeMap(action => (
      ajax.put(`/api/yarders/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateYarderSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeYarderEpic = action$ => (
  action$.ofType(REMOVE_YARDER)
    .mergeMap(action => (
      ajax.delete(`/api/yarders/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeYarderSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);


export const bulkActivateYarderEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_YARDERS)
      .mergeMap(action => (
          ajax.post('/api/yarders/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setYarders(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const YarderEpics = combineEpics(
  addYarderEpic,
  updateYarderEpic,
  removeYarderEpic,
  bulkActivateYarderEpic
);