import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addProcessorSuccess, updateProcessorSuccess, removeProcessorSuccess, ADD_PROCESSOR, UPDATE_PROCESSOR, REMOVE_PROCESSOR, BULK_ACTIVATE_PROCESSORS, setProcessors } from '../../reducers/processors';
import { getAuthService } from '../AuthService';



const addProcessorEpic = action$ => (
  action$.ofType(ADD_PROCESSOR)
    .mergeMap(action => (
      ajax.post('/api/processors', action.payload, getAuthService().header)

        .map(ajaxResponse => success(() => addProcessorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateProcessorEpic = action$ => (
  action$.ofType(UPDATE_PROCESSOR)
    .mergeMap(action => (
      ajax.put(`/api/processors/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateProcessorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeProcessorEpic = action$ => (
  action$.ofType(REMOVE_PROCESSOR)
    .mergeMap(action => (
      ajax.delete(`/api/processors/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeProcessorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);


export const bulkActivateProcessorEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_PROCESSORS)
      .mergeMap(action => (
          ajax.post('/api/processors/bulkActivate', action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setProcessors(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const ProcessorEpics = combineEpics(
  addProcessorEpic,
  updateProcessorEpic,
  removeProcessorEpic,
  bulkActivateProcessorEpic
);