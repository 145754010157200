import React from 'react';
import find from 'lodash/find';
class DashboardScenarioTruck extends React.Component {

  getComment(comment) {
    if (comment) {
      return <React.Fragment>
        <p>Comments</p>
        <div>{comment}</div>
      </React.Fragment>
    } else {
      return null;
    }
  }

  getDispatcherComment(comment) {
    if (comment) {
      return <React.Fragment>
        <p>Dispatcher Comments</p>
        <div>{comment}</div>
      </React.Fragment>
    } else {
      return null;
    }
  }

  getDriverExperience() {
    const data = this.props.dashboardInfo;
    const drivers = this.props.drivers;
    const driver = find(drivers, {name: data.driverName})
    if (driver) {
      if (driver.experience === null) {
        return ' N/A';
      }else {
        return ('Level '+ driver.experience)
      }
    } else {
      return null;
    } 
  }
  
  render() {
    const data = this.props.dashboardInfo;
    return (
      <div className='dashboard-scenario-truck'>
        <h4>Truck {data.truckName} details </h4>
        <div>Driver: {data.driverName} </div>
        <div>Driver Experience: {this.getDriverExperience()}</div>
        <div>Trailer: {data.trailerName}</div>
        <div>Work Day Duration: {data.workDayDuration} minutes</div>
        <div>Start Time: {data.startTime}</div>
        <div>License Plate: {data.licensePlate}</div>
        <div>Truck Company: {data.truckCompanyName}</div>
        {this.getComment(data.comments)}
        {this.getDispatcherComment(data.dispatcherComment)}
      </div>
    );
  }
}

export default DashboardScenarioTruck;