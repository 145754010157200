import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { closeLocationScheduleModal } from '../../reducers/modalWindows';
import { updateActivity } from '../../reducers/schedule';
import { TRANSIT, BLOCK, DRYLAND, YARD } from '../../constants/typeConstants';
import LoaderPicker from '../LoaderPicker';
import ErrorCodePicker from '../ErrorCodePicker';
import YardSleeperEditor from '../YardSleeperEditor';
import { emptyUuid } from '../../utils/uuidHelper';

class LocationScheduleEditActivityModal extends React.Component {

  constructor(props) {
    super(props);

    const propComments = props.modalProps.comments;
    const validComments = propComments? propComments: "";
    const propDelay = props.modalProps.delay;
    const validDelay = propDelay? propDelay: 0;
    const propSecondDelay = props.modalProps.secondDelay;
    const validSecondDelay = propSecondDelay? propSecondDelay: 0;
    const propErrorCode = props.modalProps.errorCodeId;
    const validErrorCode = propErrorCode? propErrorCode: "";
    const propPayOption = props.modalProps.payOptions;
    const validPayOption = propPayOption? propPayOption: "";
    const propSecondErrorCode = props.modalProps.secondErrorCodeId;
    const validSecondErrorCode = propSecondErrorCode? propSecondErrorCode: "";
    const propSecondPayOption = props.modalProps.secondPayOption;
    const validSecondPayOption = propSecondPayOption? propSecondPayOption: "";
    const validSleeperDestinationId = props.modalProps.sleeperDestinationId? props.modalProps.sleeperDestinationId: "";
    const propReasonForAdjustment = props.modalProps.reasonForAdjustment;
    const validReasonForAdjustment = propReasonForAdjustment? propReasonForAdjustment: "";
    const validHasSecondErrorCode = props.modalProps.hasSecondErrorCode;
    const loaderId = props.modalProps.loaderId? props.modalProps.loaderId : "";
    const isThirdParty = props.modalProps.isThirdParty;
    
    this.state = {
      isModified: false,
      comments: validComments,
      delay: validDelay,
      secondDelay: validSecondDelay,
      loaderId: loaderId,
      hasLoadDelay: props.modalProps.hasLoadDelay,
      errorCodeId: validErrorCode,
      payOptions: validPayOption,
      secondErrorCodeId: validSecondErrorCode,
      secondPayOption: validSecondPayOption,
      sleeperDestinationId: validSleeperDestinationId,
      sleeperOriginBlockId: emptyUuid,
      reasonForAdjustment: validReasonForAdjustment,
      hasSecondErrorCode: validHasSecondErrorCode,
      isThirdParty: isThirdParty
    }
    
    this.modifyState = this.modifyState.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleDelayChange = this.handleDelayChange.bind(this);
    this.handleSecondDelayChange = this.handleSecondDelayChange.bind(this);
    this.handleChangeLoader = this.handleChangeLoader.bind(this);
    this.handleCheckLoadDelay = this.handleCheckLoadDelay.bind(this);
    this.handleChangeErrorCode = this.handleChangeErrorCode.bind(this);
    this.handleChangeSecondErrorCode = this.handleChangeSecondErrorCode.bind(this);
    this.handleSleeperDestinationChange = this.handleSleeperDestinationChange.bind(this);
    this.handleReasonForAdjustmentChange = this.handleReasonForAdjustmentChange.bind(this);
    this.handleCheckSecondErrorCode = this.handleCheckSecondErrorCode.bind(this);
    this.handleCheckIsThirdParty = this.handleCheckIsThirdParty.bind(this);
    ReactModal.setAppElement('#root');
  }

  componentDidMount() {
    const scheduleRow = this.props.scheduleRowState
      .filter(scheduleRow => scheduleRow.rowId === this.props.modalProps.rowId).map(scheduleRow => scheduleRow.schedule);
    var previousBlock = {};
    for (var i = 0; i < scheduleRow[0].length; i++) {
      if (scheduleRow[0][i].locationId === this.props.modalProps.locationId && scheduleRow[0][i].id === this.props.modalProps.scenarioActivityId) {
        previousBlock = scheduleRow[0][i - 2];
        break;     
      }
    }
    if (previousBlock) {
      this.setState({sleeperOriginBlockId: previousBlock.locationId});
    }
  }

  modifyState() {
    this.setState({
      isModified: true
    });
  }

  handleCheckLoadDelay() {
    this.modifyState();
    this.setState({hasLoadDelay: !this.state.hasLoadDelay});
  }

  handleChangeLoader(event) {
    this.modifyState();
    this.setState({
      loaderId: event.target.value
    });
  }

  handleChangeErrorCode(errorCodeId, payOptions) {
    this.modifyState();
    this.setState({errorCodeId: errorCodeId, payOptions: payOptions});
  }

  handleChangeSecondErrorCode(secondErrorCodeId, secondPayOption) {
    this.modifyState();
    this.setState({secondErrorCodeId: secondErrorCodeId, secondPayOption: secondPayOption});
  }

  handleDelayChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({delay: event.target.value});  
  }

  handleSecondDelayChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({secondDelay: event.target.value});  
  }

  handleCommentChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({comments: event.target.value});
  }

  handleSleeperDestinationChange(destinationId) {
    this.setState({sleeperDestinationId: destinationId});
    this.modifyState();
  }

  handleReasonForAdjustmentChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({reasonForAdjustment: event.target.value});
  }

  getValidDelayNumber() {
    const delayValue = parseInt(this.state.delay, 10);
    const type = this.props.modalProps.type;
    const isValid = type === TRANSIT || type === BLOCK || type === DRYLAND;
    if (isValid && delayValue < 0) {
      return delayValue;
    } else {
      const validDelay = delayValue < 0? 0: delayValue;
      return validDelay;
    }
  }

  getValidSecondDelayNumber() {
    const delayValue = parseInt(this.state.secondDelay, 10);
    const type = this.props.modalProps.type;
    const isValid = type === TRANSIT || type === BLOCK || type === DRYLAND;
    if (isValid && delayValue < 0) {
      return delayValue;
    } else {
      const validDelay = delayValue < 0? 0: delayValue;
      return validDelay;
    }
  }

  handleCheckSecondErrorCode() {
    this.modifyState();
    const checkValue = !this.state.hasSecondErrorCode;
    this.setState({hasSecondErrorCode : checkValue});
  }

  handleCheckIsThirdParty() {
    this.modifyState();
    this.setState({isThirdParty : !this.state.isThirdParty});
  }

  saveChanges() {
    if (this.state.isModified) {
      const payload = {
        ...this.props.modalProps,
        comments: this.state.comments,
        delay: this.getValidDelayNumber(),
        secondDelay: this.getValidSecondDelayNumber(),
        errorCodeId: this.state.errorCodeId,
        payOptions: this.state.payOptions,
        secondErrorCodeId: this.state.secondErrorCodeId,
        secondPayOption: this.state.secondPayOption,
        sleeperDestinationId: this.state.sleeperDestinationId,
        sleeperOriginBlockId: this.state.sleeperOriginBlockId,
        reasonForAdjustment: this.state.reasonForAdjustment,
        isThirdParty: this.state.isThirdParty
      }

      if (this.props.modalProps.type === BLOCK) {
        payload.loaderId = this.state.loaderId;
        payload.hasLoadDelay = this.state.hasLoadDelay;
      }

      this.props.updateActivity(payload);
    }
  }
  
  getModalHeight(locationType) {
    if (this.props.isReopened && (locationType === BLOCK || locationType === YARD) && this.state.hasSecondErrorCode) {
      return '640px';
    }
    if (this.props.isReopened && (locationType === BLOCK || locationType === YARD)) {
      return '530px';
    }
    if (this.props.isReopened && (locationType === DRYLAND || locationType === TRANSIT) && this.state.hasSecondErrorCode) {
      return '550px';
    }
    if (this.props.isReopened && (locationType === DRYLAND || locationType === TRANSIT)) {
      return '460px';
    }
    if (!this.props.isReopened && (locationType === BLOCK || locationType === YARD) && this.state.hasSecondErrorCode) {
      return '570px';
    }
    if (!this.props.isReopened && (locationType === BLOCK || locationType === YARD)) {
      return '500px';
    }
    if (!this.props.isReopened && (locationType === DRYLAND || locationType === TRANSIT) && this.state.hasSecondErrorCode) {
      return '450px';
    }
    if (!this.props.isReopened && (locationType === DRYLAND || locationType === TRANSIT)) {
      return '360px';
    }
    return '450px';
  }

  getLoaderEditSection(props) {
    if (props.type === BLOCK) {
      return <div className='loader-section'>
      <div>Select Loader</div>
        <LoaderPicker blockId={props.locationId} 
          handleChangeLoader={this.handleChangeLoader}
          selectedLoaderId={props.loaderId}/>
      </div>
    } else {
      return null;
    }
  }

  getErrorCodeEditSection(props) {
    return (
      <ErrorCodePicker 
        handleChangeErrorCode = {this.handleChangeErrorCode}
        errorCodeId = {props.errorCodeId}
        payOptions = {props.payOptions}/>
    );
  }

  getSecondErrorCodeEditSection(props) {
    if (this.state.hasSecondErrorCode) {
      return (
        <ErrorCodePicker 
          handleChangeErrorCode = {this.handleChangeSecondErrorCode}
          errorCodeId = {props.secondErrorCodeId}
          payOptions = {props.secondPayOption}/>
      );
    }
  }

  getBlockLoadTimeDelay(props) {
    if (props.type === BLOCK) {
      return <div className='load-time-delay-checker'>
        <input type='checkbox' onChange={this.handleCheckLoadDelay} checked={this.state.hasLoadDelay}  />
        <label>Load Time Delay</label>
      </div>
    } else {
      return null;
    }
  }

  getThirdPartyCheck(props) {
    if (props.type === BLOCK) {
      return <div className='block-third-party-check'>
        <input type='checkbox' onChange={this.handleCheckIsThirdParty} checked={this.state.isThirdParty} />
        <label>Third Party</label>
      </div>
    }
    else {
      return null;
    }
  }

  getYardSleeperEditSection(type, id) {
    if (type === YARD) {
      return <YardSleeperEditor yardId={id}
        sleeperDestinationId={this.state.sleeperDestinationId}
        handleSleeperDestinationChange={this.handleSleeperDestinationChange}
      />;
    } else {
      return null;
    }
  }

  getReasonForAdjustmentSection() {
    if (this.props.isReopened) {
      return (
      <div className='reasonForAdjustment-section'>
        <div>Reason for adjustment:</div>
          <textarea className='comment-field' placeholder='Please enter the reason for adjustment to be able to save.' rows='4' cols='50' value={this.state.reasonForAdjustment} onChange={this.handleReasonForAdjustmentChange} />
      </div>
      )}
  }

  getSecondDelaySection() {
    if (this.state.hasSecondErrorCode) {
      return (
        <div style={{marginTop: 10}}>
        <div className='delay-label' >Second Delay (minutes):</div>
        <input type='number' min="0" value={this.state.secondDelay} onChange={this.handleSecondDelayChange} />
        </div>
      )
    }
  }

  render() {
    const closeModal = () => {
      this.props.closeLocationScheduleModal();
      this.saveChanges();
    }

    const props = this.props.modalProps;

    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          height: this.getModalHeight(props.type),
          width: '400px',
          display: 'flex',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
      >
        <div className='location-schedule-comment-modal'>
          <div className='display-name'>{props.displayName}</div>
          <div className='property-labels'>
            <div className='truck-name'>Truck: {props.truckName}</div>
            <div className='start-time'>Start Time: {props.startTime}</div>
            <div className='end-time'>End Time: {props.endTime}</div>
          </div>
          {this.getThirdPartyCheck(props)}
          <div className='delay-section'>
            <div className='delay-label'>Delay (minutes):</div>
            <input type='number' value={this.state.delay} onChange={this.handleDelayChange} />
            {this.getBlockLoadTimeDelay(props)}
            {this.getErrorCodeEditSection(props)}
            <div style={{marginTop: 10}}>
            <input type='checkbox' checked={this.state.hasSecondErrorCode} onChange={this.handleCheckSecondErrorCode} />
            <label>Has Second Error Code?</label>
            </div>
            {this.getSecondDelaySection()}
            {this.getSecondErrorCodeEditSection(props)}
            {this.getYardSleeperEditSection(props.type, props.locationId)}
          </div>
          {this.getLoaderEditSection(props)}
          <div className='comment-section'>
            <div className='comment-label'>Comments:</div>
            <textarea className='comment-field' rows='4' cols='50' value={this.state.comments} onChange={this.handleCommentChange} />
          </div>
          {this.getReasonForAdjustmentSection()} 
          <button className='cancel' onClick={() => this.props.closeLocationScheduleModal()}>Cancel</button>
          <button className='done' disabled={this.props.isReopened && this.state.reasonForAdjustment === ""} onClick={closeModal}>Done</button>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scheduleRowState: state.scheduleRowState,
    isReopened: state.scenarioState.selectedScenario.isReopened
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeLocationScheduleModal: () => {
      dispatch(closeLocationScheduleModal());
    },
    updateActivity: (updatePayload) => {
      dispatch(updateActivity(updatePayload));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationScheduleEditActivityModal);