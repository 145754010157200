import axios from 'axios';
import store from '../store';
import { setDrivers } from '../reducers/drivers';
import { getAuthService } from '../services/AuthService'


class DriverService {
  static getDriversApi() {
    return axios.get('/api/drivers', { headers: getAuthService().header });
  }

  static getDrivers() {
    return this.getDriversApi()
      .then((drivers) => {
        store.dispatch(setDrivers(drivers.data))
      })
      .catch(error => console.log(error));
  }
}



export default DriverService;