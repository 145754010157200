import { UPDATE_BLOCK_LOCATION, UPDATE_YARD } from "../reducers/locations";
import { EDIT_TRUCK } from "../reducers/trucks";
import lodash_has from 'lodash/has';
import { emptyUuid } from "../utils/uuidHelper";

function addOperatingUnitId(opUnitAssign, action) {
  const id = getId(action);
  if (id !== emptyUuid) {
    const assign = opUnitAssign.find(opUnit => opUnit.configObjectId === id);
    if (assign) {
      action.payload.operatingUnitId = assign.operatingUnitId;
    }
  }

  return action;
}

function getId(action) {
  if (action.type === UPDATE_BLOCK_LOCATION) {
    return action.payload.blockId;
  } else if (action.type === UPDATE_YARD) {
    return action.payload.id;
  } else if (action.type === EDIT_TRUCK) {
    return action.payload.truckId;
  } else {
    return emptyUuid;
  }
}

export const addOperatingUnitIdOnUpdate = store => next => action => {

  const updateActions = [UPDATE_BLOCK_LOCATION, UPDATE_YARD, EDIT_TRUCK];
  const hasOpUnitUpdate = updateActions.indexOf(action.type) >= 0;
  const hasOpUnitId = lodash_has(action.payload, 'operatingUnitId');

  if (!hasOpUnitUpdate || (hasOpUnitUpdate && hasOpUnitId)) {
    next(action);
  } else {
    const opUnitAssign = store.getState().operatingUnits.operatingUnitAssignment;
    const updatedAction = addOperatingUnitId(opUnitAssign, action);
    next(updatedAction);
  }
}