import { setProcessors } from '../reducers/processors';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class ProcessorService {
  static getProcessorsApi() {
    return axios.get('/api/processors', { headers: getAuthService().header });
  }

  static getProcessors() {
    return this.getProcessorsApi()
      .then((processor) => {
        store.dispatch(setProcessors(processor.data))
      })
      .catch(error => console.log(error));
  }
}

export default ProcessorService;