import { openPdfPreviewModal } from "../reducers/modalWindows";
import store from '../store';

class PdfService {

  static isDevelopmentMode() {
    const host = window.location.host;
    return host === 'localhost:3000';
  }

  static openPreviewModal(previewUrl) {
    const tempAnchor = document.createElement('a');
    tempAnchor.href = window.location.origin;
    if (this.isDevelopmentMode()) {
      tempAnchor.port = '3001';
    }
    const completePreviewUrl = tempAnchor.href + previewUrl;
    store.dispatch(openPdfPreviewModal(completePreviewUrl));
  }

  static previewBlockPdf(scenarioId, blockId) {
    const previewUrl = `api/previewSchedule/blocks?scenarioId=${scenarioId}&blockId=${blockId}`;
    this.openPreviewModal(previewUrl);
  }

  static previewScheduleDeliveryPdf(scenarioId) {
    const previewUrl = `api/previewSchedule/scheduleDelivery?scenarioId=${scenarioId}`;
    this.openPreviewModal(previewUrl);
  }

  static previewDestinationPdf(scenarioId, destinationId) {
    const previewUrl = `api/previewSchedule/destinations?scenarioId=${scenarioId}&destinationId=${destinationId}`;
    this.openPreviewModal(previewUrl);
  }

  static previewTruckPdf(scenarioId, scenarioTruckId) {
    const previewUrl = `api/previewSchedule/trucks?scenarioId=${scenarioId}&truckId=${scenarioTruckId}`;
    this.openPreviewModal(previewUrl);
  }

  static previewAll(scenarioId) {
    const previewUrl = `api/previewSchedule/previewAll?scenarioId=${scenarioId}`;
    this.openPreviewModal(previewUrl);
  }

  static previewStartReport(scenarioId) {
    const previewUrl = `api/previewSchedule/previewStartReport?scenarioId=${scenarioId}`;
    this.openPreviewModal(previewUrl);
  }

  static downloadBlockPdf(scenarioId, blockId) {
    const downloadUrl = `api/previewSchedule/blockDownload?scenarioId=${scenarioId}&blockId=${blockId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadDestinationPdf(scenarioId, destinationId) {
    const downloadUrl = `api/previewSchedule/destinationDownload?scenarioId=${scenarioId}&destinationId=${destinationId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadTruckPdf(scenarioId, scenarioTruckId) {
    const downloadUrl = `api/previewSchedule/truckDownload?scenarioId=${scenarioId}&truckId=${scenarioTruckId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadCombinedPdf(scenarioId) {
    const downloadUrl = `api/previewSchedule/combinedDownload?scenarioId=${scenarioId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadStartReportPdf(scenarioId) {
    const downloadUrl = `api/previewSchedule/startReportDownload?scenarioId=${scenarioId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadScheduleDeliveryReportPdf(scenarioId) {
    const downloadUrl = `api/previewSchedule/scheduleDeliveryReportDownload?scenarioId=${scenarioId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadTimeCards(scenarioId) {
    const downloadUrl = `api/previewSchedule/timeCardsDownload?scenarioId=${scenarioId}`;
    this.openPreviewModal(downloadUrl);
  }

  static downloadRollup(fromDate, toDate, truckCompanyId) {
    const downloadUrl = `api/previewSchedule/rollupDownload?fromDate=${fromDate}&toDate=${toDate}&truckCompanyId=${truckCompanyId}`;
    this.openPreviewModal(downloadUrl);
  }
}


export default PdfService;