import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ADD_OPERATOR, setOperators, REMOVE_OPERATOR, UPDATE_OPERATOR, BULK_ACTIVATE_OPERATORS, addOperatorSuccess, removeOperatorSuccess, updateOperatorSuccess } from '../../reducers/operators';
import { success, failure } from '../notificationMessages';
import { combineEpics } from 'redux-observable';
import { getAuthService } from '../AuthService';


export const addOperatorEpic = action$ => (
  action$.ofType(ADD_OPERATOR)
    .mergeMap(action => (
      ajax.post('/api/operators', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addOperatorSuccess(ajaxResponse.response), 'Successfully added new operator'))
        .catch(fail => Observable.of(failure('Could not add new operator')))
    ))
);

export const removeOperatorEpic = action$ => (
  action$.ofType(REMOVE_OPERATOR)
    .mergeMap(action => (
      ajax.delete(`/api/operators/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeOperatorSuccess(action.payload), 'Successfully removed operator'))
        .catch(fail => Observable.of(failure('Could not remove operator')))
    ))
);

export const updateOperatorEpic = action$ => (
  action$.ofType(UPDATE_OPERATOR)
    .mergeMap(action => (
      ajax.put('/api/operators', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateOperatorSuccess(ajaxResponse.response), 'Successfully updated operator'))
        .catch(fail => Observable.of(failure('Could not update operator')))
    ))
);

export const bulkActivateOperatorsEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_OPERATORS)
    .mergeMap(action => (
      ajax.post('/api/operators/bulkActivate', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setOperators(ajaxResponse.response), 'Successfully updated operators'))
        .catch(fail => Observable.of(failure('Could not update operators')))
    ))
);

export const operatorEpics = combineEpics(
  addOperatorEpic,
  removeOperatorEpic,
  updateOperatorEpic,
  bulkActivateOperatorsEpic
);