import React from 'react';
import { connect } from 'react-redux';
import { ANY } from '../../constants/locations';
import includes from 'lodash/includes';
import find from 'lodash/find';
import last from 'lodash/last';
import ConfigComment from '../../containers/configuration/ConfigComment';
import YardTimeEditor from './YardTimeEditor';
import { toast } from 'react-toastify';
import { isInactiveStyle } from '../../utils/inactiveStyleHelper';
import OperatingUnitPicker from '../OperatingUnitPicker';
import LoaderOperatorHelper from '../../utils/loaderOperatorHelper';
import { LEVEL1, LEVEL2, LEVEL3 } from '../../constants/driverBlockRankings';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { ISL, TW } from '../../constants/destinationCompany';

class BlockFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const blockFieldProps = props.blockFieldProps? props.blockFieldProps: {};
    const defaultBlockId = blockFieldProps.blockId? blockFieldProps.blockId: '';
    const defaultBlockName = blockFieldProps.name? blockFieldProps.name: '';
    const defaultRoadName = blockFieldProps.roadName? blockFieldProps.roadName: '';
    const defaultEmail = blockFieldProps.email? blockFieldProps.email: '';
    const defaultOperatingUnit = blockFieldProps.operatingUnit? blockFieldProps.operatingUnit: '';
    const defaultDestinations = blockFieldProps.destinations? blockFieldProps.destinations: [];
    const defaultDestinationValues = blockFieldProps.destinationValues? blockFieldProps.destinationValues: [];
    const defaultActive = blockFieldProps.active? blockFieldProps.active: false;
    const defaultHammermark = blockFieldProps.selectedHammerMarks? blockFieldProps.selectedHammerMarks: [];
    const defaultPreloadTime = blockFieldProps.preloadTime? blockFieldProps.preloadTime: 10;
    const defaultLoadTime = blockFieldProps.loadTime? blockFieldProps.loadTime: 10;
    const defaultPostloadTime = blockFieldProps.postloadTime? blockFieldProps.postloadTime: 10;
    const defaultContractor = blockFieldProps.selectedContractors? blockFieldProps.selectedContractors: [];
    const defaultFaller = blockFieldProps.selectedFallers? blockFieldProps.selectedFallers: [];
    const defaultProcessor = blockFieldProps.selectedProcessors? blockFieldProps.selectedProcessors: [];
    const defaultVendor = blockFieldProps.selectedVendors? blockFieldProps.selectedVendors: [];
    const defaultYarder = blockFieldProps.selectedYarders? blockFieldProps.selectedYarders: [];
    const defaultComment = blockFieldProps.comments? blockFieldProps.comments: '';
    const defaultRanking = blockFieldProps.ranking? blockFieldProps.ranking: '';
    const defaultChannel = blockFieldProps.channel? blockFieldProps.channel: '';
    const defaultCompany = blockFieldProps.company? blockFieldProps.company: '';

    this.state = {
      blockId: defaultBlockId,
      name: defaultBlockName,
      roadName: defaultRoadName,
      email: defaultEmail,
      operatingUnit: defaultOperatingUnit,
      destinations: defaultDestinations,
      destinationValues: defaultDestinationValues,
      isModified: false,
      active: defaultActive,
      selectedHammerMarks: defaultHammermark,
      preloadTime: defaultPreloadTime,
      loadTime: defaultLoadTime,
      postloadTime: defaultPostloadTime,
      selectedContractors: defaultContractor,
      selectedFallers: defaultFaller,
      selectedProcessors: defaultProcessor,
      selectedVendors: defaultVendor,
      selectedYarders: defaultYarder,
      comments: defaultComment,
      ranking: defaultRanking,
      channel: defaultChannel,
      company: defaultCompany
    }

    this.loaderOperatorHelper = new LoaderOperatorHelper();

    this.changeBlockName = this.changeBlockName.bind(this);
    this.changeRoadName = this.changeRoadName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeOperatingUnit = this.changeOperatingUnit.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.handleSelectDestination = this.handleSelectDestination.bind(this);
    this.changePreloadTime = this.changePreloadTime.bind(this);
    this.changeLoadTime = this.changeLoadTime.bind(this);
    this.changePostloadTime = this.changePostloadTime.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.handleYardDataUpdate = this.handleYardDataUpdate.bind(this);
    this.handleChangeOperatingUnit = this.handleChangeOperatingUnit.bind(this);
    this.changeRanking = this.changeRanking.bind(this);
    this.changeChannel = this.changeChannel.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this);
    this.handleSelectHammerMark = this.handleSelectHammerMark.bind(this);
    this.handleSelectContractor = this.handleSelectContractor.bind(this);
    this.handleSelectedFaller = this.handleSelectedFaller.bind(this);
    this.handleSelectYarder = this.handleSelectYarder.bind(this);
    this.handleSelectProcessor = this.handleSelectProcessor.bind(this);  
    this.changeCompany = this.changeCompany.bind(this);  
  }

  hasZeroValueOnPicker() {
    const hasZeroValueDestination = this.state.destinationValues.some(destinationValue => (destinationValue.toDestinationTime <= 0));
    
    let hasZeroValueYard = false;
    const yardTimeData = this.state.yardTimeData;
    if (yardTimeData) {
      hasZeroValueYard = yardTimeData.some(yardTime => yardTime.toYardTime <= 0);
    }

    return hasZeroValueDestination || hasZeroValueYard;
  }

  clickDone() {
    const hasZero = this.hasZeroValueOnPicker();
    if (hasZero) {
      toast.error('ERROR: Must enter a valid travel time for yards and destinations');
    } else {
        this.props.clickDone(this.state)
        this.setNotModified();
    }  
  }  

  clickCancel() {
    this.props.clickCancel();
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  // yardTimeData contains a list of objects holding yard Id and time to yard
  handleYardDataUpdate(yardTimeData) {
    this.setState({yardTimeData: yardTimeData});
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeBlockName(event) {
    const truncatedName = event.target.value.substring(0, 8);
    if (truncatedName !== this.state.name) {
      this.setState({
        name: truncatedName
      });
      this.setModified();
    }
  }

  changeRoadName(event) {
    this.setState({
      roadName: event.target.value
    });
    this.setModified();
  }

  changeEmail(event) {
    const email = event.target.value.substring(0, 500);
    this.setState({
      email: email
    });
    this.setModified();
  }

  changeOperatingUnit(event) {
    this.setState({
      operatingUnit: event.target.value
    });
    this.setModified();
  }

  changePreloadTime(event) {
    this.setState({preloadTime: event.target.value});
    this.setModified();
  }

  changeLoadTime(event) {
    this.setState({loadTime: event.target.value});
    this.setModified();
  }

  changePostloadTime(event) {
    this.setState({postloadTime: event.target.value});
    this.setModified();
  }

  changeRanking(event) {
    this.setState({ranking: event.target.value});
    this.setModified();
  }

  changeChannel(event) {
    this.setState({channel: event.target.value});
    this.setModified();
  }

  changeCompany(event) {
    this.setState({company: event.target.value});
    this.setModified();
  }

  getCompanyPicker() {
    return (
      <select className='destination-company-picker' value={this.state.company} onChange={this.changeCompany}>
        <option key='blank' value=''>None</option>
        <option key={ISL} value={ISL}>ISL</option>
        <option key={TW} value={TW}>TW</option>
      </select>
    );
  }

  canAddText(inputString, limit) {
    const lastChar = last(inputString);
    if (lastChar !== ',') {
      const split = inputString.split(',');
      const lastText = last(split).trim();
      if (lastText.length <= limit) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  isDestinationSelected(destinationId) {
    return includes(this.state.destinations, destinationId);
  }

  handleSelectDestination(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newDestinationState = [];
    let destinationValues = [];
    if (isChecked) {
      newDestinationState = [...this.state.destinations, id];
      destinationValues = [...this.state.destinationValues, {destinationId: id, toDestinationTime: 0}]
    } else {
      newDestinationState = this.state.destinations.filter(destinationId => destinationId !== id);
      destinationValues = this.state.destinationValues.filter(destination => (destination.id !== id));
    }

    this.setState({
      destinations: newDestinationState,
      destinationValues: destinationValues
    });
    this.setModified();
  }

  handleDestinationTimeUpdate(event, destinationId) {
    const value = Number(event.target.value);
    const validValue = value < 0? 0: value;
    const updated = this.state.destinationValues.map(destination => {
      if (destination.destinationId === destinationId) {
        return {
          destinationId: destinationId,
          toDestinationTime: validValue
        }
      } else {
        return destination;
      }
    });
    this.setState({
      destinationValues: updated
    });
    this.setModified();
  }

  getDestinationTimeEditor(destinationId) {
    if (this.isDestinationSelected(destinationId)) {
      const targetDestination = find(this.state.destinationValues, {destinationId: destinationId});
      return (
        <input className='time-to-destination' type='number'
          placeholder='Travel time (min)'
          value={targetDestination.toDestinationTime}
          onChange={(event) => this.handleDestinationTimeUpdate(event, destinationId)} />
      );
    }
  }

  getDestinations() {
    return (
      this.props.destinations.filter(d => d.name !== ANY && d.active && !d.isDeleted).map(destination => {
        return (
          <div className='destination-picker-row' key={destination.id}>
            <div className='destination-name' style={isInactiveStyle(destination)}>
              {destination.name}
            </div>
            <input className='destination-checkbox' type='checkbox' 
              value={destination.id}
              onChange={this.handleSelectDestination}
              checked={this.isDestinationSelected(destination.id)} />
            {this.getDestinationTimeEditor(destination.id)}
          </div>
        );
      })
    );
  }

  getHammerMarks() {
    const hammerMarkElements = this.props.hammerMarks
    const sortedHammerMarks = sortBy(hammerMarkElements, hammerMark => hammerMark.name)
      .filter(hammerMark => hammerMark.active && !hammerMark.isDeleted)
      .map(hammerMark => {
        const id = hammerMark.id;
        return (
          <div className='hammerMark-picker-row' key={id}>
            <input className='hammerMark-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectHammerMark}
            checked={this.isHammerMarkSelected(id)}
          />
          <div className='hammerMark-name'>
            {hammerMark.name}
          </div>
        </div>
      );
    });
    if (sortedHammerMarks.length > 0) {
      return sortedHammerMarks;
    } else {
      return <p>No HammerMarks available</p>;
    }
  }


  isHammerMarkSelected(id) {
    return includes(this.state.selectedHammerMarks, id);
  }

  handleSelectHammerMark(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedHammerMarks, id];
    } else {
      newSelected = this.state.selectedHammerMarks.filter(hammerMarkId => hammerMarkId !== id);
    }

    this.setState({
      selectedHammerMarks: newSelected
    });
    this.setModified();
  }

  getContractors() {
    const contractorElements = this.props.contractors;
    const sortedContractors = sortBy(contractorElements, contractor => contractor.name)
      .filter(contractor => contractor.active && !contractor.isDeleted)
      .map(contractor => {
        const id = contractor.id;
        return (
          <div className='contractor-picker-row' key={id}>
            <input className='contractor-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectContractor}
            checked={this.isContractorSelected(id)}
          />
          <div className='contractor-name'>
            {contractor.name}
          </div>
        </div>
      );
    });
    if (sortedContractors.length > 0) {
      return sortedContractors;
    } else {
      return <p>No Contractors available</p>;
    }
  }


  isContractorSelected(id) {
    return includes(this.state.selectedContractors, id);
  }

  handleSelectContractor(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedContractors, id];
    } else {
      newSelected = this.state.selectedContractors.filter(contractorId => contractorId !== id);
    }

    this.setState({
      selectedContractors: newSelected
    });
    this.setModified();
  }

  getVendors() {
    const vendorElements = this.props.vendors;
    const sortedVendors = sortBy(vendorElements, vendor => vendor.name)
      .filter(vendor => vendor.active && !vendor.isDeleted)
      .map(vendor => {
        const id = vendor.id;
        return (
          <div className='vendor-picker-row' key={id}>
            <input className='vendor-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectVendor}
            checked={this.isVendorSelected(id)}
          />
          <div className='vendor-name'>
            {vendor.name}
          </div>
        </div>
      );
    });
    if (sortedVendors.length > 0) {
      return sortedVendors;
    } else {
      return <p>No Vendors available</p>;
    }
  }

  isVendorSelected(id) {
    return includes(this.state.selectedVendors, id);
  }

  handleSelectVendor(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedVendors, id];
    } else {
      newSelected = this.state.selectedVendors.filter(vendorId => vendorId !== id);
    }

    this.setState({
      selectedVendors: newSelected
    });
    this.setModified();
  }

  getFallers() {
    const fallerElements = this.props.fallers;
    const sortedFallers = sortBy(fallerElements, faller => faller.name)
      .filter(faller => faller.active && !faller.isDeleted)
      .map(faller => {
        const id = faller.id;
        return (
          <div className='faller-picker-row' key={id}>
            <input className='faller-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectedFaller}
            checked={this.isFallerSelected(id)}
          />
          <div className='faller-name'>
            {faller.name}
          </div>
        </div>
      );
    });
    if (sortedFallers.length > 0) {
      return sortedFallers;
    } else {
      return <p>No Fallers available</p>;
    }
  }


  isFallerSelected(id) {
    return includes(this.state.selectedFallers, id);
  }

  handleSelectedFaller(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedFallers, id];
    } else {
      newSelected = this.state.selectedFallers.filter(fallerId => fallerId !== id);
    }

    this.setState({
      selectedFallers: newSelected
    });
    this.setModified();
  }

  getYarders() {
    const yarderElements = this.props.yarders;
    const sortedYarders = sortBy(yarderElements, yarder => yarder.name)
      .filter(yarder => yarder.active && !yarder.isDeleted)
      .map(yarder => {
        const id = yarder.id;
        return (
          <div className='yarder-picker-row' key={id}>
            <input className='yarder-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectYarder}
            checked={this.isYarderSelected(id)}
          />
          <div className='yarder-name'>
            {yarder.name}
          </div>
        </div>
      );
    });
    if (sortedYarders.length > 0) {
      return sortedYarders;
    } else {
      return <p>No Yarders available</p>;
    }
  }


  isYarderSelected(id) {
    return includes(this.state.selectedYarders, id);
  }

  handleSelectYarder(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedYarders, id];
    } else {
      newSelected = this.state.selectedYarders.filter(yarderId => yarderId !== id);
    }
    this.setState({
      selectedYarders: newSelected
    });
    this.setModified();
  }
  getProcessors() {
    const processorElements = this.props.processors;
    const sortedProcessors = sortBy(processorElements, processor => processor.name)
      .filter(processor => processor.active && !processor.isDeleted)
      .map(processor => {
        const id = processor.id;
        return (
          <div className='processor-picker-row' key={id}>
            <input className='processor-checkbox' type='checkbox'
            value={id}
            onChange={this.handleSelectProcessor}
            checked={this.isProcessorSelected(id)}
          />
          <div className='processor-name'>
            {processor.name}
          </div>
        </div>
      );
    });
    if (sortedProcessors.length > 0) {
      return sortedProcessors;
    } else {
      return <p>No Processors available</p>;
    }
  }


  isProcessorSelected(id) {
    return includes(this.state.selectedProcessors, id);
  }

  handleSelectProcessor(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let newSelected = [];
    if (isChecked) {
      newSelected = [...this.state.selectedProcessors, id];
    } else {
      newSelected = this.state.selectedProcessors.filter(processorId => processorId !== id);
    }

    this.setState({
      selectedProcessors: newSelected
    });
    this.setModified();
  }

  getRankingPicker() {
    return (
      <select className='block-ranking-picker' value={this.state.ranking} onChange={this.changeRanking}>
        <option key='blank' value={0}>None</option>
        <option key={LEVEL1} value={LEVEL1}>Level 1</option>
        <option key={LEVEL2} value={LEVEL2}>Level 2</option>
        <option key={LEVEL3} value={LEVEL3}>Level 3</option>
      </select>
    );
  }

  handleChangeOperatingUnit(operatingUnitId, setModified = false) {
    this.setState({operatingUnitId: operatingUnitId});
    if (setModified) {
      this.setModified();
    }
  }

  getAssignedLoaders() {
    const loaderOperatorHelper = new LoaderOperatorHelper();
    const operators = loaderOperatorHelper.getOperatorsByBlockId(this.state.blockId);
    if (isEmpty(operators)) {
      return (<li key={'none'} style={{color: 'red'}}>N/A</li>);
    } else {
      const operatorElements = operators.map(operator => {
        const assignment = find(this.props.loaderOperatorAssignment, {loaderOperatorId: operator.id});
        const loader = loaderOperatorHelper.getLoader(assignment.loaderId);
        const loaderName = loader? loader.name: 'No loader';
        return <li key={operator.id}>{operator.name} - {loaderName}</li>
      });
      return operatorElements;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='email-fields block-fields'>
          <div>Email</div>
          <textarea rows='4' cols='55' value={this.state.email} onChange={this.changeEmail}
            placeholder='Enter each email address separated by a comma'
          />
        </div>
        <OperatingUnitPicker objectId={this.state.blockId}
          onChangeOperatingUnit={this.handleChangeOperatingUnit} />
        <div className ='ranking-field block-fields'>
          <div>Block Ranking</div>
          {this.getRankingPicker()}
        </div>
        <div className='channel-field block-fields'>
          <div>Block Channel</div>
          <input className='block-field-block-channel-input' type='text' value={this.state.channel} onChange={this.changeChannel}/>
        </div>
        <div className='name-fields block-fields'>
          <div>Setting Number</div>
          <input className='block-field-setting-number-input' type='text' value={this.state.name} onChange={this.changeBlockName}/>
        </div>
        <div className='road-name-fields block-fields'>
          <div>Road Name</div>
          <input className='block-field-road-name-input'type='text' value={this.state.roadName} onChange={this.changeRoadName}/>
        </div>
        <div className='preload-time block-fields'>
          <div>Preload Time</div>
          <input className='block-field-preload-time-input' type='number' value={this.state.preloadTime} onChange={this.changePreloadTime} />
        </div>
        <div className='load-time block-fields'>
          <div>Load Time</div>
          <input className='block-field-load-time-input' type='number' value={this.state.loadTime} onChange={this.changeLoadTime} />
        </div>
        <div className='postload-time block-fields'>
          <div>Postload Time</div>
          <input className='block-field-postload-time-input' type='number' value={this.state.postloadTime} onChange={this.changePostloadTime} />
        </div>
        <div className='postload-time block-fields'>
          <div className='destination-input-label'>Company</div>
          {this.getCompanyPicker()}
        </div>
        <div style={{marginTop: 20}}>
          Assigned Loaders:
        </div>
          <ul className='loader-operator-list'>
            {this.getAssignedLoaders()}
          </ul>
        <div className='hammerMarks-and-processors-block-field'>
        <div className='hammerMark-picker block-fields'>
          <div>Hammer Marks</div>
          {this.getHammerMarks()}
        </div>
        <div className='processor-picker block-fields'>
          <div>Processors</div>
          {this.getProcessors()}   
        </div>
        </div>
        <div className='yarders-and-fallers-block-field'>
        <div className='yarder-picker block-fields'>
          <div>Yarders</div>
          {this.getYarders()}
        </div>
        <div className='faller-picker block-fields'>
          <div>Fallers</div>
          {this.getFallers()}
        </div>
        </div>
        <div className='contractors-and-vendors-block-field'>
        <div className='contractor-picker block-fields'>
          <div>Contractors</div>
          {this.getContractors()}
        </div>
        <div className='vendor-picker block-fields'>
          <div>Vendors</div>
          {this.getVendors()}
        </div>
        </div>
        <div className='yardTimeEditor-and-destination-picker-block-field'>
        <YardTimeEditor setModified={() => this.setModified()} 
          locationId={this.state.blockId}
          handleYardDataUpdate={this.handleYardDataUpdate}
        />
        <div className='destination-picker block-fields'>
          <div className='destination-picker-label'>Travel Time To Destinations (min)</div>
          {this.getDestinations()}
        </div>
        </div>
        <ConfigComment key={this.state.blockId}
          top={300}
          left={705}
          comments={this.state.comments} 
          updateComments={this.changeComments}
        />
        <div className='block-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destinations: state.locations.destinations,
    loaders: state.loaders.loaderList,
    vendors: state.vendors.vendorsList,
    hammerMarks: state.hammerMarks.hammerMarksList,
    contractors: state.contractors.contractorsList,
    fallers: state.fallers.fallersList,
    yarders: state.yarders.yardersList,
    processors: state.processors.processorsList,
    loaderOperatorAssignment: state.loaderOperatorAssignment.loaderOperatorAssignmentList
  }
}

export default connect(mapStateToProps)(BlockFieldEditor);
