import React from 'react';
import { connect } from 'react-redux';
import BlockFieldEditor from '../../components/fieldEditors/BlockFieldEditor';
import find from 'lodash/find';
import { updateBlockLocation, addNewBlockLocation, bulkActivateBlocks } from '../../reducers/locations';
import { openRemoveObjectModal, openBulkActivationModal, openBlockHistoryModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import uniqid from 'uniqid';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { BULK_ACTIVATION_MODAL, BLOCK_HISTORY_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import Select, { createFilter } from 'react-select';
import { getYardTimes } from '../../utils/yardTimeHelper';
import { BLOCK } from '../../constants/typeConstants';
import BlockHistoryModal from '../../components/modals/BlockHistoryModal';
import { bulkAddDriversToHistory } from '../../reducers/driversToBlocksHistory';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class BlocksConfiguration extends React.Component {
  
  static defaultProps = { // <-- DEFAULT PROPS
    blockVendors: [],
    blockHammerMarks: [] ,
    blockContractors: [] ,
    blockFallers: [],
    blockYarders: [],
    blockProcessors: []   // undefined gets converted to array,render won't trigger error
}
  constructor(props) {
    super(props);

    this.state = {
      blockSelected: '',
      isAddingNewBlock: false,
    }

    this.addNewBlock = this.addNewBlock.bind(this);
    this.removeBlock = this.removeBlock.bind(this);
    this.getBlockHistory = this.getBlockHistory.bind(this);
  }

  componentDidUpdate(previousProps) {
    const previousBlocks = previousProps.blocks.map(b => b.id);
    const currentBlocks = this.props.blocks.map(b => b.id);
    if (currentBlocks.length > previousBlocks.length) {
      const diff = difference(currentBlocks, previousBlocks);
      if (diff.length > 0) {
        const newBlock = diff[0];
        this.setState({
          blockSelected: newBlock,
          isAddingNewBlock: false,
        });
      }
    }
  }


  addNewBlock() {
    this.setState({
      blockSelected: '',
      isAddingNewBlock: true
    });
  }

  removeBlock() {
    this.props.removeBlockModal(this.state.blockSelected);
  }

  cancelSelection() {
    this.setState({
      blockSelected: '',
      isAddingNewBlock: false
    });
  }

  getSelectedBlock() {
    const block = find(this.props.blocks, {id: this.state.blockSelected});
    return block? block: {};
  }

  getSelectedBlockName() {
    const block = this.getSelectedBlock();
    if (block.name) {
      return block.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedBlock() {
    return this.state.blockSelected !== '';
  }

  getProps() {
    const block = this.getSelectedBlock();
    if (block) {
      const destinationValues = this.props.blocksToDestination
        .filter(blockToDestination => blockToDestination.blockId === block.id)
        .map(blockToDestination => {
          return {
            destinationId: blockToDestination.destinationId,
            toDestinationTime: blockToDestination.toDestinationTime
          }
      });
      const destinations = destinationValues.map(destination => destination.destinationId);

      const selectedVendors = this.props.blockVendors
        .filter(blockVendor => blockVendor.blockId === this.state.blockSelected)
        .map(blockVendor => blockVendor.vendorId);

      const selectedHammerMarks = this.props.blockHammerMarks
        .filter(blockHammerMark => blockHammerMark.blockId === this.state.blockSelected)
        .map(blockHammerMark => blockHammerMark.hammerMarkId);

      const selectedContractors = this.props.blockContractors
        .filter(blockContractor => blockContractor.blockId === this.state.blockSelected)
        .map(blockContractor => blockContractor.contractorId);

      const selectedFallers = this.props.blockFallers
        .filter(blockFaller => blockFaller.blockId === this.state.blockSelected)
        .map(blockFaller => blockFaller.fallerId);

      const selectedYarders = this.props.blockYarders 
        .filter(blockYarder => blockYarder.blockId === this.state.blockSelected)
        .map(blockYarder => blockYarder.yarderId);

      const selectedProcessors = this.props.blockProcessors
        .filter(blockProcessor => blockProcessor.blockId === this.state.blockSelected)
        .map(blockProcessor => blockProcessor.processorId);

      const yardTimes = getYardTimes(this.state.blockSelected);

      return {
        blockId: block.id,
        name: block.name,
        toYardTime: block.toYardTime,
        roadName: block.roadName,
        email: block.email,
        destinations: destinations,
        destinationValues: destinationValues,
        active: block.active,
        selectedHammerMarks: selectedHammerMarks,
        preloadTime: block.preloadTime,
        loadTime: block.loadTime,
        postloadTime: block.postloadTime,
        selectedContractors: selectedContractors,
        selectedFallers: selectedFallers,
        selectedProcessors: selectedProcessors,
        selectedVendors: selectedVendors,
        selectedYarders: selectedYarders,
        comments: block.comments,
        yardTimeData: yardTimes,
        ranking: block.ranking,
        channel: block.channel,
        company: block.company
      }
    } else {
      return {};
    }
  }

  selectBlock(id) {
    this.setState({
      blockSelected: id,
      isAddingNewBlock: false
    });
  }

  getBlockButtonStyle(id) {
    if (id === this.state.blockSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getBlockList() {
    const options = this.props.blocks
    .filter(block => !block.isDeleted)
    .map(block => {
      return {
        value: block.id,
        label: block.name
      }
    });

    const handleChange = (selectedOption) => this.selectBlock(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedBlockName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options} 
      onChange={handleChange} 
    />;
  }

  checkExistence(state) {
    if (this.state.isAddingNewBlock) {
      const block = find(this.props.blocks, {name: state.name});
      if (block) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewBlock) {
      if (this.getSelectedBlockName() !== state.name) {
        const block = find(this.props.blocks, {name: state.name});
        if (block) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getBlockEditPanel() {
    if (this.hasSelectedBlock() && !this.state.isAddingNewBlock) {
      return (
        <div>
          <BlockFieldEditor 
            key={this.state.blockSelected}
            blockFieldProps={this.getProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.cancelSelection()}
          />
        </div>
      );
    } else if (this.state.isAddingNewBlock) {
      return (
        <div>
          <BlockFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.cancelSelection()}
          />
        </div>
      );
    }
  }

  getRemoveBlockButton() {
    if (this.hasSelectedBlock()) {
      return <button className='remove-block' onClick={this.removeBlock}>Remove Block</button>;
    }
  }

  getBlockHistory() {
    if (this.hasSelectedBlock()) {
      const block = find(this.props.blocks, {id: this.state.blockSelected});
      const blockHistory = this.props.history.filter(history => history.blockId === block.id);
      const driverIds = blockHistory.map(history => history.driverId);
      const names = []
      for (var i=0; i < driverIds.length; i++) {
        const a =  find(this.props.drivers,{driverId: driverIds[i]});
        if (a) {
        names.push(a.name)
        }
      }
      return driverIds;
      
    }    
  }    

  activate() {
    const block = this.getProps();
    if (block) {
      block.active = !block.active;
      this.props.update(block);
    }
  }

  getActiveButton() {
    const block = this.getSelectedBlock();
    if (block && block.id) {
      const buttonName = block.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    } 
  }

  getBlockHistoryModal() {
    const modal = this.props.modalWindow;
    if (modal.modalType === BLOCK_HISTORY_MODAL && modal.isModalOpen) {
      
        return <BlockHistoryModal
          driverIds={this.getBlockHistory()}
          drivers={this.props.drivers}
          onClickDone={(selectedDrivers) => this.props.bulkAddDriversToHistory(selectedDrivers, this.state.blockSelected)}
          />
      } else {
        return null;
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      return <BulkActivationModal
        type={BLOCK}
        activationItems={this.props.blocks.filter(block => !block.isDeleted)}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getPrompt() {
    const block = this.getSelectedBlock();
    if (block.name) {
      return `Edit block ${block.name}`;
    } else if (this.state.isAddingNewBlock) {
      return 'Add a new block';
    } else {
      return 'Select a block to edit';
    }
  }

  render() {
    return (
      <div className='blocks-configuration configuration-forms'>
        <button className='add-new-block' onClick={this.addNewBlock}>Add New Block</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal(this.props.blocks)}>
          Bulk Activation
        </button>
        <button className='block-history-button'
          onClick={() => this.props.openBlockHistoryModal(this.getBlockHistory())}
          disabled={!this.hasSelectedBlock()}>
          Prework
        </button>
        <div className='block-editor'>
          <p>{this.getPrompt()}</p>
          <div className='block-list'>
            {this.getBlockList()}
          </div>
          <div className='block-edit-panel'>
            {this.getBlockEditPanel()}
            <div className='side-panel'>
              <div className='block-config-buttons'>
                {this.getActiveButton()}
                {this.getRemoveBlockButton()}
                
              </div>
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.cancelSelection()}/>
        {this.getBulkActivationModal()}
        {this.getBlockHistoryModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    blocksToDestination: state.locations.blocksToDestinations,
    modalWindow: state.modalWindows,
    loaders: state.loaders.loaderList,
    history: state.driversToBlocksHistory.driversToBlocksHistoryList,
    drivers: state.drivers.driverList,
    blockVendors: state.blockProperties.blockVendorsList,
    blockHammerMarks: state.blockProperties.blockHammerMarksList,
    blockContractors: state.blockProperties.blockContractorsList,
    blockFallers: state.blockProperties.blockFallersList,
    blockYarders: state.blockProperties.blockYardersList,
    blockProcessors: state.blockProperties.blockProcessorsList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (state) => {
      dispatch(updateBlockLocation(state));
    },
    add: (state) => {
      dispatch(addNewBlockLocation(state));
    },
    removeBlockModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: () => {
      dispatch(openBulkActivationModal());
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateBlocks(ids));
    },
    openBlockHistoryModal: (driverIds) => {
      dispatch(openBlockHistoryModal(driverIds));
    },
    bulkAddDriversToHistory: (driverIds, blockId) => {
      dispatch(bulkAddDriversToHistory(driverIds, blockId));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlocksConfiguration)
