import React, { Component } from 'react';
import { DESTINATION_MAX_CAPACITY_MODAL } from '../../constants/modalTypes';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import { DRYLAND } from '../../constants/typeConstants';

class DestinationMaxCapacityModal extends Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#root');  
  }

  getDestinationsOnSchedule() {
    return this.props.schedule.flatMap(scheduleRow => {
     const drylands = scheduleRow.schedule.filter(scheduleItem => scheduleItem.type === DRYLAND);
     return drylands.map(dryland => {
       return {
         ...dryland,
         truckName: scheduleRow.truckName
       }
     });
    });
  }

  getMaxCapacity(destination) {
    if (destination.maxCapacity === null) {
      return 'N/A';
    } else {
      return destination.maxCapacity;
    }
  }

  getNumberOfDestination(destinationName) {
    const destList = this.getDestinationsOnSchedule().map(destination => destination.name)
    let count = 0
    for (var i=0; i<destList.length; i++) {
      if (destList[i] === destinationName ) {
        count = count + 1;
      }
    } return count;  
  }



  getText(destination) {
    if (destination.maxCapacity >= this.getNumberOfDestination(destination.name)) {
      return (
        <div>
          <p>With this load, the capacity of the {destination.name} dryland has been met.</p>
          <br></br>
          <p>Max Capacity: {this.getMaxCapacity(destination)}</p>
        </div>
      );
    }else if (destination.maxCapacity < this.getNumberOfDestination(destination.name)) {
      return (
        <div>
          <p>With this load, the capacity of {destination.name} dryland has been exceeded by {this.getNumberOfDestination(destination.name) - destination.maxCapacity } load(s)</p>
          <br></br>
          <p>Max Capacity: {this.getMaxCapacity(destination)}</p>
          <br></br>
          <p>Scheduled: {this.getNumberOfDestination(destination.name)}</p>
        </div>
      )
    }
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DESTINATION_MAX_CAPACITY_MODAL && modalWindow.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '300px',
            width: '385px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
        <div className='destination-max-capacity-modal'>
          {this.getText(modalWindow.destination)}
          <div className='destination-max-capacity-modal-buttons'>
              <button className='done-button' onClick={() => this.props.closeModal()}>Done</button>
          </div>
        </div>
        </ReactModal>
      );
    } else {
      return null;
    } 
  }
}


const mapStateToProps = (state) => {
  return {
  modalWindow: state.modalWindows,
  schedule: state.scheduleRowState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationMaxCapacityModal)
