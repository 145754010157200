import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import SchedulerCanvas from './containers/SchedulerCanvas';
import LocationDraggableSection from './containers/dragAndDrop/LocationDraggableSection';
import TruckDraggableSection from './containers/dragAndDrop/TruckDraggableSection';
import './App.css';
import ScenarioDatePicker from './containers/ScenarioDatePicker';
import ScenarioPicker from './containers/ScenarioPicker';
import Dashboard from './components/dashboards/Dashboard';
import logo from './assets/logo-no-text.png';
import LoadInventoryStatus from './components/LoadInventoryStatus';
import ConfigurationPage from './containers/configuration/ConfigurationPage';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import connect from 'react-redux/lib/connect/connect';
import UserSettings from './containers/UserSettings';
import ViewSelectionSettings from './components/contextMenus/ViewSelectionSettings';
import LoadingScreen from './containers/LoadingScreen';
import OperatingUnitModalSelector from './components/modals/OperatingUnitModalSelector';
import { isViewerRole } from './utils/userHelper';
import packageJson from '../package.json';
import { getAuthService } from './services/AuthService';
import store from './store';
import { hideLoading, showLoading } from "./reducers/loadingScreen";
import { setUser } from "./reducers/users";
import { fetchScenarios } from "./reducers/scenarios";
import { getDateIsoFormat } from "./utils/dateTimeHelpers";
import { changeOperatingUnit } from "./reducers/operatingUnits";
import LocationService from "./services/locationService";
import TruckService from "./services/truckService";
import TrailerService from "./services/trailerService";
import DriverService from "./services/driverService";
import LoaderService from "./services/loaderService";
import ErrorCodeService from './services/errorCodeService';
import DriverToBlockHistoryService from './services/driverToBlockHistoryService';
import OperatingUnitAssignmentService from "./services/operatingUnitAssignmentService";
import TruckShareService from './services/truckShareService';
import VendorService from './services/vendorService';
import HammerMarkService from './services/hammerMarkService';
import ContractorService from './services/contractorService';
import FallerService from './services/fallerService';
import YarderService from './services/yarderService';
import ProcessorService from './services/processorService';
import BlockPropertiesService from './services/blockPropertiesService';
import PaintMarkService from './services/paintMarkService';
import ReportService from './services/reportService';
import { AuthConstants } from './constants/AuthConstants';
import OperatingUnitListService from './services/operatingUnitListService';
import TruckCompanyService from './services/truckCompanyService';

global.appVersion = packageJson.version;

const schedule = 'schedule';
const configuration = 'configuration';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: 'initial',
      currentEditorSelected: schedule
    }

    AuthConstants.stsAuthority = window['runConfig'].stsAuthority;
    AuthConstants.clientId = window['runConfig'].clientId;
    AuthConstants.clientRoot = window['runConfig'].clientRoot;
    AuthConstants.clientScope = window['runConfig'].clientScope;

    this.authService = getAuthService();
    this.clickConfigButton = this.clickConfigButton.bind(this);
    this.clickScheduleButton = this.clickScheduleButton.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: 'true' });
    this.authService.getUser()
    .then(user => {
      if (user) {
        this.authService.setHeader(user);  // Ensure header is set before populate store
        this.populateStore(user);
        this.setState({
          loading: 'false'
        });
      } else {
        this.setState({
          loading: 'no user'
        });
      }

      
    });
  }

  hasNoScenarios() {
    return this.props.currentScenarioId == null;
  }

  getCurrentEditorView() {
    const scheduleSelected = this.state.currentEditorSelected === schedule;
    if (this.hasNoScenarios() && scheduleSelected) {
      return <div className='no-scenarios-available'>No scenarios have been created</div>;
    }
    else if (scheduleSelected) {
      return (
        <React.Fragment>
          <SchedulerCanvas />
          <div className="bottom-panel">
            <div className="draggable-sections">
              <TruckDraggableSection />
              <LocationDraggableSection />
            </div>
            <Dashboard />
          </div>
        </React.Fragment>
      );
    } else {
      return <ConfigurationPage />;
    }
  }


  populateStore(user) {
    store.dispatch(showLoading());
    const token = user.access_token;
    sessionStorage.setItem('token', token);
    user.role = user.profile.role;
    user.name = user.profile.name;
    store.dispatch(setUser(user));
    if (typeof(user.profile.operating_units) === "string") {
      store.dispatch(changeOperatingUnit(user.profile.operating_units.split()));
    }
    else {
      store.dispatch(changeOperatingUnit(user.profile.operating_units));
    }
   
    

    const locationsPromise = LocationService.getLocations();
    const trucksPromise = TruckService.getTrucks();
    const trailersPromise = TrailerService.getTrailers();
    const driversPromise = DriverService.getDrivers();
    const loadersPromise = LoaderService.getLoaders();
    const operatorsPromise = LoaderService.getOperators();
    const operatingUnitAssignmentPromise = OperatingUnitAssignmentService.getOperatingUnits(user.name);
    const errorCodePromise = ErrorCodeService.getErrorCodes();
    const driverToBlockHistoryPromise = DriverToBlockHistoryService.getDriversToBlocksHistory();
    const truckSharePromise = TruckShareService.getTruckShares();
    const vendorPromise = VendorService.getVendors();
    const blockVendorPromise = BlockPropertiesService.getBlockVendors();
    const hammerMarkPromise = HammerMarkService.getHammerMarks();
    const blockHammerMarkPromise = BlockPropertiesService.getBlockHammerMarks();
    const contractorPromise = ContractorService.getContractors();
    const blockContractorPromise = BlockPropertiesService.getBlockContractors();
    const fallerPromise = FallerService.getFallers();
    const blockFallerPromise = BlockPropertiesService.getBlockFallers();
    const yarderPromise = YarderService.getYarders();
    const blockYarderPromise = BlockPropertiesService.getBlockYarders();
    const processorPromise = ProcessorService.getProcessors();
    const blockProcessorPromise = BlockPropertiesService.getBlockProcessors();
    const paintMarkPromise = PaintMarkService.getMarks();
    const reportPromise = ReportService.getReports();
    const loaderOperatorAssignmentPromise = LoaderService.getLoaderOperatorAssignments();
    const truckCompanyPromise = TruckCompanyService.getTruckCompanies();
    let operatingUnitListPromise;
    if (typeof(user.profile.operating_units) === 'string') {
      operatingUnitListPromise = OperatingUnitListService.getUserOperatingUnits(user.profile.operating_units.split());
    }
    else {
      operatingUnitListPromise = OperatingUnitListService.getUserOperatingUnits(user.profile.operating_units);
    }
    
    Promise.all(
      [
        locationsPromise,
        trucksPromise,
        trailersPromise,
        driversPromise,
        loadersPromise,
        operatorsPromise,
        operatingUnitAssignmentPromise,
        errorCodePromise,
        driverToBlockHistoryPromise,
        truckSharePromise,
        vendorPromise,
        blockVendorPromise,
        hammerMarkPromise,
        blockHammerMarkPromise,
        contractorPromise,
        blockContractorPromise,
        fallerPromise,
        blockFallerPromise,
        yarderPromise,
        blockYarderPromise,
        processorPromise,
        blockProcessorPromise,
        paintMarkPromise,
        reportPromise,
        loaderOperatorAssignmentPromise,
        truckCompanyPromise,
        operatingUnitListPromise,
      ]).then(() => {
        store.dispatch(fetchScenarios(getDateIsoFormat()));
      }).then(() => store.dispatch(hideLoading()));
  }


  clickConfigButton() {
    this.setState({currentEditorSelected: configuration});
  }

  clickScheduleButton() {
    this.setState({currentEditorSelected: schedule});
  }


  getScheduleHeaderItems() {
    if (this.state.currentEditorSelected === schedule) {
      return (
        <React.Fragment>
          <ScenarioDatePicker />
          <ScenarioPicker />
          <button className='config-button change-view-button'
            onClick={this.clickConfigButton}
            disabled={isViewerRole()}>Configuration</button>
          <ViewSelectionSettings hasNoScenarios={this.hasNoScenarios()}/>
          <OperatingUnitModalSelector />
          <UserSettings />
          <LoadInventoryStatus />
        </React.Fragment>
      );
    } else {
      return (
        <button className='schedule-button change-view-button' onClick={this.clickScheduleButton}>Schedule</button>
      );
    }
  }

  getApplication() {

      return (
        <div className="truck-scheduler-app">
          <header className="truck-scheduler-header">
            <img className='logo' alt='Island Timberlands Logo' src={logo} />
            <div className='title-info'>
              <h1 className="title">Truck Scheduler</h1>
              <span className='version-label'>Version: {global.appVersion}</span>
            </div>
            {this.getScheduleHeaderItems()}  
          </header>
          <div className="truck-scheduler">
            {this.getCurrentEditorView()}
          </div>
          <ToastContainer 
            transition={Zoom}
            position={toast.POSITION.TOP_CENTER}
          />
        </div>
      );
  }

  getLogin() {
    return (
      <div className="truck-scheduler-app">
        <header className="truck-scheduler-header">
          <img className='logo' alt='Island Timberlands Logo' src={logo} />
          <div className='title-info'>
            <h1 className="title">Truck Scheduler</h1>
            <span className='version-label'>Version: {global.appVersion}</span>
          </div>
        </header>
        <h2>You are not logged in</h2>
        <button onClick={this.login}>Login</button>
      </div>
    );
  }

  login() {
    this.authService.login();
  }


  render() {
    
    if (this.state.loading === 'initial') {
      return <h2>Intializing...</h2>;
    }


    if (this.state.loading === 'true') {
      return <h2>Checking Authentication...</h2>;
    }

    if (this.state.loading === 'no user') {
      return (
        <React.Fragment>
        <LoadingScreen />
        {this.getLogin()}
      </React.Fragment>
      );
    }
    
    return (
      <React.Fragment>
        <LoadingScreen />
        {this.getApplication()}
      </React.Fragment>
    );    
  }
}

const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  const scenarioId = selectedScenario? selectedScenario.id: null;
  return {
    currentScenarioId: scenarioId,
    modalWindow: state.modalWindows,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds
  }
}

export default connect(mapStateToProps)(DragDropContext(HTML5Backend)(App));
