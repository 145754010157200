import React from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import flatMap from 'lodash/flatMap';
import LocationDropTarget from './dragAndDrop/LocationDropTarget';
import { addLocationToSchedule } from '../reducers/schedule';
import LocationSchedule from '../components/LocationSchedule';
import TruckScheduleRowHeader from '../components/TruckScheduleRowHeader';
import WorkDayMarker from '../components/WorkdayMarker';
import { BLOCK, DRYLAND, TRANSIT, YARD } from '../constants/typeConstants';
import { showBlockActivityDashboard, showDrylandActivityDashboard, showTransitActivityDashboard } from '../reducers/dashboard';
import ScenarioTruckContextMenu from '../components/contextMenus/ScenarioTruckContextMenu';
import LocationScheduleContextMenu from '../components/contextMenus/LocationScheduleContextMenu';
import { openAssignLoaderModal, openDriverToBlockRankingModal, openDestinationMaxCapacityModal, openSleeperYardPromptModal, openTraineeDriverModal } from '../reducers/modalWindows';
import { getLoadersAssigned } from '../utils/loaderHelper';
import isNil from 'lodash/isNil';
import { getEndDate } from '../utils/dateTimeHelpers';
import isEqual from 'date-fns/is_equal';
import { isViewerRole } from '../utils/userHelper';
import find from 'lodash/find';
import last from 'lodash/last';



class ScheduleRow extends React.Component {

  clickActivity(activity, clickOnItem = true) {
    if (activity.type === BLOCK) {
      this.props.showBlockActivityDashboard(activity);
    } else if (activity.type === DRYLAND) {
      this.props.showDrylandActivityDashboard(activity);
    } else if (activity.type === TRANSIT) {
      this.props.showTransitActivityDashboard(activity);
    }

    if (clickOnItem) {
      this.props.clickedOnItem(activity.id);
    }
  }

  getTravelTime(location) {
    let travelTime = (location.transit)? location.transit.travelTime : 0;
    return travelTime;
  }

  getLocationScheduleElement(location, index, hasYardPrevious, hasMismatchedTime) {
    const travelTime = this.getTravelTime(location);
    return (
      <LocationScheduleContextMenu
        key={location.id}
        id={location.id}
        name={location.name}
        activity={location}
        rowId={this.props.rowId}
        truckName={this.props.truckName}
        isShared={this.props.isShared}
        scenarioTruck={this.props.scenarioTruck}
        driverId={this.scheduleRowDriver()}
      >
        <LocationSchedule
          key={location.id}
          rowId={this.props.rowId}
          id={location.id}
          locationId={location.locationId}
          name={location.name}
          delay={location.delay}
          secondDelay={location.secondDelay}
          travelTime={travelTime}
          timeAtDestination={location.transit? location.transit.timeAtDestination : 0}
          type={location.type}
          isFirstItem={index === 0}
          isLastItem={index === (this.props.schedule.length - 1)}
          hasYardPrevious={hasYardPrevious}
          index={index}
          clickedItemId={this.props.clickedItemId}
          clickHandler={(clickOnItem) => this.clickActivity(location, clickOnItem)}
          isPublished={this.props.isPublished}
          hasMismatchedTime={hasMismatchedTime}
          isShared={this.props.isShared}
          location={location}
          driverId={this.scheduleRowDriver()} 
          isInTruckShares={this.isInTruckShares()}
          isClosed={this.props.isClosed}
        />
      </LocationScheduleContextMenu>
    );
  }

  hasYardPrevious(schedule, index) {
    const previousLocation = schedule[index - 1];
    if (previousLocation) {
      return previousLocation.type === YARD;
    } else {
      return false;
    }
  }

  hasMismatchedTime(schedule, index) {
    const activity1 = schedule[index];
    const activity2 = schedule[index+1];
    
    if ( (isNil(activity1) || isNil(activity2)) || activity2.isAddingToSchedule) {
      return false;
    } else {
      const activity1EndDate = getEndDate(activity1);
      const activity2StartDate = activity2.dateObjects.locationStartTime;
      return !isEqual(activity1EndDate, activity2StartDate);
    }
  }

  getLocationsForSchedule() {
    const schedule = this.props.schedule;
    return flatMap(schedule, ((location, index) => {
      const hasYardPrevious = this.hasYardPrevious(schedule, index);
      const hasMismatchedTime = this.hasMismatchedTime(schedule, index);
      const locationSchedule = this.getLocationScheduleElement(location, index, hasYardPrevious, hasMismatchedTime);
      return locationSchedule;
    }));
  }

  hasEmptySchedule() {
    return this.props.schedule.length === 0;
  }

  previousLoaderIdAssignedToBlock(blockId) {
    const reversedSchedule = this.props.schedule.slice().reverse();
    const blocks = reversedSchedule.filter(scheduleItem => scheduleItem.locationId === blockId);
    if (blocks.length > 0) {
      return blocks[0].loaderId;
    } else {
      return null;
    }
  }

  scheduleRowDriver() {
    const rowId = this.props.rowId;
      const targetRow = find(this.props.scheduleRowState, {rowId: rowId})
      const rowDriverId = targetRow.scenarioTruck.driverId;
      const targetDriver = find(this.props.drivers, {id: rowDriverId})
      if (targetDriver) {
        return targetDriver.id
      }   
  }

  isFromBlock() {
    const lastScheduled = last(this.props.schedule);
    if (lastScheduled) {
      return lastScheduled.type === BLOCK;
    } else {
      return false;
    }
  }

  getOriginBlockForSleeper() {
    const lastBlock = last(this.props.schedule)
    if (lastBlock) {
      return lastBlock.locationId;
    } 
  }

  checkRankings(locationItem) {
    if (locationItem.type === BLOCK) {
      const blockId = locationItem.locationId;
      const targetBlock = find(this.props.blocks,{id: blockId})
      const targetBlockRanking = targetBlock.ranking;
      const rowId = this.props.rowId;
      const targetRow = find(this.props.scheduleRowState, {rowId: rowId})
      const rowDriverId = targetRow.scenarioTruck.driverId;
      const targetDriver = find(this.props.drivers, {id: rowDriverId})
      if (targetDriver) {
        // in here we should check for the isTrainee flag.
        // if the target driver is a trainee we'll show new trainee modal instead of ranking modal.
        // if (targerDriver.isTrainee) {
        //  this.props.openTraineeDriverModal(targetDriver);
        //}
        const targetDriverExperience = targetDriver.experience;
        if (targetDriver.isTrainee) {
          const mentorDriver = find(this.props.drivers, {id: targetDriver.mentorDriverId});
          this.props.openTraineeDriverModal(targetDriver, mentorDriver);
          return true;
        }

        else if ((targetBlockRanking > targetDriverExperience) || targetDriverExperience === null || targetBlockRanking === null ) {
          this.props.openDriverToBlockRankingModal(targetDriver, targetBlock);
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }


  getDriverExperience() {
    const rowId = this.props.rowId;
    const targetRow = find(this.props.scheduleRowState, {rowId: rowId})
    const rowDriverId = targetRow.scenarioTruck.driverId;
    const a = find(this.props.drivers, {id: rowDriverId})
    const targetDriverExperience = a.experience;
    return targetDriverExperience;
  }

  handleLocationDrop(locationItem) {
    if (this.checkRankings(locationItem)) {
      const locationId = locationItem.locationId;
      const rowId = this.props.rowId;
      const loadersAssigned = getLoadersAssigned(locationId);
      if (locationItem.type === BLOCK && loadersAssigned.length > 0) {
        if (loadersAssigned.length >= 2) {
          const previousLoader = this.previousLoaderIdAssignedToBlock(locationId);
          if (previousLoader) {
            this.props.addLocationToSchedule(rowId, locationId, previousLoader);
          } else {
            locationItem.rowId = rowId;
            this.props.openAssignLoaderModal(locationItem);
          }
        } else if (loadersAssigned.length === 1) {
          const loaderId = loadersAssigned[0].loaderId;
          this.props.addLocationToSchedule(rowId, locationId, loaderId);
        }
      } else if (locationItem.type === YARD && this.isFromBlock()) {
        this.props.openSleeperYardPromptModal(rowId, locationId, this.getOriginBlockForSleeper());
      } else if (locationItem.type === DRYLAND) {
          if (this.checkMaxCapacity(locationItem)) {
            this.props.addLocationToSchedule(rowId, locationId); 
          }  
      } else {
        this.props.addLocationToSchedule(rowId, locationId);
      }

    }
  }
  

  getLocationDropTarget() {
    if ((this.props.startTime.startHour && !this.props.isPublished && !isViewerRole() && this.props.isShared && !this.props.isClosed) || (!this.isInTruckShares() && !this.props.isClosed)) {
      return (
        <LocationDropTarget
          key={uniqid()}
          rowId={this.props.rowId}
          startTime={this.props.startTime}
          workBeginHour={this.props.beginHour}
          hasEmptySchedule={this.hasEmptySchedule()}
          handleLocationDrop={(location) => this.handleLocationDrop(location)}/>
      );
    } else {
      return null;
    }
  }

  isInTruckShares(scenarioTruckId) {
    scenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
    const sharedIds = this.props.truckShares.map(share => share.scenarioTruckId);
    return sharedIds.includes(scenarioTruckId);
  }
  

  getWorkdayMarkers() {
    if (!this.hasEmptySchedule()) {
      let timeData = {
        beginHourSchedule: this.props.beginHourSchedule,
        startTimeSchedule: this.props.startTime,
        maxScheduledMinutes: this.props.maxScheduledMinutes
      }

      return <WorkDayMarker {...timeData} />;
    }
  }

  getScenarioTruckRowHeader() {
    if (this.props.scenarioTruck) {
      return (
        <ScenarioTruckContextMenu
          id={this.props.rowId}
          name={this.props.truckName}
          scenarioTruck={this.props.scenarioTruck}
          startTime={this.props.startTime}
          workDayMinutes={this.props.scenarioTruck.workDayMinutes}
          isShared={this.props.isShared}>
          <TruckScheduleRowHeader
            truckName={this.props.truckName}
            scenarioTruck={this.props.scenarioTruck}
            clickedItemId={this.props.clickedItemId}
            clickHandler={(scenario) => this.clickActivity(scenario)}/>
        </ScenarioTruckContextMenu> 
      );
    }
  }

  rowHighlights() {
      const isShared = this.props.isShared;
      const greenBorder = '3px solid green';
      if (isShared) {
        return {
          borderTop: greenBorder,
          borderBottom: greenBorder
        }
        } else if (this.props.clickedItemId === this.props.rowId) {
          const yellowBorder = '5px solid yellow';
          return {
            borderTop: yellowBorder,
            borderBottom: yellowBorder
          }
        }
    } 
  

  //DRYLAND STUFF
  getDestinationsOnSchedule() {
    return this.props.scheduleRowState.flatMap(scheduleRow => {
     const drylands = scheduleRow.schedule.filter(scheduleItem => scheduleItem.type === DRYLAND);
     return drylands.map(dryland => {
       return {
         ...dryland,
         truckName: scheduleRow.truckName
       }
     });
    });
  }

  checkMaxCapacity(locationItem) {
      const destList = this.getDestinationsOnSchedule().map(destination => destination.name);
      const destination = find(this.props.destinations, {id: locationItem.locationId});
      const destinationName = destination.name;
      let count = 0
      for (var i=0; i<destList.length; i++) {
        if (destList[i] === destinationName ) {
          count = count + 1;
          
        }
      } if ( count >= destination.maxCapacity-1 ) {
          this.props.openDestinationMaxCapacityModal(destination);
          return true;   
      } else {
        return true;
      }
  }


  render () {    
    return (
      <div className="schedule-row" style={this.rowHighlights()}>
        {this.getScenarioTruckRowHeader()}
        {this.getWorkdayMarkers()}
        {this.getLocationsForSchedule()}
        {this.getLocationDropTarget()}
        <div className="schedule-background" />
      </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => {
  const rowState = state.scheduleRowState.find((row) => (row.rowId === ownProps.rowId));
  if (rowState) {
    const workDayMinutes = rowState.workDayMinutes? rowState.workDayMinutes: state.workHoursForSchedule.maxScheduledMinutes;
    return {
      schedule: rowState.schedule,
      truckId: rowState.truckId,
      startTime: rowState.startTime,
      maxScheduledMinutes: workDayMinutes,
      beginHourSchedule: state.workHoursForSchedule.beginHour,
      scenarioTruck: rowState.scenarioTruck,
      operatingUnits: state.operatingUnits,
      blocks: state.locations.blocks,
      scheduleRowState: state.scheduleRowState,
      drivers: state.drivers.driverList,
      destinations: state.locations.destinations,
      truckShares: state.truckShares.truckSharesList
    };
  } else {
    return {
      schedule: [],
      truckId: '',
      startTime: 0,
      maxScheduledMinutes: 600
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLocationToSchedule: (rowId, locationId, workHours) => {
      dispatch(addLocationToSchedule(rowId, locationId, workHours));
    },
    showBlockActivityDashboard: (blockActivity) => {
      dispatch(showBlockActivityDashboard(blockActivity));
    },
    showDrylandActivityDashboard: (drylandActivity) => {
      dispatch(showDrylandActivityDashboard(drylandActivity));
    },
    showTransitActivityDashboard: (transitActivity) => {
      dispatch(showTransitActivityDashboard(transitActivity));
    },
    openAssignLoaderModal: (props) => {
      dispatch(openAssignLoaderModal(props))
    },
    openDriverToBlockRankingModal:(driverExperience, blockRanking) => {
      dispatch(openDriverToBlockRankingModal(driverExperience, blockRanking));
    },
    openDestinationMaxCapacityModal:(destination) => {
      dispatch(openDestinationMaxCapacityModal(destination));
    },
    openSleeperYardPromptModal: (rowId, locationId, blockId) => {
      dispatch(openSleeperYardPromptModal(rowId, locationId, blockId));
    },
    openTraineeDriverModal: (traineeDriver, mentorDriver) => {
      dispatch(openTraineeDriverModal(traineeDriver, mentorDriver));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRow);
