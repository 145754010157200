import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { closeModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import PdfService from '../../services/pdfService';
import sortBy from 'lodash/sortBy';


class RollupDayPickerModal extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.isoFormat = 'YYYY-MM-DD';
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.closeModal = this.closeModal.bind(this);
    this.downloadRollup = this.downloadRollup.bind(this);
    this.handleChangeContractor = this.handleChangeContractor.bind(this);
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      selectedContractor: undefined
    };
  }

  getNoneOption() {
    return <option key={'none'} value={"undefined"}>None</option>;
  }

  getCompanyList() {
    const companyList = sortBy(this.props.truckCompanies
      .filter(x => !x.isDeleted && x.active), truckCompany => truckCompany.name)
    const uniqueOptions = companyList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
    uniqueOptions.unshift(this.getNoneOption());
    return uniqueOptions;
  }

  handleChangeContractor(event) {
    const contractor = event.target.value;
    this.setState({
      selectedContractor: contractor,
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  downloadRollup() {
    const formattedFromDate = format(this.state.from, this.isoFormat);
    const formattedToDate = format(this.state.to, this.isoFormat);
    PdfService.downloadRollup(formattedFromDate, formattedToDate, this.state.selectedContractor);
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.closeModal}
        style={{content: {
          height: '440px',
          width: '560px',
          display: 'block',
          margin: 'auto'
        }}}
      >
      <div className="RangeExample">
        <p>Please select a range including up to 5 completed schedules.</p>
        <p>
          {!from && !to && 'Please select the first day.'}
          {from && !to && 'Please select the last day.'}
          {from &&
            to &&
            `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
          {from && to && (
            <button className="link" onClick={this.handleResetClick}>
              Reset
            </button>
          )}
        </p>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          formatDate={format}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
      </div>
      
      <div>
      <span>Truck Company </span>
        <select onChange={this.handleChangeContractor} value={undefined}>
          {this.getCompanyList()}
        </select>
      </div>
      <div className='rollup-modal-buttons'>
        <button className='rollup-modal-cancel-button' onClick={this.closeModal}>Cancel</button>
        <button className='rollup-modal-done-button' disabled={this.state.from === undefined || this.state.to === undefined || this.state.selectedContractor === undefined} onClick={this.downloadRollup}>Done</button>
      </div>
      </ReactModal>
    );
  }   
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    trucks: state.trucks.truckList,
    truckCompanies: state.truckCompanies.truckCompaniesList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RollupDayPickerModal);