import React from 'react';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import PdfService from '../../services/pdfService';
import PublishService from '../../services/publishService';
import { connect } from 'react-redux';
import { openSendPdfConfirmationModal } from '../../reducers/modalWindows';
import { TRUCK } from '../../constants/typeConstants';
import { isViewerRole } from '../../utils/userHelper';

class TruckContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.openTruckPdfPreview = this.openTruckPdfPreview.bind(this);
    this.openConfirmationDialog = this.openConfirmationDialog.bind(this);
    this.openTruckPdfDownload = this.openTruckPdfDownload.bind(this);
  }

  openConfirmationDialog() {
    const sendAction = () => PublishService.sendTruckDriverEmail(this.props.scenarioId, this.props.id, this.props.user);
    const props = {
      scenarioId: this.props.scenarioId,
      id: this.props.id,
      type: TRUCK,
      name: this.props.name,
      sendAction: sendAction,
      user: this.props.user
    }
    this.props.openConfirmation(props);
  }

  openTruckPdfPreview() {
    if (this.props.isTruckInSchedule) {
      PdfService.previewTruckPdf(this.props.scenarioId, this.props.id);

    }
  }

  openTruckPdfDownload() {
    if (this.props.isTruckInSchedule) {
      PdfService.downloadTruckPdf(this.props.scenarioId, this.props.id);
    }
  }

  render() {
    return (
      <div className='truck-context-menu'>
        <ContextMenuTrigger id={this.props.id} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>

        <ContextMenu id={this.props.id}>
          <MenuItem attributes={{className:'context-menu-title'}}>{this.props.name}</MenuItem>
          <MenuItem onClick={this.openTruckPdfPreview}
                    disabled={!this.props.isTruckInSchedule}>
            Preview Truck Schedule PDF
          </MenuItem>
          <MenuItem onClick={this.openTruckPdfDownload}
                    disabled={!this.props.isTruckInSchedule}>
            Download Truck Schedule PDF
          </MenuItem>
          <MenuItem onClick={this.openConfirmationDialog} disabled={isViewerRole()}>Send Truck Schedule Email</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirmation: (props) => {
      dispatch(openSendPdfConfirmationModal(props))
    }
  }
}

export default connect(null, mapDispatchToProps)(TruckContextMenu);