import { setYarders } from '../reducers/yarders';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class YarderService {
  static getYardersApi() {
    return axios.get('/api/yarders', { headers: getAuthService().header });
  }

  static getYarders() {
    return this.getYardersApi()
      .then((yarder) => {
        store.dispatch(setYarders(yarder.data))
      })
      .catch(error => console.log(error));
  }
}

export default YarderService;