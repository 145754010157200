import find from 'lodash/find';
import store from '../store';

function getStoreState() {
  return store.getState();
}

function getBlocks() {
  return getStoreState().locations.blocks;
}

function getDestinations() {
  return getStoreState().locations.destinations;
}

function getTrucks() {
  return getStoreState().trucks.truckList;
}

function getLocationDisplayName(locations, id) {
  const targetLocation = find(locations, {id: id});
  const name = (targetLocation) ? targetLocation.name : '';
  return name;
}

export function getBlockDisplayName(blockId) {
  return getLocationDisplayName(getBlocks(), blockId);
}

export function getDestinationDisplayName(destinationId) {
  return getLocationDisplayName(getDestinations(), destinationId);
}

export function getTruckDisplayName(truckId) {
  const trucks = getTrucks();
  let targetTruck = {name: ''};
  if (trucks) {
    targetTruck = trucks.find(truck => truck.id === truckId);
  }

  return targetTruck.name;
}