import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { failure } from '../notificationMessages';

import { FETCH_SLEEPER_LOADS, 
  setSleeperLoads, 
  ADD_OR_UPDATE_SLEEPER_LOADS, 
  addOrUpdateSleeperLoadSuccess, 
  DELETE_SLEEPER_LOADS, 
  deleteSleeperLoadSuccess, 
  SHOW_SCENARIOS_FOR_SLEEPER_LOADS, 
  showScenariosSuccess, 
  IMPORT_SLEEPER_LOADS,
  importSuccessful
 } 
 from '../../reducers/sleeperLoads';
 import { getAuthService } from '../AuthService';



export const fetchSleeperLoads = action$ => (
  action$.ofType(FETCH_SLEEPER_LOADS)
    .mergeMap(action => {
      const fetchUrl = `/api/sleeperLoad/${action.payload}`;
      return ajax.get(fetchUrl, getAuthService().header)
        .map(ajaxResponse => setSleeperLoads(ajaxResponse.response))
        .catch(() => Observable.of(failure('Could not fetch sleeper loads')));
    })
);

export const addOrUpdateSleeperLoads = action$ => (
  action$.ofType(ADD_OR_UPDATE_SLEEPER_LOADS)
    .mergeMap(action => (
      ajax.post('/api/sleeperLoad', action.payload, getAuthService().header) 
        .map(ajaxResponse => addOrUpdateSleeperLoadSuccess(ajaxResponse.response))
        .catch(() => Observable.of(failure('Could not add or update sleeper loads')))
    ))
);

export const deleteSleeperLoads = action$ => (
  action$.ofType(DELETE_SLEEPER_LOADS)
    .mergeMap(action => {
      const scenarioId = action.payload.scenarioId;
      const yardId = action.payload.yardId;
      return ajax.delete(`/api/sleeperLoad/${scenarioId}?yardId=${yardId}`, getAuthService().header)
        .map(ajaxResponse => deleteSleeperLoadSuccess(ajaxResponse.response))
        .catch(() => Observable.of(failure('Could not delete sleeper loads')))
    })
);

export const showScenariosForSleeperLoads = action$ => (
  action$.ofType(SHOW_SCENARIOS_FOR_SLEEPER_LOADS)
    .mergeMap( action => {
      const scenarios = action.payload? `/api/scenarios/${action.payload}` : '/api/scenarios';
      return ajax.get(scenarios, getAuthService().header)
        .map(ajaxResponse => showScenariosSuccess(ajaxResponse.response))

    })
);

export const importSleeperLoads = action$ => (
  action$.ofType(IMPORT_SLEEPER_LOADS)
    .mergeMap(action => {
      return ajax.post('/api/sleeperLoad/importSleeperLoads', action.payload, getAuthService().header)
      .map(ajaxResponse => importSuccessful(ajaxResponse.response));
    })
)


export const sleeperLoadEpics = combineEpics(
  fetchSleeperLoads,
  addOrUpdateSleeperLoads,
  deleteSleeperLoads,
  showScenariosForSleeperLoads,
  importSleeperLoads
);