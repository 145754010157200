import React from 'react';
import { emptyUuid } from '../utils/uuidHelper';
import { connect } from 'react-redux';
import { find } from 'lodash';

class LoaderPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderId: emptyUuid
    }
  }

  componentDidMount() {
    this.setState({
      loaderId: this.getSelectedLoaderId()
    });
  }

  getLoaderElements(blockId) {
    const none = <option key='blank' value={emptyUuid}>None</option>;
    const loaderOptions = this.props.assignments
      .filter(x => x.blockId === blockId)
      .map(x => {
        const id = x.loaderId;
        const name = find(this.props.loaders, {id: id}).name;
        return <option key={id} value={id}>{name}</option>
      });
    loaderOptions.unshift(none);
    return loaderOptions;
  }

  getSelectedLoaderId() {
    const loaderId = this.props.selectedLoaderId;
    if(loaderId) {
      return loaderId;
    } else {
      return emptyUuid;
    }
  }

  handleChangeLoader(event) {
    this.setState({
      loaderId: event.target.value
    });
    this.props.handleChangeLoader(event);
  }

  getLoaderPicker(blockId) {
    return (
      <select className='loader-picker' onChange={(e) => this.handleChangeLoader(e)}
        value={this.state.loaderId}>
        {this.getLoaderElements(blockId)}
      </select>
    );
  }

  render() {
    return (
      <div className='loader-picker-component'>
        {this.getLoaderPicker(this.props.blockId)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaders: state.loaders.loaderList,
    assignments: state.loaderOperatorAssignment.loaderOperatorAssignmentList
  }
}

export default connect(mapStateToProps)(LoaderPicker);