import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import FallerFieldEditor from '../../components/fieldEditors/FallerFieldEditor';
import uniqid from 'uniqid';
import { addFaller, updateFaller, bulkActivateFallers } from '../../reducers/fallers';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { FALLER } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class FallersConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fallerIdSelected: '',
      isAddingNewFaller: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousFallers = previousProps.fallers.map(faller => faller.id);
    const currentFallers = this.props.fallers.map(faller => faller.id);
    if (currentFallers.length > previousFallers.length) {
      const diff = difference(currentFallers, previousFallers);
      if (diff.length > 0) {
        const newFaller = diff[0];
        this.setState({
          fallerIdSelected: newFaller,
          isAddingNewFaller: false
        });
      }
    }
  }

  addNewFaller() {
    this.setState({
      fallerIdSelected: '',
      isAddingNewFaller: true
    });
  }

  selectFaller(id) {
    this.setState({
      fallerIdSelected: id,
      isAddingNewFaller: false
    });
  }

  getSelectedFaller() {
    const faller = find(this.props.fallers, {id: this.state.fallerIdSelected});
    return faller;
  }

  getSelectedFallerName() {
    const faller = this.getSelectedFaller();
    if (faller) {
      return faller.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedFaller() {
    return this.state.fallerIdSelected !== '';
  }

  getFallersList() {
    const options = this.props.fallers;
    const sortedOptions = sortBy(options, faller => faller.name)
    .filter(faller => !faller.isDeleted)
    .map(faller => {
      return {
        value: faller.id,
        label: faller.name
      }
    });

    const handleChange = (selectedOption) => this.selectFaller(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedFallerName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const faller = this.getSelectedFaller();
    if (faller) {
      return `Edit faller ${faller.name}`;
    } else if (this.state.isAddingNewFaller) {
      return 'Add a new faller';
    } else {
      return 'Select a faller to edit';
    }
  }

  checkExistence(state) {
    const faller = find(this.props.fallers, {name: state.name});
    if (faller) {
      this.props.openDuplicateObjectNamesModal(faller);
    }
    else {
      if (this.state.isAddingNewFaller === true) {
        this.props.add(state);
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getFallerEditPanel() {
    if (this.hasSelectedFaller() && !this.state.isAddingNewFaller) {
      return (
        <div>
          <FallerFieldEditor
            key={uniqid()}
            fallerFieldProps={this.getSelectedFaller()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectFaller('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewFaller) {
      return (
        <div>
          <FallerFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectFaller('')}
          />
        </div>
      );
    }
  }

  activate() {
    const faller = this.getSelectedFaller();
    if (faller) {
      faller.active = !faller.active;
      this.props.update(faller);
    }
  }

  getActivateButton() {
    const faller = this.getSelectedFaller();
    if (faller) {
      const buttonName = faller.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const fallerId = this.state.fallerIdSelected;
    if (fallerId !== '') {
      return (
        <button className='remove-faller'
          onClick={() => this.props.removeFallerModal(fallerId)}>
          Remove Faller
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedFallersWithId = this.props.fallers
      .filter(faller => !faller.isDeleted)
      .map(faller => {
        return {
          ...faller,
          id: faller.id,
          name: faller.name
        }
      });
      return <BulkActivationModal
        type={FALLER}
        activationItems={updatedFallersWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='faller-configuration configuration-forms'>
        <button className='add-new-faller' onClick={() => this.addNewFaller()}>Add New Faller</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='faller-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='faller-list'>
          {this.getFallersList()}
        </div>
        <div className='faller-edit-panel'>
          {this.getFallerEditPanel()}
          <div className='faller-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectFaller('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    fallers: state.fallers.fallersList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addFaller(state));
    },
    update: (state) => {
      dispatch(updateFaller(state));
    },
    removeFallerModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateFallers(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(FallersConfiguration);