import React, { Component } from 'react';
import { connect } from 'react-redux';

class ReportFieldEditor extends Component {
  constructor(props) {
    super(props);

    const fieldProps = props.reportFieldProps? props.reportFieldProps: {};
    const defaultReportId = fieldProps.id? fieldProps.id: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultReportEmails = fieldProps.emails? fieldProps.emails: '';

    this.state = {
      reportId: defaultReportId,
      isModified: false,
      name: defaultName,
      emails: defaultReportEmails
    }

    this.changeReportName = this.changeReportName.bind(this);
    this.changeReportEmails = this.changeReportEmails.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeReportName(event) {
    const truncatedName = event.target.value.substring(0, 8);
    this.setState({name: truncatedName});
    this.setModified();
  }

  changeReportEmails(event) {
    this.setState({emails: event.target.value});
    this.setModified();
  }

  
  render() {
    return (
      <React.Fragment>
        <div className='name-field report-field'>
          <div>Name</div>
          <input disabled={true} type='text' value={this.state.name} onChange={this.changeReportName} />
        </div>
        <div className='email-field report-field'>
          <div>Email</div>
          <textarea rows='4' cols='55' value={this.state.emails} onChange={this.changeReportEmails}
            placeholder='Enter each email address separated by a comma'
          />
        </div>
        <div className='report-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
        
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    blockReports: state.blockProperties.blockReportsList
  }
}

export default connect(mapStateToProps)(ReportFieldEditor);

