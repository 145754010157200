import React from 'react';
import { connect } from 'react-redux';
import { emptyUuid } from '../utils/uuidHelper';

class OperatingUnitPicker extends React.Component {
  constructor(props) {
    super(props);

    const defaultObjectId = props.objectId? props.objectId: emptyUuid;
    const chosenOperatingUnitId = this.getAssignedOperatingUnit(defaultObjectId);

    this.state = {
      configObjectId: defaultObjectId,
      operatingUnitIdSelected: chosenOperatingUnitId
    }

    this.changeOperatingUnit = this.changeOperatingUnit.bind(this);
  }

  componentDidMount() {
    this.props.onChangeOperatingUnit(this.state.operatingUnitIdSelected);
  }

  changeOperatingUnit(event) {
    const id = event.target.value;
    this.setState({operatingUnitIdSelected: id});
    this.props.onChangeOperatingUnit(id, true);
  }

  getAssignedOperatingUnit(objectId) {
    if (objectId !== emptyUuid) {
      const target = this.props.assignment.find(assign => assign.configObjectId === objectId);
      if (target) {
        return target.operatingUnitId;
      }
    }

    return emptyUuid;
  }

  setModified() {
    this.setState({isModified: true});
  }

  selectOperatingUnit(id) {
    this.setState({
      operatingUnitIdSelected: id
    });
  }

  getOperatingUnitOptions() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeOperatingUnits = this.props.operatingUnits.filter(ou => ou.active && !ou.isDeleted);
    const operatingUnitOptions = activeOperatingUnits.map(operatingUnit => {
      const id = operatingUnit.id;
      return <option key={id} value={id}>{operatingUnit.name}</option>;
    });
    operatingUnitOptions.unshift(noSelection);
    return operatingUnitOptions;
  }

  render() {
    return (
      <div className='operating-unit-picker-container'>
        <div>Operating Unit</div>
        <select className='operating-unit-picker' 
          value={this.state.operatingUnitIdSelected}
          onChange={this.changeOperatingUnit}>
          {this.getOperatingUnitOptions()}
        </select>  
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    operatingUnits: state.operatingUnits.operatingUnitList,
    assignment: state.operatingUnits.operatingUnitAssignment
  }
}

export default connect(mapStateToProps)(OperatingUnitPicker);