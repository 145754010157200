const SET_NAVISTREAM = "SET_NAVISTREAM";
export const FETCH_NAVISTREAM_DATA = "FETCH_NAVISTREAM_DATA";

export function setNavistream(navistreamData) {
  return {
    type: SET_NAVISTREAM,
    payload: navistreamData
  }
}

export function fetchNavistreamData(scenarioId) {
  return {
    type: FETCH_NAVISTREAM_DATA,
    payload: {
      scenarioId: scenarioId
    }
  }
}

export default function navistream(state = {
  navistreamData: []
}, action) {
  switch(action.type) {
    case SET_NAVISTREAM:
      return _setNavistream(state, action);
    default:
      return state;
  }
}

function _setNavistream(state, action) {
  return {
    ...state,
    navistreamData: action.payload
  }
}