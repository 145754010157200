import sortBy from 'lodash/sortBy';

const SET_TRUCKS = 'SET_TRUCKS';
export const ADD_TRUCK = 'ADD_TRUCK';
export const EDIT_TRUCK = 'EDIT_TRUCK';
export const REMOVE_TRUCK = 'REMOVE_TRUCK';
export const BULK_ACTIVATE_TRUCKS = 'BULK_ACTIVATE_TRUCKS';
export const EDIT_TRUCK_DEFAULTS = 'EDIT_TRUCK_DEFAULTS';

export function addTruck(truck) {
  return {
    type: ADD_TRUCK,
    payload: truck
  }
}

export function editTruck(truck) {
  return {
    type: EDIT_TRUCK,
    payload: truck
  }
}

export function removeTruckObject(truckId) {
  return {
    type: REMOVE_TRUCK,
    payload: truckId
  }
}

export function setTrucks(trucks) {
  return {
    type: SET_TRUCKS,
    payload: trucks
  }
}

export function bulkActivateTrucks(truckIds) {
  return {
    type: BULK_ACTIVATE_TRUCKS,
    payload: truckIds
  }
}

export default function trucks(state = {
  truckList: []
}, action) {
  switch (action.type) {
    case SET_TRUCKS:
      return setTrucksReducer(state, action);
    default:
      return state;
  }
}

function setTrucksReducer(state, action) {
  const sortedTrucks = sortBy(action.payload, truck => truck.name);
  return {
    ...state,
    truckList: sortedTrucks
  }
}