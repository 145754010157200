import { setFallers } from '../reducers/fallers';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class FallerService {
  static getFallersApi() {
    return axios.get('/api/fallers', { headers: getAuthService().header });
  }

  static getFallers() {
    return this.getFallersApi()
      .then((faller) => {
        store.dispatch(setFallers(faller.data))
      })
      .catch(error => console.log(error));
  }
}

export default FallerService;