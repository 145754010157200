import React from 'react';
import { connect } from 'react-redux';
import TruckDraggable from './TruckDraggable';
import { showTruckDashboard, clearDashboard } from '../../reducers/dashboard';
import { TRUCK_DASHBOARD_DETAILS } from '../../constants/dashboardTypes';
import find from 'lodash/find';
import TruckContextMenu from '../../components/contextMenus/TruckContextMenu';
import { isTruckInSchedule } from '../../utils/truckHelpers';
import { matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';

class TruckDraggableSection extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      clickedItemId: ''
    }

    this.showTruckInDashboard = this.showTruckInDashboard.bind(this);
  }

  clearDashboard() {
    this.props.clearDashboard();
    this.setState({clickedItemId: ''});
  }

  getDefaultDriverName(driverId) {
    if (driverId) {
      const driver = find(this.props.drivers, {id: driverId});
      return driver? driver.name : '';
    } else {
      return '';
    }
  }

  getDefaultTrailerName(trailerId) {
    if (trailerId) {
      const trailer = find(this.props.trailers, {id: trailerId});
      return trailer? trailer.trailerName: '';
    } else {
      return ''
    }
  }

  showTruckInDashboard(id) {
    if (id !== this.state.clickedItemId || this.props.dashboard.dashboardType !== TRUCK_DASHBOARD_DETAILS) {
      const targetTruck = find(this.props.truckList, {id: id});
      const defaultAxle = targetTruck.defaultAxle? targetTruck.defaultAxle: 0;
      const defaultDriverName = this.getDefaultDriverName(targetTruck.defaultDriver);
      const defaultTrailerName = this.getDefaultTrailerName(targetTruck.defaultTrailer);
      const truckCompany = find(this.props.truckCompanies, {id: targetTruck.truckCompanyId});
      const dashboardProps = {
        ...targetTruck,
        defaultAxle: defaultAxle,
        defaultDriverName: defaultDriverName,
        defaultTrailerName: defaultTrailerName,
        truckCompany: truckCompany ? truckCompany.name : ''
      };
      this.props.showTruckDashboard(dashboardProps);
      this.setState({clickedItemId: id});
    } else {
      this.clearDashboard();
    }
  }

  isTruckInSchedule(truck) {
    return isTruckInSchedule(truck.id);
  }

  getStyle(trucksToRender) {
    if (trucksToRender.length > 10) {
      return {
        height: '18em'
      }
    }
  }

  render() {
    
    const trucksToRender = this.props.truckList
      .filter((truck) => truck.active && matchesSelectedOperatingUnit(truck.id) && !truck.isDeleted)
      .map((truck) => {
      return (
        <TruckContextMenu
          key={'context'+truck.id}
          id={truck.id}
          name={truck.name}
          scenarioId={this.props.currentScenarioId}
          isTruckInSchedule={this.isTruckInSchedule(truck)}
          user={this.props.user}
        >
          <TruckDraggable 
            key={truck.id}
            name={truck.name}
            id={truck.id}
            currentDashboardId={this.props.dashboard.id}
            clickHandler={this.showTruckInDashboard}
            isTruckInSchedule={this.isTruckInSchedule(truck)}
            isDraggable={!this.props.isPublished}
            />
        </TruckContextMenu>
      );
    });

    return (
      <div className="truck-draggable-list" style={this.getStyle(trucksToRender)}>
        {trucksToRender}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    truckList: state.trucks.truckList,
    dashboard: state.dashboard.currentlySelected,
    currentScenarioId: state.scenarioState.selectedScenario.id,
    trailers: state.trailers.trailerList,
    drivers: state.drivers.driverList,
    user: state.users.user.profile,
    truckCompanies: state.truckCompanies.truckCompaniesList,

    // The scheduleRows object is not used in this component directly
    // but is required here so that the trucks can be highlighted if they are
    // already in the schedule. The component will rerender on changes to scheduleRows.
    scheduleRows: state.scheduleRowState,
    isPublished: state.scenarioState.selectedScenario.published || state.scenarioState.selectedScenario.wasPublished,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showTruckDashboard: (truck) => {
      dispatch(showTruckDashboard(truck));
    },
    clearDashboard: () => {
      dispatch(clearDashboard());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckDraggableSection);