import sortBy from 'lodash/sortBy';

const SET_ERROR_CODES = 'SET_ERROR_CODES';
export const ADD_ERROR_CODE = 'ADD_ERROR_CODE';
export const REMOVE_ERROR_CODE = 'REMOVE_ERROR_CODE';
export const EDIT_ERROR_CODE = 'EDIT_ERROR_CODE';
export const BULK_ACTIVATE_ERROR_CODES = 'BULK_ACTIVATE_ERROR_CODES'


export function addErrorCode(errorCode) {
  return {
    type: ADD_ERROR_CODE,
    payload: errorCode
  }
}

export function removeErrorCode(errorCodeId) {
  return {
    type: REMOVE_ERROR_CODE,
    payload: errorCodeId
  }
}

export function editErrorCode (errorCode) {
  return {
    type: EDIT_ERROR_CODE,
    payload: errorCode
  }
}

export function setErrorCodes(errorCodes) {
  return {
    type: SET_ERROR_CODES,
    payload: errorCodes
  }
}

export function bulkActivateErrorCodes(errorCodeIds) {
  return {
    type: BULK_ACTIVATE_ERROR_CODES,
    payload: errorCodeIds
  }
}

export default function errorCodes(state =  {
  errorCodeList : []
}, action) {
  switch (action.type) {
    case SET_ERROR_CODES:
      return _setErrorCodes(state, action);
    default:
      return state;
  }
}
  
function _setErrorCodes(state, action) {
  const sortedErrorCodes = sortBy(action.payload, errorCode => errorCode.Code);
  return {
    ...state,
    errorCodeList: sortedErrorCodes
  }
}
 

