import React from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PaintMarkFieldEditor from '../../components/fieldEditors/PaintMarkFieldEditor';
import uniqid from 'uniqid';
import { addMark, updateMark, setMarks, bulkActivateMarks } from '../../reducers/paintMarks';
import axios from 'axios';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { PAINTMARK } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL } from '../../constants/modalTypes';
import sortBy from 'lodash/sortBy';

class PaintMarkConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paintMarkIdSelected: '',
      isAddingNewPaintMark: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousMarks = previousProps.paintMarks.map(p => p.id);
    const currentMarks = this.props.paintMarks.map(p => p.id);
    if (currentMarks.length > previousMarks.length) {
      const diff = difference(currentMarks, previousMarks);
      if (diff.length > 0) {
        const newMark = diff[0];
        this.setState({
          paintMarkIdSelected: newMark,
          isAddingNewPaintMark: false
        });
      }
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.paintMarks)) {
      axios.get('/api/paintmarks')
        .then((paintMarks) => {
          this.props.setMarks(paintMarks.data);
        });
    }
  }

  addNewPaintMark() {
    this.setState({
      paintMarkIdSelected: '',
      isAddingNewPaintMark: true
    });
  }

  selectPaintMark(id) {
    this.setState({
      paintMarkIdSelected: id,
      isAddingNewPaintMark: false
    });
  }

  getSelectedPaintMark() {
    const paintMark = find(this.props.paintMarks, {id: this.state.paintMarkIdSelected});
    return paintMark;
  }

  getSelectedPaintMarkDestination(paintmark) {
    const destination = find(this.props.destinations, {id: paintmark.destinationId});
    return destination.name;
  }

  getSelectedPaintMarkName() {
    const paintMark = this.getSelectedPaintMark();
    if (paintMark) {
      const destination = this.getSelectedPaintMarkDestination(paintMark);
      return paintMark.mark + ' - ' + destination;
    } else {
      return 'Select...';
    }
  }

  hasSelectedPaintMark() {
    return this.state.paintMarkIdSelected !== '';
  }

  getPaintMarkList() {
    const options = sortBy(this.props.paintMarks, paintMark => paintMark.mark)
    .filter(mark => !mark.isDeleted)
    .map(mark => {
      const destination = this.getSelectedPaintMarkDestination(mark);
      return {
        value: mark.id,
        label: mark.mark + ' - ' + destination
      }
    });

    const handleChange = (selectedOption) => this.selectPaintMark(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedPaintMarkName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const paintMark = this.getSelectedPaintMark();
    if (paintMark) {
      return `Edit paint mark ${paintMark.mark}`;
    } else if (this.state.isAddingNewPaintMark) {
      return 'Add a new paint mark';
    } else {
      return 'Select a paint mark to edit';
    }
  }

  getPaintMarkEditPanel() {
    if (this.hasSelectedPaintMark() && !this.state.isAddingNewPaintMark) {
      return (
        <div>
          <PaintMarkFieldEditor
            key={uniqid()}
            markFieldProps={this.getSelectedPaintMark()}
            clickDone={(state) => this.props.update(state)}
            clickCancel={() => this.selectPaintMark('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewPaintMark) {
      return (
        <div>
          <PaintMarkFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.props.add(state)}
            clickCancel={() => this.selectPaintMark('')}
          />
        </div>
      );
    }
  }

  activate() {
    const mark = this.getSelectedPaintMark();
    if (mark) {
      mark.active = !mark.active;
      this.props.update(mark);
    }
  }
  
  getActivateButton() {
    const mark = this.getSelectedPaintMark();
    if (mark) {
      const buttonName = mark.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const markId = this.state.paintMarkIdSelected;
    if (markId !== '') {
      return (
        <button className='remove-mark'
          onClick={() => this.props.removeMarkModal(markId)}>
          Remove Paint Mark
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedMarksWithId = this.props.paintMarks
      .filter(mark => !mark.isDeleted)
      .map(mark => {
        return {
          ...mark,
          id: mark.id,
          name: mark.mark
        }
      });
      return <BulkActivationModal
        type={PAINTMARK}
        activationItems={updatedMarksWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='mark-configuration configuration-forms'>
        <button className='add-new-mark' onClick={() => this.addNewPaintMark()}>Add New Paint Mark</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='mark-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='mark-list'>
          {this.getPaintMarkList()}
        </div>
        <div className='mark-edit-panel'>
          {this.getPaintMarkEditPanel()}
          <div className='mark-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectPaintMark('')} />
        {this.getBulkActivationModal()}
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addMark(state));
    },
    update: (state) => {
      dispatch(updateMark(state));
    },
    removeMarkModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    setMarks: (state) => {
      dispatch(setMarks(state));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateMarks(ids));
    }
  }
}

const mapStateToProps = (state) => {
  return {
    paintMarks: state.paintMarks.paintMarkList,
    destinations: state.locations.destinations,
    modalWindow: state.modalWindows
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaintMarkConfiguration);