const SET_PROCESSORS = 'SET_PROCESSORS';
export const ADD_PROCESSOR = 'ADD_PROCESSOR';
export const ADD_PROCESSOR_SUCCESS = 'ADD_PROCESSOR_SUCCESS';
export const UPDATE_PROCESSOR = 'UPDATE_PROCESSOR';
export const UPDATE_PROCESSOR_SUCCESS = 'UPDATE_PROCESSOR_SUCCESS';
export const REMOVE_PROCESSOR = 'REMOVE_PROCESSOR';
export const REMOVE_PROCESSOR_SUCCESS = 'REMOVE_PROCESSOR_SUCCESS';
export const BULK_ACTIVATE_PROCESSORS = 'BULK_ACTIVATE_PROCESSORS';

export function setProcessors(processors) {
  return {
    type: SET_PROCESSORS,
    payload: processors
  }
}

export function addProcessor(processor) {
  return {
    type: ADD_PROCESSOR,
    payload: processor
  }
}

export function addProcessorSuccess(processor) {
  return {
    type: ADD_PROCESSOR_SUCCESS,
    payload: processor
  }
}

export function updateProcessor(processor) {
  return {
    type: UPDATE_PROCESSOR,
    payload: processor
  }
}

export function updateProcessorSuccess(processor) {
  return {
    type: UPDATE_PROCESSOR_SUCCESS,
    payload: processor
  }
}

export function removeProcessor(processorId) {
  return {
    type: REMOVE_PROCESSOR,
    payload: processorId
  }
}

export function removeProcessorSuccess(processor) {
  return {
    type: REMOVE_PROCESSOR_SUCCESS,
    payload: processor
  }
}

export function bulkActivateProcessors(processorIds) {
  return {
    type: BULK_ACTIVATE_PROCESSORS,
    payload: processorIds
  }
}

export default function processors(state = {
  processorsList: []
}, action) {
  switch(action.type) {
    case SET_PROCESSORS:
      return _setProcessors(state, action);
    case ADD_PROCESSOR_SUCCESS:
      return _AddProcessor(state, action);
    case UPDATE_PROCESSOR_SUCCESS:
      return _updateProcessor(state, action);
    case REMOVE_PROCESSOR_SUCCESS:
      return _removeProcessor(state, action);
    default:
      return state;
  }
}


function _setProcessors(state, action) {
  return {
    ...state,
    processorsList: action.payload
  }
}

function _AddProcessor(state, action) {
  const processors = [...state.processorsList, action.payload];
  return {
    ...state,
    processorsList: processors
  }
}

function _updateProcessor(state, action) {
  const updatedProcessor = action.payload;
  const updatedList = state.processorsList.map(processor => {
    if(processor.id === updatedProcessor.id) {
      return updatedProcessor;
    } else {
      return processor;
    }
  });
  return {
    ...state,
    processorsList: updatedList
  }
}

function _removeProcessor(state, action) {
  const updatedList = state.processorsList
  return {
    ...state,
    processorsList: updatedList
  }
}
