import React from 'react';
import LocationScheduleDelete from './LocationScheduleDelete';
import isNil from 'lodash/isNil';
import { BLOCK, DRYLAND, YARD, TRANSIT } from '../constants/typeConstants';
import LocationDelay from './LocationDelay';
import { connect } from 'react-redux';
import { clearHighlights } from '../reducers/highlights';
import { isViewerRole } from '../utils/userHelper';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { emptyUuid } from '../utils/uuidHelper';

class LocationSchedule extends React.Component {

  componentDidUpdate() {
    if (this.isClickedToHighlight() && isEmpty(this.props.locationIdHighlighted)) {
      this.props.clickHandler(false);
    }
  }

  isClickedToHighlight() {
    return this.props.id === this.props.clickedItemId;
  }

  isHighlighted() {
    return this.props.locationId === this.props.locationIdHighlighted;
  }


  getStyle(timeAtDestination) {
    const type = this.props.type;
    const pixelWidth = timeAtDestination * 2;
    let styles = {};

    if (this.props.isFirstItem) {
      const leftMarginValue = this.props.travelTime * 2;
      styles.marginLeft = `${leftMarginValue}px`;
    }

    if (this.props.delay < 0) {
      styles.outline = '3px solid orange';
      styles.outlineOffset = '-3px';
    }
    
    if (this.isClickedToHighlight() || this.isHighlighted()) {
      styles.outline = '3px solid yellow';
      styles.outlineOffset = '-3px';
    }

    if ((type === BLOCK || type === DRYLAND) && timeAtDestination <= 0) {
      styles.width = '60px';
      return styles;
    }

    if (timeAtDestination > 0) {
      styles.width = pixelWidth;
    } else {
      styles.width = 30;
    }

    if (type === YARD && this.props.location.sleeperDestinationId !== emptyUuid) {
      styles.width = '6px';
      styles.backgroundColor = '#716CF5';
    }

    if (type === YARD) {
      if (this.props.isFirstItem) {
        styles.width = '8px';
      } else {
        styles.width = '6px';
      }
    }

    if (type === TRANSIT && this.props.hasYardPrevious) {
      if (this.props.index > 1) {
        styles.width = pixelWidth - 6;
      } else {
        styles.width = pixelWidth - 8;
      }
    }

    if (this.props.delay < 0) {
      styles.width += (this.props.delay * 2);
    }

    if (this.props.hasMismatchedTime) {
      styles.outline = '2px solid red';
      styles.outlineOffset = '-3px';
    }

    return styles;
  }

  hasDriverNotBeenInBlock() {
    const type = this.props.type;
    return type === BLOCK && !this.checkDriverToBlockHistory(this.props.location, this.props.driverId);
  }

  getDriverBlockMarkerClassName() {
    if (this.hasDriverNotBeenInBlock()) {
      return 'driver-block-no-history';
    } else {
      return '';
    }
  }


  checkDriverToBlockHistory(locationItem, driverId) {
    if (locationItem.type === BLOCK) {
      const driverInBlock = find(this.props.history, {blockId : locationItem.locationId, driverId : driverId });
      if (driverInBlock) {
        return true;
      } else {
        return false;
      } 
    }
  }


  getDelay() {
    const delay = this.props.delay + this.props.secondDelay;
    const id = this.props.id;
    return (isNil(delay) || delay <= 0)
      ? null
      : <LocationDelay 
          type={this.props.type}
          key={'delay' + id}
          delay={delay}
        />;
  }

    // call this function in render when it's fixed
  isScenarioTruckInSelectedOperatingUnit() {
    const operatingUnitId = this.props.operatingUnits.selectedOperatingUnitIds[0];
    const test = this.props.scheduleRowState.filter(x => x.rowId === this.props.rowId)
    const test1 = this.props.operatingUnits.operatingUnitAssignment.filter(x => x.configObjectId === test[0].truckId)
    if (test1.length === 0) {
      return false
    }
    if ( test1.operatingUnitId !== operatingUnitId) {
      return true;
    }
  }


  getDeleteIconElement() {
    if (this.props.isLastItem && !this.props.isPublished && !isViewerRole() && !this.props.isClosed) {
      return <LocationScheduleDelete
        rowId={this.props.rowId}
        id={this.props.id}
        type={this.props.type} />;
    }
  }

  clickOnScheduleLocation() {
    this.props.clickHandler();
    this.props.clearHighlights();
  }

  getYardNameHover() {
    if (this.props.type === YARD) {
        
      return (
        <div className='yard-name-hover'>
          {this.props.name}
        </div>
      );
    }
  }


  render() {
    
    const validTimeAtDestination = this.props.timeAtDestination? this.props.timeAtDestination : 0;
    this.isScenarioTruckInSelectedOperatingUnit()   
    const type = this.props.type;
    const name = this.props.name;
    return (
      <div className='location-item-container'>
        <div style={this.getStyle(validTimeAtDestination)}
          onClick={() => this.clickOnScheduleLocation()}
          className={`location-item ${type} ${this.getDriverBlockMarkerClassName()}`}>
          {type === YARD? '': name}
          {this.getYardNameHover()}
          {this.getDeleteIconElement()}  
        </div> 
        {this.getDelay()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationIdHighlighted: state.highlights.locationId,
    scheduleRowState: state.scheduleRowState,
    history: state.driversToBlocksHistory.driversToBlocksHistoryList,
    operatingUnits: state.operatingUnits
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearHighlights: () => {
      dispatch(clearHighlights());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSchedule);
