import React from 'react';
import { DRYLAND } from '../../constants/typeConstants';

class DashboardDryland extends React.Component {


  getDestinationsOnSchedule() {
    return this.props.schedule.flatMap(scheduleRow => {
     const drylands = scheduleRow.schedule.filter(scheduleItem => scheduleItem.type === DRYLAND);
     return drylands.map(dryland => {
       return {
         ...dryland,
         truckName: scheduleRow.truckName
       }
     });
    });
  }

  getNumberOfDestination(destinationName) {
    const destList = this.getDestinationsOnSchedule().map(destination => destination.name)
    let count = 0
    for (var i=0; i<destList.length; i++) {
      if (destList[i] === destinationName ) {
        count = count + 1;
      }
    } return count;  
  }

  getMaxCapacity(data) {
    if (data.maxCapacity === null) {
      return 'N/A';
    } else {
      return data.maxCapacity;
    }
  }



  render() {
    const data = this.props.dashboardInfo;
    const fullName = data.fullName? data.fullName: '';
    return (
      <div className='dashboard-dryland'>
        <h4>Dryland {data.name} details </h4>
        <div>Full Name: {fullName}</div>
        <div>Unload time: {data.unloadTime} minutes</div>
        <div>Scheduled: {this.getNumberOfDestination(data.name)}</div>
        <div>Max Capacity: {this.getMaxCapacity(data)}</div>
        {this.props.timeFromYards}
        
      </div>
    );
  }
}

export default DashboardDryland;