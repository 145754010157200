import first from 'lodash/first';
import { formatDateStringIso, getDateIsoFormat } from '../utils/dateTimeHelpers';
import { SUCCESSFUL_ACTION } from './requestActions';

export const SET_SCENARIOS = 'SET_SCENARIOS';
export const ASSIGN_NEW_SCENARIO = 'ASSIGN_NEW_SCENARIO';
export const SELECT_SCENARIO = 'SELECT_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const CREATE_NEW_SCENARIO = 'CREATE_NEW_SCENARIO';
export const FETCH_SCENARIOS = 'FETCH_SCENARIOS';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
const UPDATE_SCENARIO_SUCCESS = 'UPDATE_SCENARIO_SUCCESS';
export const COPY_SCENARIO = 'COPY_SCENARIO';
export const NO_AVAILABLE_SCENARIOS = 'NO_AVAILABLE_SCENARIOS';
export const ACTIVATE_SCENARIO = 'ACTIVATE_SCENARIO';
export const COMPLETE_SCENARIO = 'COMPLETE_SCENARIO';
export const REOPEN_SCENARIO = 'REOPEN_SCENARIO';


export function createNewScenario(scenario) {
  return {
    type: CREATE_NEW_SCENARIO,
    payload: scenario
  }
}

export function assignNewScenario(scenario) {
  return {
    type: ASSIGN_NEW_SCENARIO,
    payload: {
      scenarios: scenario
    }
  }
}

export function fetchScenarios(date, id) {
  return {
    type: FETCH_SCENARIOS,
    payload: {
      date: date,
      selectedId: id
    }
  }
}

export function setScenarios(scenarios, id) {
  return {
    type: SET_SCENARIOS,
    payload: {
      scenarios: scenarios,
      selectedId: id
    }
  }
}

export function selectScenario(id) {
  return {
    type: SELECT_SCENARIO,
    payload: {
      id: id
    }
  }
}

export function deleteScenario(scenarioId) {
  return {
    type: DELETE_SCENARIO,
    payload: scenarioId
  }
}

export function updateScenario(scenario) {
  return {
    type: UPDATE_SCENARIO,
    payload: scenario
  }
}

export function updateScenarioSuccess(scenario) {
  return {
    type: UPDATE_SCENARIO_SUCCESS,
    payload: scenario
  }
}

export function copyScenario(scenarioPayload) {
  return {
    type: COPY_SCENARIO,
    payload: scenarioPayload
  }
}

export function setNoAvailableScenarios() {
  return {
    type: NO_AVAILABLE_SCENARIOS
  }
}

export function activateScenario(scenarioId, scenarioDate) {
  return {
    type: ACTIVATE_SCENARIO,
    payload: {
      scenarioId: scenarioId,
      scenarioDate: scenarioDate
    }
  }
}

export function completeScenario(scenarioId) {
  return {
    type: COMPLETE_SCENARIO,
    payload: {
      scenarioId: scenarioId
    }
  }
}

export function reopenScenario(scenarioId, password) {
  return {
    type: REOPEN_SCENARIO,
    payload: {
      scenarioId: scenarioId,
      password: password
    }
  }
}

const initialState = {
  selectedScenario: {},
  currentDateSelected: getDateIsoFormat(),
  byId: {}, // date, id, name, updated, published,
};

function scenarioState(state = initialState, action) {
  switch(action.type) {
    case FETCH_SCENARIOS:
      return {...state, currentDateSelected: action.payload.date};
    case ASSIGN_NEW_SCENARIO:
      return _assignNewScenario(state, action);
    case SET_SCENARIOS:
      return _setScenarios(state, action);
    case SELECT_SCENARIO:
      return _selectScenario(state, action);
    case DELETE_SCENARIO:
      return _deleteScenario(state, action);
    case UPDATE_SCENARIO_SUCCESS:
      return _updateScenario(state, action);
    case NO_AVAILABLE_SCENARIOS:
      return _setNoAvailableScenarios(state);
    case SUCCESSFUL_ACTION:
      return state;
    default:
      return state;
  }
}

export default scenarioState;

function _setNoAvailableScenarios(state) {
  return {
    ...state,
    selectedScenario: null
  }
}

function formatScenario(scenario) {
  return {
    date: formatDateStringIso(scenario.scenarioDate),
    id: scenario.id,
    name: scenario.name,
    published: scenario.published,
    publishDate: scenario.publishDate,
    wasPublished: scenario.wasPublished,
    comments: scenario.comments,
    active: scenario.active,
    copiedName: scenario.copiedScenarioName,
    copiedDate: scenario.copiedScenarioDate,
    isReopened: scenario.isReopened,
    isClosed: scenario.isClosed,
    isPartiallyPublished: scenario.isPartiallyPublished,
    scenarioPartialPublishmentList: scenario.scenarioPartialPublishmentList
  };
}

function _setScenarios(state, action) {
  const scenarios =
  (action.payload.scenarios instanceof Array)
  ? action.payload.scenarios
  : [action.payload.scenarios]
  const scenarioObjects = scenarios.map(scenario => {
    return {
      [scenario.id]: formatScenario(scenario)
    }
  });
  const firstScenarioId = first(scenarios).id;
  const newState = scenarioObjects.reduce((stateAcc, scenario) => ({...stateAcc, ...scenario}), {...state.byId});
  const selectedId = action.payload.selectedId;
  const selectedScenario = selectedId? newState[selectedId]: newState[firstScenarioId];
  return {
    ...state,
    selectedScenario: selectedScenario,
    byId: newState
  };
}

function _selectScenario(state, action) {
  const targetScenario = state.byId[action.payload.id];
  return {...state, selectedScenario: targetScenario};
}

function _assignNewScenario(state, action) {
  const setScenarioState = _setScenarios(state, action);
  const newScenarioId = action.payload.scenarios.id;
  const selectScenario = setScenarioState.byId[newScenarioId];
  return {
    ...setScenarioState,
    selectedScenario: selectScenario
  }
}

function _deleteScenario(state, action) {
  const updatedScenarioState = Object.keys(state.byId).reduce((stateAcc, scenarioId) => {
    if (scenarioId !== action.payload) {
      stateAcc[scenarioId] = state.byId[scenarioId];
    }
    return stateAcc;
  }, {});
  const scenariosOfSelectedDate = Object.keys(updatedScenarioState)
    .map(key => updatedScenarioState[key])
    .filter(scenario => scenario.date === state.currentDateSelected);
  const updatedSelected = first(scenariosOfSelectedDate);
  const validScenario = updatedSelected? updatedSelected: null;
  return {
    ...state,
    byId: updatedScenarioState,
    selectedScenario: validScenario,
  }

}

function _updateScenario(state, action) {
  const scenario = formatScenario(action.payload);
  const updatedScenarioState = Object.keys(state.byId).reduce((stateAcc, scenarioId) => {
    if (scenarioId === action.payload.scenarioId) {
      stateAcc[scenarioId] = scenario;
    } else {
      stateAcc[scenarioId] = state.byId[scenarioId];
    }
    return stateAcc;
  }, {});
  return {
    ...state,
    byId: updatedScenarioState,
    selectedScenario: scenario
  }
}
