import React from 'react';
import range from 'lodash/range';

class HourMarkers extends React.Component {

  getHourMarkerElement(index) {
    let containerStyle = {
      marginLeft: (120 * index)
    }

    let halfHourStyle = {
      visibility: (index < 0)? 'hidden': 'visible'
    }

    return (
      <div className="hour-marker-container" style={containerStyle} key={index}>
        <div className="half-hour-marker" style={halfHourStyle} />
        <div className="hour-marker" />
      </div>
    );
  }

  getHourMarkers() {
    return range(-1, this.props.numberOfHours).map(index => {
      return this.getHourMarkerElement(index);
    });
  }
  
  render() {
    return (
      <div className="hour-markers-container">
        {this.getHourMarkers()}
      </div>
    );
  }
}

export default HourMarkers;