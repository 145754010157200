import axios from 'axios';
import store from '../store';
import { setBlockYarders, setBlockVendors, setBlockProcessors, setBlockHammerMarks, setBlockFallers, setBlockContractors } from '../reducers/blockProperties';
import { getAuthService } from '../services/AuthService';

class BlockPropertiesService {
  static getBlockYardersApi() {
    return axios.get('/api/blockYarders', { headers: getAuthService().header });
  }


  static getBlockYarders() {
    return this.getBlockYardersApi()
      .then((blockYarders) => {
        store.dispatch(setBlockYarders(blockYarders.data))
      })
      .catch(error => console.log(error));
  }

  static getBlockVendorsApi() {
    return axios.get('/api/blockVendors', { headers: getAuthService().header });
  }


  static getBlockVendors() {
    return this.getBlockVendorsApi()
      .then((blockVendors) => {
        store.dispatch(setBlockVendors(blockVendors.data))
      })
      .catch(error => console.log(error));
  }

  static getBlockProcessorsApi() {
    return axios.get('/api/blockProcessors', { headers: getAuthService().header });
  }


  static getBlockProcessors() {
    return this.getBlockProcessorsApi()
      .then((blockProcessors) => {
        store.dispatch(setBlockProcessors(blockProcessors.data))
      })
      .catch(error => console.log(error));
  }

  static getBlockHammerMarksApi() {
    return axios.get('/api/blockHammerMarks', { headers: getAuthService().header });
  }


  static getBlockHammerMarks() {
    return this.getBlockHammerMarksApi()
      .then((blockHammerMarks) => {
        store.dispatch(setBlockHammerMarks(blockHammerMarks.data))
      })
      .catch(error => console.log(error));
  }

  static getBlockFallersApi() {
    return axios.get('/api/blockFallers', { headers: getAuthService().header });
  }


  static getBlockFallers() {
    return this.getBlockFallersApi()
      .then((blockFallers) => {
        store.dispatch(setBlockFallers(blockFallers.data))
      })
      .catch(error => console.log(error));
  }

  static getBlockContractorsApi() {
    return axios.get('/api/blockContractors', { headers: getAuthService().header });
  }


  static getBlockContractors() {
    return this.getBlockContractorsApi()
      .then((blockContractors) => {
        store.dispatch(setBlockContractors(blockContractors.data))
      })
      .catch(error => console.log(error));
  }
}

export default BlockPropertiesService;
