import React from 'react';
import uniqid from 'uniqid';
import TruckScenarioDelete from './TruckScenarioDelete';
import find from 'lodash/find';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import { showScenarioTruckDashboard } from '../reducers/dashboard';
import { clearHighlights } from '../reducers/highlights';

class TruckScheduleRowHeader extends React.Component {

  componentDidUpdate() {
    if (this.isHighlighted()) {
      const scenarioTruckDashboardInfo = this.getDashboardInfo();
      this.props.showScenarioTruckDashboard(scenarioTruckDashboardInfo);
    }
  }

  isHighlighted() {
    return this.props.clickedItemId === this.props.scenarioTruck.scenarioTruckId;
  }

  getStyle() {
    if (this.isHighlighted()) {
      return {border: '3px solid yellow'};
    }
    if (this.props.isClosed && !this.props.scenarioTruck.isAuthorized) {
      return {borderTop: '2px solid red'};
    }
    if (this.isScheduledTwice(this.props.scenarioTruck.truckId)) {
      return {border: '4px dashed darkgreen'};
    }
  }

  isScheduledTwice(truckId) {
    const truckIds = []
    this.props.scheduleRowState.forEach(element => {
      truckIds.push(element.truckId);
    });
    var count = truckIds.filter(x => x === truckId).length;
    if (count > 1) {
      return true;
    }
    else{
      return false;
    }
  }

  getDashboardInfo() {
    const scenarioTruck = this.props.scenarioTruck;
    const trailerId = scenarioTruck.trailerId
    const truckId = scenarioTruck.truckId;
    const driverId = scenarioTruck.driverId;
    const truck = find(this.props.truckList, {id: truckId});
    const truckCompany = find(this.props.truckCompanies, {id: truck.truckCompanyId})
    const trailer = find(this.props.trailerList, {id: trailerId});
    const driver = find(this.props.driverList, {id: driverId});
    const truckName = truck? truck.name : "";
    const trailerName = trailer? trailer.trailerName : "";
    const driverName = driver? driver.name : "";
    const workDayDuration = scenarioTruck? scenarioTruck.workDayMinutes : "";
    const startTime = scenarioTruck? format(scenarioTruck.startTime, "h:mma") : "";
    const truckCompanyName = truckCompany? truckCompany.name : "";
    const licensePlate = truck? truck.licensePlate: "";
    const comments = scenarioTruck.comments? scenarioTruck.comments: "";
    const dispatcherComment = scenarioTruck.dispatcherComment? scenarioTruck.dispatcherComment: "";

    const scenarioTruckDashboardInfo = {
      truckName: truckName, 
      trailerName: trailerName, 
      driverName: driverName,
      workDayDuration: workDayDuration,
      startTime: startTime,
      truckCompanyName: truckCompanyName,
      licensePlate: licensePlate,
      comments: comments,
      dispatcherComment: dispatcherComment
    }

    return scenarioTruckDashboardInfo;
  }

  showScenarioTruckDashboard() {
    const scenarioTruck = this.props.scenarioTruck;
    const scenarioTruckDashboardInfo = this.getDashboardInfo();
    this.props.showScenarioTruckDashboard(scenarioTruckDashboardInfo);
    this.props.clickHandler({type: 'SCENARIO', id: scenarioTruck.scenarioTruckId}); //fucked up
    this.props.clearHighlights();
  }

  render() {
    const scenarioTruck = this.props.scenarioTruck;
    const scenarioTruckId = scenarioTruck.scenarioTruckId;
    return (
      <div className='scenario-truck-item' 
        style={this.getStyle()}
        onClick={() => this.showScenarioTruckDashboard()}>
        <div key={uniqid('truck')} className='truck-name'>
          {this.props.truckName}
        </div>
        <TruckScenarioDelete scenarioTruckId={scenarioTruckId} />
      </div>
    ); 
  }
}

const mapStateToProps = (state) => {
  return {
    driverList: state.drivers.driverList,
    truckList: state.trucks.truckList,
    trailerList: state.trailers.trailerList,
    isClosed: state.scenarioState.selectedScenario.isClosed,
    truckCompanies: state.truckCompanies.truckCompaniesList,
    scheduleRowState: state.scheduleRowState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showScenarioTruckDashboard: (scenarioTruckDashboardInfo) => {
      dispatch(showScenarioTruckDashboard(scenarioTruckDashboardInfo));
    },
    clearHighlights: () => {
      dispatch(clearHighlights());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckScheduleRowHeader);