import { setContractors } from '../reducers/contractors';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class ContractorService {
  static getContractorsApi() {
    return axios.get('/api/contractors', { headers: getAuthService().header });
  }

  static getContractors() {
    return this.getContractorsApi()
      .then((contractor) => {
        store.dispatch(setContractors(contractor.data))
      })
      .catch(error => console.log(error));
  }
}

export default ContractorService;