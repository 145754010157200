import axios from 'axios';
import store from '../store';
import {setErrorCodes} from '../reducers/errorCodes';
import { getAuthService } from '../services/AuthService';

class ErrorCodeService {
    static getErrorCodesApi() {
        return axios.get('/api/errorCodes', { headers: getAuthService().header });
    }

    static getErrorCodes() {
        return this.getErrorCodesApi()
            .then((errorCodes) => {
                store.dispatch(setErrorCodes(errorCodes.data))
            })
            .catch(error => console.log(error));
    }
}

export default ErrorCodeService;