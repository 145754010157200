import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { changeOperatingUnit } from '../../reducers/operatingUnits';
import { ADMIN } from '../../constants/userRole';
import includes from 'lodash/includes';

class OperatingUnitModalSelector extends React.Component {
  constructor(props) {
    super(props);
    const defaultIds = props.selectedOperatingUnitIds? props.selectedOperatingUnitIds: [];
    
    this.state = {
      selectedOperatingUnitIds: defaultIds,
      isModalOpen: false,
    }

    this.handleSelect = this.handleSelect.bind(this);
    this.selectAllOperatingUnits = this.selectAllOperatingUnits.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }


  showModal() {
    this.setState({
      isModalOpen: true,
      selectedOperatingUnitIds: this.props.selectedOperatingUnitIds
    });
  }

  hideModal() {
    this.setState({
      isModalOpen: false,
      //selectedOperatingUnitIds: this.props.selectedOperatingUnitIds
    });
  }

  isOperatingUnitSelected(id) {
    return includes(this.state.selectedOperatingUnitIds, id);
  }


  getOperatingUnitOptions() {
    const options = this.props.operatingUnits
      .filter(operatingUnit => operatingUnit.active && !operatingUnit.isDeleted)
      .map(operatingUnit => {
      const id = operatingUnit.id;
      return <div className='operating-unit-filter-row' key={id}>
          <input type='checkbox' value={id}
            checked={this.isOperatingUnitSelected(id)}
            onChange={this.handleSelect}/>
          <label htmlFor={id}>{operatingUnit.name}</label>
        </div>
    });
    return options;
  }

  handleSelect(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let selectedOperatingUnitIds = [];
    if (isChecked) {
      selectedOperatingUnitIds = [...this.state.selectedOperatingUnitIds, id];
    } else {
      selectedOperatingUnitIds = this.state.selectedOperatingUnitIds.filter(opUnitId => opUnitId !== id);
    }

    this.setState({
      selectedOperatingUnitIds: selectedOperatingUnitIds
    });
  }

  selectAllOperatingUnits() {
    this.setState({
      selectedOperatingUnitIds: this.props.operatingUnits.map(operatingUnit => operatingUnit.id)
    })
  }

  clickDone() {
    const selected = this.state.selectedOperatingUnitIds;
    this.props.changeOperatingUnit(selected);
    this.setState({
      isModalOpen: false,
      selectedOperatingUnitIds: selected
    }); 
  }

  getModal() {
    if (this.state.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            top: '100px',
            left: '945px',
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.hideModal}
        >
          <p>Select Operating Units</p>
          <div className='operating-unit-filter-options-container'>
            <button className='select-all-button' onClick={this.selectAllOperatingUnits}>Select All</button>
            {this.getOperatingUnitOptions()}
          </div>

          <div className='operating-unit-modal-buttons'>
            <button className='cancel-button' onClick={this.hideModal}>Cancel</button>
            <button className='done-button' onClick={this.clickDone}>Done</button>
          </div>

        </ReactModal>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='operating-unit-selection-container'>
        <button className='operating-unit-select-button' onClick={this.showModal}>Operating Unit</button>
        {this.getModal()}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    operatingUnits: state.operatingUnits.operatingUnitList,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    isAdmin: state.users.user.role === ADMIN
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeOperatingUnit: (ids) => {
      dispatch(changeOperatingUnit(ids));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OperatingUnitModalSelector);