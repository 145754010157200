import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

const SET_OPERATING_UNITS = 'SET_OPERATING_UNITS';
const SET_OPERATING_UNIT_ASSIGNMENT = 'SET_OPERATING_UNIT_ASSIGNMENT';
const CHANGE_OPERATING_UNITS_SELECTED = 'CHANGE_OPERATING_UNITS_SELECTED';
export const ADD_OPERATING_UNIT = 'ADD_OPERATING_UNIT';
export const ADD_OPERATING_UNIT_SUCCESS = 'ADD_OPERATING_UNIT_SUCCESS';
export const REMOVE_OPERATING_UNIT = 'REMOVE_OPERATING_UNIT';
export const REMOVE_OPERATING_UNIT_SUCCESS = 'REMOVE_OPERATING_UNIT_SUCCESS';
export const UPDATE_OPERATING_UNIT = 'UPDATE_OPERATING_UNIT';
export const UPDATE_OPERATING_UNIT_SUCCESS = 'UPDATE_OPERATING_UNIT_SUCCESS';
export const UPDATE_USER_OPERATING_UNIT = 'UPDATE_USER_OPERATING_UNIT';
const UPDATE_OPERATING_UNIT_ASSIGNMENT = 'UPDATE_OPERATING_UNIT_ASSIGNMENT';

export function addOperatingUnit(operatingUnit) {
  return {
    type: ADD_OPERATING_UNIT,
    payload: operatingUnit
  }
}

export function addOperatingUnitSuccess(operatingUnit) {
  return {
    type: ADD_OPERATING_UNIT_SUCCESS,
    payload: operatingUnit
  }
}

export function updateOperatingUnit(operatingUnit) {
  return {
    type: UPDATE_OPERATING_UNIT,
    payload: operatingUnit,
  }
}

export function updateOperatingUnitSuccess(operatingUnit) {
  return {
    type: UPDATE_OPERATING_UNIT_SUCCESS,
    operatingUnit: operatingUnit
  }
}

export function removeOperatingUnit(operatingUnitId) {
  return {
    type: REMOVE_OPERATING_UNIT,
    payload: operatingUnitId
  }
}

export function removeOperatingUnitSuccess(operatingUnitId) {
  return {
    type: REMOVE_OPERATING_UNIT_SUCCESS,
    payload: operatingUnitId
  }
}

export function setOperatingUnits(operatingUnits) {
  return {
    type: SET_OPERATING_UNITS,
    operatingUnits: operatingUnits
  }
}

export function setOperatingUnitAssignment(assignment) {
  return {
    type: SET_OPERATING_UNIT_ASSIGNMENT,
    assignment: assignment
  }
}

export function changeOperatingUnit(ids) {
  return {
    type: CHANGE_OPERATING_UNITS_SELECTED,
    payload: ids
  }
}

export function updateUserOperatingUnit(userResponse) {
  return {
    type: UPDATE_USER_OPERATING_UNIT,
    payload: userResponse
  }
}

export function updateOperatingUnitAssignment(configObjectId, operatingUnitId) {
  return {
    type: UPDATE_OPERATING_UNIT_ASSIGNMENT,
    payload: {
      configObjectId: configObjectId,
      operatingUnitId: operatingUnitId
    }
  }
}

const initialState = {
  operatingUnitList: [],
  operatingUnitAssignment: [],
  selectedOperatingUnitIds: []
}

export default function operatingUnits(state = initialState, action) {
  switch(action.type) {
    case SET_OPERATING_UNITS:
      return _setOperatingUnits(state, action);
    case ADD_OPERATING_UNIT_SUCCESS:
      return _addOperatingUnit(state, action);
    case REMOVE_OPERATING_UNIT_SUCCESS: 
      return _removeOperatingUnit(state, action);
    case UPDATE_OPERATING_UNIT_SUCCESS:
      return _updateOperatingUnit(state, action);
    case SET_OPERATING_UNIT_ASSIGNMENT:
      return _setOperatingUnitAssignment(state, action);
    case UPDATE_OPERATING_UNIT_ASSIGNMENT:
      return _updateOperatingUnitAssignment(state, action);
    case CHANGE_OPERATING_UNITS_SELECTED:
      return _changeOperatingUnit(state, action);
    case UPDATE_USER_OPERATING_UNIT:
      return _updateUserOperatingUnit(state, action);
    default:
      return state;
  }
}

function _updateOperatingUnitAssignment(state, action) {
  const configObjectId = action.payload.configObjectId;
  const operatingUnitId = action.payload.operatingUnitId;
  const targetAssign = find(state.operatingUnitAssignment, {configObjectId: configObjectId});
  let updatedAssign = [];

  if (targetAssign) {
    targetAssign.operatingUnitId = operatingUnitId;
    updatedAssign = state.operatingUnitAssignment;
  } else {
    updatedAssign = [...state.operatingUnitAssignment, action.payload];
  }

  return {
    ...state,
    operatingUnitAssignment: updatedAssign
  }
}

function _changeOperatingUnit(state, action) {
  return {
    ...state,
    selectedOperatingUnitIds: action.payload
  };
}

function sortByName(objects) {
  return sortBy(objects, obj => obj.name);
}

function _setOperatingUnitAssignment(state, action) {
  return {
    ...state,
    operatingUnitAssignment: action.assignment
  }
}

function _setOperatingUnits(state, action) {
  return {
    ...state,
    operatingUnitList: action.operatingUnits
  };
}

function _addOperatingUnit(state, action) {
  const operatingUnits = sortByName([...state.operatingUnitList, action.payload]);
  return {
    ...state,
    operatingUnitList: operatingUnits
  };
}

function _removeOperatingUnit(state, action) {
  //const operatingUnitId = action.payload;
  const operatingUnits = state.operatingUnitList
  // .filter(operatingUnit => {
  //   return operatingUnit.operatingUnitId !== operatingUnitId
  // });
  const assignment = state.operatingUnitAssignment
  // .filter(assignment => {
  //   return assignment.operatingUnitId !== operatingUnitId
  // });

  return {
    ...state,
    operatingUnitList: operatingUnits,
    operatingUnitAssignment: assignment

  };
}

function _updateOperatingUnit(state, action) {
  const updatedOperatingUnit = action.operatingUnit;
  const updatedOps = state.operatingUnitList.map(operatingUnit => {
    if (operatingUnit.id === updatedOperatingUnit.id) {
      return updatedOperatingUnit;
    } else {
      return operatingUnit;
    }
  });

  const sorted = sortByName(updatedOps);
  return {
    ...state,
    operatingUnitList: sorted
  };
}

function _updateUserOperatingUnit(state, action) {
  const selectedResponse = action.payload.selectedOperatingUnitIds;
  return {
    ...state,
    selectedOperatingUnitIds: selectedResponse
  }
}