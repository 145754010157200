import { addLocationReducer } from './addLocation';
import find from 'lodash/find';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { BLOCK } from '../constants/typeConstants';

export const ADD_LOCATION_TO_SCHEDULE = 'ADD_LOCATION_TO_SCHEDULE';
export const REMOVE_LOCATION_FROM_SCHEDULE = 'REMOVE_LOCATION_FROM_SCHEDULE';
export const SAVE_SCHEDULE = 'SAVE_SCHEDULE';
export const UPDATE_IDS_AFTER_SAVE = 'UPDATE_IDS_AFTER_SAVE';
export const FETCH_SCHEDULE = 'FETCH_SCHEDULE';
export const FETCH_SCENARIO_TRUCKS = 'FETCH_SCENARIO_TRUCKS';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const ADD_TRUCK_TO_SCHEDULE = 'ADD_TRUCK_TO_SCHEDULE';
export const CREATE_SCENARIO_TRUCK = 'CREATE_SCENARIO_TRUCK';
export const UPDATE_SCENARIO_TRUCK = 'UPDATE_SCENARIO_TRUCK';
export const SHARE_SCENARIO_TRUCK = 'SHARE_SCENARIO_TRUCK';
export const FETCH_SCHEDULE_BY_SCENARIO_TRUCK_ID = 'FETCH_SCHEDULE_BY_SCENARIO_TRUCK_ID';
export const REMOVE_TRUCK_SCENARIO = 'REMOVE_TRUCK_SCENARIO';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
const ADD_NEW_SCHEDULE = 'ADD_NEW_SCHEDULE';
const SET_START_TIME = 'SET_START_TIME';
export const SET_SCENARIO_TRUCKS = 'SET_SCENARIO_TRUCKS';
const SET_UPDATED_SCENARIO_TRUCK = 'SET_UPDATED_SCENARIO_TRUCK';
const SORT_ASCENDING_TRUCK_NAME = 'SORT_ASCENDING_TRUCK_NAME';
const SORT_DESCENDING_TRUCK_NAME = 'SORT_DESCENDING_TRUCK_NAME';
const SORT_ASCENDING_START_TIME = 'SORT_ASCENDING_START_TIME';
const SORT_DESCENDING_START_TIME = 'SORT_DESCENDING_START_TIME';
const SORT_ASCENDING_BLOCK_NAME = 'SORT_ASCENDING_BLOCK_NAME';
export const SWAP_ACTIVITY = 'SWAP_ACTIVITY';
export const UPDATE_SCHEDULE_SWAP = 'UPDATE_SCHEDULE_SWAP';
export const AUTHORIZE_SCENARIO_TRUCK = 'AUTHORIZE_SCENARIO_TRUCK';


export function swapActivity(swapPayload) {
  return {
    type: SWAP_ACTIVITY,
    payload: swapPayload
  }
}

export function updateScheduleSwap(updatePayload) {
  return {
    type: UPDATE_SCHEDULE_SWAP,
    payload: updatePayload
  }
}

export function updateSchedule(updatePayload) {
  return {
    type: UPDATE_SCHEDULE,
    payload: updatePayload
  }
}

export function updateActivity(updatePayload) {
  return {
    type: UPDATE_ACTIVITY,
    payload: updatePayload
  }
}

export function saveSchedule(savePayload) {
  return {
    type: SAVE_SCHEDULE,
    payload: savePayload
  }
}

export function addLocationToSchedule(rowId, locationId, loaderId) {
  const payload = {
    rowId: rowId,
    locationId: locationId,
    loaderId: loaderId
  }

  return {
    type: ADD_LOCATION_TO_SCHEDULE,
    payload: payload
  }
}

export function addYardWithSleeper(rowId, yardId, sleeperDestinationId, sleeperOriginBlockId) {
  return {
    type: ADD_LOCATION_TO_SCHEDULE,
    payload: {
      rowId: rowId,
      locationId: yardId,
      sleeperDestinationId: sleeperDestinationId,
      sleeperOriginBlockId: sleeperOriginBlockId
    }
  }
}

export function removeLocationFromSchedule(rowId, id, type) {
  return {
    type: REMOVE_LOCATION_FROM_SCHEDULE,
    payload: {
      rowId: rowId,
      id: id,
      type: type
    }
  }
}

export function createScenarioTruck(scenarioTruckState) {
  return {
    type: CREATE_SCENARIO_TRUCK,
    payload: scenarioTruckState
  }
}



export function shareScenarioTruck(scenarioTruckState) {
  return {
    type: SHARE_SCENARIO_TRUCK,
    payload: scenarioTruckState
  }
}



export function addTruckToSchedule(rowId, truckId, truckName, scenarioTruckInfo) {
  return {
    type: ADD_TRUCK_TO_SCHEDULE,
    payload: {
      rowId: rowId,
      truckId: truckId,
      name: truckName,
      scenarioTruck: scenarioTruckInfo
    }
  }
}

export function setScenarioTrucks(truckScenarios) {
  return {
    type: SET_SCENARIO_TRUCKS,
    payload: truckScenarios
  }
}

export function addNewSchedule(rowId, beginWorkHour) {
  return {
    type: ADD_NEW_SCHEDULE,
    payload: {
      id: rowId,
      beginWorkHour: beginWorkHour
    }
  }
}

export function setStartTime(rowId, startTime) {
  return {
    type: SET_START_TIME,
    id: rowId,
    payload: startTime
  }
}

export function updateIdAfterSave(responsePayload, originalPayload) {
  const latestActivity = last(responsePayload.scenarioActivities);
  const id = latestActivity.scenarioActivityId;
  return {
    type: UPDATE_IDS_AFTER_SAVE,
    payload: responsePayload,
    originalPayload: originalPayload,
    scenarioActivityId: id
  }
}

export function fetchSchedule(scenarioId) {
  return {
    type: FETCH_SCHEDULE,
    payload: {
      scenarioId: scenarioId
    }
  }
}

export function fetchScenarioTrucks(scenarioId) {
  return {
    type: FETCH_SCENARIO_TRUCKS,
    payload: {
      scenarioId: scenarioId
    }
  }
}

export function setSchedule(schedule) {
  return {
    type: SET_SCHEDULE,
    payload: {
      schedule: schedule
    }
  }
}

export function updateScenarioTruck(scenarioTruckState) {
  return {
    type: UPDATE_SCENARIO_TRUCK,
    payload: scenarioTruckState
  }
}

export function authorizeScenarioTruck(scenarioTruckId) {
  return {
    type: AUTHORIZE_SCENARIO_TRUCK,
    payload: {
      scenarioTruckId: scenarioTruckId
    }
  }
}

export function setUpdatedScenarioTruck(scenarioTruck) {
  return {
    type: SET_UPDATED_SCENARIO_TRUCK,
    payload: {
      scenarioTruck: scenarioTruck
    }
  }
}

export function fetchScheduleByScenarioTruck(scenarioTruckId) {
  return {
    type: FETCH_SCHEDULE_BY_SCENARIO_TRUCK_ID,
    payload: {
      id: scenarioTruckId
    }
  }
}

export function removeTruckScenario(scenarioTruckId) {
  return {
    type: REMOVE_TRUCK_SCENARIO,
    payload: {
      id: scenarioTruckId
    }
  }
}

export function sortAscendingTruckName() {
  return {
    type: SORT_ASCENDING_TRUCK_NAME
  }
}

export function sortDescendingTruckName() {
  return {
    type: SORT_DESCENDING_TRUCK_NAME
  }
}

export function sortAscendingStartTime() {
  return {
    type: SORT_ASCENDING_START_TIME
  }
}

export function sortDescendingStartTime() {
  return {
    type: SORT_DESCENDING_START_TIME
  }
}

export function sortAscendingBlockName() {
  return {
    type: SORT_ASCENDING_BLOCK_NAME
  }
}

const scheduleRowState = (state = [{
  rowId: '',
  truckId: '',
  startTime: {},
  schedule: []
}], action) => {
  switch (action.type) {
    case ADD_NEW_SCHEDULE:
      return addNewScheduleReducer(state, action);
    case ADD_LOCATION_TO_SCHEDULE:
      return addLocationReducer(state, action);
    case ADD_TRUCK_TO_SCHEDULE:
      return addTruck(state, action);
    case SET_START_TIME:
      return setStartTimeReducer(state, action);
    case UPDATE_IDS_AFTER_SAVE:
      return updateIdReducer(state, action);
    case SET_SCHEDULE:
      return setScheduleReducer(state, action);
    case SET_SCENARIO_TRUCKS:
      return setScenarioTrucksReducer(state, action);
    case SET_UPDATED_SCENARIO_TRUCK:
      return setUpdatedScenarioTruckReducer(state, action);
    case REMOVE_TRUCK_SCENARIO:
      return removeTruckScenarioReducer(state, action);
    case SORT_ASCENDING_TRUCK_NAME:
      return _sortAscendingTruckName(state);
    case SORT_DESCENDING_TRUCK_NAME:
      return _sortDescendingTruckName(state);
    case SORT_ASCENDING_START_TIME:
      return _sortAscendingStartTime(state);
    case SORT_DESCENDING_START_TIME:
      return _sortDescendingStartTime(state);
    case SORT_ASCENDING_BLOCK_NAME:
      return _sortAscendingBlockName(state);
    default:
      return state;
  }
};

function _sortAscendingBlockName(state) {
  const blockSort = (row) => {
    const firstBlock = find(row.schedule, {type: BLOCK});
    if (firstBlock) {
      return firstBlock.name;
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  return sortBy(state, [blockSort]);
}

function _sortAscendingTruckName(state) {
  const sorted = sortBy(state, [(row) => row.truckName]);
  return sorted;
}

function _sortDescendingTruckName(state) {
  const sorted = _sortAscendingTruckName(state);
  return sorted.reverse();
}

function getFirstScheduledStartValue(scheduleRow) {
  const firstSchedule = scheduleRow.schedule[0];
  if (firstSchedule) {
    return firstSchedule.dateObjects.locationStartTime.valueOf();
  } else {
    return null;
  }
}

function _sortAscendingStartTime(state) {
  const startSort = (row) => {
    const firstSchedule = getFirstScheduledStartValue(row);
    if (firstSchedule) {
      return firstSchedule;
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  return sortBy(state, [startSort]);
}

function _sortDescendingStartTime(state) {
  const startSort = (row) => {
    const firstSchedule = getFirstScheduledStartValue(row);
    if (firstSchedule) {
      return firstSchedule;
    } else {
      return Number.MIN_SAFE_INTEGER;
    }
  }

  const sorted = sortBy(state, [startSort]);
  return sorted.reverse();
}

function removeTruckScenarioReducer(state, action) {
  return state.filter(row => {
    return (row.rowId !== action.payload.id)
  });
}

function setUpdatedScenarioTruckReducer(state, action) {
  return state.map(row => 
    (row.scenarioTruckId === action.payload.scenarioTruck.scenarioTruckId)
    ? {
      ...row,
      truckId: action.payload.scenarioTruck.truckId,
      truckName: action.payload.scenarioTruck.truckName,
      scenarioTruck: action.payload.scenarioTruck,
      workDayMinutes: action.payload.scenarioTruck.workDayMinutes
    }
    : row
  );
}

function setScenarioTrucksReducer(state, action) {
  const truckScenarios = action.payload;
  const scenarioTrucks = truckScenarios.reduce((stateAcc, scenarioTruck) => {
    const scheduleRow = {
      scenarioTruck: scenarioTruck,
      rowId: scenarioTruck.scenarioTruckId,
      truckId: scenarioTruck.truckId,
      truckName: scenarioTruck.truckName,
      workDayMinutes: scenarioTruck.workDayMinutes,
      schedule: [],
      startTime: {}
    }
    return stateAcc.concat(scheduleRow); 
  }, []);
  return scenarioTrucks;
}

function addNewScheduleReducer(state, action) {
  return [
    ...state,
    {
      rowId: action.payload.id,
      beginWorkHour: action.payload.beginWorkHour,
      truckId: '',
      startTime: {},
      schedule: []
    }
  ];
}

function addTruck(state, action) {
  return state.map(row =>
    (row.rowId === action.payload.rowId)
    ? {
      ...row, 
      rowId: action.payload.scenarioTruck.scenarioTruckId,
      truckId: action.payload.truckId, 
      truckName: action.payload.name, 
      scenarioTruck: action.payload.scenarioTruck,
    }
    : row
  );
}

function setStartTimeReducer(state, action) {
  return state.map(row =>
    (row.rowId === action.id)
    ? {...row, startTime: action.payload}
    : row
  );
}

function updateIdReducer(state, action) {
  return state.map(row => {
    if (row.rowId === action.originalPayload.meta.rowId) {
      return updateId(row, action);
    } else {
      return row;
    }
  });
}

function updateId(row, action) {
  const instanceId = action.originalPayload.meta.instanceId;
  const updatedIdsInSchedule = row.schedule.map(schedule => {
    if (schedule.id === instanceId) {
      return {
        ...schedule,
        id: action.scenarioActivityId,
        oldId: instanceId
      }
    } else {
      return schedule;
    }
  });
  return {
    ...row,
    schedule: updatedIdsInSchedule,

  };
}

function setScheduleReducer(state, action) {
  const updatedSchedule = state.map(scheduleRow => {
    const currentActivity = find(action.payload, {scenarioTruckId: scheduleRow.rowId});
    if (currentActivity) {
      return {
        ...scheduleRow,
        scenarioTruckId: currentActivity.scenarioTruckId,
        schedule: currentActivity.schedule,
        startTime: currentActivity.startTime
      }
    } else {
      return scheduleRow;
    }
  });
  return updatedSchedule;
}

export default scheduleRowState;
