import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeScenarioTruckModal } from '../../reducers/modalWindows';
import { addNewSchedule, createScenarioTruck, setStartTime, updateScenarioTruck } from '../../reducers/schedule';
import StartTimeSetter from '../StartTimeSetter';
import { startTimeOfScheduledItem } from '../../utils/dateTimeHelpers';
import get from 'lodash/get';
import { emptyUuid } from '../../utils/uuidHelper';
import find from 'lodash/find';
import { isTruckInSchedule } from '../../utils/truckHelpers';
import { matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';

class ScenarioTruckModal extends React.Component {

  constructor(props) {
    super(props);
    
    this.nullId = emptyUuid;
    ReactModal.setAppElement('#root');
    const propComments = props.comments;
    const validComments = propComments? propComments: "";
    const validDispatcherComments = get(props.scenarioTruck, 'dispatcherComment', '');

    this.state = {
      isModified: false,
      trailerId: this.nullId,
      driverId: this.nullId,
      startHour: this.props.beginHour,
      startMinute: 0,
      workDayMinutes: this.props.defaultWorkDayMinutes,
      comments: validComments,
      dispatcherComment: validDispatcherComments,
      truckId: props.truckId
    }

    if (!props.isAddingNewTruck) {
      this.state.driverId = props.scenarioTruck.driverId;
      this.state.trailerId = props.scenarioTruck.trailerId;
      this.state.startHour = props.startTime.startHour;
      this.state.startMinute = props.startTime.startMinute;
      this.state.workDayMinutes = props.workDayMinutes;
      this.state.truckId = props.truckId;
    } else {
      this.state = this.setDefaultValues(this.state, props.truckId);
    }


    this.handleChangeTrailer = this.handleChangeTrailer.bind(this);
    this.handleChangeDriver = this.handleChangeDriver.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.handleMinuteChange = this.handleMinuteChange.bind(this);
    this.handleChangeWorkDayMinutes = this.handleChangeWorkDayMinutes.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleDispatcherCommentChange = this.handleDispatcherCommentChange.bind(this);
    this.handleChangeTruck = this.handleChangeTruck.bind(this);
  }

  setDefaultValues(state, truckId) {
    const truck = find(this.props.truckList, {id: truckId});
    if (truck) {
      const defaultDriverId = truck.defaultDriver? truck.defaultDriver: emptyUuid;
      const defaultTrailerId = truck.defaultTrailer? truck.defaultTrailer: emptyUuid;
      return {
        ...state,
        driverId: defaultDriverId,
        trailerId: defaultTrailerId
      };
    }
  }

  modifyState() {
    this.setState({isModified: true});
  }

  handleChangeTruck(event) {
    event.preventDefault();
    this.modifyState();
    this.setState({truckId: event.target.value});
  }

  handleChangeWorkDayMinutes(event) {
    event.preventDefault();
    this.modifyState();
    this.setState({workDayMinutes: event.target.value});
  }

  handleChangeDriver(event) {
    event.preventDefault();
    this.modifyState();
    const driverId = event.target.value;
    this.setState({driverId: driverId});
  }

  handleChangeTrailer(event) {
    this.modifyState();
    event.preventDefault();
    const trailerId = event.target.value;
    this.setState({trailerId: trailerId});
  }

  handleCommentChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({comments: event.target.value});
  }

  handleDispatcherCommentChange(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({dispatcherComment: event.target.value});
  }

  getTrailerElements() {
    const noSelection = <option key='blank' value={this.nullId}>None</option>;
    const trailerOptions = this.props.trailerList.map(trailer => {
      const id = trailer.id;
      return <option key={id} value={id}> {trailer.trailerName} </option>;
    });
    trailerOptions.unshift(noSelection);
    return trailerOptions;
  }

  getTrailerPicker() {
    return (
      <select className='trailer-picker' value={this.state.trailerId}  onChange={this.handleChangeTrailer}>
        {this.getTrailerElements()}
      </select>
    );
  }

  getDriverElements() {
    const noSelection = <option key='blank' value={this.nullId}>None</option>;
    // const selectedDrivers = this.previouslySelectedDrivers();
    const activeDrivers = this.props.driverList.filter(driver => driver.active && !driver.isDeleted);
    // const notSelectedDrivers = activeDrivers.filter(element => !selectedDrivers.includes(element));
    const driverOptions = activeDrivers.map(driver => { //changed from notSelectedDrivers to Active drivers.
      const id = driver.id;
      return <option key={id} value={id}> {driver.name} - {driver.experience} </option>;
    });
    driverOptions.unshift(noSelection); 
    return driverOptions;
  }

  // previouslySelectedDrivers() {
  //   const selectedDrivers = [];
  //   this.props.scheduleRows.forEach(row => {
  //       const driver = find(this.props.driverList, {id: row.scenarioTruck.driverId});
  //       selectedDrivers.push(driver);
  //   });
  //   return selectedDrivers;
  // }

   
  getDriversAfterAddingBlock() {
    const scenarioTruckId = this.props.modalWindow.scenarioTruck.scenarioTruckId;
    const scheduleRow = this.props.scheduleRows.filter(row => row.rowId === scenarioTruckId);
    const schedules = scheduleRow.map(row => row.schedule);
    const rankings = []
    for (var i=0; i < schedules.length; i++) {
      const schedule = schedules[i];
      const blocksOfSchedule = schedule.filter(activity => activity.type === 'block')
      blocksOfSchedule.forEach(element => {
        const id = element.locationId;
        const block = find(this.props.blocks, {id : id});
        rankings.push(block.ranking);   
      });
      const maxRankOfBlocks = Math.max(...rankings);
      //So far we have the maximum block ranking of schedule.
      const noSelection = <option key='blank' value={this.nullId}>None</option>;
      const eligibleDrivers = this.props.driverList.filter(driver => driver.experience >= maxRankOfBlocks && !driver.isDeleted && driver.active);
      // const selectedDrivers = this.previouslySelectedDrivers();
      // const notSelectedDrivers = eligibleDrivers.filter(element => !selectedDrivers.includes(element));
      const driverOptions = eligibleDrivers.map(driver => { //change from notSelectedDrivers to eligibleDrivers
        const id = driver.id;
        return <option key={id} value={id}> {driver.name} - {driver.experience} </option>;
      });
      driverOptions.unshift(noSelection);
      return driverOptions;
    }
  }

  getDriverPicker() {
    if(this.props.isAddingNewTruck) {
      return (
        <select className='driver-picker' value={this.state.driverId} onChange={this.handleChangeDriver}>
          {this.getDriverElements()}
        </select>
      );
    }
    const scenarioTruckId = this.props.modalWindow.scenarioTruck.scenarioTruckId;
    const scheduleRow = this.props.scheduleRows.filter(row => row.rowId === scenarioTruckId);
    const schedules = scheduleRow.map(row => row.schedule)
    if (!this.props.isAddingNewTruck && schedules !== []) {
      return (
        <select className='driver-picker' value={this.state.driverId} onChange={this.handleChangeDriver}>
          {this.getDriversAfterAddingBlock()}
        </select>
      );
    } if (!this.props.isAddingNewTruck) {
      return (
        <select className='driver-picker' value={this.state.driverId} onChange={this.handleChangeDriver}>
          {this.getDriverElements()}
        </select>
      );
    }  
  }

  getStartTimeNumbers() {
    return {
      startHour: Number(this.state.startHour),
      startMinute: Number(this.state.startMinute)
    }
  }

  setStartTime(rowId) {
    const startTimeNumbers = this.getStartTimeNumbers();
    this.props.setStartTime(rowId, startTimeNumbers);
  }

  getSelectedTruckName() {
    const truck = find(this.props.truckList, {id: this.state.truckId});
    if (truck) {
      return truck.name;
    } else {
      return '';
    }
  }

  saveChanges() {
    const scenarioTruckState = this.state;
    const truckName = this.getSelectedTruckName();
    scenarioTruckState.truckName = truckName;
    if (this.props.isAddingNewTruck) {
      const rowId = this.props.rowId;
      scenarioTruckState.rowId = rowId;
      this.props.addNewSchedule(rowId, this.props.beginHour);
      this.setStartTime(rowId);
      scenarioTruckState.highlightTruck = this.props.highlightTruck;
      this.props.createScenarioTruck(scenarioTruckState);
    } else if (this.state.isModified) {
      scenarioTruckState.scenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
      scenarioTruckState.startTime = startTimeOfScheduledItem(this.getStartTimeNumbers(), this.props.currentDateSelected);
      this.props.updateScenarioTruck(scenarioTruckState);
    }
  }

  getTitleText() {
    if (this.props.isAddingNewTruck) {
      return 'Add New Truck To Schedule';
    } else {
      return 'Edit Truck Properties';
    }
  }

  handleHourChange(event) {
    this.modifyState();
    this.setState({startHour: event.target.value});
    event.preventDefault();
  }

  handleMinuteChange(event) {
    this.modifyState();
    this.setState({startMinute: event.target.value});
    event.preventDefault();
  }

  getStartTimeSetter() {
    return (
      <StartTimeSetter
        key={this.props.rowId}
        rowId={this.props.rowId}
        startHour={this.state.startHour}
        startMinute={this.state.startMinute}
        handleHourChange={this.handleHourChange}
        handleMinuteChange={this.handleMinuteChange}
      />
    );
  }

  getWorkdayDurationInput() {
    return (
      <input className='workday-duration-input'
        type='number' 
        value={this.state.workDayMinutes} 
        onChange={this.handleChangeWorkDayMinutes} />
    );
  }

  getTruckSelection() {
    return <select className='truck-selection' value={this.state.truckId} onChange={this.handleChangeTruck}>
      {this.props.truckList
        .filter(truck => (!isTruckInSchedule(truck.id) || truck.id === this.state.truckId) && matchesSelectedOperatingUnit(truck.id))
        .map(truck => {
          const id = truck.id;
          return <option key={id} value={id}>{truck.name}</option>;
      })}
    </select>
  }

  render() {
    const closeAndSave = () => {
      this.props.closeScenarioTruckModal();
      this.saveChanges();
    }
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          right: '60px',
          height: '520px',
          width: '395px',
          display: 'flex',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeScenarioTruckModal()}>
        <div className='scenario-truck-editor'>
          <h4>{this.getTitleText()}</h4>
          <div className='truck-picker-section'>
            <label>Truck</label>
            {this.getTruckSelection()}
          </div>
          <div className='trailer-picker-section'>
            <label>Trailer</label>
            {this.getTrailerPicker()}
          </div>
          <div className='driver-picker-section'>
            <label>Driver</label>
            {this.getDriverPicker()}
          </div>
          <div className='workday-duration-section'>
            <label>Work Day Duration (minutes)</label>
            {this.getWorkdayDurationInput()}
          </div>
          {this.getStartTimeSetter()}
          <div className='comments-section'>
            <div className='regular-comments'>
              <label className='comment-label'>Comments</label>
              <textarea className='comment-field' rows='4' cols='50' value={this.state.comments} onChange={this.handleCommentChange} />
            </div>
            <div className='dispatcher-comments'>
              <label className='dispatcher-comment-label'>Dispatcher Comments</label>
              <textarea className='dispatcher-comment-field' rows='4' cols='50' value={this.state.dispatcherComment} onChange={this.handleDispatcherCommentChange} />
            </div>
          </div>
          <div className='scenario-truck-editor-buttons'>
            <button onClick={() => this.props.closeScenarioTruckModal()} className='scenario-truck-editor-cancel'>Cancel</button>
            <button onClick={() => closeAndSave()} className='scenario-truck-editor-done'>Done</button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => {
  const activeTrucks = state.trucks.truckList.filter(truck => truck.active);
  return {
    trailerList: state.trailers.trailerList,
    driverList: state.drivers.driverList,
    scheduleRows: state.scheduleRowState,
    currentDateSelected: state.scenarioState.currentDateSelected ,
    defaultWorkDayMinutes: state.workHoursForSchedule.maxScheduledMinutes,
    truckList: activeTrucks,
    blocks: state.locations.blocks,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewSchedule: (rowId, beginHour) => {
      dispatch(addNewSchedule(rowId, beginHour));
    },
    createScenarioTruck: (scenarioTruckState) => {
      dispatch(createScenarioTruck(scenarioTruckState));
    },
    closeScenarioTruckModal: () => {
      dispatch(closeScenarioTruckModal());
    },
    setStartTime: (rowId, startTime) => {
      dispatch(setStartTime(rowId, startTime));
    },
    updateScenarioTruck: (scenarioTruckState) => {
      dispatch(updateScenarioTruck(scenarioTruckState))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTruckModal);