const SET_YARDERS = 'SET_YARDERS';
export const ADD_YARDER = 'ADD_YARDER';
export const ADD_YARDER_SUCCESS = 'ADD_YARDER_SUCCESS';
export const UPDATE_YARDER = 'UPDATE_YARDER';
export const UPDATE_YARDER_SUCCESS = 'UPDATE_YARDER_SUCCESS';
export const REMOVE_YARDER = 'REMOVE_YARDER';
export const REMOVE_YARDER_SUCCESS = 'REMOVE_YARDER_SUCCESS';
export const BULK_ACTIVATE_YARDERS = 'BULK_ACTIVATE_YARDERS';

export function setYarders(yarders) {
  return {
    type: SET_YARDERS,
    payload: yarders
  }
}

export function addYarder(yarder) {
  return {
    type: ADD_YARDER,
    payload: yarder
  }
}

export function addYarderSuccess(yarder) {
  return {
    type: ADD_YARDER_SUCCESS,
    payload: yarder
  }
}

export function updateYarder(yarder) {
  return {
    type: UPDATE_YARDER,
    payload: yarder
  }
}

export function updateYarderSuccess(yarder) {
  return {
    type: UPDATE_YARDER_SUCCESS,
    payload: yarder
  }
}

export function removeYarder(yarderId) {
  return {
    type: REMOVE_YARDER,
    payload: yarderId
  }
}

export function removeYarderSuccess(yarder) {
  return {
    type: REMOVE_YARDER_SUCCESS,
    payload: yarder
  }
}

export function bulkActivateYarders(yarderIds) {
  return {
    type: BULK_ACTIVATE_YARDERS,
    payload: yarderIds
  }
}

export default function yarders(state = {
  yardersList: []
}, action) {
  switch(action.type) {
    case SET_YARDERS:
      return _setYarders(state, action);
    case ADD_YARDER_SUCCESS:
      return _AddYarder(state, action);
    case UPDATE_YARDER_SUCCESS:
      return _updateYarder(state, action);
    case REMOVE_YARDER_SUCCESS:
      return _removeYarder(state, action);
    default:
      return state;
  }
}


function _setYarders(state, action) {
  return {
    ...state,
    yardersList: action.payload
  }
}

function _AddYarder(state, action) {
  const yarders = [...state.yardersList, action.payload];
  return {
    ...state,
    yardersList: yarders
  }
}

function _updateYarder(state, action) {
  const updatedYarder = action.payload;
  const updatedList = state.yardersList.map(yarder => {
    if(yarder.id === updatedYarder.id) {
      return updatedYarder;
    } else {
      return yarder;
    }
  });
  return {
    ...state,
    yardersList: updatedList
  }
}

function _removeYarder(state, action) {
  const updatedList = state.yardersList
  return {
    ...state,
    yardersList: updatedList
  }
}
