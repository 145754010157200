import React from 'react';

class LocationDelay extends React.Component {

  getStyle() {
    let styles = {};
    styles.width = this.props.delay * 2;
    return styles;
  }

  render() {
    return (
      <div className={`${this.props.type} location-delay`} style={this.getStyle()} />
    );
  }
}

export default LocationDelay;