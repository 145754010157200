import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { success, failure } from '../notificationMessages';
import { combineEpics } from 'redux-observable';
import { ADD_OPERATING_UNIT, addOperatingUnitSuccess, REMOVE_OPERATING_UNIT, removeOperatingUnitSuccess, UPDATE_OPERATING_UNIT, updateOperatingUnitSuccess } from '../../reducers/operatingUnits';
import { getAuthService } from '../AuthService';


export const addOperatingUnitEpic = action$ => (
  action$.ofType(ADD_OPERATING_UNIT)
    .mergeMap(action => (
      ajax.post('/api/operatingUnits', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addOperatingUnitSuccess(ajaxResponse.response), 'Successfully added new operating unit'))
        .catch(fail => Observable.of(failure('Could not add new operating unit')))
    ))
);

export const removeOperatingUnitEpic = action$ => (
  action$.ofType(REMOVE_OPERATING_UNIT)
    .mergeMap(action => (
      ajax.delete(`/api/operatingUnits/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeOperatingUnitSuccess(action.payload), 'Successfully removed operating unit'))
        .catch(fail => Observable.of(failure('Could not remove operating unit')))
    ))
);

export const updateOperatingUnitEpic = action$ => (
  action$.ofType(UPDATE_OPERATING_UNIT)
    .mergeMap(action => (
      ajax.put('/api/operatingUnits', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateOperatingUnitSuccess(ajaxResponse.response), 'Successfully updated operating unit'))
        .catch(fail => Observable.of(failure('Could not update operating unit')))
    ))
);

export const operatingUnitEpics = combineEpics(
  addOperatingUnitEpic,
  removeOperatingUnitEpic,
  updateOperatingUnitEpic
);