import React from 'react';
import { connect } from 'react-redux';
import { updateBlockLoad, removeBlockLoad } from '../reducers/blockLoads';

class BlockLoadRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOrdered: this.isOrdered(),
      currentLoadIndex: props.order
    }

    this.handleRemoveBlockLoad = this.handleRemoveBlockLoad.bind(this);
    this.handleChangeLoad = this.handleChangeLoad.bind(this);
    this.handleChangeDestination = this.handleChangeDestination.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOrdered !== this.state.isOrdered) {
      this.handleChange(this.props.loadValue, this.props.selectedDestination);
    }
  }

  handleRemoveBlockLoad() {
    const payload = {
      scenarioId: this.props.currentScenarioId,
      blockId: this.props.id,
      blockLoadId: this.props.blockLoadId,
    };
    this.props.modifyState();
    this.props.removeBlockLoad(payload);
  }

  handleChange(loads, destination) {
    const loadOrderAbsValue = Math.abs(this.state.currentLoadIndex);
    const loadOrder = this.state.isOrdered? loadOrderAbsValue: (-1 * loadOrderAbsValue);
    const payload = {
      scenarioId: this.props.currentScenarioId,
      blockId: this.props.id,
      blockLoadId: this.props.blockLoadId,
      blockLoad: {
        loads: Number(loads),
        destination: destination,
        loadOrder: loadOrder,
        orderModified: this.state.orderModified
      }
    }
    this.props.modifyState();
    this.props.updateBlockLoad(payload);
  }

  handleChangeLoad(event) {
    this.handleChange(event.target.value, this.props.selectedDestination);
    event.preventDefault();
  }

  handleChangeDestination(event) {
    this.handleChange(this.props.loadValue, event.target.value);
    event.preventDefault();
  }

  changeOrder(event) {
    this.props.increaseIndex();
    this.setState({
      isOrdered: !this.state.isOrdered,
      currentLoadIndex: this.props.currentLoadIndex
    });
    event.preventDefault();
  }

  isOrdered() {
    return this.props.order >= 0;
  }

  render() {
    return (
      <li className='block-load-row'>
        <input className='load-order' type='checkbox' checked={this.isOrdered()} onChange={this.changeOrder} />
        <input onChange={this.handleChangeLoad} className='number-of-loads' type='number' value={this.props.loadValue}/>
        <label className='assigned-value'>{this.props.assigned}</label>
        <label className='remaining-value'>{this.props.loadValue - this.props.assigned}</label>
        <select className='destination-picker' value={this.props.selectedDestination}
          onChange={this.handleChangeDestination}>
          {
            this.props.destinations.map(destination => (
              <option key={destination.id} value={destination.id}> {destination.name} </option>
            ))
          }
        </select>
        
        <button className='delete-block-load-row'
                onClick={this.handleRemoveBlockLoad}>
          ✖
        </button>
      </li>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentScenarioId: state.scenarioState.selectedScenario.id
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateBlockLoad: (blockLoadInfo) => {
      dispatch(updateBlockLoad(blockLoadInfo));
    },
    removeBlockLoad: (blockLoadInfo) => {
      dispatch(removeBlockLoad(blockLoadInfo));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockLoadRow);