export const DRYLAND = 'dryland';
export const BLOCK = 'block';
export const YARD = 'yard';
export const TRANSIT = 'transit';
export const TRUCK = 'truck';
export const DESTINATION = 'destination';
export const DRIVER = 'driver';
export const LOADER = 'loader';
export const OPERATOR = 'operator';
export const ERRORCODE = 'errorCode';
export const PAINTMARK = 'paintmark';
export const VENDOR = 'vendor';
export const HAMMERMARK = 'HammerMark';
export const CONTRACTOR = 'Contractor';
export const FALLER = 'Faller';
export const YARDER = 'Yarder';
export const PROCESSOR = 'Processor';
export const REPORT = 'report';
export const TRUCKCOMPANY = 'truckCompany';
