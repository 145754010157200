import React from 'react';
import { DropTarget } from 'react-dnd';
import DraggableTypes from '../../constants/DraggableTypes';
import { getPixelPosition } from '../../calculations/timeToPixelConversions';

class LocationDropTarget extends React.Component {

  getTimeStartLocation() {
    let startTime = this.props.startTime;
    return getPixelPosition(this.props.workBeginHour, startTime);
  }

  getStyle() {
    let style = {};
    if (this.props.hasEmptySchedule) {
      style.marginLeft = this.getTimeStartLocation() + 'px';
    }
    return style;
  }
  
  
  render() {
    const { connectDropTarget } = this.props;

    return connectDropTarget(
      <div className="add-location-prompt location-item" style={this.getStyle()}>
        +
      </div>
    );
  }
}

const rowTarget = {
  drop(props, monitor, component) {
    const location = monitor.getItem();
    props.handleLocationDrop(location);
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}



export default (DropTarget(DraggableTypes.LOCATION, rowTarget, collect)(LocationDropTarget));