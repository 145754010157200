import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import YarderFieldEditor from '../../components/fieldEditors/YarderFieldEditor';
import uniqid from 'uniqid';
import { addYarder, updateYarder, bulkActivateYarders } from '../../reducers/yarders';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { YARDER } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class YardersConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yarderIdSelected: '',
      isAddingNewYarder: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousYarders = previousProps.yarders.map(yarder => yarder.id);
    const currentYarders = this.props.yarders.map(yarder => yarder.id);
    if (currentYarders.length > previousYarders.length) {
      const diff = difference(currentYarders, previousYarders);
      if (diff.length > 0) {
        const newYarder = diff[0];
        this.setState({
          yarderIdSelected: newYarder,
          isAddingNewYarder: false
        });
      }
    }
  }

  addNewYarder() {
    this.setState({
      yarderIdSelected: '',
      isAddingNewYarder: true
    });
  }

  selectYarder(id) {
    this.setState({
      yarderIdSelected: id,
      isAddingNewYarder: false
    });
  }

  getSelectedYarder() {
    const yarder = find(this.props.yarders, {id: this.state.yarderIdSelected});
    return yarder;
  }

  getSelectedYarderName() {
    const yarder = this.getSelectedYarder();
    if (yarder) {
      return yarder.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedYarder() {
    return this.state.yarderIdSelected !== '';
  }

  getYardersList() {
    const options = this.props.yarders;
    const sortedOptions = sortBy(options, yarder => yarder.name)
    .filter(yarder => !yarder.isDeleted)
    .map(yarder => {
      return {
        value: yarder.id,
        label: yarder.name
      }
    });

    const handleChange = (selectedOption) => this.selectYarder(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedYarderName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const yarder = this.getSelectedYarder();
    if (yarder) {
      return `Edit yarder ${yarder.name}`;
    } else if (this.state.isAddingNewYarder) {
      return 'Add a new yarder';
    } else {
      return 'Select a yarder to edit';
    }
  }

  checkExistence(state) {
    const yarder = find(this.props.yarders, {name: state.name});
    if (yarder) {
      this.props.openDuplicateObjectNamesModal(yarder);
    }
    else {
      if (this.state.isAddingNewYarder === true) {
        this.props.add(state);
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getYarderEditPanel() {
    if (this.hasSelectedYarder() && !this.state.isAddingNewYarder) {
      return (
        <div>
          <YarderFieldEditor
            key={uniqid()}
            yarderFieldProps={this.getSelectedYarder()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectYarder('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewYarder) {
      return (
        <div>
          <YarderFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectYarder('')}
          />
        </div>
      );
    }
  }

  activate() {
    const yarder = this.getSelectedYarder();
    if (yarder) {
      yarder.active = !yarder.active;
      this.props.update(yarder);
    }
  }

  getActivateButton() {
    const yarder = this.getSelectedYarder();
    if (yarder) {
      const buttonName = yarder.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const yarderId = this.state.yarderIdSelected;
    if (yarderId !== '') {
      return (
        <button className='remove-yarder'
          onClick={() => this.props.removeYarderModal(yarderId)}>
          Remove Yarder
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedYardersWithId = this.props.yarders
      .filter(yarder => !yarder.isDeleted)
      .map(yarder => {
        return {
          ...yarder,
          id: yarder.id,
          name: yarder.name
        }
      });
      return <BulkActivationModal
        type={YARDER}
        activationItems={updatedYardersWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='yarder-configuration configuration-forms'>
        <button className='add-new-yarder' onClick={() => this.addNewYarder()}>Add New Yarder</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='yarder-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='yarder-list'>
          {this.getYardersList()}
        </div>
        <div className='yarder-edit-panel'>
          {this.getYarderEditPanel()}
          <div className='yarder-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectYarder('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    yarders: state.yarders.yardersList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addYarder(state));
    },
    update: (state) => {
      dispatch(updateYarder(state));
    },
    removeYarderModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateYarders(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(YardersConfiguration);