import React from 'react';
import { connect } from 'react-redux';
import BlocksConfiguration from './BlocksConfiguration';
import '../../Configuration.css';
import DestinationsConfiguration from './DestinationsConfiguration';
import TrucksConfiguration from './TrucksConfiguration';
import DriversConfiguration from './DriversConfiguration';
import LoadersConfiguration from './LoadersConfiguration';
import YardsConfiguration from './YardsConfiguration';
import OperatorsConfiguration from './OperatorsConfiguration';
import OperatingUnitConfiguration from './OperatingUnitConfiguration';
import ErrorCodeConfiguration from './ErrorCodeConfiguration'
import { ADMIN } from '../../constants/userRole';
import PaintMarkConfiguration from './PaintMarkConfiguration';
import VendorConfiguration from './VendorConfiguration';
import HammerMarksConfiguration from './HammerMarksConfiguration';
import ContractorsConfiguration from './ContractorsConfiguration';
import FallersConfiguration from './FallersConfiguration';
import YardersConfiguration from './YardersConfiguration';
import ProcessorsConfiguration from './ProcessorsConfiguration';
import ReportsConfiguration from './ReportsConfiguration';
import TruckCompanyConfiguration from './TruckCompanyConfiguration';

const view = {
  blocks: 'BlocksView',
  destinations: 'DestinationsView',
  trucks: 'TrucksView',
  drivers: 'DriversView',
  loaders: 'LoadersView',
  yards: 'YardsView',
  operators: 'OperatorsView',
  operatingUnits: 'OperatingUnitsView',
  marks: 'MarksView',
  errorCodes: 'ErrorCodesView',
  vendors: 'VendorsView',
  hammerMarks: 'HammerMarksView',
  contractors: 'ContractorsView',
  fallers: 'FallersView',
  yarders: 'YardersView',
  processors: 'ProcessorsView',
  reports: 'ReportsView',
  truckCompanies: 'TruckCompaniesView'
}

class ConfigurationPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentView: view.blocks
    }
  }

  changeConfigView(configType) {
    this.setState({currentView: configType});
  }

  getCurrentConfigView() {
    if (this.state.currentView === view.blocks) {
      return <BlocksConfiguration />;
    } else if (this.state.currentView === view.destinations) {
      return <DestinationsConfiguration />
    } else if (this.state.currentView === view.trucks) {
      return <TrucksConfiguration />;
    } else if (this.state.currentView === view.drivers) {
      return <DriversConfiguration />;
    } else if (this.state.currentView === view.loaders) {
      return <LoadersConfiguration />;
    } else if (this.state.currentView === view.yards) {
      return <YardsConfiguration />;
    } else if (this.state.currentView === view.operators) {
      return <OperatorsConfiguration />;
    } else if (this.state.currentView === view.operatingUnits) {
      return <OperatingUnitConfiguration />;
    } else if (this.state.currentView === view.marks) {
      return <PaintMarkConfiguration />;
    } else if (this.state.currentView === view.errorCodes) {
      return <ErrorCodeConfiguration />;
    } else if (this.state.currentView === view.vendors) {
      return <VendorConfiguration />;
    } else if (this.state.currentView === view.hammerMarks) {
      return <HammerMarksConfiguration />;
    } else if (this.state.currentView === view.contractors) {
      return <ContractorsConfiguration />;
    } else if (this.state.currentView === view.fallers) {
      return <FallersConfiguration />;
    } else if (this.state.currentView === view.yarders) {
      return <YardersConfiguration />;
    } else if (this.state.currentView === view.processors) {
      return <ProcessorsConfiguration />;
    } else if (this.state.currentView === view.reports) {
      return <ReportsConfiguration />;
    } else if (this.state.currentView === view.truckCompanies) {
      return <TruckCompanyConfiguration />;
    }
  }

  getButtonHighlightStyle(view) {
    if (this.state.currentView === view) {
      return {
        background: '#c5e8c5',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getUserButton() {
    if (this.props.isAdmin) {
      return (
        <button className='configuration-view-button users-view'
          style={this.getButtonHighlightStyle(view.users)}
          onClick={() => this.changeConfigView(view.users)}>
          Users
        </button>
      );
    } else {
      return null;
    }
  }

  getCategories() {
    return (
      <div className='configuration-view-categories'>
        <button className='configuration-view-button blocks-view'
          style={this.getButtonHighlightStyle(view.blocks)}
          onClick={() => this.changeConfigView(view.blocks)}>
          Blocks
        </button>
        <button className='configuration-view-button contractor-view'
          style={this.getButtonHighlightStyle(view.contractors)}
          onClick={() => this.changeConfigView(view.contractors)}>
          Contractors
        </button>
        <button className='configuration-view-button destinations-view'
          style={this.getButtonHighlightStyle(view.destinations)}
        onClick={() => this.changeConfigView(view.destinations)}>
          Destinations
        </button>
        <button className='configuration-view-button drivers-view'
          style={this.getButtonHighlightStyle(view.drivers)}
          onClick={() => this.changeConfigView(view.drivers)}>
          Drivers
        </button>
        <button className='configuration-view-button errorCodes-view'
          style={this.getButtonHighlightStyle(view.errorCodes)}
          onClick={() => this.changeConfigView(view.errorCodes)}>
          Error Codes
        </button>
        <button className='configuration-view-button hammerMark-view'
          style={this.getButtonHighlightStyle(view.hammerMarks)}
          onClick={() => this.changeConfigView(view.hammerMarks)}>
          HammerMarks
        </button>
        <button className='configuration-view-button operating-units-view'
          style={this.getButtonHighlightStyle(view.operatingUnits)}
          onClick={() => this.changeConfigView(view.operatingUnits)}>
          Operating Units
        </button>
        <button className='configuration-view-button operators-view'
          style={this.getButtonHighlightStyle(view.operators)}
          onClick={() => this.changeConfigView(view.operators)}>
          Operators
        </button> 
        <button className='configuration-view-button mark-view'
          style={this.getButtonHighlightStyle(view.marks)}
          onClick={() => this.changeConfigView(view.marks)}>
          Paint Marks
        </button>
        <button className='configuration-view-button report-view'
          style={this.getButtonHighlightStyle(view.reports)}
          onClick={() => this.changeConfigView(view.reports)}>
          Reports
        </button>
        <button className='configuration-view-button truckCompany-view'
          style={this.getButtonHighlightStyle(view.truckCompanies)}
          onClick={() => this.changeConfigView(view.truckCompanies)}>
          Truck Company
        </button>
        <button className='configuration-view-button trucks-view'
          style={this.getButtonHighlightStyle(view.trucks)}
          onClick={() => this.changeConfigView(view.trucks)}>
          Trucks
        </button>
        <button className='configuration-view-button yards-view'
          style={this.getButtonHighlightStyle(view.yards)}
        onClick={() => this.changeConfigView(view.yards)}>
          Yards
        </button>
        <h2>Equipment</h2>
        <button className='configuration-view-button faller-view'
          style={this.getButtonHighlightStyle(view.fallers)}
          onClick={() => this.changeConfigView(view.fallers)}>
          Fallers
        </button>
        <button className='configuration-view-button loaders-view'
          style={this.getButtonHighlightStyle(view.loaders)}
          onClick={() => this.changeConfigView(view.loaders)}>
          Loaders
        </button>
        <button className='configuration-view-button trucks-view'
          style={this.getButtonHighlightStyle(view.processors)}
          onClick={() => this.changeConfigView(view.processors)}>
          Processors
        </button>
        <button className='configuration-view-button vendor-view'
          style={this.getButtonHighlightStyle(view.vendors)}
          onClick={() => this.changeConfigView(view.vendors)}>
          Vendors
        </button>
        <button className='configuration-view-button yarders-view'
          style={this.getButtonHighlightStyle(view.yarders)}
        onClick={() => this.changeConfigView(view.yarders)}>
          Yarders
        </button>
        
      </div>
    )
  }

  render() {
    return (
      <div className='configuration-page'>
        {this.getCategories()}
        {this.getCurrentConfigView()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    destinations: state.locations.destinations,
    trucks: state.trucks.truckList,
    isAdmin: state.users.user.profile.role === ADMIN
  }
};

export default connect(mapStateToProps)(ConfigurationPage);
