import React from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionItem } from 'react-sanfona';
import { fetchNavistreamData } from '../../reducers/navistream';
import { FaSync } from 'react-icons/fa';

class NavistreamView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      scenarioDate: this.props.scenarioDate,
      scenarioId: this.props.scenarioId
    }

    this.updateData = this.updateData.bind(this);
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({data: nextProps.data});
    }
    if (nextProps.scenarioDate !== this.props.scenarioDate) {
      this.setState({scenarioDate: nextProps.scenarioDate, scenarioId: nextProps.scenarioId});
    }
  }

  componentDidMount() {
    this.props.fetchNavistreamData(this.state.scenarioId);
  }

  updateData() {
    this.props.fetchNavistreamData(this.state.scenarioId);
  }

  render() {
    setTimeout(this.updateData,600000)
    const data = this.state.data;
    if (data.length !== 0) {
      return (
        <div className='navistream-window'>
          <div className='header'>
            <h1 className='title'>Navistream Data</h1>
            <button className='refresh-button' onClick={this.updateData}><FaSync/></button>
          </div>
          <br></br>
          <div className='navistream-window-activities'>
            <Accordion>
              {data.map(activity => {
                return (
                  <AccordionItem title={activity.vehName + " - " + activity.events[activity.events.length - 1].locationName 
                  + " - " + activity.events[activity.events.length - 1].eventType
                  + " - " + activity.events[activity.events.length - 1].eventAt} key={Math.random()}>
                    <table>
                      <tbody>
                        <tr>
                          <th>Time</th>
                          <th>Location Name</th>
                          <th>Event</th>
                        </tr>
                        {activity.events.map(event => <tr key={Math.random()}>
                                                        <td key={Math.random()}>{event.eventAt}</td>
                                                        <td key={Math.random()}> {event.locationName}</td>
                                                        <td key={Math.random()}>{event.eventType}</td>
                                                      </tr>)}
                      </tbody>
                    </table>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </div>
        </div>
      )
    }
    else {
      return (
        <h2>Data File Was Not Found.</h2>
      )
    }  
  }   
}

const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  return {
    scenarioDate: selectedScenario.date,
    data: state.navistream.navistreamData,
    scenarioId: selectedScenario.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNavistreamData: (scenarioId) => {
      dispatch(fetchNavistreamData(scenarioId));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(NavistreamView);