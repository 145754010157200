import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ADD_NEW_YARD, REMOVE_YARD, UPDATE_YARD, BULK_ACTIVATE_YARDS, setYardLocations } from '../../reducers/locations';
import { success, failure } from '../notificationMessages';
import { combineEpics } from 'redux-observable';
import { setOperatingUnitAssignment } from '../../reducers/operatingUnits';
import { getAuthService } from '../AuthService';


const addYardEpic = action$ => (
  action$.ofType(ADD_NEW_YARD)
    .mergeMap(action => (
      ajax.post('/api/yards', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => {
          //const yard = ajaxResponse.response.yard;
          const assignment = ajaxResponse.response.operatingUnitAssignment;
          //yard.type = YARD;
          return Observable.of(
            setOperatingUnitAssignment(assignment),
            success(() => setYardLocations(ajaxResponse.response), 'Successfully added new yard')
          );
        })
        .catch(fail => Observable.of(failure('Could not add new yard')))
    ))
);

const removeYardEpic = action$ => (
  action$.ofType(REMOVE_YARD)
    .mergeMap(action => (
      ajax.delete(`/api/yards/${action.payload}`, getAuthService().header)
        .flatMap(ajaxResponse => {
          //const yard = ajaxResponse.response.yard;
          const assignment = ajaxResponse.response.operatingUnitAssignment;
          return Observable.of(
            setOperatingUnitAssignment(assignment),
            success(() => setYardLocations(ajaxResponse.response), 'Successfully removed yard')
          );
        })
        .catch(fail => Observable.of(failure('Could not remove yard')))
    ))
);

const updateYardEpic = action$ => (
  action$.ofType(UPDATE_YARD)
    .mergeMap(action => (
      ajax.put('/api/yards', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => {
          //const yard = ajaxResponse.response.yard;
          const assignment = ajaxResponse.response.operatingUnitAssignment;
          //yard.type = YARD;
          return Observable.of(
            setOperatingUnitAssignment(assignment),
            success(() => setYardLocations(ajaxResponse.response), 'Successfully updated yard')
          );
        })
        .catch(fail => Observable.of(failure('Could not update yard')))
    ))
);

const bulkActivateYardsEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_YARDS)
    .mergeMap(action => (
      ajax.post('/api/yards/bulkActivate', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setYardLocations(ajaxResponse.response), 'Successfully updated yards'))
        .catch(fail => Observable.of(failure('Could not bulk activate yards')))
    ))
);

export const yardEpics = combineEpics(
  addYardEpic,
  removeYardEpic,
  updateYardEpic,
  bulkActivateYardsEpic
);