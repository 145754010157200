import React, { Component } from 'react'
import { emptyUuid } from '../utils/uuidHelper';
import { connect } from 'react-redux';
import {DISPATCHERSCHOICE, PAY, NOPAY} from '../constants/payOptions';


class ErrorCodePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorCodeId: props.errorCodeId? props.errorCodeId : emptyUuid,
      payOptions: props.payOptions? props.payOptions: NOPAY
    }
  }

  getSelectederrorCodeId() {
    const errorCodeId = this.props.selectedErrorCodeId;
      if (errorCodeId) {
        return errorCodeId;
      } else {
        return emptyUuid;
    }
  }
  
  
  getErrorCodeElements() {
    const none = <option key='blank' value={emptyUuid}>None</option>;
    const errorCodeOptions = this.props.errorCodes
    .filter(errorCode => errorCode.active && !errorCode.isDeleted)
    .map (errorCode => {
      const id = errorCode.id;
      return <option key={id} value={id}>{errorCode.description}</option>
    });
    errorCodeOptions.unshift(none);
    
    return errorCodeOptions;
  }

  getDispatchersOptionPicker() {
    const selectedErrorCode = this.props.errorCodes.find(errorCode => errorCode.id === this.state.errorCodeId)
    if (selectedErrorCode && selectedErrorCode.payOptions === DISPATCHERSCHOICE) {
      return (
        <select value={this.state.payOptions} className = 'pay-option-picker' onChange={(event) => this.handleChangePayOptions(event)}>
         <option key='none' value='none'>None</option>
         <option key={PAY} value={PAY}>Pay</option>
         <option key={NOPAY} value={NOPAY}>No Pay</option>
        </select>
        );
    } else if ( selectedErrorCode && selectedErrorCode.payOptions !== DISPATCHERSCHOICE) {
      let payOption = '';
      if (selectedErrorCode.payOptions === PAY) {
        payOption = 'Pay';

      } else if (selectedErrorCode.payOptions === NOPAY) {
        payOption = 'No Pay';
      }
      return (
        <label className='default-pay-option-label'>  Default Pay Option: {payOption}</label>
      );
    } else {
      return null;
    }
  }

  
  handleChangePayOptions(event) {
    this.setState({payOptions: event.target.value});
    this.props.handleChangeErrorCode(this.state.errorCodeId, event.target.value);   
  }
  
  
  handleChangeErrorCode(event) {
    this.setState({ errorCodeId: event.target.value});
    let payOption = NOPAY;
    const errorCode = this.props.errorCodes.find(errorCode => errorCode.id === event.target.value);
    if (errorCode) {
      if (errorCode.payOptions === DISPATCHERSCHOICE) {
        payOption = this.state.payOptions;
      } else {
      payOption = errorCode.payOptions;
      }
    }
    this.props.handleChangeErrorCode(event.target.value, payOption);
  }
  
  getErrorCodePicker(errorCodeId) {
    return (
      <select className='errorCode-picker' onChange={(event) => this.handleChangeErrorCode(event)}
        value={this.state.errorCodeId}>
        {this.getErrorCodeElements(errorCodeId)}
      </select>
    );
  }
    
  render() {
    return (
      <div className='errorCode-picker-component'>
        <div>Select Error Code</div>
        {this.getErrorCodePicker(this.state.errorCodeId)}
        {this.getDispatchersOptionPicker()}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    errorCodes : state.errorCodes.errorCodeList
  }
}


export default connect(mapStateToProps)(ErrorCodePicker);
