import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { openSleeperLoadInventoryModal } from '../../reducers/modalWindows';

class YardContextMenu extends React.Component {
  
  constructor(props) {
    super(props);

    this.showSleeperModal = this.showSleeperModal.bind(this);

  }

  getYardInfo() {
    return {
      id: this.props.id,
      name: this.props.name
    }
  }

  showSleeperModal(event) {
    event.preventDefault();
    this.props.openSleeperModal(this.getYardInfo());
  }

  render() {
    const contextId = 'context-menu' + this.props.id;
    return (
      <div className='yard-context-menu'>
        <ContextMenuTrigger id={contextId} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>
        <ContextMenu id={contextId}>
          <MenuItem attributes={{className:'context-menu-title'}}>{this.props.name}</MenuItem>
          <MenuItem onClick={this.showSleeperModal} disabled={this.props.hasBeenPublished}>Sleeper Load Inventory</MenuItem>

        </ContextMenu>
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch, props) => {
  return {
    openSleeperModal: (info) => {
      dispatch(openSleeperLoadInventoryModal(info));
    }
  }

}

export default connect(null, mapDispatchToProps)(YardContextMenu);