const SET_REPORTS = 'SET_REPORTS';
export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';
export const BULK_ACTIVATE_REPORTS = 'BULK_ACTIVATE_REPORTS';

export function setReports(reports) {
  return {
    type: SET_REPORTS,
    payload: reports
  }
}

export function addReport(report) {
  return {
    type: ADD_REPORT,
    payload: report
  }
}

export function addReportSuccess(report) {
  return {
    type: ADD_REPORT_SUCCESS,
    payload: report
  }
}

export function updateReport(report) {
  return {
    type: UPDATE_REPORT,
    payload: report
  }
}

export function updateReportSuccess(report) {
  return {
    type: UPDATE_REPORT_SUCCESS,
    payload: report
  }
}

export function removeReport(reportId) {
  return {
    type: REMOVE_REPORT,
    payload: reportId
  }
}

export function removeReportSuccess(report) {
  return {
    type: REMOVE_REPORT_SUCCESS,
    payload: report
  }
}

export function bulkActivateReports(reportIds) {
  return {
    type: BULK_ACTIVATE_REPORTS,
    payload: reportIds
  }
}

export default function reports(state = {
  reportsList: []
}, action) {
  switch(action.type) {
    case SET_REPORTS:
      return _setReports(state, action);
    case ADD_REPORT_SUCCESS:
      return _AddReport(state, action);
    case UPDATE_REPORT_SUCCESS:
      return _updateReport(state, action);
    case REMOVE_REPORT_SUCCESS:
      return _removeReport(state, action);
    default:
      return state;
  }
}


function _setReports(state, action) {
  return {
    ...state,
    reportsList: action.payload
  }
}

function _AddReport(state, action) {
  const reports = [...state.reportsList, action.payload];
  return {
    ...state,
    reportsList: reports
  }
}

function _updateReport(state, action) {
  const updatedReport = action.payload;
  const updatedList = state.reportsList.map(report => {
    if(report.reportId === updatedReport.reportId) {
      return updatedReport;
    } else {
      return report;
    }
  });
  return {
    ...state,
    reportsList: updatedList
  }
}

function _removeReport(state, action) {
  const updatedList = state.reportsList
  return {
    ...state,
    reportsList: updatedList
  }
}
