import React from 'react';
import { connect } from 'react-redux';
import { removeLocationFromSchedule } from '../reducers/schedule';

class LocationScheduleDelete extends React.Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
  }

  delete(event) {
    event.stopPropagation();
    this.props.removeLocation();
  }
  
  
  render() {    
    return (
      <div className='delete-item'
          onClick={this.delete} disabled={this.props.isPublished}>
        ✖
    </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    truckShares: state.truckShares.truckSharesList
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    removeLocation: () => {
      dispatch(removeLocationFromSchedule(props.rowId, props.id, props.type));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationScheduleDelete);