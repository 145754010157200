import sortBy from 'lodash/sortBy';

const SET_OPERATORS = 'SET_OPERATORS';
export const ADD_OPERATOR = 'ADD_OPERATOR';
export const ADD_OPERATOR_SUCCESS = 'ADD_OPERATOR_SUCCESS';
export const REMOVE_OPERATOR = 'REMOVE_OPERATOR';
export const REMOVE_OPERATOR_SUCCESS = 'REMOVE_OPERATOR_SUCCESS';
export const UPDATE_OPERATOR = 'UPDATE_OPERATOR';
export const UPDATE_OPERATOR_SUCCESS = 'UPDATE_OPERATOR_SUCCESS';
export const BULK_ACTIVATE_OPERATORS = 'BULK_ACTIVATE_OPERATORS';

export function addOperator(operator) {
  return {
    type: ADD_OPERATOR,
    payload: operator
  }
}

export function addOperatorSuccess(operator) {
  return {
    type: ADD_OPERATOR_SUCCESS,
    payload: operator
  }
}

export function updateOperator(operator) {
  return {
    type: UPDATE_OPERATOR,
    payload: operator
  }
}

export function updateOperatorSuccess(operator) {
  return {
    type: UPDATE_OPERATOR_SUCCESS,
    payload: operator
  }
}

export function removeOperator(operatorId) {
  return {
    type: REMOVE_OPERATOR,
    payload: operatorId
  }
}

export function removeOperatorSuccess(operatorId) {
  return {
    type: REMOVE_OPERATOR_SUCCESS,
    payload: operatorId
  }
}

export function bulkActivateOperators(operatorIds) {
  return {
    type: BULK_ACTIVATE_OPERATORS,
    payload: operatorIds
  }
}

export function setOperators(operators) {
  return {
    type: SET_OPERATORS,
    payload: operators
  }
}

export default function operators(state = [], action) {
  switch(action.type) {
    case SET_OPERATORS: 
      return _setOperators(state, action);
    case ADD_OPERATOR_SUCCESS:
      return _addOperator(state, action);
    case REMOVE_OPERATOR_SUCCESS:
      return _removeOperator(state, action);
    case UPDATE_OPERATOR_SUCCESS:
      return _updateOperator(state, action);
    default:
      return state;
  }
}

function _setOperators(state, action) {
  const sortedOperators = sortByName(action.payload);
  return sortedOperators;
}

function sortByName(objects) {
  return sortBy(objects, obj => obj.name);
}

function _addOperator(state, action) {
  const sortedOps = sortByName([...state, action.payload]);
  return sortedOps;
}

function _removeOperator(state, action) {
  const id = action.payload;
  const filtered = state.filter(op => op.id !== id);
  return filtered;
}

function _updateOperator(state, action) {
  const updatedOperator = action.payload;
  const updatedOps = state.map(op => {
    if (op.id === updatedOperator.id) {
      return updatedOperator;
    } else {
      return op;
    }
  });
  const sorted = sortByName(updatedOps);
  return sorted;
}
