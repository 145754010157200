import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ADD_DRIVER_TO_BLOCK_HISTORY, setDriversToBlocksHistory, REMOVE_DRIVER_TO_BLOCK_HISTORY, EDIT_DRIVER_TO_BLOCK_HISTORY, BULK_ADD_DRIVERS_TO_HISTORY, BULK_ADD_BLOCKS_TO_HISTORY } from '../../reducers/driversToBlocksHistory';
import { success, failure } from '../notificationMessages';
import { getAuthService } from '../AuthService';


export const addDriverToBlockHistoryEpic = action$ => (
  action$.ofType(ADD_DRIVER_TO_BLOCK_HISTORY)
    .mergeMap(action => (
      ajax.post('/api/driversToBlocksHistory', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setDriversToBlocksHistory(ajaxResponse.response), 'Successfully added new driver history'))
        .catch(fail => Observable.of(failure('Could not add new driver history')))
    ))
);

export const removeDriverToBlockHistoryEpic = action$ => (
  action$.ofType(REMOVE_DRIVER_TO_BLOCK_HISTORY)
    .mergeMap(action => (
      ajax.delete(`/api/driversToBlocksHistory/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => setDriversToBlocksHistory(ajaxResponse.response), 'Successfully removed driver history'))
        .catch(fail => Observable.of(failure('Could not remove driver history')))
    ))
);

export const updateDriverToBlockHistoryEpic = action$ => (
  action$.ofType(EDIT_DRIVER_TO_BLOCK_HISTORY)
    .mergeMap(action => (
      ajax.put('/api/driversToBlocksHistory', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setDriversToBlocksHistory(ajaxResponse.response), 'Successfully updated driver history'))
        .catch(fail => Observable.of(failure('Could not update driver history')))
    ))
);


export const bulkAddDriversToHistoryEpic = action$ => (
  action$.ofType(BULK_ADD_DRIVERS_TO_HISTORY)
    .mergeMap(action => (
      ajax.post('/api/driversToBlocksHistory/bulkAddDriversToHistory', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setDriversToBlocksHistory(ajaxResponse.response), 'Successfully added drivers to block history'))
        .catch(fail => Observable.of(failure('Could not add drivers to block history')))
    ))
);

export const bulkAddBlocksToHistoryEpic = action$ => (
  action$.ofType(BULK_ADD_BLOCKS_TO_HISTORY)
    .mergeMap(action => (
      ajax.post('/api/driversToBlocksHistory/bulkAddBlocksToHistory', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setDriversToBlocksHistory(ajaxResponse.response), 'Successfully added blocks to driver history'))
        .catch(fail => Observable.of(failure('Could not add blocks to driver history')))
    ))
);

