import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { openLocationScheduleActivityModal, openSwapScheduleActivityModal } from '../../reducers/modalWindows';
import { getStartTime, getEndTime } from '../../utils/dateTimeHelpers';
import { isViewerRole } from '../../utils/userHelper';
import { addDriverToBlockHistory } from '../../reducers/driversToBlocksHistory';
import find from 'lodash/find';
import { emptyUuid } from '../../utils/uuidHelper';

class LocationScheduleContextMenu extends React.Component {

  getDisplayName() {
    if (this.props.name === '') {
      return 'Transit';
    } else {
      return this.props.name;
    }
  }

  openModal() {
    const activity = this.props.activity;
    const modalProps = {
      id: activity.id,
      scenarioActivityId: activity.id,
      rowId: this.props.rowId,
      displayName: this.getDisplayName(),
      truckName: this.props.truckName,
      startTime: getStartTime(activity),
      endTime: getEndTime(activity),
      comments: activity.comments,
      delay: activity.delay,
      secondDelay: activity.secondDelay,
      type: activity.type,
      loaderId: activity.loaderId,
      locationId: activity.locationId,
      hasLoadDelay: activity.hasLoadDelay,
      errorCodeId: activity.errorCodeId,
      payOptions: activity.payOptions,
      secondErrorCodeId: activity.secondErrorCodeId,
      secondPayOption: activity.secondPayOption,
      sleeperDestinationId: activity.sleeperDestinationId,
      reasonForAdjustment: activity.reasonForAdjustment,
      hasSecondErrorCode: activity.secondErrorCodeId === emptyUuid || activity.secondErrorCodeId === null ? false : true,
      isThirdParty: activity.isThirdParty
    }
    this.props.openLocationScheduleActivityModal(modalProps);
  }

  openSwapScheduleActivityModal() {
    const activity = this.props.activity;
    const modalProps = {
      id: activity.id,
      scenarioActivityId: activity.id,
      rowId: this.props.rowId,
      displayName: this.getDisplayName(),
      truckName: this.props.truckName,
      startTime: getStartTime(activity),
      endTime: getEndTime(activity),
      comments: activity.comments,
      delay: activity.delay,
      secondDelay: activity.secondDelay,
      type: activity.type,
      loaderId: activity.loaderId,
      locationId: activity.locationId,
      hasLoadDelay: activity.hasLoadDelay,
      errorCodeId: activity.errorCodeId,
      payOptions: activity.payOptions,
      sleeperDestinationId: activity.sleeperDestinationId
    }
    this.props.openSwapScheduleActivityModal(modalProps);
  }

  addDriverToBlockHistory() {
    const activity = this.props.activity;
    const blockId = activity.locationId;
    const driverId = this.props.driverId;
    this.props.addDriverToBlockHistory(driverId, blockId)
  }

  checkIfActivityIsBlock() {
    if (this.props.activity.type === 'block') {
      return false;
    } else {
      return true;
    }
  }

  checkIfActivityIsTransit() {
    if (this.props.activity.type === 'transit'){
      return false;
    } else {
      return true;
    }
  }

  checkIfIsInHistory () {
    const blockId = this.props.activity.locationId;
    const driverId = this.props.driverId;
    const targetHistory = find(this.props.history, {blockId: blockId, driverId: driverId});
    if (targetHistory) {
      return false;
    }
    else {
      return true;
    }
  }

  getSwapManu() {
    if (this.props.activity.type === 'block' && this.blockIsBeforeYard()) {
      return (
        <MenuItem onClick={() => this.openSwapScheduleActivityModal()}
        disabled={isViewerRole() || this.props.isPublished  || this.props.isClosed || !this.checkIfActivityIsTransit()}>Swap Block</MenuItem>
    )}
    else if (this.props.activity.type === 'dryland' && this.destinationIsAfterYard()) {
      return (
        <MenuItem onClick={() => this.openSwapScheduleActivityModal()}
        disabled={isViewerRole() || this.props.isPublished  || this.props.isClosed || !this.checkIfActivityIsTransit()}>Swap Destination</MenuItem>
      )
    }
    else {
      return null;
    }   
  }

  destinationIsAfterYard() {
    const schedule = this.props.scheduleRowState.filter(row => row.rowId === this.props.rowId)[0].schedule;
    var previousLocation = {};
    for (var i = 0; i < schedule.length; i++) {
      if(schedule[i].id === this.props.id) {
        previousLocation = schedule[i - 2];
        break;
      }
    }
    if (previousLocation && previousLocation.type === 'yard') {
      return false;
    }
    else {
      return true;
    }
  }


  blockIsBeforeYard() {
    const schedule = this.props.scheduleRowState.filter(row => row.rowId === this.props.rowId)[0].schedule;
    var nextLocation = {};
    for (var i = 0; i < schedule.length; i++) {
      if (schedule[i].id === this.props.id) {
        nextLocation = schedule[i + 2];
        break;
      }
    }
    if (nextLocation && nextLocation.type === 'yard') {
      return false;
    }
    else {
      return true;
    }
  }

  isInTruckShares(scenarioTruckId) {
    scenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
    const sharedIds = this.props.truckShares.map(share => share.scenarioTruckId);
    return sharedIds.includes(scenarioTruckId);
  }

  render() {
    return (
      <div className='location-schedule-context-menu'>
        <ContextMenuTrigger id={this.props.id} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>
        <ContextMenu id={this.props.id}>
          <MenuItem attributes={{className: 'context-menu-title'}}>{this.getDisplayName()}</MenuItem>
          <MenuItem onClick={() => this.openModal()}
                    disabled={(this.props.isPublished || isViewerRole() || this.props.isClosed) || (this.props.isPublished || (!this.props.isShared && this.isInTruckShares() ))}>Edit Activity</MenuItem>
          {this.getSwapManu()}
          <MenuItem onClick={() => this.addDriverToBlockHistory()}
                    disabled={isViewerRole() || this.checkIfActivityIsBlock() || !this.checkIfIsInHistory()}>Prework Complete</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    truckShares: state.truckShares.truckSharesList,
    scheduleRowState: state.scheduleRowState,
    history: state.driversToBlocksHistory.driversToBlocksHistoryList,
    isPublished: state.scenarioState.selectedScenario.published ||
                 state.scenarioState.selectedScenario.wasPublished,
    isClosed: state.scenarioState.selectedScenario.isClosed
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openLocationScheduleActivityModal: (modalProps) => {
      dispatch(openLocationScheduleActivityModal(modalProps));
    },
    addDriverToBlockHistory: (driverId, blockId) => {
      dispatch(addDriverToBlockHistory(driverId, blockId))
    },
    openSwapScheduleActivityModal: (modalProps) => {
      dispatch(openSwapScheduleActivityModal(modalProps));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationScheduleContextMenu);
