import React from 'react';
import { connect } from 'react-redux';
import { matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';
import YardContextMenu from '../../components/contextMenus/YardContextMenu';
import LocationDraggable from './LocationDraggable';

class DraggableYard extends React.Component {

  getYardNameWithSleepers(name, yardId) {
    const yardSleepers = this.props.sleeperLoads.filter(load => load.yardId === yardId);
    const sleeperCount = yardSleepers.length;
    const tripCount = yardSleepers.filter(load => load.tripCount > 0).length;
    const diff = sleeperCount - tripCount;
    if (sleeperCount === 0) {
      return name;
    } else if (sleeperCount > 0) {
      return `${name} (${diff})`;
    }
  }

  getStyle(yards) {
    const heightPerRow = 3;
    const maxNumRows = 6;
    const heightValue = heightPerRow * Math.min(yards.length, maxNumRows);
    return {
      height: `${heightValue}em`
    }
  }

  render() {
    const yardsToRender = this.props.yards
      .filter(yard => yard.active && matchesSelectedOperatingUnit(yard.id) && !yard.isDeleted)
      .map(yard =>
        <YardContextMenu
          key={'context'+yard.id}
          id={yard.id}
          name={yard.name}
          scenarioId={this.props.currentScenarioId}
          hasBeenPublished={this.props.hasBeenPublished}>
          <LocationDraggable id={yard.id} type={yard.type}
            name={this.getYardNameWithSleepers(yard.name, yard.id)}
            currentDashboardId={this.props.dashboard.id}
            clickHandler={(id) => this.props.showYardInDashboard(id)}
            isDraggable={!this.props.hasBeenPublished}
          />
        </YardContextMenu>);
    
    return (
      <div className='yard-draggable-list' style={this.getStyle(this.props.yards)}>
        {yardsToRender}
      </div>  
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard.currentlySelected,
    yards: state.locations.yards,
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds
  }
}

export default connect(mapStateToProps)(DraggableYard);