import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { DRIVER_TO_BLOCK_RANKING } from '../../constants/modalTypes';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';

class DriverToBlockRankingModal extends Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#root');  
  }
  
  getBlockRanking() {
    const blockRanking = this.props.modalWindow.blockRanking.ranking;
    if (blockRanking === null) {
      return ' N/A';
    }else {
      return 'Level '+blockRanking;
    }
  }

  getDriverExperience() {
    const driverExperience = this.props.modalWindow.driverExperience.experience;
    if (driverExperience === null ) {
      return ' N/A';
    }else {
      return 'Level '+driverExperience;
    }
  }


  
  render() {
    const modalWindow = this.props.modalWindow; 
    if (modalWindow.modalType === DRIVER_TO_BLOCK_RANKING && modalWindow.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '220px',
            width: '385px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
      <div className='driver-to-block-ranking-modal'>
        <p>The selected driver is not experienced enough to go to this block.</p>
        <p>Please choose another driver.</p>
        <p>{this.props.modalWindow.driverExperience.name}: {this.getDriverExperience()}</p>
        <p>{this.props.modalWindow.blockRanking.name} Block: {this.getBlockRanking()}</p>
        <div className='ranking-modal-buttons'>
              <button className='done-button' onClick={() => this.props.closeModal()}>Done</button>
        </div>
      </div>  
      </ReactModal>
      );
    }else {
      return null;
    }
  }  
}


const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(DriverToBlockRankingModal);
