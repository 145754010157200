import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ADD_NEW_LOADER, addLoaderSuccess, UPDATE_LOADER, updateLoaderSuccess, REMOVE_LOADER, removeLoaderSuccess, BULK_ACTIVATE_LOADERS, setLoaders } from '../../reducers/loaders';
import { setLoaderOperators } from '../../reducers/loaderOperatorAssignment';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { getAuthService } from '../AuthService';
import store from '../../store';


const addLoaderEpic = action$ => (
  action$.ofType(ADD_NEW_LOADER)
    .mergeMap(action => (
      ajax.post('/api/loaders', action.payload, getAuthService().header)
        .map(ajaxResponse => {
          const loaders = ajaxResponse.response.loader;
          store.dispatch(setLoaderOperators(ajaxResponse.response.assignments));
          return success(() => addLoaderSuccess(loaders), 'Successfully added new loader');
        })
        .catch(fail => Observable.of(failure('Could not add new loader')))
    ))
);

const updateLoaderEpic = action$ => (
  action$.ofType(UPDATE_LOADER)
    .mergeMap(action => (
      ajax.put('/api/loaders', action.payload, getAuthService().header)
        .map(ajaxResponse => {
          const loaders = ajaxResponse.response.loader;
          store.dispatch(setLoaderOperators(ajaxResponse.response.assignments));
          return success(() => updateLoaderSuccess(loaders), 'Successfully updated loader');
        })
        .catch(fail => Observable.of(failure('Could not update loader')))
    ))
);

const removeLoaderEpic = action$ => (
  action$.ofType(REMOVE_LOADER)
    .mergeMap(action => (
      ajax.delete(`/api/loaders/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => {
          const loaderId = action.payload;
          store.dispatch(setLoaderOperators(ajaxResponse.response.assignments));
          return success(() => removeLoaderSuccess(loaderId), 'Successfully removed loader');
        })
        .catch(fail => Observable.of(failure('Could not remove loader')))
    ))
);

const bulkActivateLoadersEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_LOADERS)
    .mergeMap(action => (
      ajax.post('/api/loaders/bulkActivate', action.payload, getAuthService().header)
        .map(ajaxResponse => {
          const loaders = ajaxResponse.response.loaders;
          //const assignment = ajaxResponse.response.assignment;
          return success(() => setLoaders(loaders), 'Successfully updated loaders');
        })
      .catch(fail => Observable.of(failure('Could not bulk activate loaders')))
    ))
);

export const loaderEpics = combineEpics(
  addLoaderEpic,
  updateLoaderEpic,
  removeLoaderEpic,
  bulkActivateLoadersEpic,
);