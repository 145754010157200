import React, { Component } from 'react';
import { emptyUuid } from '../../utils/uuidHelper';
import { closeScenarioTruckShareModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import { shareTruck } from '../../reducers/truckShares';
// import ScenarioTruckShareConfModal from './ScenarioTruckShareConfModal';

class ScenarioTruckShareModal extends Component {
  
  constructor(props) {
    super(props);
  
    this.nullId = emptyUuid;
    ReactModal.setAppElement('#root');
    const validDispatcherComments = get(props.scenarioTruck, 'dispatcherComment', '');
    const propComments = props.comments;
    const validComments = propComments? propComments: "";
    

    this.state = {
      isModified: false,
      truckId: props.truckId,
      comments: validComments,
      dispatcherComment: validDispatcherComments,
      tempOperatingUnitId: this.nullId,
      operatingUnitId: this.nullId,
      scenarioTruck: this.props.scenarioTruck,
      startHour: this.props.beginHour,
      startMinute: 0,
      workDayMinutes: this.props.defaultWorkDayMinutes,
      driverId: this.props.scenarioTruck.driverId,
      trailerId: props.scenarioTruck.trailerId,
      scenarioTruckId: props.scenarioTruck.scenarioTruckId,
      scenarioId: this.props.scenarioId
    }


    this.handleChangeTargetOperatingUnit = this.handleChangeTargetOperatingUnit.bind(this);
    this.handleChangeDispatcherComment = this.handleChangeDispatcherComment.bind(this);
    this.handleChangeComments = this.handleChangeComments.bind(this);  
    this.openConfModal = this.openConfModal.bind(this);
  }


  modifyState() {
    this.setState({isModified: true});
  }

  
  handleChangeTargetOperatingUnit(event) {
    this.modifyState();
    event.preventDefault();
    const tempOperatingUnitId = event.target.value;
    this.setState({tempOperatingUnitId: tempOperatingUnitId});
    const selectedTruckScenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
    const sharesIds = this.props.truckShares.map(share => share.scenarioTruckId);
    if (sharesIds.includes(selectedTruckScenarioTruckId)) {
      const shares = find(this.props.truckShares,{scenarioTruckId: selectedTruckScenarioTruckId})
      this.setState({operatingUnitId: shares.tempOperatingUnitId})
    } else {
      const truckId = this.props.scenarioTruck.truckId;
      const operatingUnitAssignment = find(this.props.operatingUnitAssignment, {configObjectId : truckId});
      const  operatingUnit = find(this.props.operatingUnits, {id : operatingUnitAssignment.operatingUnitId});
        if (operatingUnitAssignment) {
        if (operatingUnit) {
          this.setState({operatingUnitId: operatingUnit.id});
        }
      }
    }
  }

  handleChangeDispatcherComment(event) {
    event.preventDefault();
    this.modifyState();
    this.setState({dispatcherComment: event.target.value});
  }

  handleChangeComments(event) {
    this.modifyState();
    event.preventDefault();
    this.setState({comments: event.target.value});
  }

  
  getOperatingUnitElements() {
    const current = this.getCurrentOperatingUnitName()
    const noSelection = <option key='blank' value={this.nullId}>None</option>;
    const avail = this.props.operatingUnits.filter(operatingUnit => operatingUnit.name !== current)
    const operatingUnitOptions = avail.map(operatingUnit => {
        const id = operatingUnit.id;
        return <option key={id} value={id}>{operatingUnit.name}</option>
    });
    operatingUnitOptions.unshift(noSelection);
    return operatingUnitOptions;

  }

  getOperatingUnitPicker() {
    return (
        <select className='operating-unit-picker' value={this.state.tempOperatingUnitId} onChange={this.handleChangeTargetOperatingUnit}>
          {this.getOperatingUnitElements()}
        </select>
    ); 
  }
    
    
  getCurrentOperatingUnitName(){
    const selectedTruckScenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
    const sharesIds = this.props.truckShares.map(share => share.scenarioTruckId);
    if (sharesIds.includes(selectedTruckScenarioTruckId)) {
      const sharedTruck = find(this.props.truckShares, {scenarioTruckId : selectedTruckScenarioTruckId});
      const operatingUnit = find(this.props.operatingUnits, {id : sharedTruck.tempOperatingUnitId});
      return operatingUnit.name;
    } else {
    const truckId = this.props.scenarioTruck.truckId;
    const operatingUnitAssignment = find(this.props.operatingUnitAssignment, {configObjectId : truckId});
    const  operatingUnit = find(this.props.operatingUnits, {id : operatingUnitAssignment.operatingUnitId});
    if (operatingUnitAssignment) {
      if (operatingUnit) {
        return operatingUnit.name;
      }
    }  
    }
  }

  getTitleText() {
      return 'Share Truck'; 
  }


  saveChanges() {
    const scenarioTruckState = this.state;
    this.props.shareTruck(scenarioTruckState);
  }
//NEW STUFF
  openConfModal() {
    // event.stopPropagation();
    // const scenarioTruckState = this.props.scenarioTruck.scenarioTruckState;
    const scenarioTruckState = this.state
    this.props.openConfModal(scenarioTruckState)
  }

  
  
  render() {
    const closeAndSave = () => {
      this.props.closeScenarioTruckShareModal();
      this.saveChanges();
    }
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          right: '60px',
          height: '400px',
          width: '400px',
          display: 'flex',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeScenarioTruckShareModal()}>
        <div className='scenario-truck-share'>
          <h4>{this.getTitleText()} {this.props.scenarioTruck.truckName}</h4>
          <div className='current-operating-unit'>
            <label>Current Operating Unit: </label>
            {this.getCurrentOperatingUnitName()} 
          </div>  
          <div className='operating-unit-picker-section'>  
            <label>Target Operating Unit:</label>
            {this.getOperatingUnitPicker()}
          </div>
          
          <div className='comments-section'>
            <div className='regular-comments'>
              <label className='comment-label'>Comments</label>
              <textarea className='comment-field' rows='4' cols='50' value={this.state.comments} onChange={this.handleChangeComments}/>
            </div>
            <div className='dispatcher-comments'>
              <label className='dispatcher-comment-label'>Dispatcher Comments</label>
              <textarea className='dispatcher-comment-field' rows='4' cols='50' value={this.state.dispatcherComment} onChange={this.handleChangeDispatcherComment}/>
            </div>
          </div>
          <div className='scenario-truck-share-buttons'>
            <button onClick={() => this.props.closeScenarioTruckShareModal()} className='scenario-truck-share-cancel'>Cancel</button>
            {/* <button onClick={() => this.openConfModal()} className='scenario-truck-share-done'>Done</button> */}
            <button onClick={() => closeAndSave()} className='scenario-truck-share-done'>Done</button>

          </div>
          
        </div>        
      </ReactModal>  
    );
  }
}

const mapStateToProps = (state) => {
  const activeTrucks = state.trucks.truckList.filter(truck => truck.active);
  return {
    operatingUnits: state.operatingUnits.operatingUnitList,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    scheduleRows: state.scheduleRowState,
    operatingUnitAssignment:state.operatingUnits.operatingUnitAssignment,
    currentDateSelected: state.scenarioState.currentDateSelected,
    truckList: activeTrucks,
    trailerList: state.trailers.trailerList,
    driverList: state.drivers.driverList,
    defaultWorkDayMinutes: state.workHoursForSchedule.maxScheduledMinutes,
    scenarioId: state.scenarioState.selectedScenario.id,
    modalWindow: state.modalWindows,
    truckShares: state.truckShares.truckSharesList
    }
  }

  const mapDispatcherToProps = (dispatch) => {
    return {
      closeScenarioTruckShareModal: () => {
        dispatch(closeScenarioTruckShareModal());
      },
      shareTruck: (scenarioTruckState) => {
        dispatch(shareTruck(scenarioTruckState));
      }
    }
  }

export default connect(mapStateToProps, mapDispatcherToProps)(ScenarioTruckShareModal)
