import { find } from 'lodash';
import { BLOCK, YARD, DRYLAND } from '../constants/typeConstants';

class TransitTimeCalculations {

  constructor(blocksToDestinations, locationsToYards) {
    this.blocksToDestinations = blocksToDestinations;
    this.locationsToYards = locationsToYards;
  }

  getTravelTime(block, dryland) {
    const blockToDestination = find(
      this.blocksToDestinations,
      {blockId: block.id, destinationId: dryland.id});
    if (blockToDestination) {
      return blockToDestination.toDestinationTime
    } else {
      return 0;
    }
  }

  isBlock(location) {
    return (location.type === BLOCK);
  }

  isYard(location) {
    return (location.type === YARD);
  }

  isDryland(location) {
    return (location.type === DRYLAND);
  }

  blockToDryland(origin, destination) {
    let toDryland = this.getTravelTime(origin, destination);
    let inBlock = origin.preloadTime + origin.loadTime + origin.postloadTime;
    return {
      timeAtCutblock: inBlock,
      travelTime: toDryland,
      timeAtDestination: destination.unloadTime
    };
  }

  getToYardTime(originLocation, yard) {
    const target = find(this.locationsToYards, {locationId: originLocation.id, yardId: yard.id});
    const toYardTime = target? target.toYardTime: 0;
    return {
      travelTime: toYardTime
    };
  }

  drylandToBlock(origin, destination) {
    let toCutblock = this.getTravelTime(destination, origin);
    let inBlock = toCutblock? 30 : 0;
    return {
      travelTime: toCutblock,
      timeAtDestination: inBlock
    };
  }


  getTransitTime(origin, destination) {
    let originIsCutblock =  this.isBlock(origin);
    let originIsYard = this.isYard(origin);
    let originIsDryland = this.isDryland(origin);
    let destinationIsCutblock = this.isBlock(destination);
    let destinationIsYard = this.isYard(destination);
    let destinationIsDryland = this.isDryland(destination);

    if (originIsCutblock) {
      if (destinationIsYard) {
        return this.getToYardTime(origin, destination);
      } else if (destinationIsDryland) {
        return this.blockToDryland(origin, destination);
      } else {
        return null;
      }
      
    } else if (originIsYard) {
      if (destinationIsCutblock || destinationIsDryland) {
        return this.getToYardTime(destination, origin);
      } else {
        return null;
      }
    
    } else if (originIsDryland) {
      if (destinationIsYard) {
        return this.getToYardTime(origin, destination);
      } else if (destinationIsCutblock) {
        return this.drylandToBlock(origin, destination);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

export default TransitTimeCalculations;