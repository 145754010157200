import axios from 'axios';
import store from '../store';
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from '../reducers/loadingScreen';

class ExportCsvService {
  static baseUrl() {
    return window.location.origin + '/';
  }

  static downloadCsv(scenarioId, scenarioName) {
    const url = this.baseUrl() + `api/csvExport?scenarioId=${scenarioId}`;
    const fileName = scenarioName + '_EXPORT.csv';
    store.dispatch(showLoading());
    
    axios.get(url).then(response => {
      toast.success('Successfully downloaded ' + fileName);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.log(error))
    .then(() => store.dispatch(hideLoading()));
  }
}

export default ExportCsvService;