import React from 'react';
import { connect } from 'react-redux';
import range from 'lodash/range';
import { displayFormat } from '../TimeMarkers';

class VerticalTimeMarkers extends React.Component {

  getVerticalTimeStyle(hour) {
    const style = {};

    if (hour % 2 === 0) {
      style.background = '#dedede';
    } else {
      style.background = '#f3f3f3';
    }

    return style;
  }

  getTimeMarkers() {
    const begin = this.props.workHours.beginHour;
    const end = this.props.workHours.endHour + 1;
    const hours = range(begin, end);
    return hours.map(hour => (
      <div className='vertical-time' key={hour} style={this.getVerticalTimeStyle(hour)}>
        <div className='horizontal-line-separator' style={{
          width: 50 + this.props.columnCount * 123
        }}></div>
        <div className='time-display'>
          {displayFormat(hour)}
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div className='vertical-time-markers'>
        {this.getTimeMarkers()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workHours: state.workHoursForSchedule
  }
}

export default connect(mapStateToProps)(VerticalTimeMarkers);