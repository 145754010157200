import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';
import { BLOCK_HISTORY_MODAL } from '../../constants/modalTypes';

class BlockHistoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModified: false,
      selectedDrivers: this.props.driverIds
    }

    ReactModal.setAppElement('#root');  

    this.modifyState = this.modifyState.bind(this);
    this.handleCheckDrivers = this.handleCheckDrivers.bind(this);
  }


  modifyState() {
    this.setState({isModified: true});
  }

  getDriver(driver) {
      return (
        <div className='all-drivers-list' key={driver.id}>
          <input className='driver-select-checkbox' type='checkbox'  
            value={driver.id}
            checked={this.state.selectedDrivers.includes(driver.id)}
            onChange={this.handleCheckDrivers}/>
          <label>{driver.name}</label>
        </div>
      )
    }
  

  getAllDrivers() {
    return this.props.drivers
    .filter(driver => !driver.isDeleted)
    .map(driver => this.getDriver(driver))
  }

  handleCheckDrivers(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let updatedCheckedDrivers = [];
    if (isChecked) {
      updatedCheckedDrivers = [...this.state.selectedDrivers, id];
    } else {
      updatedCheckedDrivers = this.state.selectedDrivers.filter(selectId => selectId !== id);
    }
    this.setState({
      selectedDrivers: updatedCheckedDrivers
    });
    this.modifyState();
  }

  clickDone() {
    this.props.closeModal();
    if (this.state.isModified) {
      this.props.onClickDone(this.state.selectedDrivers);
    }
  }
  
  
  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === BLOCK_HISTORY_MODAL && modalWindow.isModalOpen) {
      return (
        <ReactModal
            isOpen={true}
            style={{content: {
            top: '100px',
            left: '800px',
            right: '60px',
            height: '500px',
            width: '700px'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
        <div className='block-history-modal'>
          <p>Drivers who have been in this block before:</p>
          <div className='drivers-in-block-list'>
            {this.getAllDrivers()}
          </div>
          <div className='block-history-modal-buttons'>
            <button className='cancel-button'
              onClick={() => this.props.closeModal()}>Cancel</button>
            <button className='done-button'
              onClick={() => this.clickDone()}>Done</button>
          </div>
        </div>
        </ReactModal>
      )
    }
    
  }
}

const mapStateToProps = (state) => {
  return {
  modalWindow: state.modalWindows,
  driversList: state.drivers.driverList,
  history: state.driversToBlocksHistory.driversToBlocksHistoryList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockHistoryModal)
