import { SET_SCHEDULE } from '../reducers/schedule';
import { loadScheduleFormatter } from './loadScheduleFormatter';

export const loadSchedule = store => next => action => {
  if (action.type === SET_SCHEDULE) {
    const schedule = loadScheduleFormatter(store.getState(), action);
    const payload = {type: action.type, payload: schedule};
    next(payload);
  } else {
    next(action);
  }
}