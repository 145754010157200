import React from 'react';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import capitalize from 'lodash/capitalize';
import { getStartTime, getEndTime } from '../../utils/dateTimeHelpers';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { BLOCK, DRYLAND } from '../../constants/typeConstants';
import LoaderOperatorHelper from '../../utils/loaderOperatorHelper';
import {PAY, NOPAY} from '../../constants/payOptions';

class DashboardScheduleActivity extends React.Component {
  constructor(props) {
    super(props);

    this.displayFormat =  'MMMM D, YYYY';
  }

  getStartTime() {
    return getStartTime(this.props.activity);
  }

  getEndTime() {
    return getEndTime(this.props.activity);
  }

  getDuration() {
    const activity = this.props.activity;
    const start = activity.dateObjects.locationStartTime;
    const end = activity.dateObjects.locationEndTime;
    const difference = differenceInMinutes(end, start) + activity.delay + activity.secondDelay;
    return difference;
  }

  getActivityDate() {
    const activityTime = this.props.activity.dateObjects.locationStartTime;
    const formatted = format(activityTime, this.displayFormat);
    return formatted;
  }

  getDelay(activity) {
    if (activity.delay) {
      if (activity.delay > 0) {
        return <div style={{color: 'red'}}>Delay: {activity.delay} minutes</div>;
      } else if (activity.delay < 0) {
        const early = -1 * activity.delay;
        return <div>Early: {early} minutes</div>;
      }
    } else {
      return null;
    }
  }

  getSecondDelay(activity) {
    if (activity.secondDelay && activity.secondDelay > 0) {
      return <div style={{color: 'red'}}>Second Delay: {activity.secondDelay} minutes</div>;
    } else {
      return null;
    }
  }

  getErrorCode(activity) {
    const errorCode = find(this.props.errorCodes, {id: activity.errorCodeId});
    if (errorCode) {
      const description = errorCode? errorCode.description : errorCode.description;
      let payOption = '';
      if (activity.payOptions === PAY) {
        payOption = 'Pay';
      } else if (activity.payOptions === NOPAY) {
        payOption = 'No Pay';
      }
      return <div>Error Code: {description}<br></br> Pay Option: {payOption}</div>
    }      
  }

  getSecondErrorCode(activity) {
    const secondErrorCode = find(this.props.errorCodes, {id: activity.secondErrorCodeId});
    if (secondErrorCode ) {
      const secondDescription = secondErrorCode? secondErrorCode.description : secondErrorCode.description;
      let secondPayOption = '';
      secondPayOption = activity.secondPayOption === PAY? 'Pay' : 'No Pay';
      return <div>Second Error Code: {secondDescription}<br></br>Second Pay Option: {secondPayOption}</div>
    }
  }

  getComments(activity) {
    if (activity.comments && activity.comments !== '') {
      return (
        <React.Fragment>
          <p>Comments:</p>
          <div>{activity.comments}</div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  getOperatorName(loader, locationId) {
    if(loader) {
      const loaderOperatorHelper = new LoaderOperatorHelper();
      const operatorName = loaderOperatorHelper.getBlockActivityOperator(loader.id, locationId);
      return operatorName;
    }

    return 'None';
  }

  getLoader(activity) {
    if (activity.type === BLOCK && activity.loaderId) {
      const loader = find(this.props.loaders, {id: activity.loaderId});
      const name = loader? loader.name: 'None';
      const operatorName = this.getOperatorName(loader, activity.locationId);
      return (
        <React.Fragment>
          <div>Loader: {name}</div>
          <div>Operator: {operatorName}</div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  getBlockRanking(activity) {
    if (activity.type === BLOCK) {
      const block = find(this.props.blocks, {name: activity.name});
      if (block.ranking === null) {
        return (
          <React.Fragment>
            <div>Ranking: N/A</div> 
          </React.Fragment>
        )
      }else {
        return (
          <React.Fragment>
            <div>Ranking: Level {block.ranking}</div> 
          </React.Fragment>
      )};
    }
  }

  getBlockChannel(activity) {
    if (activity.type === BLOCK) {
      const block = find(this.props.blocks, {name: activity.name});
      if (block.channel === null || block.channel === '') {
        return (
          <React.Fragment>
            <div>Channel: N/A</div> 
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <div>Channel: {block.channel}</div> 
          </React.Fragment>
        )};
    }
  }

  getDestinationMaxCapacity(activity) {
    if (activity.type === DRYLAND) {
      const dryland = find(this.props.destinations, {name: activity.name});
      if (dryland.maxCapacity === null) {
        return ' Max Capacity: N/A';
      } else {
        return (
          <React.Fragment>
            <div>Max Capacity: {dryland.maxCapacity}</div> 
          </React.Fragment>
        )
      }
    }
  }

  getDestinationsOnSchedule() {
    return this.props.schedule.flatMap(scheduleRow => {
     const drylands = scheduleRow.schedule.filter(scheduleItem => scheduleItem.type === DRYLAND);
     return drylands.map(dryland => {
       return {
         ...dryland,
         truckName: scheduleRow.truckName
       }
     });
    });
  }

  getNumberOfDestination(activity) {
    if (activity.type === DRYLAND) {
      const destList = this.getDestinationsOnSchedule().map(destination => destination.name)
      let count = 0
      for (var i=0; i<destList.length; i++) {
        if (destList[i] === activity.name ) {
          count = count + 1;
        }
      } return (
        <React.Fragment>
          <div>Scheduled: {count}</div>
        </React.Fragment>
      )  
    }
  }

  render() {
    const activity = this.props.activity;
    return (
      <div className='dashboard-block-activity'>
        <h4>{capitalize(activity.type)} {activity.name} Activity for {this.getActivityDate()}</h4>
        {this.getBlockRanking(activity)}
        {this.getBlockChannel(activity)}
        {this.getNumberOfDestination(activity)}
        {this.getDestinationMaxCapacity(activity)}
        <div>Start Time: {this.getStartTime()}</div>
        <div>End Time: {this.getEndTime()}</div>
        <div>Duration: {this.getDuration()} minutes</div>
        {this.getLoader(activity)}
        {this.getDelay(activity)}
        {this.getErrorCode(activity)}
        {this.getSecondDelay(activity)}
        {this.getSecondErrorCode(activity)}
        {this.getComments(activity)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaders: state.loaders.loaderList,
    errorCodes: state.errorCodes.errorCodeList,
    blocks: state.locations.blocks,
    destinations: state.locations.destinations,
    schedule: state.scheduleRowState
  }
}

export default connect(mapStateToProps)(DashboardScheduleActivity);
