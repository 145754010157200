import { setTruckCompanies } from "../reducers/truckCompanies";
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class TruckCompanyService {
  static getTruckCompanyApi() {
    return axios.get('/api/truckCompanies', { headers: getAuthService().header });
  }

  static getTruckCompanies() {
    return this.getTruckCompanyApi()
      .then((truckCompany) => {
        store.dispatch(setTruckCompanies(truckCompany.data))
      })
      .catch(error => console.log(error));
  }
}

export default TruckCompanyService;