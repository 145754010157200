import store from '../store';
import { DRYLAND, BLOCK, YARD } from '../constants/typeConstants';
import find from 'lodash/find';
import last from 'lodash/last';
import get from 'lodash/get';
import { getDestinationDisplayName } from '../utils/displayNames';

class AddScheduleValidator {

  getStoreState() {
    return store.getState();
  }

  getCurrentScenarioId() {
    return this.getStoreState().scenarioState.selectedScenario.id;
  }

  getBlockLoadProperties(blockId) {
    const scenarioId = this.getCurrentScenarioId();
    const blockLoadState = this.getStoreState().blockLoadState;
    const scenarioBlockLoad = blockLoadState.byId[scenarioId];
    if (scenarioBlockLoad) {
      const targetBlockLoad = scenarioBlockLoad[blockId];

      // rearrange so that the ordered loads show up before the unordered loads
      const ordered = targetBlockLoad.blockLoads.filter(load => load.loadOrder > 0);
      const unordered = targetBlockLoad.blockLoads.filter(load => load.loadOrder < 0);
      const arrangedLoads = [...ordered, ...unordered.reverse()];
      targetBlockLoad.blockLoads = arrangedLoads;
      return targetBlockLoad;
    } 

    return null;
  }

  getScheduleRow(rowId) {
    const scheduleRowState = this.getStoreState().scheduleRowState;
    const targetRow = find(scheduleRowState, {rowId: rowId});
    return targetRow;
  }

  getLastScheduledItem(rowId) {
    const scheduleRow = this.getScheduleRow(rowId);
    const lastScheduled = last(scheduleRow.schedule);
    return lastScheduled;
  }

  getLocationsToYards() {
    return this.getStoreState().locations.locationsToYards;
  }

  getBlocksToDestinations() {
    return this.getStoreState().locations.blocksToDestinations;
  }

  getSleeperLoads() {
    return this.getStoreState().sleeperLoads.sleeperLoadList;
  }

  hasYardTransitTime(originLocation, yard) {
    const locationsToYards = this.getLocationsToYards();
    const target = find(locationsToYards, {locationId: originLocation.id, yardId: yard.id});
    return target !== undefined;
  }

  isLastItemBlock(rowId) {
    const lastScheduled = this.getLastScheduledItem(rowId);
    if (lastScheduled) {
      return lastScheduled.type === BLOCK;
    } else {
      return false;
    }
  }

  findNextAvailableLoad(blockLoadProperties) {
    const blockLoadLength = blockLoadProperties.blockLoads.length;
    for (let i = 0; i < blockLoadLength; i++) {
      const currentBlockLoad = blockLoadProperties.blockLoads[i];
      const assigned = currentBlockLoad.assigned;
      const loads = currentBlockLoad.loads;
      if (assigned < loads && loads > 0) {
        return currentBlockLoad;
      }
    }

    return null;
  }

  noLoadsAvailableMessage(block, destination) {
    const errorMessage = `No loads available for ${destination.name} from ${block.name}`;
    return {
      canAddToSchedule: false,
      errorMessage: errorMessage
    };
  }

  hasOrderedLoadAvailable(availableBlockLoad, action) {
    const destination = action.payload;
    const hasTargetDestination = availableBlockLoad.destination === destination.locationId;
    if (hasTargetDestination) {
      return {canAddToSchedule: true};
    } else {
      const expectedDestinationName = getDestinationDisplayName(availableBlockLoad.destination);
      const blockName = action.meta.origin.name;
      const loadsLeft = availableBlockLoad.loads - availableBlockLoad.assigned;
      const errorMessage = `You are trying to schedule loads out of order.
                            There are still ${loadsLeft} loads available at ${blockName} 
                            for ${expectedDestinationName} that should be
                            scheduled first before ${destination.name}.`;
      return {
        canAddToSchedule: false,
        errorMessage: errorMessage
      }
    }
    
  }

  hasUnorderedLoadAvailable(unorderedLoads, action) {
    const destination = action.payload;
    const blockLoadLength = unorderedLoads.length;
    for (let i = 0; i < blockLoadLength; i++) {
      const currentBlockLoad = unorderedLoads[i];
      if (currentBlockLoad.destination === destination.locationId) {
        const assigned = currentBlockLoad.assigned;
        const loads = currentBlockLoad.loads;
        const hasLoadAvailable = assigned < loads && loads > 0;
        if (hasLoadAvailable) {
          return {canAddToSchedule: true}
        } 
      }
    }

    const block = action.meta.origin;
    return this.noLoadsAvailableMessage(block, destination);
  }

  hasBlockToDestinationDefinition(block, destination) {
    const blocksToDestinations = this.getBlocksToDestinations();
    const targetBlockToDestination = find(blocksToDestinations, {blockId: block.id, destinationId: destination.id});
    return targetBlockToDestination !== undefined;
  }

  hasBlockLoadForDestination(action) {
    const blockOriginId = action.meta.origin.id;
    const blockLoadProperties = this.getBlockLoadProperties(blockOriginId);
    const availableBlockLoad = this.findNextAvailableLoad(blockLoadProperties);
    if (availableBlockLoad == null) {
      const block = action.meta.origin;
      const destination = action.payload;
      return this.noLoadsAvailableMessage(block, destination);
    } else if (availableBlockLoad.loadOrder > 0) {
      return this.hasOrderedLoadAvailable(availableBlockLoad, action);
    } else {
      const unorderedLoads = blockLoadProperties.blockLoads.filter(load => load.loadOrder < 0);
      return this.hasUnorderedLoadAvailable(unorderedLoads, action);
    }
  }

  noTransitDefinitionError(origin, destination) {
    const errorMessage = `No transit definition is defined 
                          between ${origin.name} and ${destination.name}`;
    return {
      canAddToSchedule: false,
      errorMessage: errorMessage
    }
  }

  validateLocationToYard(origin, destination) {
    const canAdd = {canAddToSchedule: true}; 
    const originIsBlockOrDryland = origin.type === BLOCK || origin.type === DRYLAND;
    const destinationIsBlockOrDryland = destination.type === BLOCK || destination.type === DRYLAND;
    if (originIsBlockOrDryland && destination.type === YARD) {
      if (this.hasYardTransitTime(origin, destination)) {
        return canAdd;
      } else {
        return this.noTransitDefinitionError(origin, destination);
      }
    } else if (origin.type === YARD && destinationIsBlockOrDryland) {
      if (this.hasYardTransitTime(destination, origin)) {
        return canAdd;
      } else {
        return this.noTransitDefinitionError(origin, destination);
      }
    } 
    return {canAddToSchedule: false, errorMessage: 'Cannot add this to the schedule'};
  }

  getOrigin(action) {
    return get(action, 'meta.origin', {});
  }

  getDestination(action) {
    return get(action, 'meta.destination', {})
  }

  hasUnassignedSleeperLoads(truckId, yard, destination) {
    const sleepers = this.getSleeperLoads();
    const unassigned = sleepers.filter(load => {
      const noTrip = load.tripCount === 0;
      const sameTruck = load.truckId === truckId;
      const sameYard = load.yardId === yard.id;
      const sameDest = load.destinationId === destination.id;
      return noTrip && sameTruck && sameYard && sameDest;
    });
    return unassigned.length > 0;
  }

  hasSleepersInYard(yardId) {
    const targetSleepers = this.getSleeperLoads().filter(load => load.yardId === yardId);
    return targetSleepers.length > 0;
  }

  hasValidSleeperLoad(scheduleRow, yard, destination) {
    const hasSleepersInYard = this.hasSleepersInYard(yard.id);
    if (this.hasUnassignedSleeperLoads(scheduleRow.truckId, yard, destination)) {
      return {canAddToSchedule: true};
    } else if (!hasSleepersInYard) {
      return {canAddToSchedule: false, errorMessage: 'No sleeper loads to assign'}
    } else {
      return {canAddToSchedule: false, errorMessage: 'There are sleeper loads that need to be scheduled'};
    } 
  }

  canAddToSchedule(action) {
    const scheduleRow = this.getScheduleRow(action.payload.rowId);
    const hasItemsScheduled = scheduleRow && scheduleRow.schedule.length > 0;
    const origin = this.getOrigin(action);
    const destination = this.getDestination(action);
    const validateOriginToDestination = () => this.validateLocationToYard(origin, destination);
    if (!hasItemsScheduled) {
      return {canAddToSchedule: true};
    } else if (action.payload.type === DRYLAND && hasItemsScheduled) {
      if (this.isLastItemBlock(action.payload.rowId)) {
        return this.hasBlockLoadForDestination(action);
      } else if (origin.type === YARD && destination.type === DRYLAND) {
        return this.hasValidSleeperLoad(scheduleRow, origin, destination);
      } else {
        return validateOriginToDestination(scheduleRow, origin, destination);
      }
    } else if (origin.type === DRYLAND && destination.type === BLOCK) {
      if (this.hasBlockToDestinationDefinition(destination, origin)) {
        return {canAddToSchedule: true};
      } else {
        return this.noTransitDefinitionError(origin, destination);
      }
    } else {
      return validateOriginToDestination();
    }
  }
}

export default AddScheduleValidator;