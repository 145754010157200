import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import { connect } from 'react-redux';

class OperatorFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.operatorFieldProps? props.operatorFieldProps: {};
    const defaultId = fieldProps.id? fieldProps.id: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultEmail = fieldProps.email? fieldProps.email: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const defaultComment = fieldProps.comments? fieldProps.comments: '';

    this.state = {
      id: defaultId,
      name: defaultName,
      email: defaultEmail,
      active: defaultActive,
      isModified: false,
      comments: defaultComment
    }

    this.changeOperatorName = this.changeOperatorName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeOperatorName(event) {
    const name = event.target.value.substring(0, 50);
    this.setState({name: name});
    this.setModified();
  }

  changeEmail(event) {
    this.setState({email: event.target.value});
    this.setModified();
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  getAssignedLoaderName() {
    const targetAssignment = this.props.assignments.filter(assign => assign.loaderOperatorId === this.state.id);
    if (targetAssignment.length !== 0) {
      const pairs = targetAssignment.map(assignment => {
        const loader = this.props.loaders.find(loader => loader.id === assignment.loaderId);
        const block = this.props.blocks.find(block => block.id === assignment.blockId);
        const blockName = block? block.name: 'None';
        return (
          <div key={blockName}>
            <div key={block.id}>Loader: {loader.name} - Block: {blockName}</div><br></br>
          </div>
        )
      });
      return pairs;
    } else {
        return (<li key={'none'}>Not Assigned to any loader</li>);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field operator-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeOperatorName} />
        </div>
        <div className='email-field operator-field'>
          <div>Email</div>
          <textarea rows='4' cols='55' value={this.state.email} onChange={this.changeEmail}
            placeholder='Enter each email address separated by a comma'
          />
        </div>
        {/* <div className='currently-assigned-loader operator-field'> */}
        <div style={{marginTop:20}}>
          {this.getAssignedLoaderName()}
        </div>
        <ConfigComment key={this.state.driverId} 
          top={300}
          left={710}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        <div className='operator-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaders: state.loaders.loaderList,
    assignments: state.loaderOperatorAssignment.loaderOperatorAssignmentList,
    blocks: state.locations.blocks
  }
}

export default connect(mapStateToProps)(OperatorFieldEditor);