import { 
  BLOCK_DASHBOARD_DETAILS,
  BLOCK_ACTIVITY_DASHBOARD_DETAILS,
  DRYLAND_DASHBOARD_DETAILS,
  DRYLAND_ACTIVITY_DASHBOARD_DETAILS,
  TRUCK_DASHBOARD_DETAILS,
  SCENARIO_TRUCK_DASHBOARD_DETAILS,
  TRANSIT_DASHBOARD_DETAILS,
  YARD_DASHBOARD_DETAILS
} from '../constants/dashboardTypes';

export const SHOW_BLOCK_DASHBOARD = 'SHOW_BLOCK_DASHBOARD';
export const SHOW_BLOCK_ACTIVITY_DASHBOARD = 'SHOW_BLOCK_ACTIVITY_DASHBOARD';
export const SHOW_DRYLAND_DASHBOARD = 'SHOW_DRYLAND_DASHBOARD';
export const SHOW_DRYLAND_ACTIVITY_DASHBOARD = 'SHOW_DRYLAND_ACTIVITY_DASHBOARD';
export const SHOW_TRUCK_DASHBOARD = 'SHOW_TRUCK_DASHBOARD';
export const SHOW_YARD_DASHBOARD = 'SHOW_YARD_DASHBOARD';
const SHOW_SCENARIO_TRUCK_DASHBOARD = 'SHOW_SCENARIO_TRUCK_DASHBOARD';
const SHOW_TRANSIT_DASHBOARD = 'SHOW_TRANSIT_DASHBOARD';
const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

export function showBlockDashboard(blockId, data) {
  return {
    type: SHOW_BLOCK_DASHBOARD,
    payload: {
      id: blockId,
      dashboardType: BLOCK_DASHBOARD_DETAILS,
      data: data
    }
  }
}

export function showBlockActivityDashboard(blockActivity) {
  return {
    type: SHOW_BLOCK_ACTIVITY_DASHBOARD,
    payload: {
      id: blockActivity.id,
      dashboardType: BLOCK_ACTIVITY_DASHBOARD_DETAILS,
      activity: blockActivity,
    }
  }
}

export function showDrylandDashboard(drylandId, data) {
  return {
    type: SHOW_DRYLAND_DASHBOARD,
    payload: {
      id: drylandId,
      dashboardType: DRYLAND_DASHBOARD_DETAILS,
      data: data
    }
  }
}

export function showDrylandActivityDashboard(drylandActivity) {
  return {
    type: SHOW_DRYLAND_ACTIVITY_DASHBOARD,
    payload: {
      id: drylandActivity.id,
      dashboardType: DRYLAND_ACTIVITY_DASHBOARD_DETAILS,
      activity: drylandActivity
    }
  }
}

export function showTruckDashboard(truck) {
  return {
    type: SHOW_TRUCK_DASHBOARD,
    payload: {
      id: truck.id,
      dashboardType: TRUCK_DASHBOARD_DETAILS,
      data: truck
    }
  }
}

export function showScenarioTruckDashboard(scenarioTruckDashboardInfo) {
  return {
    type: SHOW_SCENARIO_TRUCK_DASHBOARD,
    payload: {
      dashboardType: SCENARIO_TRUCK_DASHBOARD_DETAILS,
      data: scenarioTruckDashboardInfo
    }
  }
}

export function showTransitActivityDashboard(transitInfo) {
  return {
    type: SHOW_TRANSIT_DASHBOARD,
    payload: {
      dashboardType: TRANSIT_DASHBOARD_DETAILS,
      activity: transitInfo
    }
  }
}

export function showYardDashboard(yardId, yard) {
  return {
    type: SHOW_YARD_DASHBOARD,
    payload: {
      id: yardId,
      dashboardType: YARD_DASHBOARD_DETAILS,
      data: yard
    }
  }
}

export function clearDashboard() {
  return {
    type: CLEAR_DASHBOARD
  }
}

const initialState = { 
  currentlySelected: {
    dashboardType: 'None',
    id: ''
  }
};

function dashboard(state = initialState, action) {
  switch(action.type) {
    case SHOW_BLOCK_DASHBOARD:
    case SHOW_BLOCK_ACTIVITY_DASHBOARD:
    case SHOW_DRYLAND_ACTIVITY_DASHBOARD:
    case SHOW_DRYLAND_DASHBOARD:
    case SHOW_TRUCK_DASHBOARD:
    case SHOW_SCENARIO_TRUCK_DASHBOARD:
    case SHOW_TRANSIT_DASHBOARD:
    case SHOW_YARD_DASHBOARD:
      return setDashboardState(state, action);
    case CLEAR_DASHBOARD:
      return initialState;
    default:
      return state;
  }
}

export default dashboard;

function setDashboardState(state, action) {
  return {
    currentlySelected: {
      ...action.payload
    }
  }
}