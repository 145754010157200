import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addMarkSuccess, updateMarkSuccess, removeMarkSuccess, ADD_MARK, UPDATE_MARK, REMOVE_MARK, BULK_ACTIVATE_MARKS, setMarks } from '../../reducers/paintMarks';
import { updateOperatingUnitAssignment } from '../../reducers/operatingUnits';
import { getAuthService } from '../AuthService';


function addSuccess(response) {
  return Observable.of(
    updateOperatingUnitAssignment(response.id, response.operatingUnitId),
    addMarkSuccess(response)
  );
}

const addMarkEpic = action$ => (
  action$.ofType(ADD_MARK)
    .mergeMap(action => (
      ajax.post('/api/paintMarks', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => addSuccess(ajaxResponse.response), 'Successfully added new paint mark'))
        .catch(() => Observable.of(failure('Could not add new paint mark')))
    ))
);

function updateSuccess(response) {
  const configObjectId = response.id;
  const operatingUnitId = response.operatingUnitId;
  return Observable.of(
    updateOperatingUnitAssignment(configObjectId, operatingUnitId),
    updateMarkSuccess(response)
  );
}

const updateMarkEpic = action$ => (
  action$.ofType(UPDATE_MARK)
    .mergeMap(action => (
      ajax.put(`/api/paintMarks/${action.payload.id}`, action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => updateSuccess(ajaxResponse.response), 'Successfully updated paint mark'))
        .catch(() => Observable.of(failure('Could not update paint mark')))
    ))
);

const removeMarkEpic = action$ => (
  action$.ofType(REMOVE_MARK)
    .mergeMap(action => (
      ajax.delete(`/api/paintMarks/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeMarkSuccess(ajaxResponse.response), 'Successfully removed paint mark'))
        .catch(() => Observable.of(failure('Could not remove paint mark')))
    ))
);

const bulkActivateMarks = action$ => (
  action$.ofType(BULK_ACTIVATE_MARKS)
    .mergeMap(action => (
      ajax.post('/api/paintMarks/bulkActivate', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setMarks(ajaxResponse.response), 'Successfully updated paint marks'))
      .catch(fail => Observable.of(failure('Could not bulk activate paint marks')))
    ))
);

export const paintMarkEpics = combineEpics(
  addMarkEpic,
  updateMarkEpic,
  removeMarkEpic,
  bulkActivateMarks
);