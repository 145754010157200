import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addContractorSuccess, updateContractorSuccess, removeContractorSuccess, ADD_CONTRACTOR, UPDATE_CONTRACTOR, REMOVE_CONTRACTOR, BULK_ACTIVATE_CONTRACTORS, setContractors } from '../../reducers/contractors';
import { getAuthService } from '../AuthService';


const addContractorEpic = action$ => (
  action$.ofType(ADD_CONTRACTOR)
    .mergeMap(action => (
      ajax.post('/api/contractors', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addContractorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateContractorEpic = action$ => (
  action$.ofType(UPDATE_CONTRACTOR)
    .mergeMap(action => (
      ajax.put(`/api/contractors/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateContractorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeContractorEpic = action$ => (
  action$.ofType(REMOVE_CONTRACTOR)
    .mergeMap(action => (
      ajax.delete(`/api/contractors/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeContractorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);


export const bulkActivateContractorEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_CONTRACTORS)
      .mergeMap(action => (
          ajax.post('/api/contractors/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setContractors(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const ContractorEpics = combineEpics(
  addContractorEpic,
  updateContractorEpic,
  removeContractorEpic,
  bulkActivateContractorEpic
);