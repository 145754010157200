import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { success, failure } from '../notificationMessages';
import { updateReportSuccess, UPDATE_REPORT} from '../../reducers/reports';
import { getAuthService } from '../AuthService';


export const updateReportEpic = action$ => (
  action$.ofType(UPDATE_REPORT)
    .mergeMap(action => (
      ajax.put(`/api/reports/${action.payload.reportId}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateReportSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);