export const SCENARIO_TRUCK = 'ScenarioTruck';
export const LOCATION_SCHEDULE_COMMENT = 'LocationScheduleComment';
export const DELETE_SCENARIO_TRUCK = 'DeleteScenarioTruck';
export const COPY_SCENARIO_MODAL = 'CopyScenario';
export const REMOVE_OBJECT = 'RemoveObject';
export const BULK_ACTIVATION_MODAL = 'BulkActivationModal';
export const ASSIGN_LOADER_MODAL = 'AssignLoaderModal';
export const SEND_PDF_EMAIL_MODAL = 'SendPdfEmailModal';
export const PDF_PREVIEW_MODAL = 'PdfPreviewModal';
export const SCENARIO_DELETE_MODAL = 'ScenarioDeleteModal';
export const PUBLISH_SCENARIO_MODAL = 'PublishScenarioModal';
export const SLEEPER_LOAD_MODAL = 'SleeperLoadModal';
export const SCENARIO_TRUCK_SHARE = 'ScenarioTruckShare';
export const SCENARIO_TRUCK_SHARE_CONF = 'ScenarioTruckShareConf';
export const DRIVER_TO_BLOCK_RANKING = 'DriverToBlockRanking';
export const DESTINATION_MAX_CAPACITY_MODAL = 'DestinationMaxCapacityModal';
export const BLOCK_HISTORY_MODAL = 'BlockHistoryModal';
export const DRIVER_HISTORY_MODAL = 'DriverHistoryModal';
export const ASSIGN_SLEEPER_YARD_MODAL = 'AssignSleeperYardModal';
export const IMPORT_SLEEPERS_MODAL = 'ImportSleepersModal';
export const SWAP_SCHEDULE_ACTIVITY_MODAL = 'SwapScheduleActivityModal';
export const DUPLICATE_OBJECT_NAMES_MODAL = 'duplicateObjectNamesModal';
export const ROLLUP_DAY_PICKER_MODAL = 'RollupDayPickerModal';
export const REOPEN_SCENARIO_PASSWORD_MODAL = 'ReopenScenarioPasswordModal';
export const SEND_CONTRACTOR_TIMECARDS_MODAL = 'SendContractorTimeCardsModal';
export const TRAINEE_DRIVER_MODAL = 'TraineeDriverModal';
export const PUBLISH_PARTIALLY_MODAL = "PublishPartiallyModal";