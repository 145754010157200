import React from 'react'
import { connect } from 'react-redux';
import { openBulkActivationModal, openRemoveObjectModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import find from 'lodash/find';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import Select from 'react-select';
import uniqid from 'uniqid';
import { addErrorCode, editErrorCode, bulkActivateErrorCodes } from '../../reducers/errorCodes';
import ErrorCodeFieldEditor from '../../components/fieldEditors/ErrorCodeFieldEditor';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { ERRORCODE } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class ErrorCodeConfiguration extends React.Component {
  constructor (props) {
    super(props);
    
    this.state = {
      errorCodeSelected: '',
      isAddingNewErrorCode: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousErrorCodes = previousProps.errorCodes.map(e => e.id);
    const currentErrorCodes = this.props.errorCodes.map(e => e.id);
    if (currentErrorCodes.length > previousErrorCodes.length) {
      const diff = difference(currentErrorCodes, previousErrorCodes);
      if (diff.length > 0) {
        const newErrorCode = diff[0];
        this.setState({
          errorCodeSelected: newErrorCode,
          isAddingNewErrorCode: false
        });
      }
    }
  }
  

  addNewErrorCode() {
    this.setState({
      errorCodeSelected: '',
      isAddingNewErrorCode: true
    });
  }
  
  selectErrorCode(errorCodeId) {
    this.setState({
      errorCodeSelected: errorCodeId,
      isAddingNewErrorCode: false
    });
  }

  getSelectedErrorCode() {
    const errorCode = find(this.props.errorCodes, {id: this.state.errorCodeSelected});
    return errorCode;
  }
  
  getSelectedErrorCodeCode() {
    const errorCode = this.getSelectedErrorCode();
    if (errorCode) {
      return errorCode.code;
    } else {
      return 'Select...';
    }
  }
  
  getErrorCodeProps() {
    const errorCode = this.getSelectedErrorCode();
    if (errorCode) {
      return {
        errorCodeId : errorCode.id,
        code: errorCode.code,
        payOptions: errorCode.payOptions,
        description : errorCode.description,
        comments: errorCode.comments,
        active: errorCode.active
      };
    } else{
      return{};
    }
  }


  hasSelectedErrorCode() {
    return this.state.errorCodeSelected !== '';
  }

  checkExistence(state) {
    if (this.state.isAddingNewErrorCode) {
      const errorCode = find(this.props.errorCodes, {code: state.code});
      if (errorCode) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewErrorCode) {
      if (this.getSelectedErrorCodeCode() !== state.code) {
        const errorCode = find(this.props.errorCodes, {code: state.code});
        if (errorCode) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  
  getErrorCodeEditPanel() {
    if (this.hasSelectedErrorCode() && !this.state.isAddingNewErrorCode) {
      const errorCodeId = this.state.errorCodeSelected;
      return (
        <div>
          <ErrorCodeFieldEditor
          key={errorCodeId}
          errorCodeFieldProps={this.getErrorCodeProps()}
          clickDone={(state) => this.checkExistence(state)}
          clickCancel={() => this.selectErrorCode('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewErrorCode) {
      return (
        <div>
          <p>Add New Error Code</p>
          <ErrorCodeFieldEditor
          key={uniqid()}
          clickDone={(state) => this.checkExistence(state)}
          clickCancel={() => this.selectErrorCode('')}
          />
        </div>
      );
    }
  }

  getErrorCodeButtonStyle(id) {
    if (id === this.state.errorCodeSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }


  getErrorCodeList() {
    const options = this.props.errorCodes;
    const sortedOptions = sortBy(options, errorCode => errorCode.code)
    .filter(errorCode => !errorCode.isDeleted)
    .map(errorCode => {
      return {
        value: errorCode.id,
        label: errorCode.code + " - " + errorCode.description
      }
    });
    
  const handleChange =(selectedOption) => this.selectErrorCode(selectedOption.value);

  return <Select className='configuration-object-selector'
    value={{label: this.getSelectedErrorCodeCode()}}
    options={sortedOptions}
    onChange={handleChange}
  />;
  }


  activate() {
    const errorCodeState = this.getErrorCodeProps();
    if (errorCodeState) {
      errorCodeState.active = !errorCodeState.active;
      this.props.update(errorCodeState);
    }
  }

  getActivateButton() {
    const errorCode = this.getSelectedErrorCode();
    if (errorCode) {
      const buttonName = errorCode.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveErrorCodeButton() {
    const errorCodeId = this.state.errorCodeSelected;
    if (errorCodeId !== '') {
      return (
        <button className='remove-errorCode'
          onClick={() => this.props.remove(errorCodeId)}>
            Remove Error Code
        </button>
      );
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedErrorCodeWithId = this.props.errorCodes
      .filter(errorCode => !errorCode.isDeleted)
      .map(errorCode => {
        return {
          ...errorCode,
          id: errorCode.id,
          code: errorCode.code
        }
      });
      return <BulkActivationModal
        type={ERRORCODE}
        activationItems={updatedErrorCodeWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }


  getPrompt() {
    const errorCode = this.getSelectedErrorCode();
    if (errorCode) {
      return `Edit Error Code ${errorCode.code}`;
    } else if (this.state.isAddingNewErrorCode) {
      return `Add a new Error Code`;
    } else {
      return `Select an Error Code to edit`;
    }
  }
  

  render() {
    return (
      <div className='errorCodes-configuration configuration-forms'>
        <button className='add-new-errorCode' onClick={() => this.addNewErrorCode()}>Add New Error Code</button>
        <button className='bulk-activation-button'
          onClick={() => this.props.openBulkActivationModal()}>
            Bulk Activation
        </button>
        <div className='errorCode-editor'>
          <p>{this.getPrompt()}</p>
          <div className='errorCode-list'>
            {this.getErrorCodeList()}
          </div>
          <div className='errorCode-edit-panel'>
            {this.getErrorCodeEditPanel()}
            <div className='errorCode-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveErrorCodeButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectErrorCode('')}/>
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}
  
const mapStateToProps = (state) => {
  return {
    errorCodes: state.errorCodes.errorCodeList,
    modalWindow: state.modalWindows
    
  }
}
  
const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addErrorCode(state));
    },
    update: (state) => {
      dispatch(editErrorCode(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems))
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateErrorCodes(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ErrorCodeConfiguration);
  
