import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { sortAscendingTruckName, sortDescendingTruckName, sortAscendingStartTime, sortDescendingStartTime, sortAscendingBlockName } from '../reducers/schedule';

class SortSchedule extends React.Component {
  hasScheduledTrucks() {
    return this.props.scheduleRows.length > 0;
  }

  render() {
    if (this.hasScheduledTrucks()) {
      const id = 'sort-schedule-id';
      return (
        <div className='sort-schedule-context-menu'>
          <ContextMenuTrigger id={id} holdToDisplay={0}>
            <button className='sort-schedule-button'>
              Sort
            </button>
          </ContextMenuTrigger>

          <ContextMenu id={id}>
            <MenuItem onClick={() => this.props.sortAscendingTruckName()}>Sort by ascending truck names</MenuItem>
            <MenuItem onClick={() => this.props.sortDescendingTruckName()}>Sort by descending truck names</MenuItem>
            <MenuItem onClick={() => this.props.sortAscendingStartTime()}>Sort by ascending start times</MenuItem>
            <MenuItem onClick={() => this.props.sortDescendingStartTime()}>Sort by descending start times</MenuItem>
            <MenuItem onClick={() => this.props.sortAscendingBlockName()}>Sort by ascending block name</MenuItem>
          </ContextMenu>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    scheduleRows: state.scheduleRowState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sortAscendingTruckName: () => dispatch(sortAscendingTruckName()),
    sortDescendingTruckName: () => dispatch(sortDescendingTruckName()),
    sortAscendingStartTime: () => dispatch(sortAscendingStartTime()),
    sortDescendingStartTime: () => dispatch(sortDescendingStartTime()),
    sortAscendingBlockName: () => dispatch(sortAscendingBlockName())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SortSchedule);