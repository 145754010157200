import React from 'react';
import { connect } from 'react-redux';
import { matchesSelectedOperatingUnit, isTruckInYardOperatingUnits } from '../utils/operatingUnitHelper';
import { getActiveDestinationsInOperatingUnit } from '../utils/yardDestinations';
import find from 'lodash/find';

class SleeperLoadRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectTruck = this.handleSelectTruck.bind(this);
    this.handleSelectDestination = this.handleSelectDestination.bind(this);
    this.handleSelectOriginBlock = this.handleSelectOriginBlock.bind(this);
    this.handleRemoveLoad = this.handleRemoveLoad.bind(this);
  }

  handleRemoveLoad() {
    this.props.handleRemoveLoad(this.props.id);
  }

  handleSelectTruck(event) {
    const truckId = event.target.value;
    this.props.handleChangeSelection(this.props.id, truckId, this.props.selectedOriginBlockId, this.props.selectedDestinationId);
  }

  handleSelectOriginBlock(event) {
    const originBlockId = event.target.value;
    this.props.handleChangeSelection(this.props.id, this.props.selectedTruckId, originBlockId, this.props.selectedDestinationId);
  }

  handleSelectDestination(event) {
    const destinationId = event.target.value;
    this.props.handleChangeSelection(this.props.id, this.props.selectedTruckId, this.props.selectedOriginBlockId, destinationId);
  }

  getAvailableTrucks() {
    const yard = find(this.props.yards, {id: this.props.yardId});
    const yardOperatingUnits = yard.operatingUnitIds;
    return this.props.trucks.filter(truck => truck.active 
      && matchesSelectedOperatingUnit(truck.id)
      && isTruckInYardOperatingUnits(truck.id, yardOperatingUnits));
  }

  getNoneOption() {
    return <option key={'none'} value={''}>None</option>;
  }

  getTruckOptions() {
    const truckIdSet = this.props.selectedTruckIdSet;
    const unselectedTrucks = this.getAvailableTrucks().filter(truck => !truckIdSet.has(truck.id) || truck.id === this.props.selectedTruckId);
    const truckOptions = unselectedTrucks.map(truck => {
      const id = truck.id;
      return <option key={id} value={id}>{truck.name}</option>;
    });
    truckOptions.unshift(this.getNoneOption());
    return truckOptions;
  }

  getDestinationOptions() {
    const activeDestinationsInOpUnits = getActiveDestinationsInOperatingUnit(this.props.yardId);
    const destinationOptions = activeDestinationsInOpUnits
      .map(dest => <option key={dest.id} value={dest.id}>{dest.name}</option>);
    destinationOptions.unshift(this.getNoneOption());
    return destinationOptions;
  }

  getOriginBlockOptions() {
    const activeBlocks = this.props.blocks.filter(block => block.active && !block.isDeleted);
    const blockOptions = activeBlocks
      .map(block => <option key={block.id} value={block.id}>{block.name}</option>);
      blockOptions.unshift(this.getNoneOption());
      return blockOptions;
  }

  showWarningSymbol() {
    if (this.props.selectedTruckId === '' || this.props.selectedDestinationId === '' || this.props.selectedOriginBlockId === '') {
      return '⚠️';
    } else {
      return ' ';
    }
  }

  render() {
    return (
      <div className='sleeper-load-row'>
        <span className='warning-sign'>{this.showWarningSymbol()}</span>
        <select className='truck-picker' onChange={this.handleSelectTruck} value={this.props.selectedTruckId}>
          {this.getTruckOptions()}
        </select>
        <select className='originBlock-picker' onChange={this.handleSelectOriginBlock} value={this.props.selectedOriginBlockId}>
          {this.getOriginBlockOptions()}
        </select>
        <select className='destination-picker' onChange={this.handleSelectDestination} value={this.props.selectedDestinationId}>
          {this.getDestinationOptions()}
        </select>
        <button className='delete-sleeper-load-row'
                onClick={this.handleRemoveLoad}>
          ✖
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trucks: state.trucks.truckList,
    blocks: state.locations.blocks,
    yards: state.locations.yards
  }
}

export default connect(mapStateToProps)(SleeperLoadRow);