import React from 'react';
import { connect } from 'react-redux';

export const SCROLL_TOP = 'scroll-top';
export const SCROLL_BOTTOM = 'scroll-bottom';

class ScrollSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    }

    this.scrollAmount = 50;
    this.scheduleClassName = 'scheduler-canvas-container';
  }

  isScrollTop() {
    return this.props.scrollDirection === SCROLL_TOP;
  }

  isScrollBottom() {
    return this.props.scrollDirection === SCROLL_BOTTOM;
  }

  getClassName() {
    if (this.isScrollTop()) {
      return 'schedule-' + SCROLL_TOP;
    } else if (this.isScrollBottom()) {
      return 'schedule-' + SCROLL_BOTTOM;
    }
  }

  getContainerElement() {
    return document.getElementsByClassName(this.scheduleClassName)[0];
  }

  scrollTop() {
    const container = this.getContainerElement();
    container.scrollTop -= this.scrollAmount;
  }

  scrollBottom() {
    const container = this.getContainerElement();
    container.scrollTop += this.scrollAmount;
  }

  scrollAction() {
    if (this.isScrollTop()) {
      this.scrollTop();
    } else if (this.isScrollBottom()) {
      this.scrollBottom();
    }
  }

  isScheduleScrollable() {
    return this.props.schedule.length > 11;
  }

  hoverOver() {
    this.setState({isHovering: true});
  }

  hoverOff() {
    this.setState({isHovering: false});
  }

  getButtonClassName() {
    if (this.isScrollTop()) {
      return SCROLL_TOP + '-button';
    } else if (this.isScrollBottom()) {
      return SCROLL_BOTTOM + '-button';
    }
  }

  getIcon() {
    if (this.isScrollTop()) {
      return '⯅';
    } else if (this.isScrollBottom()) {
      return '⯆';
    }
  }

  render() {
    if (this.isScheduleScrollable()) {
      return (
        <div className={this.getClassName()}
          onClick={() => this.scrollAction()}>
          <button className={this.getButtonClassName()}
            onClick={() => this.scrollAction()}>
            <div className='scroll-icon'>{this.getIcon()}</div>
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    schedule: state.scheduleRowState
  }
}

export default connect(mapStateToProps)(ScrollSchedule);