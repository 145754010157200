import React from 'react';

class ConfigComment extends React.Component {
  constructor(props) {
    super(props);

    const defaultComments = props.comments? props.comments: '';

    this.state = {
      comments: defaultComments
    }
    this.changeComments = this.changeComments.bind(this);
  }

  changeComments(event) {
    const inputComment = event.target.value;
    this.setState({comments: inputComment});
    this.props.updateComments(inputComment);
  }

  getStyle() {
    let style = {
      position: 'absolute'
    };

    if (this.props.top) {
      style.top = `${this.props.top}px`;
    }

    if (this.props.left) {
      style.left = `${this.props.left}px`;
    }

    return style;
  }

  render() {
    return (
      <div className='config-comment-section' style={this.getStyle()}>
        <div>Comments</div>
        <textarea rows='5' cols='20' type='text'
          value={this.state.comments}
          onChange={this.changeComments}
        />
      </div>
    );
  }
}

export default ConfigComment;