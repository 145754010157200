import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { success, failure } from '../notificationMessages';
import { SHARE_TRUCK, setTruckShares, DELETE_TRUCK_SHARE, deleteTruckShareSuccess}  from '../../reducers/truckShares';
import { getAuthService } from '../AuthService';


export const shareTruckEpic = action$ => (
  action$.ofType(SHARE_TRUCK)
    .mergeMap(action => (
      ajax.post(`/api/truckShares`,action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setTruckShares(ajaxResponse.response), 'Truck shared successfully'))
        .catch(fail => Observable.of(failure('Could not share the truck')))  
    ))
);

export const deleteShareTruckEpic = action$ => (
  action$.ofType(DELETE_TRUCK_SHARE)
  .mergeMap(action => (
    ajax.delete(`/api/truckShares/${action.payload}`, getAuthService().header)
      .map(ajaxResponse => success(() => deleteTruckShareSuccess(ajaxResponse.response)))
      .catch(fail => Observable.of(failure()))
  ))
);

