import { setTruckShares } from '../reducers/truckShares';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class TruckShareService {
    static getTruckShareApi() {
        return axios.get('/api/truckshares', { headers: getAuthService().header });
    }

    static getTruckShares() {
        return this.getTruckShareApi()
            .then((truckShares) => {
                store.dispatch(setTruckShares(truckShares.data))
            })
            .catch(error => console.log(error));
    }
}

export default TruckShareService;