import React from 'react';

class DashboardTruck extends React.Component {

  getRate(rate) {
    if (rate === 0) {
      return <div style={{color: 'red'}}>Rate: {rate}</div>
    }
    else {
      return <div>Rate: {rate}</div>
    }
  }

  render() {
    const data = this.props.dashboardInfo;
    return (
      <div className='dashboard-dryland'>
        <h4>Truck {data.name} details </h4>
        <div>License Plate: {data.licensePlate}</div>
        <div>Truck Company: {data.truckCompany}</div>
        <div>Default Driver: {data.defaultDriverName}</div>
        <div>Default Trailer: {data.defaultTrailerName}</div>
        <div>Year: {data.year}</div>
        {this.getRate(data.rate)}
      </div>
    );
  }
}

export default DashboardTruck;