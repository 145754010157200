import React from 'react';
import ScheduleRow from './ScheduleRow';
import uniqid from 'uniqid';
import TimeMarkers from '../components/TimeMarkers';
import CurrentTimeLineTracker from '../components/CurrentTimeLineTracker';
import HourMarkers from '../components/HourMarkers';
import { connect } from 'react-redux';
import TruckDropTarget from './dragAndDrop/TruckDropTarget';
import { clearDashboard } from '../reducers/dashboard';
import { BLOCK_ACTIVITY_DASHBOARD_DETAILS, DRYLAND_ACTIVITY_DASHBOARD_DETAILS, SCENARIO_TRUCK_DASHBOARD_DETAILS, TRANSIT_DASHBOARD_DETAILS } from '../constants/dashboardTypes';
import { SCENARIO_TRUCK, LOCATION_SCHEDULE_COMMENT, SCENARIO_TRUCK_SHARE, SLEEPER_LOAD_MODAL, IMPORT_SLEEPERS_MODAL, SWAP_SCHEDULE_ACTIVITY_MODAL, ROLLUP_DAY_PICKER_MODAL, REOPEN_SCENARIO_PASSWORD_MODAL, SEND_CONTRACTOR_TIMECARDS_MODAL } from '../constants/modalTypes';
import ScenarioTruckModal from '../components/modals/ScenarioTruckModal';
import ScenarioTruckShareModal from '../components/modals/ScenarioTruckShareModal';
import { openScenarioTruckShareModal } from '../reducers/modalWindows';
import { openScenarioTruckModal } from '../reducers/modalWindows';
import LocationScheduleEditActivityModal from '../components/modals/LocationScheduleEditActivityModal';
import ScrollSchedule, { SCROLL_TOP, SCROLL_BOTTOM } from '../components/ScrollSchedule';
import ScenarioTruckDeleteModal from '../components/modals/ScenarioTruckDeleteModal';
import CopyScenarioModal from '../components/modals/CopyScenarioModal';
import AssignLoaderModal from '../components/modals/AssignLoaderModal';
import SendPdfEmailConfirmationModal from '../components/modals/SendPdfEmailConfirmationModal';
import SortSchedule from '../components/SortSchedule';
import { isInOperatingUnit } from '../utils/operatingUnitHelper';
import PdfPreview from '../components/PdfPreview';
import ScenarioDeleteModal from '../components/modals/ScenarioDeleteModal';
import { isViewerRole } from '../utils/userHelper';
import PublishScenarioModal from '../components/modals/PublishScenarioModal';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import SleeperLoadModal from '../components/modals/SleeperLoadModal';
import AssignSleeperModal from '../components/modals/AssignSleeperModal';
import DriverToBlockRankingModal from '../components/modals/DriverToBlockRankingModal';
import DestinationMaxCapacityModal from '../components/modals/DestinationMaxCapacityModal';
import ImportSleepersModal from '../components/modals/ImportSleepersModal';
import SwapScheduleActivityModal from '../components/modals/SwapScheduleActivityModal';
import RollupDayPickerModal from '../components/modals/RollupDayPickerModal';
import ReopenScenarioPasswordModal from '../components/modals/ReopenScenarioPasswordModal';
import SendContractorTimeCardsModal from '../components/modals/SendContractorTimeCardsModal';
import TraineeDriverModal from '../components/modals/TraineeDriverModal';
import PublishPartiallyModal from '../components/modals/PublishPartiallyModal';

class SchedulerCanvas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedItemId: '',
      currentScenario: this.props.currentScenario
    }

    this.clickedOnItem = this.clickedOnItem.bind(this);
    this.rowId = this.getScheduleRowId();
  }

  componentDidUpdate() {
    const hasScenarioChanged = this.props.currentScenario !== this.state.currentScenario;
    if (hasScenarioChanged) {
      this.setState({
        clickedItemId: '',
        currentScenario: this.props.currentScenario
      });
    }

    const dashboardType = this.props.dashboard.dashboardType;
    if (dashboardType !== 'None' && this.state.clickedItemId !== '' && 
        (dashboardType !== BLOCK_ACTIVITY_DASHBOARD_DETAILS &&
         dashboardType !== DRYLAND_ACTIVITY_DASHBOARD_DETAILS &&
         dashboardType !== SCENARIO_TRUCK_DASHBOARD_DETAILS &&
         dashboardType !== TRANSIT_DASHBOARD_DETAILS)) {
      this.setState({clickedItemId: ''});
    }
  }

  getScheduleRowId() {
    return uniqid('scheduleRow');
  }

  createNewRowId() {
    this.rowId = this.getScheduleRowId();
  }

  clickedOnItem(id) {
    if (this.state.clickedItemId === id) {
      this.setState({clickedItemId: ''});
      this.props.clearDashboard();
    } else {
      this.setState({clickedItemId: id});
    }
  }


  getSharedTruckOperatingUnitId() {
    const shares = this.props.truckShares
    let operatingUnitId = shares.map(share => share.tempOperatingUnitId)
    return operatingUnitId;
  }


  getScheduleRows(operatingUnitId) {
    const scheduleRows = this.props.scheduleRowState
      .filter(scheduleRow => scheduleRow.rowId !== '')
      .filter(scheduleRow => isInOperatingUnit(scheduleRow.truckId, operatingUnitId))
      .map(scheduleRow => {
        const comments = scheduleRow.scenarioTruck? scheduleRow.scenarioTruck.comments: "";
        return <ScheduleRow
          key={scheduleRow.rowId} 
          rowId={scheduleRow.rowId}
          truckName={scheduleRow.truckName}
          beginHour={this.props.beginHour}
          endHour={this.props.endHour}
          comments={comments}
          clickedItemId={this.state.clickedItemId}
          clickedOnItem={this.clickedOnItem} 
          isPublished={this.props.hasBeenPublished}
          truckId={scheduleRow.truckId}
          isClosed={this.props.isClosed}
          isShared={false}/>
      });
      return scheduleRows;
  }

  getScheduleRowGroupedByOperatingUnit() {
    const operatingUnits = this.props.operatingUnits;
    const selectedOperatingUnits = new Set(this.props.selectedOperatingUnitIds);
    return operatingUnits
      .filter(operatingUnit => selectedOperatingUnits.has(operatingUnit.id))
      .map(operatingUnit => {
        const rows = this.getScheduleRows(operatingUnit.id);
        const shared = this.getSharedTruckRow(operatingUnit.id)
        if (rows.length > 0 || shared.length > 0) {
          return (
            <div key={operatingUnit.id}>
              <div className='operating-unit-label-container'>
                <p className='operating-unit-name-label'>{operatingUnit.name}</p>
                <hr className='operating-unit-divider'></hr>
              </div>
              {rows}
              {shared}
            </div>
          );
        } else {
          return null;
        }
      });
  }

  getSharedTruckRow(operatingUnitId) {
    const scheduleRows = this.props.scheduleRowState
    .filter(scheduleRow => this.isShared(scheduleRow.truckId, scheduleRow.scenarioTruckId, operatingUnitId))
    .map( scheduleRow => {
      const comments = scheduleRow.scenarioTruck? scheduleRow.scenarioTruck.comments: "";
      return <ScheduleRow 
      key={scheduleRow.rowId}
      rowId={scheduleRow.rowId}
      truckName={scheduleRow.truckName+" Shared"}
      beginHour={scheduleRow.beginHour}
      endHour={scheduleRow.endHour}
      comments={comments}
      clickedItemId={this.state.clickedItemId}
      clickedOnItem={this.clickedOnItem} 
      isPublished={scheduleRow.hasBeenPublished}
      isShared={true}/>  
    });
    return scheduleRows;
  }

  isShared(truckId, scenarioTruckId, operatingUnitId) {
    const shares = this.props.truckShares;
    const target = find(shares, {truckId: truckId, tempOperatingUnitId: operatingUnitId, scenarioTruckId: scenarioTruckId});
    return !isNil(target);
  }
  

  getScenarioTruckModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === SCENARIO_TRUCK && modalWindow.isModalOpen) {
      const isAdding = modalWindow.isAddingNewScenarioTruck;
      return <ScenarioTruckModal 
        highlightTruck={(scenarioTruckId) => {
          this.props.clearDashboard();
          this.setState({clickedItemId: scenarioTruckId});
        }}
        truckId={modalWindow.truckId}
        beginHour={this.props.beginHour}
        name={modalWindow.name}
        rowId={this.getScheduleRowId()}
        isAddingNewTruck={isAdding}
        scenarioTruck={modalWindow.scenarioTruck}
        startTime={modalWindow.startTime}
        workDayMinutes={modalWindow.workDayMinutes}
        comments={modalWindow.comments}
        />;
    } else {
      return null;
    }
  }


  getScenarioTruckShareModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === SCENARIO_TRUCK_SHARE && modalWindow.isModalOpen) {
      const isSharing = modalWindow.isSharingScenarioTruck;
      return <ScenarioTruckShareModal
        highlightTruck={(scenarioTruckId) => {
          this.props.clearDashboard();
          this.setState({clickedItemId: scenarioTruckId});
        }}
        truckId={modalWindow.truckId}
        name={modalWindow.name}
        rowId={this.getScheduleRowId()}
        isSharingScenarioTruck={isSharing}
        scenarioTruck={modalWindow.scenarioTruck}
        />;
    } else {
      return null;
    }
  }   

  getImportSleepersModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === IMPORT_SLEEPERS_MODAL && modalWindow.isModalOpen) {
      return <ImportSleepersModal
        highlightTruck={(scenarioTruckId) => {
          this.props.clearDashboard();
          this.setState({clickedItemId: scenarioTruckId});
        }}
      />
    }
  }

  getRollupDayPickerModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === ROLLUP_DAY_PICKER_MODAL && modalWindow.isModalOpen) {
      return <RollupDayPickerModal />
    }
  }


  getReopenScenarioPasswordModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === REOPEN_SCENARIO_PASSWORD_MODAL && modalWindow.isModalOpen) {
      return <ReopenScenarioPasswordModal />
    }
  }

  getSendContractorTimeCardsModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === SEND_CONTRACTOR_TIMECARDS_MODAL && modalWindow.isModalOpen) {
      return <SendContractorTimeCardsModal />
    }
  }


  getTruckDropTarget() {
    if (!this.props.hasBeenPublished && !isViewerRole() && !this.props.isClosed) {
      return (
        <TruckDropTarget
          openScenarioTruckModal={this.props.openScenarioTruckModal}
          rowCount={this.props.scheduleRowState.length}
        />
      );
    } else {
      return null;
    }
  }

  getLocationScheduleCommentModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === LOCATION_SCHEDULE_COMMENT && modalWindow.isModalOpen) {
      return <LocationScheduleEditActivityModal 
        modalProps={modalWindow.modalProps}
      />;
    } else {
      return null;
    }
  }

  getSwapScheduleActivityModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === SWAP_SCHEDULE_ACTIVITY_MODAL && modalWindow.isModalOpen) {
      return <SwapScheduleActivityModal
        modalProps={modalWindow.modalProps}
      />
    } else {
      return null;
    }
  }


  getSleeperLoadModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === SLEEPER_LOAD_MODAL && modalWindow.isModalOpen) {
      const { name, id } = modalWindow.sleeperInfo;
      return <SleeperLoadModal id={id} name={name} />;
    } else {
      return null;
    }
  }

  render() {
    let beginHour = this.props.beginHour;
    let endHour = this.props.endHour;
    return (
      <div className="scheduler-canvas">
        <SortSchedule />
        <ScrollSchedule scrollDirection={SCROLL_TOP} />
        <div className="time-markers">
          {TimeMarkers(beginHour, endHour)}
        </div>
        <div className="scheduler-canvas-container">
          <div className="scheduler-canvas-body">
            <CurrentTimeLineTracker beginHour={beginHour} endHour={endHour} />
            <HourMarkers numberOfHours={endHour - beginHour + 1} />
            <div className="schedule-rows">
              {this.getScheduleRowGroupedByOperatingUnit()}
            </div>
            {this.getTruckDropTarget()}
            {this.getScenarioTruckModal()}
            {this.getScenarioTruckShareModal()}
            {this.getLocationScheduleCommentModal()}
            <ScenarioTruckDeleteModal />
            <CopyScenarioModal />
            <AssignLoaderModal />
            <SendPdfEmailConfirmationModal />
            <PdfPreview />
            <ScenarioDeleteModal />
            <PublishScenarioModal />
            <AssignSleeperModal />
            <PublishPartiallyModal />
            {this.getSleeperLoadModal()}
            <DriverToBlockRankingModal />
            <DestinationMaxCapacityModal />
            <TraineeDriverModal />
            {this.getImportSleepersModal()}
            {this.getSwapScheduleActivityModal()}
            {this.getRollupDayPickerModal()}
            {this.getReopenScenarioPasswordModal()}
            {this.getSendContractorTimeCardsModal()}
          </div>
        </div>
        <ScrollSchedule scrollDirection={SCROLL_BOTTOM} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beginHour: state.workHoursForSchedule.beginHour,
    endHour: state.workHoursForSchedule.endHour,
    scheduleRowState: state.scheduleRowState,
    currentScenario: state.scenarioState.selectedScenario.id,
    dashboard: state.dashboard.currentlySelected,
    modalWindow: state.modalWindows,
    hasBeenPublished: state.scenarioState.selectedScenario.published || state.scenarioState.selectedScenario.wasPublished,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    operatingUnits: state.operatingUnits.operatingUnitList,
    truckShares: state.truckShares.truckSharesList,
    operatingUnitAssignment:state.operatingUnits.operatingUnitAssignment,
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    isClosed: state.scenarioState.selectedScenario.isClosed,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    openScenarioTruckModal: (truckId, truckName, isAddingNewScenarioTruck) => {
      dispatch(openScenarioTruckModal(truckId, truckName, isAddingNewScenarioTruck));
    },
    clearDashboard: () => {
      dispatch(clearDashboard());
    },
    openScenarioTruckShareModal: (truckId, truckName, isSharingTruck) => {
      dispatch(openScenarioTruckShareModal(truckId, truckName, isSharingTruck));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerCanvas);
