const SET_BLOCK_YARDERS = 'SET_BLOCK_YARDERS';
const SET_BLOCK_VENDORS = 'SET_BLOCK_VENDORS';
const SET_BLOCK_PROCESSORS = 'SET_BLOCK_PROCESSORS';
const SET_BLOCK_HAMMERMARKS = 'SET_BLOCK_HAMMERMARKS';
const SET_BLOCK_FALLERS = 'SET_BLOCK_FALLERS';
const SET_BLOCK_CONTRACTORS = 'SET_BLOCK_CONTRACTORS';

export function setBlockYarders (blockYarders) {
  return {
    type: SET_BLOCK_YARDERS,
    payload: blockYarders
  }
}

export function setBlockVendors (blockVendors) {
  return {
    type: SET_BLOCK_VENDORS,
    payload: blockVendors
  }
}

export function setBlockProcessors (blockProcessors) {
  return {
    type: SET_BLOCK_PROCESSORS,
    payload: blockProcessors
  }
}

export function setBlockHammerMarks (blockHammerMarks) {
  return {
    type: SET_BLOCK_HAMMERMARKS,
    payload: blockHammerMarks
  }
}

export function setBlockFallers (blockFallers) {
  return {
    type: SET_BLOCK_FALLERS,
    payload: blockFallers
  }
}

export function setBlockContractors (blockContractors) {
  return {
    type: SET_BLOCK_CONTRACTORS,
    payload: blockContractors
  }
}

export default function blockProperties ( state = {
  blockYardersList: [],
  blockFallersList:[],
  blockVendorsList:[],
  blockContractorsList: [],
  blockProcessorsList: [],
  blockHammerMarksList: [],
}, action) {
  switch (action.type) {
    case SET_BLOCK_YARDERS:
      return _setBlockYarders(state, action);
    case SET_BLOCK_VENDORS:
      return _setBlockVendors(state, action);
    case SET_BLOCK_PROCESSORS:
      return _setBlockProcessors(state, action);
    case SET_BLOCK_HAMMERMARKS:
      return _setBlockHammerMarks(state, action);
    case SET_BLOCK_FALLERS:
      return _setBlockFallers(state, action);
    case SET_BLOCK_CONTRACTORS:
      return _setBlockContractors(state, action);
    default:
      return state;
  }
}

function _setBlockYarders(state, action) {
  return {
    ...state,
    blockYardersList: action.payload
  }
}

function _setBlockVendors(state, action) {
  return {
    ...state,
    blockVendorsList: action.payload
  }
}

function _setBlockProcessors(state, action) {
  return {
    ...state,
    blockProcessorsList: action.payload
  }
}

function _setBlockHammerMarks(state, action) {
  return {
    ...state,
    blockHammerMarksList: action.payload
  }
}

function _setBlockFallers (state, action) {
  return {
    ...state,
    blockFallersList: action.payload
  }
}

function _setBlockContractors(state, action) {
  return {
    ...state,
    blockContractorsList: action.payload
  }
}