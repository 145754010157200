import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { PUBLISH_PARTIALLY_MODAL } from '../../constants/modalTypes';
import { closeModal } from '../../reducers/modalWindows';
import includes from 'lodash/includes';
import PublishService from '../../services/publishService';


class PublishPartiallyModal extends React.Component {
  constructor(props) {
    super(props);
    const defaultIds = props.selectedOperatingUnitIds? props.selectedOperatingUnitIds: [];
    const scenarioPartialPublishmentList = props.scenarioPartialPublishmentList? props.scenarioPartialPublishmentList : [];

    this.state = {
      selectedOperatingUnitIds: defaultIds,
      scenarioPartialPublishmentList: scenarioPartialPublishmentList,
    }
  }

  publishIndividualOperatintUnit(operatingUnitId) {
    this.props.closeModal();
    const user = this.props.modalWindow.user;
    const scenarioId = this.props.modalWindow.scenarioId;
    PublishService.publishPartially(scenarioId, operatingUnitId, user);
  }

  isPublished(id) {
    const publishedOperatingUnitIds = this.props.scenarioPartialPublishmentList.map(x => x.operatingUnitId);
    return includes(publishedOperatingUnitIds, id);
  }

  areTruckInOperatingUnit(id) {
    const truckIds = this.props.scheduleRows.map(x => x.truckId);
    const scheduled = this.props.assignment.filter(assignment => assignment.operatingUnitId === id && truckIds.includes(assignment.configObjectId));
    if (scheduled.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  getOperatingUnitOptions() {
    const options = this.props.operatingUnits
      .filter(operatingUnit => operatingUnit.active && !operatingUnit.isDeleted && includes(this.props.selectedOperatingUnitIds, operatingUnit.id))
      .map(operatingUnit => {
      const id = operatingUnit.id;
      return <div style={{marginTop: '25px'}} key={id}>
          <label htmlFor={id}>{operatingUnit.name}</label>
          <button style={{position: 'absolute', right: 0, marginRight: '24px'}} onClick={() => this.publishIndividualOperatintUnit(id)} disabled={this.isPublished(id) || this.areTruckInOperatingUnit(id)}>Publish</button>
        </div>
    });
    return options;
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === PUBLISH_PARTIALLY_MODAL && modalWindow.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '220px',
            width: '385px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
            <div>
            {this.getOperatingUnitOptions()}
            </div>
        </ReactModal>
      );
    }
    else {
      return null;
    }
  }
}


const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    operatingUnits: state.operatingUnits.operatingUnitList,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    scenarioPartialPublishmentList: state.scenarioState.selectedScenario.scenarioPartialPublishmentList,
    scheduleRows: state.scheduleRowState,
    assignment: state.operatingUnits.operatingUnitAssignment,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PublishPartiallyModal);