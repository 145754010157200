import React from 'react';
import { connect } from 'react-redux';
import { selectScenario, updateScenario, createNewScenario } from '../reducers/scenarios';
import ScenarioSettingsMenu from '../components/contextMenus/ScenarioSettingsMenu';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import uniqid from 'uniqid';
import { getScenariosFromCurrentSelectedDate } from '../utils/scenarioHelper';
import { isViewerRole } from '../utils/userHelper';

class ScenarioPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scenarioName: ''
    }
    this.addNewScenario = this.addNewScenario.bind(this);
    this.showScenarioSelection = this.showScenarioSelection.bind(this);
    this.handleScenarioChange = this.handleScenarioChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      isAddingNewScenario: false
    });
  }

  addNewScenario() {
    const scenario = {
      actual: false,
      date: this.props.currentScenarioDate,
      comments: '',
      name: this.state.scenarioName,
      published: false
    }
    this.props.createNewScenario(scenario);
  }

  showScenarioSelection() {
    this.setState({
      isAddingNewScenario: false,
      isRenamingScenario: false
    });
  }

  getScenarioBySelectedDate() {
    return getScenariosFromCurrentSelectedDate();
  }

  getScenarioNamePrefix(scenario) {
    let prefix = '';
    if (scenario.published) {
      prefix = '[PUBLISHED]';
    } else if (scenario.wasPublished) {
      prefix = '[UNPUBLISHED]';
    } else if (scenario.active) {
      prefix = '[ACTIVE]';
    } else if (scenario.isClosed) {
      prefix = '[CLOSED]'
    } else if (scenario.isReopened) {
      prefix = '[REOPENED]'
    } else if (scenario.isPartiallyPublished) {
        prefix = '[PARTIAL PUB.]'
    }

    return prefix;
  }

  scenarioOptions() {
    const filteredScenarios = this.getScenarioBySelectedDate();

    const scenarios = sortBy(filteredScenarios, scenario => scenario.name);

    return scenarios.map(scenario =>
      <option
        key={scenario.id}
        value={scenario.id}>
        {this.getScenarioNamePrefix(scenario)} {scenario.name}
      </option>
    );
  }

  handleScenarioChange(event) {
    event.preventDefault();
    this.props.selectScenario(event.target.value);
    this.setState({scenarioName: this.props.selectedScenario.name});
  }

  shouldDisableAdd() {
    const scenarios = this.getScenarioBySelectedDate();
    const scenarioNames = this.getScenarioBySelectedDate().map(scenario => scenario.name);
    const containsLastScenario = some(scenarioNames, (scenario => includes(scenario, 'Z')));

    return scenarios.length >= 26 || containsLastScenario || isViewerRole();
  }

  getSelectedScenarioId() {
    const scenario = this.props.selectedScenario;
    const id = scenario ? scenario.id: '';
    return id;
  }

  getStyle() {
    const selectedScenario = this.props.selectedScenario;
    const isPublished = selectedScenario? selectedScenario.published: false;
    const isPartiallyPublished = selectedScenario? selectedScenario.isPartiallyPublished : false;
    if (isPublished) {
      return {
        border: '2px solid #33cc33',
        borderLeft: '5px solid #33cc33',
      }
    }
    if (isPartiallyPublished) {
      return {
        border: '2px solid #c36e06',
        borderLeft: '5px solid #c36e06',
      }
    }
  }

  getCopiedScenarioInfo() {
    const scenario = this.props.selectedScenario;

    const makeMenuItem = (str) => (
      <MenuItem key={uniqid()} attributes={{className:'context-menu-title'}}>
        {str}
      </MenuItem>
    );

    if (scenario && scenario.copiedName) {
      return [
        makeMenuItem(`Original Scenario Date: ${scenario.copiedDate}`),
        makeMenuItem(`Original Scenario Name: ${scenario.copiedName}`)
      ];
    } else {
      return makeMenuItem('Scenario is not a copy');
    }
  }

  getScenarioPicker() {
    const id = 'scenarioPicker';
    return (
      <React.Fragment>
        <ContextMenuTrigger id={id} holdToDisplay={-1}>
          <select className='scenario-picker'
            onChange={this.handleScenarioChange}
            value={this.getSelectedScenarioId()}
            style={this.getStyle()}>
              {this.scenarioOptions()}
          </select>
        </ContextMenuTrigger>

        <ContextMenu id={id}>
          {this.getCopiedScenarioInfo()}
          
        </ContextMenu>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className='scenario-section'>
        <div className='scenario-edit-section'>
        {this.getScenarioPicker()}
        <button className='add-scenario-button' disabled={this.shouldDisableAdd()}
          onClick={this.addNewScenario}>
          Add
        </button>
        <ScenarioSettingsMenu />
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedScenario: state.scenarioState.selectedScenario,
    currentScenarioDate: state.scenarioState.currentDateSelected,
    scenarios: state.scenarioState.byId
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createNewScenario: (scenario) => {
      dispatch(createNewScenario(scenario));
    },
    selectScenario: (id) => {
      dispatch(selectScenario(id));
    },
    updateScenario: (scenario) => {
      dispatch(updateScenario(scenario));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioPicker);
