import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import DestinationOperatingUnitEditor from './DestinationOperatingUnitEditor';

class YardFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const yardProps = props.yardFieldProps? props.yardFieldProps: {};
    const defaultId = yardProps.id? yardProps.id: '';
    const defaultName = yardProps.name? yardProps.name: '';
    const defaultFullName = yardProps.fullName? yardProps.fullName: '';
    const defaultActive = yardProps.active? yardProps.active: false;
    const defaultComments = yardProps.comments? yardProps.comments: '';
    const defaultDescription = yardProps.description? yardProps.description: '';
    const defaultOperatingUnitIds = yardProps.operatingUnitIds? yardProps.operatingUnitIds : []
    
    this.state = {
      isModified: false,
      id: defaultId,
      name: defaultName,
      fullName: defaultFullName,
      active: defaultActive,
      comments: defaultComments,
      description: defaultDescription,
      operatingUnitIds: defaultOperatingUnitIds
    }

    this.changeName = this.changeName.bind(this);
    this.changeFullName = this.changeFullName.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.handleChangeOperatingUnit = this.handleChangeOperatingUnit.bind(this);
  }

  clickDone() {
    this.props.clickDone(this.state);
  }

  clickCancel() {
    this.props.clickCancel();
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeName(event) {
    const name = event.target.value.substring(0, 10);
    this.setState({name: name});
    this.setModified();
  }

  changeFullName(event) {
    const name = event.target.value.substring(0, 50);
    this.setState({fullName: name});
    this.setModified();
  }

  changeDescription(event) {
    this.setState({description: event.target.value});
    this.setModified();
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  handleChangeOperatingUnit(operatingUnitIds) {
    this.setState({operatingUnitIds: operatingUnitIds});
    this.setModified();
  }

  render() {
    return (
      <div>
        <div className='name-field yard-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeName} />
        </div>
        <div className='full-name-field yard-field'>
          <div>Full Name</div>
          <input type='text' value={this.state.fullName} onChange={this.changeFullName} />
        </div>
        <DestinationOperatingUnitEditor selectedOperatingUnitIds={this.state.operatingUnitIds} 
          handleOperatingUnitSelect={this.handleChangeOperatingUnit}
        />
        <div className='description-field yard-field'>
          <div>Description</div>
          <textarea rows='3' cols='40' value={this.state.description} onChange={this.changeDescription}/>
        </div>
        <ConfigComment key={this.state.id}
          top={300}
          left={640}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        {this.props.children}
        <div className='yard-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </div>
    );
  }
}

export default YardFieldEditor;