const SET_FALLERS = 'SET_FALLERS';
export const ADD_FALLER = 'ADD_FALLER';
export const ADD_FALLER_SUCCESS = 'ADD_FALLER_SUCCESS';
export const UPDATE_FALLER = 'UPDATE_FALLER';
export const UPDATE_FALLER_SUCCESS = 'UPDATE_FALLER_SUCCESS';
export const REMOVE_FALLER = 'REMOVE_FALLER';
export const REMOVE_FALLER_SUCCESS = 'REMOVE_FALLER_SUCCESS';
export const BULK_ACTIVATE_FALLERS = 'BULK_ACTIVATE_FALLERS';


export function setFallers(fallers) {
  return {
    type: SET_FALLERS,
    payload: fallers
  }
}

export function addFaller(faller) {
  return {
    type: ADD_FALLER,
    payload: faller
  }
}

export function addFallerSuccess(faller) {
  return {
    type: ADD_FALLER_SUCCESS,
    payload: faller
  }
}

export function updateFaller(faller) {
  return {
    type: UPDATE_FALLER,
    payload: faller
  }
}

export function updateFallerSuccess(faller) {
  return {
    type: UPDATE_FALLER_SUCCESS,
    payload: faller
  }
}

export function removeFaller(fallerId) {
  return {
    type: REMOVE_FALLER,
    payload: fallerId
  }
}

export function removeFallerSuccess(faller) {
  return {
    type: REMOVE_FALLER_SUCCESS,
    payload: faller
  }
}

export function bulkActivateFallers(fallerIds) {
  return {
    type: BULK_ACTIVATE_FALLERS,
    payload: fallerIds
  }
}

export default function fallers(state = {
  fallersList: []
}, action) {
  switch(action.type) {
    case SET_FALLERS:
      return _setFallers(state, action);
    case ADD_FALLER_SUCCESS:
      return _AddFaller(state, action);
    case UPDATE_FALLER_SUCCESS:
      return _updateFaller(state, action);
    case REMOVE_FALLER_SUCCESS:
      return _removeFaller(state, action);
    default:
      return state;
  }
}

function _setFallers(state, action) {
  return {
    ...state,
    fallersList: action.payload
  }
}

function _AddFaller(state, action) {
  const fallers = [...state.fallersList, action.payload];
  return {
    ...state,
    fallersList: fallers
  }
}

function _updateFaller(state, action) {
  const updatedFaller = action.payload;
  const updatedList = state.fallersList.map(faller => {
    if(faller.id === updatedFaller.id) {
      return updatedFaller;
    } else {
      return faller;
    }
  });
  return {
    ...state,
    fallersList: updatedList
  }
}

function _removeFaller(state, action) {
  const updatedList = state.fallersList
  return {
    ...state,
    fallersList: updatedList
  }
}
