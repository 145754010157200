import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import { LEVEL1, LEVEL2, LEVEL3 } from '../../constants/driverBlockRankings';
import { connect } from 'react-redux';
import { emptyUuid } from '../../utils/uuidHelper';

class DriverFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.driverFieldProps? props.driverFieldProps: {};
    const defaultDriverId = fieldProps.driverId? fieldProps.driverId: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultEmail = fieldProps.email? fieldProps.email: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultExperience = fieldProps.experience? fieldProps.experience:'';
    const defaultIsService = fieldProps.isService? fieldProps.isService: false;
    const defaultIsTrainee = fieldProps.isTrainee? fieldProps.isTrainee: false;
    const defaultTrainingStartDate = fieldProps.trainingStartDate? fieldProps.trainingStartDate: '';
    const defaultMentorDriverId = fieldProps.mentorDriverId? fieldProps.mentorDriverId: '';
    const defaultMentorSectionDisplay = fieldProps.isTrainee? 'flex': 'none';

    this.state = {
      driverId: defaultDriverId,
      name: defaultName,
      email: defaultEmail,
      active: defaultActive,
      isModified: false,
      comments: defaultComment,
      experience: defaultExperience,
      isService: defaultIsService,
      isTrainee: defaultIsTrainee,
      trainingStartDate: defaultTrainingStartDate,
      mentorDriverId: defaultMentorDriverId,
      mentorSectionDisplay: defaultMentorSectionDisplay
    }

    this.changeDriverName = this.changeDriverName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.changeExperience = this.changeExperience.bind(this);
    this.changeIsService = this.changeIsService.bind(this);
    this.changeIsTrainee = this.changeIsTrainee.bind(this);
    this.changeMentorDriver = this.changeMentorDriver.bind(this);
  }

  componentDidMount() {
    var mentorDriverSection = document.getElementById('mentor-driver-section');
    if (this.state.mentorDriverId !== '') {
      mentorDriverSection.style.display = 'flex';
    }
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeDriverName(event) {
    this.setState({name: event.target.value});
    this.setModified();
  }

  changeExperience(event) {
    this.setState({experience: event.target.value});
    this.setModified();
  }

  changeEmail(event) {
    this.setState({email: event.target.value});
    this.setModified();
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeIsService(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.setState({isService: true});
      this.setModified();
    }
    else {
      this.setState({isService: false});
      this.setModified();
    }
  }

  changeIsTrainee(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.setState({isTrainee: true, trainingStartDate: new Date().toISOString().split('T')[0], mentorSectionDisplay: 'flex'});
      this.setModified();
    }
    else {
      this.setState({isTrainee: false, mentorDriverId: '', trainingStartDate: '', mentorSectionDisplay: 'none'});
      this.setModified();
    }
  }

  changeMentorDriver(event) {
    this.setState({mentorDriverId: event.target.value});
    this.setModified();
  }

  getDriverElements() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeDrivers = this.props.drivers.filter(driver => driver.active && !driver.isDeleted && driver.id !== this.state.driverId);
    const driverOptions = activeDrivers.map(driver => {
      const id = driver.id;
      return <option key={id} value={id}> {driver.name} </option>;
    });
    driverOptions.unshift(noSelection);
    return driverOptions;
  }

  getDriverPicker() {
    return (
      <select className='driver-picker' value={this.state.mentorDriverId} onChange={this.changeMentorDriver}>
        {this.getDriverElements()}
      </select>
    );
  }

  getExperiencePicker() {
    return (
      <select className='driver-experience-picker' value={this.state.experience} onChange={this.changeExperience}>
        <option key='blank' value={0}>None</option>
        <option key={LEVEL1} value={LEVEL1}>Level 1</option>
        <option key={LEVEL2} value={LEVEL2}>Level 2</option>
        <option key={LEVEL3} value={LEVEL3}>Level 3</option>
      </select>
    );
  }


  render() {
    return (
      <React.Fragment>
        <div className='name-field driver-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeDriverName} />
        </div>
        <div className='experience-field driver-field'>
          <div>Experience Level</div>
          {this.getExperiencePicker()}
        </div>
        <div className='isService-field driver-field'>
          <div>Service</div>
          <input type='checkbox' onChange={(e) => this.changeIsService(e)}
            checked={this.state.isService}
          />
        </div>
        <div className='isService-field driver-field'>
          <div>Trainee</div>
          <input type='checkbox' onChange={(e) => this.changeIsTrainee(e)}
            checked={this.state.isTrainee}
          />
        </div>
        <div className='experience-field driver-field' id='mentor-driver-section' style={{display: this.state.mentorSectionDisplay}}>
            <div>Mentor Driver</div>
            {this.getDriverPicker()}
          </div>
        <div className='email-field driver-field'>
          <div>Email</div>
          <textarea rows='4' cols='55' value={this.state.email} onChange={this.changeEmail}
            placeholder='Enter each email address separated by a comma'
          />
        </div>
        <ConfigComment key={this.state.driverId} 
          top={300}
          left={710}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        <div className='driver-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified || (this.state.isTrainee && this.state.mentorDriverId === '')}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    drivers: state.drivers.driverList
  }
}

export default connect(mapStateToProps)(DriverFieldEditor);