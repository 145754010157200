import React from 'react';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
import find from 'lodash/find';
import uniqid from 'uniqid';
import { addOperator, bulkActivateOperators, updateOperator } from '../../reducers/operators';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import OperatorFieldEditor from '../../components/fieldEditors/OperatorFieldEditor';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import Select from 'react-select';
import { OPERATOR } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class OperatorsConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      operatorSelected: '',
      isAddingNewOperator: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousOperators = previousProps.operators.map(o => o.id);
    const currentOperators = this.props.operators.map(o => o.id);
    if (currentOperators.length > previousOperators.length) {
      const diff = difference(currentOperators, previousOperators);
      if (diff.length > 0) {
        const newOperator = diff[0];
        this.setState({
          operatorSelected: newOperator,
          isAddingNewOperator: false
        });
      }
    }
  }

  addNewOperator() {
    this.setState({
      operatorSelected: '',
      isAddingNewOperator: true
    });
  }

  selectOperator(id) {
    this.setState({
      operatorSelected: id,
      isAddingNewOperator: false
    });
  }

  getSelectedOperator() {
    const operator = find(this.props.operators, {id: this.state.operatorSelected});
    return operator;
  }

  getSelectedOperatorName() {
    const operator = this.getSelectedOperator();
    if (operator) {
      return operator.name;
    } else {
      return 'Select...';
    }
  }

  getOperatorProps() {
    const operator = this.getSelectedOperator();
    if (operator) {
      return operator;
    } else {
      return {};
    }
  }

  hasSelectedOperator() {
    return this.state.operatorSelected !== '';
  }

  checkExistence(state) {
    if (this.state.isAddingNewOperator) {
      const operator = find(this.props.operators, {name: state.name});
      if (operator) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewOperator) {
      if (this.getSelectedOperatorName() !== state.name) {
        const operator = find(this.props.operators, {name: state.name});
        if (operator) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getOperatorEditPanel() {
    if (this.hasSelectedOperator() && !this.state.isAddingNewOperator) {
      return (
        <div>
          <OperatorFieldEditor
            key={uniqid()}
            operatorFieldProps={this.getOperatorProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectOperator('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewOperator) {
      return (
        <div>
          <OperatorFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectOperator('')}
          />
        </div>
      );
    } 
  }

  getOperatorButtonStyle(id) {
    if (id === this.state.operatorSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf' 
      }
    }
  }

  getOperatorList() {
    const options = this.props.operators
    .filter(operator => !operator.isDeleted)
    .map(operator => {
      return {
        value: operator.id,
        label: operator.name
      }
    });

    const handleChange = (selectedOption) => this.selectOperator(selectedOption.value);

    return <Select className='configuration-object-selector' 
      value={{label: this.getSelectedOperatorName()}}
      options={options}
      onChange={handleChange}
    />;
  }

  activate() {
    const operatorState = this.getOperatorProps();
    if (operatorState) {
      operatorState.active = !operatorState.active;
      this.props.update(operatorState);
    }
  }

  getActivateButton() {
    const operator = this.getSelectedOperator();
    if (operator) {
      const buttonName = operator.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveOperatorButton() {
    const operatorId = this.state.operatorSelected;
    if (operatorId !== '') {
      return (
        <button className='remove-operator'
          onClick={() => this.props.remove(operatorId)}>
          Remove Operator  
        </button>
      );
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedOperatorWithId = this.props.operators
      .filter(operator => !operator.isDeleted)
      .map(operator => {
        return {
          ...operator,
          id: operator.id
        }
      });
      return <BulkActivationModal 
        type={OPERATOR}
        activationItems={updatedOperatorWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getPrompt() {
    const operator = this.getSelectedOperator();
    if (operator) {
      return `Edit operator ${operator.name}`;
    } else if (this.state.isAddingNewOperator) {
      return `Add a new operator`;
    } else {
      return 'Select a loader operator to edit';
    }
  }

  render() {
    return (
      <div className='operators-configuration configuration-forms'>
        <button className='add-new-operator' onClick={() => this.addNewOperator()}>Add New Operator</button>
        <button className='bulk-activation-button'
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='operator-editor'>
          <p>{this.getPrompt()}</p>
          <div className='operator-list'>
            {this.getOperatorList()}
          </div>
          <div className='operator-edit-panel'>
            {this.getOperatorEditPanel()}
            <div className='operator-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveOperatorButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectOperator('')}/>
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    operators: state.operators,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addOperator(state));
    },
    update: (state) => {
      dispatch(updateOperator(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateOperators(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsConfiguration);