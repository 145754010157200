import React, { Component } from 'react'
import ConfigComment from '../../containers/configuration/ConfigComment';
import { PAY, NOPAY, DISPATCHERSCHOICE } from '../../constants/payOptions';

class ErrorCodeFieldEditor extends Component {
  constructor(props){
    super(props);

    const fieldProps = props.errorCodeFieldProps? props.errorCodeFieldProps: {};
    const defaulErrorCodeId = fieldProps.errorCodeId? fieldProps.errorCodeId: '';
    const defaultCode = fieldProps.code? fieldProps.code: '';
    const defaultDescription = fieldProps.description? fieldProps.description: '';
    const defaultPayOptions = fieldProps.payOptions? fieldProps.payOptions: DISPATCHERSCHOICE;
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultActive = fieldProps.active? fieldProps.active: true;

    this.state = {
    errorCodeId: defaulErrorCodeId,
    code: defaultCode,
    description: defaultDescription,
    payOptions: defaultPayOptions,
    isModified: false,
    comments: defaultComment,
    active: defaultActive,
  }
    this.changeCode = this.changeCode.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.handleChangePayOptions = this.handleChangePayOptions.bind(this);
  }


  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeCode(event) {
    this.setState({code: event.target.value});
    this.setModified();
  }

  changeDescription(event) {
      this.setState({description: event.target.value});
      this.setModified();
    }



  handleChangePayOptions(event) {
    this.setState({payOptions: event.target.value});
    this.setModified();
  }
    
  
  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

    
  clickCancel() {
    this.props.clickCancel();
  }



  getPayOptionsPicker() {
    return (
      <select className='payOptions-picker' value={this.state.payOptions} onChange={this.handleChangePayOptions}>
        <option key={PAY} value={PAY}>Pay</option>
        <option key={NOPAY} value={NOPAY}>No Pay</option>
        <option key={DISPATCHERSCHOICE} value={DISPATCHERSCHOICE}>Dispatcher's Choice</option>
      </select>
    );
  }

  
  render() {
    return (
        <React.Fragment>
          <div className='Code-field error-code-field'>
            <div>Code</div>
            <input type='text' value={this.state.code} onChange={this.changeCode} />
          </div>
          <div className='description-field error-code-field'>
            <div>Description</div>
            <textarea rows='3' cols='40' value={this.state.description} onChange={this.changeDescription}/>
          </div>
          <div className= 'payOptions-field error-code-field'>
            <div>Pay Options</div>
            <div className='payOptions-field-dropdown error-code-field'>
              {this.getPayOptionsPicker()}
            </div>
          </div>
          
          <ConfigComment key={this.state.errorCodeId} 
            top={310}
            left={640}
            comments={this.state.comments}
            updateComments={this.changeComments}/>

          <div className='error-code-field-edit-buttons'>
            <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
            <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
          </div>
        </React.Fragment>
    );
  }
}

export default ErrorCodeFieldEditor;
