import sortBy from 'lodash/sortBy';

const SET_TRUCK_SHARES = 'SET_TRUCK_SHARES';
export const SHARE_TRUCK = 'SHARE_TRUCK';
export const DELETE_TRUCK_SHARE = 'DELETE_TRUCK_SHARE';
export const DELETE_TRUCK_SHARE_SUCCESS = 'DELETE_TRUCK_SHARE_SUCCESS';



export function shareTruck (scenarioTruckState) {
  return {
    type: SHARE_TRUCK,
    payload: scenarioTruckState
  }
}


export function setTruckShares(truckShares) {
  return {
      type: SET_TRUCK_SHARES,
      payload: truckShares
  }
}


export function deleteTruckShare(shareId) {
  return {
    type: DELETE_TRUCK_SHARE,
    payload: shareId
  }
}


export function deleteTruckShareSuccess(truckShare) {
  return {
    type: DELETE_TRUCK_SHARE_SUCCESS,
    payload: truckShare
  }
}

export default function truckShares(state = {
    truckSharesList: []
  }, action) {
    switch (action.type) {
      case SET_TRUCK_SHARES:
        return _setTruckShares(state, action);
      case DELETE_TRUCK_SHARE_SUCCESS:
        return _deleteTruckShare(state,action);
      default:
        return state;
    }
  }


function _deleteTruckShare(state, action) {
  const removedId = action.payload.shareId;
  const updatedList = state.truckSharesList.filter(truckShare => truckShare.shareId !== removedId);
  return {
    ...state,
    truckSharesList: updatedList
  }
}

function _setTruckShares(state, action) {
  const sortedTruckShares = sortBy(action.payload, truckShare => truckShare.shareDate); 
  return {
    ...state,
    truckSharesList: sortedTruckShares
    }
}
