
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';

export function showLoading() {
  return {
    type: SHOW_LOADING
  }
}

export function hideLoading() {
  return {
    type: HIDE_LOADING
  }
}

const initialstate = {
  loadCount: 0
}

export default function loadingScreen(state = initialstate, action) {
  switch(action.type) {
    case SHOW_LOADING:
      return _setLoadCount(state, state.loadCount + 1);
    case HIDE_LOADING:
      return _setLoadCount(state, state.loadCount - 1);
    default:
      return state;
  }
}

function _setLoadCount(state, loadCount) {
  const updatedLoadCount = loadCount < 0? 0: loadCount;
  return {
    ...state,
    loadCount: updatedLoadCount
  }
}