export class AuthConstants {
  //public static stsAuthority = 'http://demo-auth.truck-scheduler.com/';
  //public static clientId = 'ts';
  //public static clientRoot = 'http://demo.truck-scheduler.com/';
  //public static clientScope = 'openid profile TruckSchedulerApi';

  public static stsAuthority = '';
  public static clientId = '';
  public static clientRoot = '';
  public static clientScope = '';
}