import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import {
  FETCH_SCENARIOS,
  CREATE_NEW_SCENARIO,
  DELETE_SCENARIO,
  UPDATE_SCENARIO,
  setScenarios,
  assignNewScenario,
  COPY_SCENARIO,
  fetchScenarios,
  selectScenario,
  setNoAvailableScenarios,
  updateScenarioSuccess,
  ACTIVATE_SCENARIO,
  COMPLETE_SCENARIO,
  REOPEN_SCENARIO
} from '../../reducers/scenarios';
import { failedAction, successfulAction }  from '../../reducers/requestActions';
import { fetchScenarioTrucks } from '../../reducers/schedule';
import { formatDateStringIso } from '../../utils/dateTimeHelpers';
import get from 'lodash/get';
import { getAuthService } from '../AuthService';
import { success, failure } from '../notificationMessages';


export const fetchScenarioEpic = action$ => (
  action$.ofType(FETCH_SCENARIOS)
    .switchMap(action => {
      const requestUrl = action.payload.date? `/api/scenarios/${action.payload.date}` : '/api/scenarios';
      return ajax.get(requestUrl, getAuthService().header)
        .flatMap(ajaxResponse => { 
          const selectedScenarioId = action.payload.selectedId;
          const scenarioId = selectedScenarioId? selectedScenarioId: get(ajaxResponse.response[0], 'id', '');
          if (scenarioId === '') {
            return Observable.of(setNoAvailableScenarios());
          } else {
            return Observable.of(
              fetchScenarioTrucks(scenarioId),
              setScenarios(ajaxResponse.response, scenarioId)
            );
          }
        })
        .catch(failure => Observable.of(failedAction(failure)))
    })
);

export const activateScenarioEpic = action$ => (
  action$.ofType(ACTIVATE_SCENARIO)
    .mergeMap(action => {
      const requestUrl = `/api/scenarios/activate/${action.payload.scenarioId}?scenarioDate=${action.payload.scenarioDate}`;
      return ajax.post(requestUrl, null, getAuthService().header)
        .map(ajaxResponse => setScenarios(ajaxResponse.response, action.payload.scenarioId))
        .catch(failure => Observable.of(failedAction(failure)))
    })
);

export const completeScenarioEpic = action$ => (
  action$.ofType(COMPLETE_SCENARIO)
    .mergeMap(action => {
      const requestUrl = `/api/scenarios/close/${action.payload.scenarioId}`;
      return ajax.post(requestUrl, null, getAuthService().header)
        .map(ajaxResponse => setScenarios(ajaxResponse.response, action.payload.scenarioId))
        .catch(failure => Observable.of(failedAction(failure)))
    })
);

export const reopenScenarioEpic = action$ => (
  action$.ofType(REOPEN_SCENARIO)
    .mergeMap(action => {
      const requestUrl = `/api/scenarios/reopen/${action.payload.scenarioId}`;
      return ajax.post(requestUrl, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setScenarios(ajaxResponse.response, action.payload.scenarioId)), 'Done')
        .catch(fail => Observable.of(failure('Incorrect Password')))
    })
);

export const createNewScenarioEpic = action$ => (
  action$.ofType(CREATE_NEW_SCENARIO)
    .mergeMap(action => (
      ajax.post('/api/scenarios', action.payload, getAuthService().header)
        .map(ajaxResponse => assignNewScenario(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);

export const deleteScenarioEpic = action$ => (
  action$.ofType(DELETE_SCENARIO)
    .mergeMap(action => (
      ajax.delete(`/api/scenarios/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => successfulAction(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);

export const updateScenarioEpic = action$ => (
  action$.ofType(UPDATE_SCENARIO)
    .mergeMap(action => (
      ajax.put(`/api/scenarios/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => updateScenarioSuccess(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))  
);

export const copyScenarioEpic = action$ => (
  action$.ofType(COPY_SCENARIO)
    .mergeMap(action => (
      ajax.post('/api/scenarios/copyScenario', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => {
          const id = ajaxResponse.response.id;
          const date = formatDateStringIso(ajaxResponse.response.scenarioDate);
          if (action.payload.isTargetCurrentDate) {
            return Observable.of(setScenarios(ajaxResponse.response), selectScenario(id));
          } else {
            return Observable.of(fetchScenarios(date, id));
          }
        })
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);
