import React from 'react';
import { getPixelPosition } from '../calculations/timeToPixelConversions';

const WorkDayMarker = ({beginHourSchedule, startTimeSchedule, maxScheduledMinutes}) => {
  let startTimePosition = getPixelPosition(beginHourSchedule, startTimeSchedule);
  let endTimePosition = startTimePosition + (maxScheduledMinutes * 2);
  return (
    <div className="workday-marker-container">
      <div className="begin-workday-marker" style={{marginLeft: startTimePosition}} />
      <div className="end-workday-marker" style={{marginLeft: endTimePosition}}/>
    </div>
  )
};

export default WorkDayMarker;