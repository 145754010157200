import React from 'react';
import { connect } from 'react-redux';
import VerticalTimeMarkers from './VerticalTimeMarkers';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import addMinutes from 'date-fns/add_minutes';
import BlockActivity from './BlockActivity';
import groupBy from 'lodash/groupBy';
import toArray from 'lodash/toArray';
import first from 'lodash/first';
import get from 'lodash/get';
import uniqid from 'uniqid';
import LoaderOperatorHelper from '../../utils/loaderOperatorHelper';
import { matchesSelectedOperatingUnit, getSelectedOperatingUnitNames } from '../../utils/operatingUnitHelper';
import isEmpty from 'lodash/isEmpty';
import getHours from 'date-fns/get_hours';
import getMinutes from 'date-fns/get_minutes';
import { startOfToday } from 'date-fns';
import { setTimeToDate } from '../../utils/dateTimeHelpers';
import parse from 'date-fns/parse';
import HorizontalTimeLineMarker from './HorizontalTimeLineMarker';

class BlockView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedActivityId: ''
    }

    this.operatorHelper = new LoaderOperatorHelper();
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  setSelectedActivity(id) {
    let assignedId = id;
    if (this.state.selectedActivityId === id) {
      assignedId = '';
    }
    this.setState({selectedActivityId: assignedId});
  }

  getBlocksWithLoads() {
    const scenarioId = this.props.scenarioId;
    const blockLoads = this.props.blockLoads.byId[scenarioId];
    if (blockLoads) {
      const blocks = Object.keys(blockLoads).map(blockId => find(this.props.blocks, {id: blockId}));
      const blocksMatchingOpUnit = blocks.filter(block => matchesSelectedOperatingUnit(block.id));
      const sortedBlocks = sortBy(blocksMatchingOpUnit, (b) => b.name);
      return sortedBlocks;
    } else {
      return [];
    }
  }

  getLoadTimeWindow(scheduledBlock, block) {
    if (scheduledBlock.loadStart && scheduledBlock.loadEnd) {
      return {
        loadStart: this.getCorrectedTime(scheduledBlock.loadStart),
        loadEnd: this.getCorrectedTime(scheduledBlock.loadEnd)
      }
    } else {
      const loadStart = addMinutes(scheduledBlock.dateObjects.locationStartTime, block.preloadTime);
      const loadEnd = addMinutes(loadStart, block.loadTime);
      return {
        loadStart: this.getCorrectedTime(loadStart),
        loadEnd: this.getCorrectedTime(loadEnd)
      };
    }
  }

  getCorrectedTime(activityTime) {
    const hour = getHours(activityTime);
    const minutes = getMinutes(activityTime);
    const correctedTime = setTimeToDate(hour, minutes, startOfToday());
    const dateTimeObject = parse(correctedTime);
    return dateTimeObject;
  }

  getBlockActivities(block) {
    const scheduledBlocks = this.props.schedule.flatMap(scheduleRow => {
      const matchingBlocks = scheduleRow.schedule.filter(activity => activity.locationId === block.id);
      const withTruckNames = matchingBlocks.map(matchingBlock => {
        return {
          ...matchingBlock,
          truckName: scheduleRow.truckName,
          ...this.getLoadTimeWindow(matchingBlock, block)
        }
      });
      
      return withTruckNames;
    });

    const sortedByStartTime = sortBy(scheduledBlocks, (b) => b.loadStart.valueOf());
    return sortedByStartTime;
  }

  groupByLoader(blockActivityArray) {
    const grouping = blockActivityArray.map(activities => {
      return groupBy(activities, (activity) => activity.loaderId);
    });
    return grouping;
  }

  getBlockColumnHeader(loader) {
    const loaderId = get(loader, 'id', '');
    const loaderName = get(loader, 'name', 'Unknown Loader');
    const operatorName = this.operatorHelper.getOperatorNameByLoaderIdSimple(loaderId);
    return (
      <div className='block-column-header' key={loaderId}>
        {loaderName}
        <div className='operator-info'>
          <div>Operator: {operatorName}</div>
        </div>
        <div className='vertical-separator'></div>
      </div>
    ); 
  }

  getBlockGrouping(grouping, headers, blockActivityColumns) {
    const blockActivities = toArray(grouping).flat();
    const firstBlockActivity = first(blockActivities);
    let blockName = '';
    if (firstBlockActivity) {
      const block = find(this.props.blocks, {id: firstBlockActivity.locationId});
      blockName = block.name;
    }
    const getNameWidth = (columnCount) => {
        return 90 + (columnCount - 1) * 120;
    }
    const blockNameWidth = getNameWidth(blockActivityColumns.length);

    return <div className='block-grouping' key={uniqid()}>
      <div className='block-grouping-name' style={{width: blockNameWidth}}>{blockName}</div>
      <div className='loader-headers'>
        {headers}
      </div>
      <div className='block-activity-column-container'>
        {blockActivityColumns}
      </div>
    </div>
  }

  getBlockActivitiesColumn() {
    const blocks = this.getBlocksWithLoads();
    const blockActivityArray = blocks.map(block => {
      const blockActivities = this.getBlockActivities(block);
      return blockActivities;
    }).filter(activities => !isEmpty(activities));

    const activitiesGroupedByLoader = this.groupByLoader(blockActivityArray);
    let columnCount = 0;

    const columnElements = activitiesGroupedByLoader.flatMap((grouping, index) => {
      const headers = [];
      const blockActivityColumns = Object.keys(grouping).map(loaderId => {
        const activities = grouping[loaderId];
        const loader = find(this.props.loaders, {id: loaderId});
        const header = this.getBlockColumnHeader(loader);
        headers.push(header);
        columnCount++;

        return (
          <div className='truck-activities-container' key={loaderId} style={{marginLeft: 5, width: 115}}>
            {this.createBlockActivityColumn(activities)}
          </div>
        );
      });

      return this.getBlockGrouping(grouping, headers, blockActivityColumns);
    });

    return {
      columnElements: columnElements,
      columnCount: columnCount
    }
  }

  createBlockActivityColumn(truckActivities) {
    return truckActivities.map((activity, index) => {
      const block = find(this.props.blocks, {id: activity.locationId});
      return <BlockActivity key={activity.id}
        activity={activity}
        previousActivity={truckActivities[index-1]}
        block={block}
        selectedActivityId={this.state.selectedActivityId}
        setSelectedActivity={(id) => this.setSelectedActivity(id)}
      />;
    });
  }

  getView() {
    const columns = this.getBlockActivitiesColumn();
    const names = getSelectedOperatingUnitNames();
    return (
      <div className='block-view-container'>
        <div className='view-header'>
          <div className='scenario-name'>
            <span className='scenario-name-label'>Scenario:</span> {this.props.scenarioName}
          </div>
          <div className='operating-unit-name'>
            <span className='operating-unit-name-label'>Operating Unit:</span> {names}
          </div>
        </div>
        <div className='grid-body'>
          <VerticalTimeMarkers columnCount={columns.columnCount}/>
          <HorizontalTimeLineMarker />
          <div className='truck-block-activities-container'>
            {columns.columnElements}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.getView();
  }
}

const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  return {
    scenarioId: selectedScenario.id,
    scenarioName: selectedScenario.name,
    scenarioDate: selectedScenario.date,
    blocks: state.locations.blocks,
    schedule: state.scheduleRowState,
    blockLoads: state.blockLoadState,
    loaders: state.loaders.loaderList,
    assignment: state.loaderOperatorAssignment.loaderOperatorAssignmentList,
    operators: state.operators,
    selectedOperatingUnitId: state.operatingUnits.selectedOperatingUnitId,
    operatingUnits: state.operatingUnits
  }
}

export default connect(mapStateToProps)(BlockView);