import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import {
  SAVE_SCHEDULE,
  FETCH_SCHEDULE,
  updateIdAfterSave,
  setSchedule,
  REMOVE_LOCATION_FROM_SCHEDULE,
  UPDATE_SCHEDULE,
  FETCH_SCHEDULE_BY_SCENARIO_TRUCK_ID,
  UPDATE_SCHEDULE_SWAP
} from '../../reducers/schedule';
import { failedAction }  from '../../reducers/requestActions';
import { failure } from '../notificationMessages';
import { getAuthService } from '../AuthService';


export const saveScheduleEpic = action$ => (
  action$.ofType(SAVE_SCHEDULE)
    .mergeMap(action => (
      ajax.post('/api/scenarioActivityDetails', action.payload.model, getAuthService().header)
        .flatMap(ajaxResponse => Observable.of(
          updateIdAfterSave(ajaxResponse.response[0], action.payload),
          setSchedule(ajaxResponse.response)
        ))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);

export const fetchScheduleEpic = action$ => (
  action$.ofType(FETCH_SCHEDULE)
    .mergeMap(action => (
      ajax.get(`/api/scenarioActivityDetails/${action.payload.scenarioId}`, getAuthService().header)
        .map(ajaxResponse => setSchedule(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);

export const removeFromScheduleEpic = action$ => (
  action$.ofType(REMOVE_LOCATION_FROM_SCHEDULE)
    .mergeMap(action => (
      ajax.delete(`/api/scenarioActivityDetails/${action.payload.id}`, getAuthService().header)
        .map(ajaxResponse => setSchedule(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);

function catchUpdateError(failureObject) {
    if (failureObject.hasMismatchedTime) {
      const message = `Failed to update schedule. There was a time mismatch on truck ${failureObject.truckName}`;
      failure(message);
    } else {
      failure('Failed to update schedule');
    }
  
    return failedAction(failureObject);
}

export const updateScheduleEpic = action$ => (
  action$.ofType(UPDATE_SCHEDULE)
    .mergeMap(action => (
      ajax.put(
        `/api/scenarioActivityDetails/${action.payload.model.scenarioActivityId}`,
        action.payload.model,
        getAuthService().header)
        .map(ajaxResponse => setSchedule(ajaxResponse.response))
        .catch(failure =>  Observable.of(catchUpdateError(failure.response)))
    ))
);

export const fetchScheduleByScenarioTruckEpic = action$ => (
  action$.ofType(FETCH_SCHEDULE_BY_SCENARIO_TRUCK_ID)
    .mergeMap(action => (
      ajax.get(`/api/scenarioActivityDetails?scenarioTruckId=${action.payload.id}`, getAuthService().header)
        .map(ajaxResponse => setSchedule(ajaxResponse.response))))
    .catch(failure => Observable.of(failedAction(failure)))
);

export const swapActivityEpic = action$ => (
  action$.ofType(UPDATE_SCHEDULE_SWAP)
    .mergeMap(action => (
      ajax.put(
        `/api/scenarioActivityDetails/swapActivity/${action.payload.model.scenarioActivityId}`, action.payload.model, getAuthService().header)
          .map(ajaxResponse => setSchedule(ajaxResponse.response))
          .catch(failure =>  Observable.of(catchUpdateError(failure.response)))
    ))
);