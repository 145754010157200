import last from 'lodash/last';
import { getStartTimeMinutes } from '../calculations/timeToPixelConversions';
import { startOfWorkDay, startTimeOfScheduledItem } from '../utils/dateTimeHelpers';
import addMinutes from 'date-fns/add_minutes';
import { YARD, BLOCK, DRYLAND } from '../constants/typeConstants';

function getDateObjectsInitial(action, startScheduledItem) {
  if (action.payload.type === YARD) {
    return {
      locationStartTime: startScheduledItem,
      locationEndTime: startScheduledItem
    }
  }
  return {
    locationStartTime: startScheduledItem,
    locationEndTime: addMinutes(startScheduledItem, 30)
  }
}

function getDateObjects(rowState, action) {
  const lastScheduledItem = last(rowState.schedule);
  const lastActivityTime = lastScheduledItem.dateObjects.locationEndTime;
  const transit = action.payload.transit;
  const arrival = addMinutes(lastActivityTime, transit.travelTime);
  if (action.payload.type === YARD) {
    return {
      locationStartTime: arrival,
      locationEndTime: arrival
    }
  }

  if (action.payload.type === DRYLAND) {
    const endTime = addMinutes(arrival, 30);
    return {
      locationStartTime: arrival,
      locationEndTime: endTime
    }
  }

  if (action.payload.type === BLOCK) {
    return {
      locationStartTime: arrival,
      locationEndTime: addMinutes(arrival, 30)
    }
  }
}

function updateTimeAtBlock(rowState, action, lastScheduled) {
  const copySchedule = rowState.schedule.slice(0, -1);
  const oldTransit = lastScheduled.transit;
  const timeAtCutblock = action.payload.transit.timeAtCutblock;
  const updatedEndTime = addMinutes(lastScheduled.dateObjects.locationStartTime, timeAtCutblock);
  const blockUpdated = {
    ...lastScheduled,
    transit: {
      ...oldTransit,
      timeAtDestination: timeAtCutblock
    },
    dateObjects: {
      ...lastScheduled.dateObjects,
      locationEndTime: updatedEndTime
    }
  }
  copySchedule.push(blockUpdated);

  const start = addMinutes(updatedEndTime, action.payload.transit.travelTime);
  const end = addMinutes(start, action.payload.transit.timeAtDestination);
  const updatedSchedule = {
    ...action.payload,
    dateObjects: {
      locationStartTime: start,
      locationEndTime: end
    }
  };
  const newState = {
    blockToDrylandUpdate: {
      block: blockUpdated,
      dryland: updatedSchedule
    },
    ...rowState,
    schedule: [
      ...copySchedule,
      {...updatedSchedule}
    ]
  };
  return newState;

}

function updateRowWithInitialTime(rowState, action) {
  const beginWorkHour = action.payload.beginWorkHour;
  const initialTravelTime = getStartTimeMinutes(beginWorkHour, rowState.startTime);
  const scenarioDate = action.payload.scenarioDate;
  const startWork = startOfWorkDay(beginWorkHour, scenarioDate);
  const startSchedule = startTimeOfScheduledItem(rowState.startTime, scenarioDate);
  return {
    ...rowState,
    startOfWorkDay: startWork,
    startScheduledTime: startSchedule,
    schedule: [
      ...rowState.schedule,
      {
        ...action.payload,
        transit: {
          travelTime: initialTravelTime
        },
        dateObjects: getDateObjectsInitial(action, startSchedule)
      }
    ]
  }
}

function updateRowTransitTimes(rowState, action) {
  return {
    ...rowState,
    schedule: [
      ...rowState.schedule,
      {
        ...action.payload,
        transit: {
          ...action.payload.transit,

        },
        dateObjects: getDateObjects(rowState, action)
      }
    ]
  }
}

function updateRowState(rowState, action) {
  if (rowState.schedule.length > 0 && action.meta.hasBlockToDryland) {
    const lastScheduled = last(rowState.schedule);
    return updateTimeAtBlock(rowState, action, lastScheduled);
  } else if (rowState.schedule.length === 0) {
    return updateRowWithInitialTime(rowState, action);
  } else {
    return updateRowTransitTimes(rowState, action);
  }
}

export function addLocationReducer(state, action) {
  let transitValue = action.payload.transit;
  if (transitValue === null) {
    return state;
  } else {
    return state.map(row => {
      if (row.rowId === action.payload.rowId) {
        return updateRowState(row, action);
      } else {
        return row;
      }
    });
  }
}
