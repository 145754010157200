import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { isInactiveStyle } from '../../utils/inactiveStyleHelper';
import { getYardTimes } from '../../utils/yardTimeHelper';

class YardTimeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationId: props.locationId,
      isModified: false
    }
  }

  componentDidMount() {
    this.setYardTimes();    
  }

  setYardTimes() {
    const yardTimes = getYardTimes(this.state.locationId);
    const selectedYardIds = yardTimes.map(row => row.yardId);

    this.setState({
      selectedYardIds: selectedYardIds,
      yardTimes: yardTimes
    });

    this.props.handleYardDataUpdate(yardTimes);
  }

  setModified() {
    this.setState({isModified: true});
    this.props.setModified();
  }

  getLocation(targetLocationId) {
    return find(this.props.locations, {id: targetLocationId});
  }

  updateYardTimeState(yardTimes) {
    this.setModified();
    this.props.handleYardDataUpdate(yardTimes);
  }

  handleYardSelect(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let selectedYardIds = [];
    let yardTimes = [];
    if (isChecked) {
      selectedYardIds = [...this.state.selectedYardIds, id];
      yardTimes = [...this.state.yardTimes, {yardId: id, toYardTime: 0}];
    } else {
      selectedYardIds = this.state.selectedYardIds.filter(yardId => yardId !== id);
      yardTimes = this.state.yardTimes.filter(yard => yard.yardId !== id);
    }
    
    this.setState({
      selectedYardIds: selectedYardIds,
      yardTimes: yardTimes
    });
    this.updateYardTimeState(yardTimes);
  }

  isYardSelected(yardId) {
    return includes(this.state.selectedYardIds, yardId);
  }

  handleYardTimeUpdate(event, yardId) {
    const value = Number(event.target.value);
    const validValue = value < 0? 0: value;
    const updatedValue = this.state.yardTimes.map(yard => {
      if (yard.yardId === yardId) {
        return {
          ...yard,
          toYardTime: validValue
        };
      } else {
        return yard;
      }
    });
    this.setState({yardTimes: updatedValue});
    this.updateYardTimeState(updatedValue);
  }

  getTimeToYardInput(yard) {
    if (this.isYardSelected(yard.id)) {
      const targetYard = find(this.state.yardTimes, {yardId: yard.id});
      const timeToYard = targetYard? targetYard.toYardTime: 0;
      return (
        <input className='time-to-yard' type='number' value={timeToYard}
          onChange={(e) => this.handleYardTimeUpdate(e, yard.id)}
        />
      );
    } else {
      return null;
    }
  }

  getYardSelectionRows() {
    const yardRows = this.props.yards
    .filter(yard => !yard.isDeleted)
    .map(row => {
      const yard = this.getLocation(row.id);
      return (
        <div className='yard-edit-row' key={row.id}>
          <div style={isInactiveStyle(yard)} className='yard-label'>{yard.name}</div>
          <input type='checkbox' className='yard-select-checkbox' value={yard.id}
            onChange={(e) => this.handleYardSelect(e)}
            checked={this.isYardSelected(yard.id)}
          />
          {this.getTimeToYardInput(yard)}
        </div>
      );
    });

    return <div className='yard-edit-rows-container'>
      {yardRows}
    </div>
  }

  render() {
    return (
      <div className='yard-time-editor'>
        <div>Travel Time From Yard (min)</div>
        {this.getYardSelectionRows()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const blocks = state.locations.blocks;
  const destinations = state.locations.destinations;
  const yards = state.locations.yards;
  const combinedLocations = [...blocks, ...destinations, ...yards];
  return {
    yards: yards,
    locations: combinedLocations,
  }
}

export default connect(mapStateToProps)(YardTimeEditor);