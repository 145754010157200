import React from 'react';
import { connect } from 'react-redux';
import VerticalTimeMarkers from './VerticalTimeMarkers';
import sortBy from 'lodash/sortBy';
import { DRYLAND } from '../../constants/typeConstants';
import DestinationActivity from './DestinationActivity';
import { getSelectedOperatingUnitNames, matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';

class DestinationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedActivityId: ''
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  setSelectedActivity(id) {
    let assignedId = id;
    if (this.state.selectedActivityId === id) {
      assignedId = '';
    }
    this.setState({selectedActivityId: assignedId});
  }

  createDestinationMapping() {
    const destinationsOnSchedule = this.getDestinationsOnSchedule();
    const mapping = new Map();
    destinationsOnSchedule.forEach(scheduleItem => {
      const destinationId = scheduleItem.locationId;
      const scheduleForDestination = mapping.has(destinationId)? mapping.get(destinationId) : [];
      mapping.set(destinationId, [...scheduleForDestination, scheduleItem]);
    });
    return mapping;
  }

  getDestinationsOnSchedule() {
    return this.props.schedule.flatMap(scheduleRow => {
     const drylands = scheduleRow.schedule.filter(scheduleItem => scheduleItem.type === DRYLAND);
     return drylands.map(dryland => {
       return {
         ...dryland,
         truckName: scheduleRow.truckName
       }
     });
    });
  }

  getNumberOfDestination(destinationName) {
    const destList = this.getDestinationsOnSchedule().map(destination => destination.name)
    let count = 0
    for (var i=0; i<destList.length; i++) {
      if (destList[i] === destinationName ) {
        count = count + 1;
      }
    } return count;  
  }

  // Get only the destinations that are in the schedule
  getScheduledDestinations(destinationMapping) {
    return this.props.destinations
      .filter(d => destinationMapping.has(d.id))
      .filter(d => matchesSelectedOperatingUnit(d.id));
  }

  getMaxCapacity(destination) {
    if (destination.maxCapacity) {
      return destination.maxCapacity;
    }
    else {
      return '0';
  }
  }

  getDestinationColumnHeaders(scheduledDestinations) {
    return scheduledDestinations.map(dest => {
      return <div className='destination-column-header' key={dest.id}>
        {dest.name}({this.getNumberOfDestination(dest.name)}/{this.getMaxCapacity(dest)})
        <div className='vertical-separator' style={{
            borderRight: '0.5px solid #e4e4e4',
            height: '1500px',
            marginLeft: '110px'
          }}></div>
      </div>
    });
  }


  createDestinationActivityColumn(sortedSchedule, destination) {
    return sortedSchedule.map(activity => {
      return <DestinationActivity key={activity.id}
        activity={activity}
        destination={destination}
        selectedActivityId={this.state.selectedActivityId}
        setSelectedActivity={(id) => this.setSelectedActivity(id)}
      />;
    });
  }

  getDestinationActivitiesColumn(destinationMapping, scheduledDestinations) {
    return scheduledDestinations.map(dest => {
      const scheduleForDest = destinationMapping.get(dest.id);
      const sortedSchedule = sortBy(scheduleForDest, (d) => d.dateObjects.locationStartTime);
      return <div className='destination-activity-column' key={dest.id} style={{marginTop: 10, marginLeft: 5, width: 115}}>
        {this.createDestinationActivityColumn(sortedSchedule, dest)}
      </div>; 
    });
  }

  render() {
    const destinationMapping = this.createDestinationMapping();
    const scheduledDestinations = this.getScheduledDestinations(destinationMapping);
    const headers = this.getDestinationColumnHeaders(scheduledDestinations);
    return (
      <div className='destination-view-container'>
        <div className='view-header'>
          <div className='scenario-name'>
            <span className='scenario-name-label'>Scenario:</span> {this.props.scenarioName}
          </div>
          <div className='operating-unit-name'>
            <span className='operating-unit-name-label'>Operating Unit:</span> {getSelectedOperatingUnitNames()}
          </div>
        </div>
        <div className='destination-column-headers-container'>
          {headers}
        </div>
        <div className='grid-body'>
          <VerticalTimeMarkers columnCount={headers.length} />
          <div className='truck-destination-activities-container'>
            {this.getDestinationActivitiesColumn(destinationMapping, scheduledDestinations)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  return {
    scenarioId: selectedScenario.id,
    scenarioName: selectedScenario.name,
    scenarioDate: selectedScenario.date,
    destinations: state.locations.destinations,
    schedule: state.scheduleRowState,
    selectedOperatingUnitId: state.operatingUnits.selectedOperatingUnitId
  }
}

export default connect(mapStateToProps)(DestinationView);