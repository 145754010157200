import React from 'react';
import { connect } from 'react-redux';
import { addNewBlock, addNewBlockLoad, saveBlockLoadChanges } from '../../reducers/blockLoads';
import BlockLoadRow from '../../containers/BlockLoadRow';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import uuid from 'uuid';
import sortBy from 'lodash/sortBy';
import { matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';

class BlockLoadEditorModal extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isModified: false,
      comment: '',
      activeDestinations: []
    }

    this.handleAddBlockLoad = this.handleAddBlockLoad.bind(this);
    this.modifyState = this.modifyState.bind(this);
    this.changeComment = this.changeComment.bind(this);
    this.increaseIndex = this.increaseIndex.bind(this);
    this.closeModal = this.closeModal.bind(this);

    ReactModal.setAppElement('#root');
  }

  componentWillMount() {
    this.addBlock();
  }

  componentDidMount() {
    const comment = this.props.blockLoadInfo.comment;
    const validComment = comment? comment: '';
    const blockLoads = this.props.blockLoadInfo.blockLoads;
    const validLength = blockLoads? blockLoads.length : 0;
    this.setState({
      comment: validComment,
      currentLoadIndex: validLength,
      activeDestinations: this.getActiveDestinations()
    });
  }

  increaseIndex() {
    this.setState({currentLoadIndex: this.state.currentLoadIndex + 1});
  }

  modifyState() {
    this.setState({
      isModified: true
    });
  }

  addBlock() {
    const payload = {
      scenarioId: this.props.currentScenarioId,
      blockId: this.props.id
    }
    this.props.addNewBlock(payload);
  }

  hasBlockLoads() {
    const blockLoads = this.props.blockLoadInfo.blockLoads;
    if (blockLoads) {
      return blockLoads.length > 0;
    } else {
      return false;
    }
  }

  handleAddBlockLoad(event) {
    const firstActive = this.state.activeDestinations[0];
    const firstId = firstActive? firstActive.id: '';
    // negative indexes mean that the load order does not matter which is the default when adding new row
    const loadOrderIndex = (1 + this.state.currentLoadIndex) * -1;
    const payload = {
      scenarioId: this.props.currentScenarioId,
      blockId: this.props.id,
      blockLoad: {
        loadOrder: loadOrderIndex,
        loads: 0,
        destination: firstId,
        assigned: 0,
        blockLoadId: uuid()
      }
    };
    this.modifyState();
    this.increaseIndex();
    this.props.addNewBlockLoad(payload);
    event.preventDefault();
  }

  getActiveDestinations() {
    const blockId = this.props.id;
    const targetTrips = this.props.blocksToDestinations.filter(b2d => b2d.blockId === blockId);
    const destinationIdSet = new Set();
    targetTrips.forEach(trip => {
      destinationIdSet.add(trip.destinationId);
    });
    const filteredDestinations = this.props.destinations.filter(destination => {
      return destination.active && destinationIdSet.has(destination.id) && matchesSelectedOperatingUnit(destination.id);
    });
    return filteredDestinations;
  }

  createBlockLoadRow(blockLoad, idx) {
    return <BlockLoadRow key={blockLoad.blockLoadId} order={idx} id={this.props.id} blockLoadId={blockLoad.blockLoadId} destinations={this.state.activeDestinations}
      loadValue={blockLoad.loads} selectedDestination={blockLoad.destination} assigned={blockLoad.assigned}
      modifyState={this.modifyState} currentLoadIndex={this.state.currentLoadIndex} increaseIndex={this.increaseIndex}
    />;
  }

  getOrderedLoads() {
    const filtered = this.props.blockLoadInfo.blockLoads.filter(blockLoad => blockLoad.loadOrder >= 0); 
    return sortBy(filtered, blockLoad => blockLoad.loadOrder);
  }

  getUnorderedLoads() {
    const filtered = this.props.blockLoadInfo.blockLoads.filter(blockLoad => blockLoad.loadOrder < 0);
    return sortBy(filtered, blockLoad => blockLoad.loadOrder).reverse();
  }

  getOrderedBlockLoadRows() {
    const ordered = this.getOrderedLoads();
    const loadedBlocks = ordered.map(blockLoad => this.createBlockLoadRow(blockLoad, blockLoad.loadOrder));
    return loadedBlocks;
  }

  getUnorderedBlockLoadRows() {
    const unordered = this.getUnorderedLoads();
    const loadedBlocks = unordered.map(blockLoad => this.createBlockLoadRow(blockLoad, blockLoad.loadOrder));
    return loadedBlocks;
  }

  getBlockLoadForms() {
    if (this.hasBlockLoads()) {
      return (
        <div className='block-load-forms'>
          <div className='block-load-edit-labels'>
            <label className='order-label'>Order</label>
            <label className='total-label'>Total</label>
            <label className='assigned-label'>Assigned</label>
            <label className='remaining-label'>Remaining</label>
            <label className='destination-label'>Destination</label>
          </div>
          <ol className='block-load-rows ordered-block-loads'>
            {this.getOrderedBlockLoadRows()}
          </ol>
          <div>
            <div className='unordered-label'>Unordered</div>
            <div className='load-order-line-break'></div>
          </div>
          <ul className='block-load-rows unordered-block-loads'>
            {this.getUnorderedBlockLoadRows()}
          </ul>
        </div>
      );
    }
  }

  saveChanges() {
    if (this.state.isModified) {
      const orderedWithCorrectedIndex = this.getOrderedLoads().map((blockLoad, index) => {
        return {
          ...blockLoad,
          loadOrder: 1 + index
        }
      });
      const unorderedWithCorrectedIndex = this.getUnorderedLoads().map((blockLoad, index) => {
        return {
          ...blockLoad,
          loadOrder: -1 * (1 + index)
        }
      });
      const payload = {
        blockId: this.props.id,
        blockLoads: [...orderedWithCorrectedIndex, ...unorderedWithCorrectedIndex],
        scenarioId: this.props.currentScenarioId,
        comment: this.state.comment
      }
      this.props.saveBlockLoadChanges(payload);
    }
  }

  changeComment(event) {
    this.modifyState();
    this.setState({comment: event.target.value});
  }

  getCommentSection() {
    if (this.hasBlockLoads()) {
      return (
        <div className='comment-section'>
          <label className='block-load-comment-label'>Comments</label>
          <textarea className='block-load-comment' rows='4' cols='55' 
            value={this.state.comment} onChange={this.changeComment} />
        </div>
        );
    } else {
      return null;
    }
  }

  closeModal() {
    this.props.closeBlockModal();
    this.saveChanges();
  }

  getLoadEditorContents() {

    if (this.state.activeDestinations.length > 0) {
      return (
        <React.Fragment>
          {this.getCommentSection()}
          {this.getBlockLoadForms()}
          <button className="add-load" onClick={this.handleAddBlockLoad}>Add Load</button>
          <button className="done" onClick={this.closeModal}>Done</button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>This block does not have any active destinations</p>
          <button style={{float: 'right', marginRight: '30px'}} onClick={this.closeModal}>Close</button>
        </React.Fragment>
      );
    }
  }

  getBlockLoadEditorModal() {
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          top: '60px',
          left: '60px',
          right: '60px',
          bottom: '60px',
          width: '480px'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.closeModal}
      >
        <div className="block-load-editor">
          <p>Block {this.props.name} loads</p>
          {this.getLoadEditorContents()}
        </div>
      </ReactModal>
    );
  }
  
  render() {
    return this.getBlockLoadEditorModal();
  }
}

const mapStateToProps = (state) => {
  const currentBlockId = state.modalWindows.modalId;
  const currentScenarioId = state.scenarioState.selectedScenario.id;
  const blockLoadScenario = state.blockLoadState.byId[currentScenarioId];
  const blockLoad = blockLoadScenario? blockLoadScenario[currentBlockId] : {};
  const validBlockLoad = blockLoad? blockLoad : {};
  return {
    blocks: state.locations.blocks,
    destinations: state.locations.destinations,
    blockLoadInfo: validBlockLoad,
    modalWindows: state.modalWindows,
    currentScenarioId: currentScenarioId,
    blocksToDestinations: state.locations.blocksToDestinations
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    addNewBlock: (blockLoadInfo) => {
      dispatch(addNewBlock(blockLoadInfo));
    },
    addNewBlockLoad: (blockLoad) => {
      dispatch(addNewBlockLoad(blockLoad));
    },
    closeBlockModal: () => {
      dispatch(closeModal());
    },
    saveBlockLoadChanges: (blockLoadPayload) => {
      dispatch(saveBlockLoadChanges(blockLoadPayload));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockLoadEditorModal);