import { CREATE_SCENARIO_TRUCK, UPDATE_SCENARIO_TRUCK } from '../reducers/schedule';
import find from 'lodash/find';
import first from 'lodash/first';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import { getCurrentDate, setTimeToDate } from '../utils/dateTimeHelpers';

function getTruckScheduleDates(scheduleRow, action) {
  const schedule = scheduleRow.schedule;
  if (action.type === UPDATE_SCENARIO_TRUCK) {
    return {
      start: action.payload.startTime,
      end: scheduleRow.scenarioTruck.endTime
    }
  } else if (isEmpty(schedule)) {
    const currentDate = getCurrentDate();
    const startHour = scheduleRow.startTime.startHour;
    const startMinute = scheduleRow.startTime.startMinute;
    const startWorkDate = setTimeToDate(startHour, startMinute, currentDate);
    return {
      start: startWorkDate,
      end: startWorkDate
    }
  } else {
    const firstScheduleDates = first(schedule).dateObjects;
    const lastScheduleDates = last(schedule).dateObjects;
    return {
      start: firstScheduleDates.locationStartTime,
      end: lastScheduleDates.locationEndTime
    }
  }
}

function getScenarioTruckModel(store, action) {
  const storeState = store.getState();
  const scenarioId = storeState.scenarioState.selectedScenario.id;
  const rowIdFromPayload = action.payload.rowId;
  const rowId = rowIdFromPayload? rowIdFromPayload : action.payload.scenarioTruckId;
  const scheduleRow = find(storeState.scheduleRowState, {rowId: rowId});
  const truckDates = getTruckScheduleDates(scheduleRow, action)
  const truckStart = truckDates.start;
  const truckEnd = truckDates.end;

  return {
    scenarioTruckId: action.payload.scenarioTruckId,
    scenarioId: scenarioId,
    truckId: action.payload.truckId,
    driverId: action.payload.driverId,
    trailerId: action.payload.trailerId,
    comments: action.payload.comments,
    dispatcherComment: action.payload.dispatcherComment,
    startTime: truckStart,
    endTime: truckEnd,
    workDayMinutes: action.payload.workDayMinutes
  }
}

export const createTruckScenario = store => next => action => {
  if (action.type === CREATE_SCENARIO_TRUCK || action.type === UPDATE_SCENARIO_TRUCK) { // fucked up
    const scenarioTruckModel = getScenarioTruckModel(store, action);
    action.payload.scenarioTruckModel = scenarioTruckModel;
  } 

  next(action);
}