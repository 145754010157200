import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { setNavistream, FETCH_NAVISTREAM_DATA} from '../../reducers/navistream';
import { failedAction }  from '../../reducers/requestActions';
import { getAuthService } from '../AuthService';



export const fetchNavistreamDataEpic = action$ => (
  action$.ofType(FETCH_NAVISTREAM_DATA)
    .mergeMap(action => (
      ajax.get(`api/navistream/${action.payload.scenarioId}`, getAuthService().header)
        .map(ajaxResponse => setNavistream(ajaxResponse.response))
        .catch(failure => Observable.of(failedAction(failure)))
    ))
);