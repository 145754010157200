import React from 'react';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import PdfService from '../../services/pdfService';
import PublishService from '../../services/publishService';
import { connect } from 'react-redux';
import { DRYLAND } from '../../constants/typeConstants';
import { openSendPdfConfirmationModal } from '../../reducers/modalWindows';
import { isViewerRole } from '../../utils/userHelper';

class DestinationContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.openDestinationPdfPreview = this.openDestinationPdfPreview.bind(this);
    this.openConfirmationDialog = this.openConfirmationDialog.bind(this);
    this.openDestinationPdfDownload = this.openDestinationPdfDownload.bind(this);
  }

  openDestinationPdfPreview() {
    PdfService.previewDestinationPdf(this.props.scenarioId, this.props.id);
  }

  openDestinationPdfDownload() {
    PdfService.downloadDestinationPdf(this.props.scenarioId, this.props.id);
  }

  openConfirmationDialog() {
    const sendAction = () =>PublishService.sendDestinationEmail(this.props.scenarioId, this.props.id, this.props.user);
    const props = {
      scenarioId: this.props.scenarioId,
      id: this.props.id,
      type: DRYLAND,
      name: this.props.name,
      sendAction: sendAction,
      user: this.props.user
    }
    this.props.openConfirmation(props);
  }

  render() {
    return (
      <div className='destination-context-menu'>
        <ContextMenuTrigger id={this.props.id} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>

        <ContextMenu id={this.props.id}>
          <MenuItem attributes={{className:'context-menu-title'}}>{this.props.name}</MenuItem>
          <MenuItem onClick={this.openDestinationPdfPreview}>Preview Destination Schedule PDF</MenuItem>
          <MenuItem onClick={this.openDestinationPdfDownload}>Download Destination Schedule PDF</MenuItem>
          <MenuItem onClick={this.openConfirmationDialog} disabled={isViewerRole()}>Send Destination Schedule Email</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirmation: (props) => {
      dispatch(openSendPdfConfirmationModal(props))
    }
  }
}

export default connect(null, mapDispatchToProps)(DestinationContextMenu);