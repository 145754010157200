import React from 'react';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
import find from 'lodash/find';
import uniqid from 'uniqid';
import { addTruck, editTruck, bulkActivateTrucks } from '../../reducers/trucks';
import TruckFieldEditor from '../../components/fieldEditors/TruckFieldEditor';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import Select, { createFilter } from 'react-select';
import { TRUCK } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class TrucksConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      truckSelected: '',
      isAddingNewTruck: false
    }

    this.removeTruck = this.removeTruck.bind(this);
  }

  componentDidUpdate(previousProps) {
    const previousTrucks = previousProps.trucks.map(t => t.id);
    const currentTrucks = this.props.trucks.map(t => t.id);
    if (currentTrucks.length > previousTrucks.length) {
      const diff = difference(currentTrucks, previousTrucks);
      if (diff.length > 0) {
        const newTruck = diff[0];
        this.setState({
          truckSelected: newTruck,
          isAddingNewTruck: false
        });
      }
    }
  }

  addNewTruck() {
    this.setState({
      truckSelected: '',
      isAddingNewTruck: true
    });
  }

  selectTruck(id) {
    this.setState({
      truckSelected: id,
      isAddingNewTruck: false
    });
  }

  getTruckButtonStyle(id) {
    if (id === this.state.truckSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getTruckList() {
    const options = this.props.trucks
    .filter(truck => !truck.isDeleted)
    .map(truck => {
      return {
        value: truck.id,
        label: truck.isCentralDispatch ? truck.name + " (CD)" : truck.name
      }
    });

    const handleChange = (selectedOption) => this.selectTruck(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedTruckName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChange}
    />;
  }

  hasSelectedTruck() {
    return this.state.truckSelected !== '';
  }

  getSelectedTruck() {
    const truck = find(this.props.trucks, {id: this.state.truckSelected});
    return truck;
  }

  getSelectedTruckName() {
    const truck = this.getSelectedTruck();
    if (truck) {
      return truck.name;
    } else {
      return 'Select...';
    }
  }

  getTruckProps() {
    const truck = this.getSelectedTruck();
    if (truck) {
      return {
        truckId: truck.id,
        licensePlate: truck.licensePlate,
        name: truck.name,
        truckCompanyId: truck.truckCompanyId,
        active: truck.active,
        comments: truck.comments,
        defaultDriver: truck.defaultDriver,
        defaultTrailer: truck.defaultTrailer,
        year: truck.year,
        rate: truck.rate,
        isCentralDispatch: truck.isCentralDispatch
      };
    } else {
      return {};
    }
  }

  removeTruck() {
    this.props.remove(this.state.truckSelected);
  }

  getRemoveTruckButton() {
    if (this.hasSelectedTruck()) {
      return <button className='remove-truck' onClick={this.removeTruck}>Remove Truck</button>
    }
  }

  checkExistence(state) {
    if (this.state.isAddingNewTruck) {
      const truck = find(this.props.trucks, {name: state.name});
      if (truck) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewTruck) {
      if (this.getSelectedTruckName() !== state.name) {
        const truck = find(this.props.trucks, {name: state.name});
        if (truck) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getTruckEditPanel() {
    if (this.hasSelectedTruck() && !this.state.isAddingNewTruck) {
      return (
        <div>
          <TruckFieldEditor
            key={uniqid()}
            truckFieldProps={this.getTruckProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectTruck('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewTruck) {
      return (
        <div>
          <p>Add New Truck</p>
          <TruckFieldEditor
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectTruck('')}
          />
        </div>
      );
    }
  }

  activate() {
    const truckState = this.getTruckProps();
    if (truckState) {
      truckState.active = !truckState.active;
      this.props.update(truckState);
    }
  }

  getActivateButton() {
    const truck = this.getSelectedTruck();
    if (truck) {
      const buttonName = truck.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedTruckWithId = this.props.trucks.map(truck => {
        return {
          ...truck,
          id: truck.id
        }
      });
      return <BulkActivationModal
        type={TRUCK}
        activationItems={updatedTruckWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getPrompt() {
    const truck = this.getSelectedTruck();
    if (truck) {
      return `Edit truck ${truck.name}`;
    } else if (this.state.isAddingNewTruck) {
      return 'Add a new truck';
    } else {
      return 'Select a truck to edit';
    }
  }

  render() {
    return (
      <div className='trucks-configuration configuration-forms'>
        <button className='add-new-truck' onClick={() => this.addNewTruck()}>Add New Truck</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='truck-editor'>
          <p>{this.getPrompt()}</p>
          <div className='truck-list'>
            {this.getTruckList()}
          </div>
          <div className='truck-edit-panel'>
            {this.getTruckEditPanel()}
            <div className='truck-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveTruckButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectTruck('')}/>
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trucks: state.trucks.truckList,
    trailers: state.trailers.trailerList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addTruck(state));
    },
    update: (state) => {
      dispatch(editTruck(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems))
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateTrucks(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrucksConfiguration);