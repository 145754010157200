import find from 'lodash/find';
import store from '../store';
import isNil from 'lodash/isNil';

export function matchesSelectedOperatingUnit(configObjectId) {
  const selectedIds = getSelectedOperatingUnitIds();
  if (selectedIds === [] || isNil(selectedIds)) {
    return true;
  }

  const opUnitAssignments = getOperatingUnitAssignment();
  const matchingSelectedOperatingUnits = selectedIds.filter(opUnitId => {
    const targetAssign = find(opUnitAssignments, 
      {
        configObjectId: configObjectId,
        operatingUnitId: opUnitId
      }
    );
    return !isNil(targetAssign);
  });

  return !isNil(matchingSelectedOperatingUnits) && matchingSelectedOperatingUnits.length > 0;
}

function getStoreState() {
  return store.getState();
}

function getOperatingUnitState() {
  return getStoreState().operatingUnits;
}

function getOperatingUnitAssignment() {
  return getOperatingUnitState().operatingUnitAssignment;
}

function getSelectedOperatingUnitIds() {
  return getOperatingUnitState().selectedOperatingUnitIds;
}

export function getSelectedOperatingUnitNames() {
  const ids = getSelectedOperatingUnitIds();
  const idSet = new Set(ids);
  const operatingUnits = getOperatingUnitState().operatingUnitList;
  const targetOpUnits = operatingUnits.filter(opUnit => idSet.has(opUnit.id));
  if (targetOpUnits) {
    return targetOpUnits.map(opUnit => opUnit.name).join(', ');
  } else {
    return 'All';
  }
}

export function isInOperatingUnit(configObjectId, operatingUnitId) {
  const assignments = getOperatingUnitAssignment();
  const targetAssign = find(assignments, {configObjectId: configObjectId, operatingUnitId: operatingUnitId});
  return !isNil(targetAssign);
}

export function isTruckInYardOperatingUnits(truckId, yardOperatingUnitIds) {
  const assignments = getOperatingUnitAssignment();
  const targetAssign = find(assignments, {configObjectId: truckId});
  if (yardOperatingUnitIds.includes(targetAssign.operatingUnitId)) {
    return true;
  }
  else {
    return false;
  }
}

export function getAssignedOperatingUnit(configObjectId) {
  const assignments = getOperatingUnitAssignment();
  const targetAssign = find(assignments, {configObjectId: configObjectId});
  if (isNil(targetAssign)) {
    return null;
  } else {
    return targetAssign.operatingUnitId;
  }
}