

const SET_DRIVERS_TO_BLOCKS_HISTORY = 'SET_DRIVERS_TO_BLOCKS_HISTORY';
export const ADD_DRIVER_TO_BLOCK_HISTORY = 'ADD_DRIVER_TO_BLOCK_HISTORY';
export const REMOVE_DRIVER_TO_BLOCK_HISTORY = 'REMOVE_DRIVER_TO_BLOCK_HISTORY';
export const EDIT_DRIVER_TO_BLOCK_HISTORY = 'EDIT_DRIVER_TO_BLOCK_HISTORY';
export const BULK_ADD_DRIVERS_TO_HISTORY = 'BULK_ADD_DRIVERS_TO_HISTORY';
export const BULK_ADD_BLOCKS_TO_HISTORY = 'BULK_ADD_BLOCKS_TO_HISTORY';


export function addDriverToBlockHistory(
  driverId,
  blockId
) {
  return {
    type: ADD_DRIVER_TO_BLOCK_HISTORY,
    payload: {
      driverId: driverId,
      blockId: blockId
    }
  }
}

export function bulkAddDriversToHistory (driverIds, blockId) {
  return {
    type: BULK_ADD_DRIVERS_TO_HISTORY,
    payload: {
      driverIds: driverIds,
      blockId: blockId
    }
  }
}

export function bulkAddBlocksToHistory (blockIds, driverId) {
  return {
    type: BULK_ADD_BLOCKS_TO_HISTORY,
    payload: {
      blockIds: blockIds,
      driverId: driverId
    }
  }
}


export function editDriverToBlockHistory(driverToBlockHistory) {
  return {
    type: EDIT_DRIVER_TO_BLOCK_HISTORY,
    payload: driverToBlockHistory
  }
}

export function removeDriverToBlockHistory(driverToBlockHistory) {
  return {
    type: REMOVE_DRIVER_TO_BLOCK_HISTORY,
    payload: driverToBlockHistory
  }
}

export function setDriversToBlocksHistory(driversToBlocksHistory) {
  return {
    type: SET_DRIVERS_TO_BLOCKS_HISTORY,
    payload: driversToBlocksHistory
  }
}

export default function driversToBlocksHistory( state = {
  driversToBlocksHistoryList: []
}, action) {
  switch (action.type) {
    case SET_DRIVERS_TO_BLOCKS_HISTORY:
      return _setDriversToBlocksHistory(action);
    case ADD_DRIVER_TO_BLOCK_HISTORY:
      return _addDriverToBlockHistory(action);
    case BULK_ADD_DRIVERS_TO_HISTORY:
      return _bulkAddDriversToHistory(action);
    case BULK_ADD_BLOCKS_TO_HISTORY:
      return _bulkAddBlocksToHistory(action);
    default:
      return state;
  }
}

function _setDriversToBlocksHistory(action) {
  return {
    
    driversToBlocksHistoryList: action.payload
  }
}

function _addDriverToBlockHistory(action) {
  return {
    driverId: action.payload.driverId,
    blockId: action.payload.blockId
  }
}

function _bulkAddDriversToHistory(action) {
  return {
    driverIds: action.payload.driverIds,
    blockId: action.payload.blockId
  }
}

function _bulkAddBlocksToHistory(action) {
  return {
    blockIds: action.payload.blockIds,
    driverId: action.payload.driverId
  }
}