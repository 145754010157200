import store from '../store';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

class LoaderOperatorHelper {
  constructor() {
    this.storeState = store.getState();
  }

  getLoaders() {
    return this.storeState.loaders.loaderList;
  }

  getOperators() {
    return this.storeState.operators;
  }

  getLoaderOperatorAssignment() {
    return this.storeState.loaderOperatorAssignment.loaderOperatorAssignmentList;
  }

  finder(list, findFunction) {
    const target = list.find(findFunction);
    if (target) {
      return target;
    } else {
      return {};
    }
  }

  getLoader(loaderId) {
    const list = this.getLoaders();
    const findFunction = loader => loader.id === loaderId;
    return this.finder(list, findFunction);
  }

  getOperator(operatorId) {
    const list = this.getOperators();
    const findFunction = op => op.id === operatorId;
    return this.finder(list, findFunction);
  }

  getOperatorByLoaderId(loaderId) {
    //const assign = this.finder(this.getLoaderOperatorAssignment(), (assign => assign.loaderId === loaderId))
    const assign = this.getLoaderOperatorAssignment()
      .filter(assignment => assignment.loaderId === loaderId)
      .map(assignment => assignment.loaderOperatorId);
    
    if (assign) {
      return this.getOperator(assign.operatorId);
    }
  }

  getOperatorNameByLoaderIdSimple(loaderId) {
    const assignments = this.getLoaderOperatorAssignment();
    const specificAssignment = find(assignments, {loaderId: loaderId});
    if (specificAssignment) {
      const loaderOperatorId = specificAssignment.loaderOperatorId;
      const operator = find(this.getOperators(), {id: loaderOperatorId});
      return operator.name;
    }
    else {
      return "None";
    }
  }

  getOperatorNameByLoaderId(loaderId) {
    const operator = this.getOperatorByLoaderId(loaderId);
    if (!isNil(operator.name)) {
      return operator.name;
    } else {
      return 'None';
    }
  }

  getBlockActivityOperator(loaderId, locationId) {
    const assignment = this.getLoaderOperatorAssignment()
      .filter(x => x.loaderId === loaderId && x.blockId === locationId);
    if (assignment.length !== 0) {
      const operator = find(this.storeState.operators, {id: assignment[0].loaderOperatorId});
      if (!isNil(operator.name)) {
        return operator.name;
      } else {
        return 'None';
      }
    }
    else {
      return 'None';
    }
  }

  getOperatorsByBlockId(blockId) {
    const assignments = this.getLoaderOperatorAssignment();
    const blockLoaderIds = assignments.filter(x => x.blockId === blockId).map(x => x.loaderId);
    if (blockLoaderIds) {
      const loaderIdSet = new Set(blockLoaderIds);
      const operatorIds = assignments.filter(x => x.blockId === blockId)
        .filter(assign => loaderIdSet.has(assign.loaderId))
        .map(assign => assign.loaderOperatorId);
      const operatorIdSet = new Set(operatorIds);
      return this.getOperators().filter(operator => operatorIdSet.has(operator.id));
    } else {
      return [];
    }
  }
}

export default LoaderOperatorHelper;