import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { addLocationGuard, updateActivity, swapActivity } from './middlewares/locationGuard';
import { addBlockLoadGuard, assignDestinationAny, setAssignedBlockLoads, callFetchBlockLoads } from './middlewares/blockLoadMiddleware';
import scheduleRowState from './reducers/schedule';
import workHoursForSchedule from './reducers/workHours';
import locations from './reducers/locations';
import trucks from './reducers/trucks';
import blockLoadState from './reducers/blockLoads';
import modalWindows from './reducers/modalWindows';
import { rootEpic } from './services/api/root';
import { createEpicMiddleware } from 'redux-observable';
import scenarioState from './reducers/scenarios';
import { loadSchedule } from './middlewares/loadScheduleMiddleware';
import dashboard from './reducers/dashboard';
import { addDetailsToDashboardData, clearDashboardDispatcher } from './middlewares/dashboardMiddleware';
import { fetchScheduleOnScenarioChange } from './middlewares/scenarioMiddleware';
import { createTruckScenario } from './middlewares/truckMiddleware';
import trailers from './reducers/trailers';
import drivers from './reducers/drivers';
import highlights from './reducers/highlights';
import loaders from './reducers/loaders';
import loadingScreen from './reducers/loadingScreen';
import { showLoadingScreen } from './middlewares/loadingScreenMiddleware';
import operators from './reducers/operators';
import operatingUnits from './reducers/operatingUnits';
import { addOperatingUnitIdOnUpdate } from './middlewares/operatingUnitMiddleware';
import users from './reducers/users';
import errorCodes from './reducers/errorCodes';
import paintMarks from './reducers/paintMarks';
import truckShares from './reducers/truckShares';
import sleeperLoads from './reducers/sleeperLoads';
import { setYardToDestination } from './middlewares/sleeperLoadMiddleware';
import driversToBlocksHistory from './reducers/driversToBlocksHistory';
import vendors from './reducers/vendors';
import hammerMarks from './reducers/hammerMarks';
import contractors from './reducers/contractors';
import fallers from './reducers/fallers';
import yarders from './reducers/yarders';
import processors from './reducers/processors';
import blockProperties from './reducers/blockProperties';
import reports from './reducers/reports';
import navistream from './reducers/navistream';
import loaderOperatorAssignment from './reducers/loaderOperatorAssignment';
import truckCompanies from './reducers/truckCompanies';
const epicMiddleware = createEpicMiddleware(rootEpic);

const store = createStore(
  combineReducers({
    blockLoadState,
    blockProperties,
    contractors,
    dashboard,
    drivers,
    driversToBlocksHistory,
    errorCodes,
    fallers,
    hammerMarks,
    highlights,
    loaders,
    loadingScreen,
    locations,
    modalWindows,
    operatingUnits,
    operators,
    paintMarks,
    processors,
    reports,
    scenarioState,
    scheduleRowState,
    sleeperLoads,
    trailers,
    trucks,
    truckShares,
    users,
    vendors,
    workHoursForSchedule,
    yarders,
    navistream,
    loaderOperatorAssignment,
    truckCompanies
  }),
  {},
  applyMiddleware(
    logger,
    addLocationGuard,
    addBlockLoadGuard,
    epicMiddleware,
    assignDestinationAny,
    loadSchedule,
    setAssignedBlockLoads,
    callFetchBlockLoads,
    addDetailsToDashboardData,
    clearDashboardDispatcher,
    fetchScheduleOnScenarioChange,
    createTruckScenario,
    updateActivity,
    swapActivity,
    showLoadingScreen,
    addOperatingUnitIdOnUpdate,
    setYardToDestination
  )
);

export default store;
