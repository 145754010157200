import React from 'react';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import BlockView from '../views/BlockView';
import NewWindow from 'react-new-window';
import '../views/ViewStyles.css';
import DestinationView from '../views/DestinationView';
import NavistreamView from '../views/NavistreamView';

class ViewSelectionSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showBlockViewPortal: false,
      showDestinationViewPortal: false,
      showNavistreamViewPortal: false
    }

    this.toggleBlockViewPortal = this.toggleBlockViewPortal.bind(this);
    this.toggleDestinationViewPortal = this.toggleDestinationViewPortal.bind(this);
    this.toggleNavistreamViewPortal = this.toggleNavistreamViewPortal.bind(this);
  }

  toggleBlockViewPortal() {
    this.setState({showBlockViewPortal: !this.state.showBlockViewPortal});
  }

  toggleDestinationViewPortal() {
    this.setState({showDestinationViewPortal: !this.state.showDestinationViewPortal});
  }

  toggleNavistreamViewPortal() {
    this.setState({showNavistreamViewPortal: !this.state.showNavistreamViewPortal});
  }

  getBlockView() {
    if (this.state.showBlockViewPortal) {
      return (
        <NewWindow
          title={'Block View'}
          copyStyles={true}
          features={{left: 200, top: 200, width: 700, height: 1000 }}
        >
          <BlockView onClose={this.toggleBlockViewPortal}/>
        </NewWindow>
      );
    }
  }

  getDestinationView() {
    if (this.state.showDestinationViewPortal) {
      return (
        <NewWindow
          title={'Destination View'}
          features= {{left: 200, top: 200, width: 700, height: 1000}}
        >
          <DestinationView onClose={this.toggleDestinationViewPortal}/>
        </NewWindow>
      );
    }
  }

  getNavistreamView() {
    if (this.state.showNavistreamViewPortal) {
      return (
        <NewWindow
          title={'NaviStream'}
          features= {{left: 200, top: 200, width: 700, height: 800}}
        >
          <NavistreamView onClose={this.toggleNavistreamViewPortal}/>
        </NewWindow>
      )
    }
  }

  render() {
    if (this.props.hasNoScenarios) {
      return null;
    }
    
    const id='view-selection-menu';
    return (
      <div className='view-selection-menu'>
        <ContextMenuTrigger id={id} holdToDisplay={0}>
          <button className='view-selection-button'>View</button>
        </ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem onClick={this.toggleBlockViewPortal}>Block View</MenuItem>
          <MenuItem onClick={this.toggleDestinationViewPortal}>Destination View</MenuItem>
          <MenuItem onClick={this.toggleNavistreamViewPortal}>Navistream View</MenuItem>
        </ContextMenu>

        {this.getBlockView()}
        {this.getDestinationView()}
        {this.getNavistreamView()}
      </div>
    );
  }
}

export default ViewSelectionSettings;