const SET_TRUCKCOMPANIES = 'SET_TRUCKCOMPANIES';
export const ADD_TRUCKCOMPANY = 'ADD_TRUCKCOMPANY';
export const ADD_TRUCKCOMPANY_SUCCESS = 'ADD_TRUCKCOMPANY_SUCCESS';
export const UPDATE_TRUCKCOMPANY = 'UPDATE_TRUCKCOMPANY';
export const UPDATE_TRUCKCOMPANY_SUCCESS = 'UPDATE_TRUCKCOMPANY_SUCCESS';
export const REMOVE_TRUCKCOMPANY = 'REMOVE_TRUCKCOMPANY';
export const REMOVE_TRUCKCOMPANY_SUCCESS = 'REMOVE_TRUCKCOMPANY_SUCCESS';
export const BULK_ACTIVATE_TRUCKCOMPANIES = 'BULK_ACTIVATE_TRUCKCOMPANIES';

export function setTruckCompanies(truckCompanies) {
  return {
    type: SET_TRUCKCOMPANIES,
    payload: truckCompanies
  }
}

export function addTruckCompany(truckCompany) {
  return {
    type: ADD_TRUCKCOMPANY,
    payload: truckCompany
  }
}

export function addTruckCompanySuccess(truckCompany) {
  return {
    type: ADD_TRUCKCOMPANY_SUCCESS,
    payload: truckCompany
  }
}

export function updateTruckCompany(truckCompany) {
  return {
    type: UPDATE_TRUCKCOMPANY,
    payload: truckCompany
  }
}

export function updateTruckCompanySuccess(truckCompany) {
  return {
    type: UPDATE_TRUCKCOMPANY_SUCCESS,
    payload: truckCompany
  }
}

export function removeTruckCompany(truckCompanyId) {
  return {
    type: REMOVE_TRUCKCOMPANY,
    payload: truckCompanyId
  }
}

export function removeTruckCompanySuccess(truckCompany) {
  return {
    type: REMOVE_TRUCKCOMPANY_SUCCESS,
    payload: truckCompany
  }
}

export function bulkActivateTruckCompanies(truckCompanyIds) {
  return {
    type: BULK_ACTIVATE_TRUCKCOMPANIES,
    payload: truckCompanyIds
  }
}

export default function truckCompanies(state = {
  truckCompaniesList: []
}, action) {
  switch(action.type) {
    case SET_TRUCKCOMPANIES:
      return _setTruckCompanies(state, action);
    case ADD_TRUCKCOMPANY_SUCCESS:
      return _AddTruckCompany(state, action);
    case UPDATE_TRUCKCOMPANY_SUCCESS:
      return _updateTruckCompany(state, action);
    case REMOVE_TRUCKCOMPANY_SUCCESS:
      return _removeTruckCompany(state, action);
    default:
      return state;
  }
}

function _setTruckCompanies(state, action) {
  return {
    ...state,
    truckCompaniesList: action.payload
  }
}

function _AddTruckCompany(state, action) {
  const truckCompanies = [...state.truckCompaniesList, action.payload];
  return {
    ...state,
    truckCompaniesList: truckCompanies
  }
}

function _updateTruckCompany(state, action) {
  const updatedTruckCompany = action.payload;
  const updatedList = state.truckCompaniesList.map(truckCompany => {
    if(truckCompany.id === updatedTruckCompany.id) {
      return updatedTruckCompany;
    } else {
      return truckCompany;
    }
  });
  return {
    ...state,
    truckCompaniesList: updatedList
  }
}

function _removeTruckCompany(state, action) {
  const updatedList = state.truckCompaniesList
  return {
    ...state,
    truckCompaniesList: updatedList
  }
}
