import { FETCH_SCHEDULE, SET_SCHEDULE, FETCH_SCENARIO_TRUCKS, SET_SCENARIO_TRUCKS } from "../reducers/schedule";
import { showLoading, hideLoading } from "../reducers/loadingScreen";
import { FETCH_BLOCK_LOAD_INFO, SET_BLOCK_LOAD_INFO } from "../reducers/blockLoads";
import { FETCH_SCENARIOS, SET_SCENARIOS, NO_AVAILABLE_SCENARIOS, COPY_SCENARIO } from "../reducers/scenarios";

export const showLoadingScreen = store => next => action => {
  const showLoadingActions = [
    FETCH_SCHEDULE, 
    FETCH_BLOCK_LOAD_INFO, 
    FETCH_SCENARIO_TRUCKS, 
    FETCH_SCENARIOS, 
    COPY_SCENARIO
  ];
  if (showLoadingActions.indexOf(action.type) >= 0) {
    const showLoadingFetchBlock = action.type === FETCH_BLOCK_LOAD_INFO && action.shouldShowLoading;
    if (action.type !== FETCH_BLOCK_LOAD_INFO || showLoadingFetchBlock) {
      store.dispatch(showLoading());
    }
    
  }

  const hideLoadingActions = [
    SET_SCHEDULE, 
    SET_BLOCK_LOAD_INFO, 
    SET_SCENARIO_TRUCKS, 
    SET_SCENARIOS, 
    NO_AVAILABLE_SCENARIOS,
  ];

  if (hideLoadingActions.indexOf(action.type) >= 0) {
    store.dispatch(hideLoading());
  }

  next(action);
}