import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import format from 'date-fns/format';
import DayPicker from 'react-day-picker';
import { formatDateStringIso } from '../../utils/dateTimeHelpers';
import { ContextMenuTrigger } from 'react-contextmenu';
import { showScenariosForSleeperLoads } from '../../reducers/sleeperLoads';
import { importSleeperLoads } from '../../reducers/sleeperLoads';
import { addNewSchedule, createScenarioTruck, setStartTime, addTruckToSchedule, addLocationToSchedule } from '../../reducers/schedule';
import uniqid from 'uniqid';
import find from 'lodash/find';
import { emptyUuid } from '../../utils/uuidHelper';


class ImportSleepersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: this.getYesterdaysDate(),
      selectedScenarioId: '',
      sleeperLoads: [],
      scheduleRowState: [],
      importButtonDisabled: false
    }

    ReactModal.setAppElement('#root');  

    this.isoFormat = 'YYYY-MM-DD';
    this.closeModal = this.closeModal.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleScenarioChange = this.handleScenarioChange.bind(this);
    this.importSleeperLoads = this.importSleeperLoads.bind(this);
    this.autoAddLocations = this.autoAddLocations.bind(this);
    this.autoAddDestinations = this.autoAddDestinations.bind(this);
    this.autoAddScenarioTrucks = this.autoAddScenarioTrucks.bind(this);
    this.showOnStartup = this.showOnStartup.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props.sleeperLoads !== prevProps.sleeperLoads) {
      this.setState({sleeperLoads: this.props.sleeperLoads}); 
    } if (this.props.scheduleRowState !== prevProps.scheduleRowState) {
      this.setState({scheduleRowState: this.props.scheduleRowState});
      }
  }

  componentDidMount() {
    this.showOnStartup(this.state.selectedDate);
  }


  autoAddScenarioTrucks() {
    const sleeperLoads = this.state.sleeperLoads;
    sleeperLoads.map(load => {
      const truckId = load.truckId;
      const trailerId = load.trailerId;
      const trailer = trailerId != null ? find(this.props.trailers, {id: trailerId}).id : null;
      const scenarioId = load.scenarioId;
      const rowId = this.getScheduleRowId();
      const targetTruck = find(this.props.trucks, {id: truckId});
      const startTimeNumbers = this.getStartTimeNumbers();
      this.props.addNewSchedule(rowId, 4);
      this.props.setStartTime(rowId, startTimeNumbers);
      const scenarioTruckState = {
        truckId: truckId,
        scenarioId: scenarioId,
        rowId: rowId,
        truckName: targetTruck.name,
        driverId: load.driverId,
        workDayMinutes: 600,
        trailerId: trailer
      }
      scenarioTruckState.highlightTruck = this.props.highlightTruck;
      this.props.createScenarioTruck(scenarioTruckState);
      return sleeperLoads;
    });
    setTimeout(this.autoAddLocations, 5000);
  }

  
  autoAddLocations() {
    const scheduleRowState = this.state.scheduleRowState;
    const loads = this.state.sleeperLoads;
    for (var i=0; i<loads.length; i++) {
      const truckId = loads[i].truckId;
      const target = scheduleRowState.filter(row => row.truckId === truckId)
      .map(row => row.rowId);
      this.props.addLocationToSchedule(target[0], loads[i].yardId)
    };
    setTimeout(this.autoAddDestinations, 5000);    
  }

  autoAddDestinations() {
    const scheduleRowState = this.state.scheduleRowState;
    const loads = this.state.sleeperLoads;
    for (var i=0; i<loads.length; i++) {
      const truckId = loads[i].truckId;
      const target = scheduleRowState.filter(row => row.truckId === truckId)
      .map(row => row.rowId);
      this.props.addLocationToSchedule(target[0], loads[i].destinationId)
    };
    setTimeout(this.closeModal, 7000)
  }


  
  getScheduleRowId() {
    return uniqid('scheduleRow');
  }
  
  getStartTimeNumbers() {
    return {
      startHour: Number(4),
      startMinute: Number(0)
    }
  }

  closeModal() {
    this.props.closeModal();
  }

  handleDayClick(day) {
    this.setState({selectedDate: day});
    const formattedDate = formatDateStringIso(day);
    this.props.showScenariosForSleeperLoads(formattedDate)
    this.getScenarioOptions();
  }

  getSelectedScenarioId(){
    const scenario = this.props.scenariosToImport;
    const id = scenario ? scenario.id: '';
    return id;
  }

  getScenarioNamePrefix(scenario) {
    let prefix = '';
    if (scenario.published) {
      prefix = '[PUBLISHED]';
    } else if (scenario.wasPublished) {
      prefix = '[UNPUBLISHED]';
    } else if (scenario.active) {
      prefix = '[ACTIVE]';
    }

    return prefix;
  }

  getScenarioOptions() {
    const none = <option key='blank' value={emptyUuid}>None</option>;
    const scenarioOptions = this.props.scenariosToImport
    .map( scenario => {
      const id = scenario.id;
      return <option key={id} value={id}>{this.getScenarioNamePrefix(scenario)} {scenario.name}</option>
    })
    scenarioOptions.unshift(none);
    return scenarioOptions;
  }

  handleScenarioChange(event) {
    this.setState({selectedScenarioId: event.target.value});  
  }


  getScenarioPicker() {
    const id = 'scenarioPicker';
    return (
      <React.Fragment>
        <ContextMenuTrigger id={id} holdToDisplay={-1}>
          <select className='scenario-picker'
            onChange={this.handleScenarioChange}
            value={this.getSelectedScenarioId()}>
              {this.getScenarioOptions()}
          </select>
        </ContextMenuTrigger>
      </React.Fragment>
    );
  }

  importSleeperLoads() {
    this.setState({importButtonDisabled: true});
    this.props.importSleeperLoads(this.state.selectedScenarioId, this.props.selectedScenario.id);
    this.setState({sleeperLoads: this.props.sleeperLoads});
    setTimeout(this.autoAddScenarioTrucks, 5500);
  }


  getModal() { 
    return (
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.closeModal}
        style={{content: {
          height: '470px',
          width: '280px',
          display: 'flex',
          margin: 'auto'
        }}}
      >
        <div className='import-sleepers-modal'>
          <div className='import-prompt'>Import sleepers from a specified date </div>
          <DayPicker
            onDayClick={this.handleDayClick}
            selectedDays={this.state.selectedDate}
            />
            <p className='import-sleepers-chosen-date-label'>Chosen Date: {format(this.state.selectedDate, this.isoFormat)}</p>
            <div className='select-scenario-section'>
            {this.getScenarioPicker()}
            </div>
            <div className='import-sleepers-modal-buttons'>
              <button className='cancel-button' onClick={this.closeModal} disabled={this.state.importButtonDisabled}>Cancel</button>
              <button className='done-button' onClick={this.importSleeperLoads} disabled={this.state.importButtonDisabled}>Import</button>
            </div>
        </div>
      </ReactModal>
    );
  }

  showOnStartup(day) {
    const formattedDate = formatDateStringIso(day);
    this.props.showScenariosForSleeperLoads(formattedDate)
    this.getScenarioOptions();
  }

  getYesterdaysDate() {
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1));
    var dateString = yesterday.toString().slice(0, 3);
    if (dateString === 'Sun') {
      yesterday = new Date(new Date().setDate(new Date().getDate()-3));
    }
    return yesterday;
  }

  

  render() {
    return this.getModal();
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    selectedScenario: state.scenarioState.selectedScenario,
    scenariosToImport: state.sleeperLoads.scenariosToImport,
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    trucks: state.trucks.truckList,
    scheduleRowState: state.scheduleRowState,
    trailers: state.trailers.trailerList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    showScenariosForSleeperLoads: (date) => {
      dispatch(showScenariosForSleeperLoads(date));
    },
    importSleeperLoads: (scenarioId, currentScenarioId) => {
      dispatch(importSleeperLoads(scenarioId, currentScenarioId));
    },
    addNewSchedule: (rowId, beginWorkHour) => {
      dispatch(addNewSchedule(rowId, beginWorkHour));
    },
    setStartTime: (rowId, startTimeNumbers) => {
      dispatch(setStartTime(rowId, startTimeNumbers));
    },
    createScenarioTruck: (scenarioTruckState) => {
      dispatch(createScenarioTruck(scenarioTruckState));
    },
    addTruckToSchedule: (rowId, truckId, truckName, scenarioTruckInfo) => {
      dispatch(addTruckToSchedule(rowId, truckId, truckName, scenarioTruckInfo))
    },
    addLocationToSchedule: (rowId, locationId) => {
      dispatch(addLocationToSchedule(rowId, locationId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportSleepersModal);
