import React from 'react';
import { closeModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { reopenScenario } from '../../reducers/scenarios';


class ReopenScenarioPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.reopenScenario = this.reopenScenario.bind(this);

    this.state = {
      password: ''
    }
  }

  closeModal() {
    this.props.closeModal();
  }

  handleChangePassword(event) {
    this.setState({password: event.target.value});
  }

  reopenScenario() {
    const scenarioId = this.props.scenarioId;
    const password = this.state.password;
    this.props.reopenScenario(scenarioId, password);
    this.closeModal();
  }


  render() {
    return (
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.closeModal}
        style={{content: {
          height: '150px',
          width: '300px',
          display: 'block',
          margin: 'auto'
        }}}
      >
      <div className="reopen-scenario-modal">
        <p>Please enter your password</p>
        <input className='test' type='password' value={this.state.password} onChange={this.handleChangePassword} />
      </div>
      
      <div className='reopen-scenario-modal-buttons'>
        <button className='reopen-scenario-modal-cancel-button' onClick={this.closeModal}>Cancel</button>
        <button className='reopen-scenario-modal-done-button' disabled={this.state.password === ''} onClick={this.reopenScenario}>Done</button>
      </div>
      </ReactModal>
    );
  }   
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    scenarioId: state.scenarioState.selectedScenario.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    reopenScenario: (scenarioId, password) => {
      dispatch(reopenScenario(scenarioId, password));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReopenScenarioPasswordModal);