import store from '../store';

export function getScenariosFromCurrentSelectedDate() {
  const selectedDate = getSelectedDate();
  const scenarios = getScenarios();
  return Object.keys(scenarios)
      .map(id => scenarios[id])
      .filter(scenario => selectedDate === scenario.date);
}

function getScenarioState() {
  return store.getState().scenarioState;
}

function getSelectedDate() {
  return getScenarioState().currentDateSelected;
}

function getScenarios() {
  return getScenarioState().byId;
}