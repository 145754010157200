import React from 'react';
import range from 'lodash/range';
import { connect } from 'react-redux';

class StartTimeSetter extends React.Component {

  getAvailableMinutes() {
    return range(0, 60, 5).map(currentSecond => {
      let displayedSecond = (currentSecond < 10)? '0' + currentSecond: currentSecond;
      return <option key={currentSecond} value={currentSecond}> {displayedSecond} </option>
    });
  }

  getAvailableHours() {
    return range(this.props.beginHour, this.props.endHour).map(currentHour => {
      let adjustedHour = (currentHour > 12)? currentHour - 12 : currentHour;
      let displayedHour = (currentHour > 11)? (adjustedHour + ' pm') : (adjustedHour + ' am');
      return <option key={currentHour} value={currentHour}> {displayedHour} </option>
    });
  }

  render() {
    return (
      <div>
        <label>Start Time: </label>
        <select value={this.props.startHour} onChange={this.props.handleHourChange}>
          {this.getAvailableHours()}
        </select>
        <select value={this.props.startMinute} onChange={this.props.handleMinuteChange}>
          {this.getAvailableMinutes()}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    beginHour: state.workHoursForSchedule.beginHour,
    endHour: state.workHoursForSchedule.endHour
  }
}


export default connect(mapStateToProps)(StartTimeSetter);