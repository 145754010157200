import React from 'react';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
import find from 'lodash/find';
import uniqid from 'uniqid';
import { addDriver, editDriver, bulkActivateDrivers } from '../../reducers/drivers';
import { BULK_ACTIVATION_MODAL, DRIVER_HISTORY_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { openRemoveObjectModal, openBulkActivationModal, openDriverHistoryModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import DriverFieldEditor from '../../components/fieldEditors/DriverFieldEditor';
import Select from 'react-select';
import { DRIVER } from '../../constants/typeConstants';
import DriverHistoryModal from '../../components/modals/DriverHistoryModal';
import { bulkAddBlocksToHistory } from '../../reducers/driversToBlocksHistory';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class DriversConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driverSelected: '',
      isAddingNewDriver: false
    }

  }

  componentDidUpdate(previousProps) {
    const previousDrivers = previousProps.drivers.map(d => d.id);
    const currentDrivers = this.props.drivers.map(d => d.id);
    if (currentDrivers.length > previousDrivers.length) {
      const diff = difference(currentDrivers, previousDrivers);
      if (diff.length > 0) {
        const newDriver = diff[0];
        this.setState({
          driverSelected: newDriver,
          isAddingNewDriver: false
        });
      }
    }
  }

  addNewDriver() {
    this.setState({
      driverSelected: '',
      isAddingNewDriver: true
    });
  }

  selectDriver(id) {
    this.setState({
      driverSelected: id,
      isAddingNewDriver: false
    });
  }

  getSelectedDriver() {
    const driver = find(this.props.drivers, {id: this.state.driverSelected});
    return driver;
  }

  getSelectedDriverName() {
    const driver = this.getSelectedDriver();
    if (driver) {
      return driver.name;
    } else {
      return 'Select...';
    }
  }


  getDriverProps() {
    const driver = this.getSelectedDriver();
    if (driver) {
      return {
        driverId: driver.id,
        name: driver.name,
        active: driver.active,
        email: driver.email,
        comments: driver.comments,
        experience: driver.experience,
        isService: driver.isService,
        isTrainee: driver.isTrainee,
        trainingStartDate: driver.trainingStartDate,
        mentorDriverId: driver.mentorDriverId
      };
    } else {
      return {};
    }
  }

  hasSelectedDriver() {
    return this.state.driverSelected !== '';
  }

  checkExistence(state) {
    if (this.state.isAddingNewDriver) {
      const driver = find(this.props.drivers, {name: state.name});
      if (driver) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewDriver) {
      if (this.getSelectedDriverName() !== state.name) {
        const driver = find(this.props.drivers, {name: state.name});
        if (driver) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }


  getDriverEditPanel() {
    if (this.hasSelectedDriver() && !this.state.isAddingNewDriver) {
      const driverId = this.state.driverSelected;
      return (
        <div>
          <DriverFieldEditor
            key={driverId}
            driverFieldProps={this.getDriverProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectDriver('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewDriver) {
      return (
        <div>
          <p>Add New Driver</p>
          <DriverFieldEditor
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectDriver('')}
          />
        </div>
      );
    }
  }

  getDriverHistory() {
    if (this.hasSelectedDriver()) {
      const driver = find(this.props.drivers, {id: this.state.driverSelected});
      const driverHistory = this.props.history.filter(history => history.driverId === driver.id);
      if (driverHistory) {
        const blockIds = driverHistory.map(history => history.blockId);
        return blockIds;
      } 
    }
  }

  getDriverButtonStyle(id) {
    if (id === this.state.driverSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getDriverList() {
    const options = this.props.drivers
    .filter(driver => !driver.isDeleted)
    .map(driver => {
      return {
        value: driver.id,
        label: driver.name
      }
    });

    const handleChange = (selectedOption) => this.selectDriver(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedDriverName()}}
      options={options}
      onChange={handleChange}
    />;
  }

  activate() {
    const driverState = this.getDriverProps();
    if (driverState) {
      driverState.active = !driverState.active;
      this.props.update(driverState);
    }
  }

  getDriverHistoryModal() {
    const modal = this.props.modalWindow;
    if (modal.modalType === DRIVER_HISTORY_MODAL && modal.isModalOpen) {
      return <DriverHistoryModal
        blockIds={this.getDriverHistory()}
        blocks={this.props.blocks}
        onClickDone={(selectedBlocks) => this.props.bulkAddBlocksToHistory(selectedBlocks, this.state.driverSelected)}
        />
    } else {
      return null;
    }
  }
  

  getActivateButton() {
    const driver = this.getSelectedDriver();
    if (driver) {
      const buttonName = driver.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveDriverButton() {
    const driverId = this.state.driverSelected;
    if (driverId !== '') {
      return (
        <button className='remove-driver' 
          onClick={() => this.props.remove(driverId)}>
            Remove Driver
        </button>
      );
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedDriverWithId = this.props.drivers
      .filter(driver => !driver.isDeleted)
      .map(driver => {
        return {
          ...driver,
          id: driver.id
        }
      });
      return <BulkActivationModal
        type={DRIVER}
        activationItems={updatedDriverWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getPrompt() {
    const driver = this.getSelectedDriver();
    if (driver) {
      return `Edit driver ${driver.name}`;
    } else if (this.state.isAddingNewDriver) {
      return 'Add a new driver';
    } else {
      return 'Select a driver to edit';
    }
  }

  render() {
    return (
      <div className='drivers-configuration configuration-forms'>
        <button className='add-new-driver' onClick={() => this.addNewDriver()}>Add New Driver</button>
        <button className='bulk-activation-button'
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <button className='driver-history-button'
          onClick={() => this.props.openDriverHistoryModal(this.getDriverHistory())}
          disabled={!this.hasSelectedDriver()}>
          Prework
        </button>
        <div className='driver-editor'>
          <p>{this.getPrompt()}</p>
          <div className='driver-list'>
            {this.getDriverList()}
          </div>
          <div className='driver-edit-panel'>
            {this.getDriverEditPanel()}
            <div className='driver-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveDriverButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectDriver('')}/>
        {this.getBulkActivationModal()}
        {this.getDriverHistoryModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    drivers: state.drivers.driverList,
    modalWindow: state.modalWindows,
    history: state.driversToBlocksHistory.driversToBlocksHistoryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addDriver(state));
    },
    update: (state) => {
      dispatch(editDriver(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems))
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateDrivers(ids));
    },
    bulkAddBlocksToHistory: (blockIds, driverId) => {
      dispatch(bulkAddBlocksToHistory(blockIds, driverId))
    },
    openDriverHistoryModal: (blockIds) => {
      dispatch(openDriverHistoryModal(blockIds))
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriversConfiguration);