import find from 'lodash/find';
import concat from 'lodash/concat';
import isNil from 'lodash/isNil';
import { startOfWorkDay } from '../utils/dateTimeHelpers';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import getHours from 'date-fns/get_hours';
import getMinutes from 'date-fns/get_minutes';

function getDateObjects(activity) {
  return {
    locationStartTime: new Date(activity.startTime),
    locationEndTime: new Date(activity.endTime)
  }
}

function getActivityInfo(locations, activity) {
  const targetLocation = find(locations, {id: activity.locationId});
  if (isNil(targetLocation)) {
    return { 
      locationId: activity.locationId,
      name: '',
      type: 'transit',
      comments: activity.comments,
      delay: activity.delay,
      secondDelay: activity.secondDelay,
      loaderId: '',
      hasLoadDelay: false,
      loadStart: null,
      loadEnd: null,
      errorCodeId : activity.errorCodeId,
      payOptions : activity.payOptions,
      secondErrorCodeId: activity.secondErrorCodeId,
      secondPayOption: activity.secondPayOption,
      sleeperDestinationId: '',
      reasonForAdjustment: activity.reasonForAdjustment
    }
  } else {
    const loadStart = activity.blockActivity? activity.blockActivity.loadStart: null;
    const loadEnd = activity.blockActivity? activity.blockActivity.loadEnd: null;
    return {
      locationId: targetLocation.id,
      name: targetLocation.name,
      type: targetLocation.type,
      comments: activity.comments,
      delay: activity.delay,
      secondDelay: activity.secondDelay,
      loaderId: activity.loaderId,
      hasLoadDelay: activity.hasLoadDelay,
      loadStart: loadStart,
      loadEnd: loadEnd,
      errorCodeId : activity.errorCodeId,
      payOptions : activity.payOptions,
      secondErrorCodeId: activity.secondErrorCodeId,
      secondPayOption: activity.secondPayOption,
      sleeperDestinationId: activity.sleeperDestinationId,
      reasonForAdjustment: activity.reasonForAdjustment,
      isThirdParty: activity.isThirdParty
    }
  }
}

function getCombinedLocations(storeState) {
  const locations = storeState.locations;
  const combined = concat(locations.destinations, locations.blocks, locations.yards);
  return combined;
}

function addTransitInfo(storeState, activities) {
  const currentDate = storeState.scenarioState.currentDateSelected;
  const startWork = startOfWorkDay(storeState.workHoursForSchedule.beginHour, currentDate);
  const withTransit = activities.map((activity, index) => {
    const start = activity.dateObjects.locationStartTime;
    const end = activity.dateObjects.locationEndTime;
    let travelTime = 0;
    let timeAtDestination = 0;
    if (index === 0) {
      travelTime = differenceInMinutes(start, startWork);
      timeAtDestination = differenceInMinutes(end, start);
    } else {
      const previous = activities[index-1];
      const previousEnd = previous.dateObjects.locationEndTime;
      travelTime = differenceInMinutes(start, previousEnd);
      timeAtDestination = differenceInMinutes(end, start);
    }
    activity.transit = {
      travelTime: travelTime,
      timeAtDestination: timeAtDestination
    }
    return activity;
  });
  return withTransit;
}

function createSchedule(storeState, activities) {
  const combinedLocations = getCombinedLocations(storeState);
  const updated = activities.map(activity => {
    const date = getDateObjects(activity);
    const info = getActivityInfo(combinedLocations, activity);
    return {
      id: activity.scenarioActivityId,
      dateObjects: date,
      ...info
    }
  });
  const updatedWithTransit = addTransitInfo(storeState, updated);
  return updatedWithTransit;
}

function getStartTime(scenarioTruck) {
  const start = scenarioTruck.startTime;
  return {
    startHour: getHours(start),
    startMinute: getMinutes(start)
  };
}

export function loadScheduleFormatter(storeState, action) {
  const schedules = action.payload.schedule;
  const formattedSchedule = schedules.map(schedule => {
    const scheduleActivities = createSchedule(storeState, schedule.scenarioActivities);
    return {
      scenarioTruckId: schedule.scenarioTruck.id,
      schedule: scheduleActivities,
      startTime: getStartTime(schedule.scenarioTruck)
    }
  });
  return formattedSchedule;
}