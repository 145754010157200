import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { closeSwapScheduleActivityModal } from '../../reducers/modalWindows';
import { swapActivity } from '../../reducers/schedule';
import { emptyUuid } from '../../utils/uuidHelper';
import { find } from 'lodash';
import { showLoading } from "../../reducers/loadingScreen";
import LoaderPicker from '../LoaderPicker';
import sortBy from 'lodash/sortBy';

class SwapScheduleActivityModal extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isModified: false,
      currentLocationId: props.modalProps.locationId,
      targetLocationId: '',
      currentDestinationIdForBlock: this.getCurrentDestinationAfterTargetBlock(),
      targetDestinationIdForBlock: '',
      targetLoaderIdForBlock: ''
    }
    
    ReactModal.setAppElement('#root');

    this.modifyState = this.modifyState.bind(this);
    this.handleChangeTargetActivity = this.handleChangeTargetActivity.bind(this);
    this.handleChangeTargetDestForBlock = this.handleChangeTargetDestForBlock.bind(this);
    this.handleChangeLoader = this.handleChangeLoader.bind(this);
    this.disableDoneButton = this.disableDoneButton.bind(this);
  }


  handleChangeTargetActivity(event) {
    this.setState({targetLocationId: event.target.value});
    this.modifyState();
  }


  handleChangeTargetDestForBlock(event) {
    this.setState({targetDestinationIdForBlock: event.target.value});
    this.modifyState();
  }


  handleChangeLoader(event) {
    this.setState({targetLoaderIdForBlock: event.target.value});
    this.modifyState();
  }

  modifyState() {
    this.setState({
      isModified: true
    });
  }

  getCurrentDestinationNameForBlockSwap() {
    if (this.props.modalProps.type === 'block') {
      const destination = find(this.props.destinations, {id: this.state.currentDestinationIdForBlock});
      return 'Current destination: '+destination.name;
    }
  }

  getLocationElements() {
    if (this.props.modalProps.type === 'block') {
      return this.getBlockElements();
    }
    else {
      return this.getDestinationElements()
    }
  }

  getBlockElements() {
    const none = <option key='blank' value={emptyUuid}>None</option>;
    const blockLoads = this.props.blockLoadScenario;
    const blockIds = [];
    const blocks = [];
    Object.keys(blockLoads).forEach(key => {
      blockIds.push(key);
    })
    blockIds.forEach(blockId => {
      const block = find(this.props.blocks, {id: blockId});
      if (block.id !== this.state.currentLocationId) {
        blocks.push(block);
      }
    });
    const blocksAfterCheck = this.checkIfTargetBlockHasTransitWithPreviousLocation(blocks);
    const blockOptions = blocksAfterCheck.map(block => {
      const id = block.id;
      return <option key={id} value={id}>{block.name}</option>
    });
    blockOptions.unshift(none);
    return blockOptions;
  }

  getDestinationElements() {
    if (this.props.modalProps.type === 'dryland') {
      const none = <option key='blank' value={emptyUuid}>None</option>;
      const destinationOptions = this.getDestinationOptions()
        .filter(destination => destination.active && !destination.isDeleted && destination.id !== this.props.modalProps.locationId)
        .map(destination => {
          const id = destination.id;
          return <option key={id} value={id}>{destination.name}</option>
      });
      destinationOptions.unshift(none);
      return destinationOptions;
    }
  }

  getDestinationOptions() {
    const scheduleRow = this.props.scheduleRowState
      .filter(scheduleRow => scheduleRow.rowId === this.props.modalProps.rowId).map(scheduleRow => scheduleRow.schedule);
    var previousBlock = {};
    var nextLocation = {}
    for (var i = 0; i < scheduleRow[0].length; i++) {
      if (scheduleRow[0][i].locationId === this.props.modalProps.locationId && scheduleRow[0][i].id === this.props.modalProps.scenarioActivityId) {
        previousBlock = scheduleRow[0][i - 2];
        nextLocation = scheduleRow[0][i + 2];
        break;     
      }
    }
    var nextBlock = {};
    if (nextLocation) {
      nextBlock = this.props.blocks.find(block => block.id === nextLocation.locationId);
    }
    let availableDestinations = []
    const previuosBlockLoads = this.getBlockLoads(previousBlock.locationId);
    previuosBlockLoads.forEach(element => {
      const destinationId = element.destination;
      const destination = find(this.props.destinations, {id: destinationId})
      availableDestinations.push(destination);
    });
    const filterList = []
    if (nextBlock) {
      const availableDestionationsToNextBlock = this.props.blocksToDestinations
        .filter(b2d => b2d.blockId === nextBlock.id)
        .map(b2d => b2d.destinationId);
      const destIdsInAvailableDests = availableDestinations.map(dest => dest.id);
      destIdsInAvailableDests.forEach(destId => {
        if (availableDestionationsToNextBlock.includes(destId)){  
        }
        else
        {
          filterList.push(destId);
        }
      });  
    }
    filterList.forEach(destId => {
      for(var i = availableDestinations.length - 1; i >= 0; i--) {
        if(availableDestinations[i].id === destId) {
          availableDestinations.splice(i, 1);
        }
      }
    });
    availableDestinations = new Set(availableDestinations);
    return Array.from(availableDestinations);
  }

  getCurrentDestinationAfterTargetBlock() {
    if (this.props.modalProps.type === 'block') {
      const scheduleRow = this.props.scheduleRowState
      .filter(scheduleRow => scheduleRow.rowId === this.props.modalProps.rowId).map(scheduleRow => scheduleRow.schedule);
      var nextLocation = {}
      for (var i = 0; i < scheduleRow[0].length; i++) {
        if (scheduleRow[0][i].locationId === this.props.modalProps.locationId && scheduleRow[0][i].id === this.props.modalProps.scenarioActivityId) {
          nextLocation = scheduleRow[0][i + 2];   
        }
      }
      if (nextLocation.type === 'dryland'){
        return nextLocation.locationId;
      }
      else {
        return '';
      }  
    }
    else {
      return '';
    }
  }

  getTargetLocationPicker() {
    let label = '';
    if (this.props.modalProps.type === 'dryland') {
      label = 'Destination';
    } else {
      label = 'Block';
    }
    return (
      <div>
        <div className='swap-modal-targetLocation-label'>
          <label>{label}</label>
        </div>
        <select className='location-picker' onChange={(event) => this.handleChangeTargetActivity(event)}
        value={this.state.targetLocationId}>
        {this.getLocationElements()}
        </select>
      </div>
    );
  }

  getTargetDestinationPickerForBlockSwap() {
     if (this.state.targetLoaderIdForBlock !== '' && this.props.modalProps.type === 'block') {
      return (
        <div className='swap-modal-destination-for-block-section'>
          <div>
            <label className='swap-modal-destination-for-block-label'>Destination</label>
          </div>
          <select className='destination-for-block-picker' onChange={(event) => this.handleChangeTargetDestForBlock(event)}
            value={this.state.targetDestinationIdForBlock}>
            {this.getDestinationsForBlock()}
          </select>
        </div>  
      );
    }  
  }

  getDestinationsForBlock() {

    // The logic is : target block should have block loads to destination and the location after destination should have transit definition to that destination as well.

    const none = <option key='blank' value={emptyUuid}>None</option>;
    const scheduleRow = this.props.scheduleRowState
    .filter(scheduleRow => scheduleRow.rowId === this.props.modalProps.rowId).map(scheduleRow => scheduleRow.schedule);
    var nextNextLocation = {};
    for (var i = 0; i < scheduleRow[0].length; i++) {
      if (scheduleRow[0][i].locationId === this.props.modalProps.locationId && scheduleRow[0][i].id === this.props.modalProps.scenarioActivityId) {
        nextNextLocation = scheduleRow[0][i + 4];
        break;     
      }
    }
    if (nextNextLocation.type === 'block') {
      const blockLoads = this.getBlockLoads(this.state.targetLocationId);
      var destinationsFromBlockLoads = []
      blockLoads.forEach(element => {
        const destinationId = element.destination;
        const destination = find(this.props.destinations, {id: destinationId});
        if (destination) {
          destinationsFromBlockLoads.push(destination);
        }
      });
      var common = [];
      destinationsFromBlockLoads.forEach(element => {
        const d2b = find(this.props.blocksToDestinations, {blockId: nextNextLocation.locationId, destinationId: element.id});
        if (d2b) {
          common.push(element);
        }
      });
      const options = common.map(dest => {
        const id = dest.id;
        return <option key={id} value={id}>{dest.name}</option>;
      });
      options.unshift(none);
      return options;
    }
    else if (nextNextLocation.type === 'yard') {
      const blockLoads = this.getBlockLoads(this.state.targetLocationId);
      var destinationsFromBlockLoads1 = []
      blockLoads.forEach(element => {
        const destinationId = element.destination;
        const destination = find(this.props.destinations, {id: destinationId});
        if (destination) {
          destinationsFromBlockLoads1.push(destination);
        }
      });
      var common1 = [];
      destinationsFromBlockLoads1.forEach(element => {
        const d2y = find(this.props.locationsToYards, {yardId: nextNextLocation.locationId, locationId: element.id});
        if (d2y) {
          common1.push(element);
        }
      });
      const options = common1.map(dest => {
        const id = dest.id;
        return <option key={id} value={id}>{dest.name}</option>;
      });
      options.unshift(none);
      return options;
    }
  }

  getTargetLoaderPickerForBlock() {
    if (this.state.targetLocationId !== '' && this.props.modalProps.type === 'block') {
      return <div className='swap-modal-loader-section'>
      <div>Loader</div>
        <LoaderPicker blockId={this.state.targetLocationId} 
          handleChangeLoader={this.handleChangeLoader}
        />
      </div>
    } else {
      return null;
    }
  }

  checkTargetBlockLoads(block, destinations) {
    const blockLoads = this.getBlockLoads(block.locationId);
    var destinationsFromBlockLoads = []
    blockLoads.forEach(element => {
      const destinationId = element.destination;
      const destination = find(this.props.destinations, {id: destinationId});
      if (destination) {
        destinationsFromBlockLoads.push(destination);
      }
    });
    const commonDestinationsForTwoBlocks = destinations.filter(x => destinationsFromBlockLoads.includes(x));
    return commonDestinationsForTwoBlocks;  
  } 

  checkIfTargetBlockHasTransitWithPreviousLocation(blocks) {
    const scheduleRow = this.props.scheduleRowState
      .filter(scheduleRow => scheduleRow.rowId === this.props.modalProps.rowId).map(scheduleRow => scheduleRow.schedule);
      var previousLocation = {};
      for (var i = 0; i < scheduleRow[0].length; i++) {
        if (scheduleRow[0][i].locationId === this.props.modalProps.locationId && scheduleRow[0][i].id === this.props.modalProps.scenarioActivityId) {
          previousLocation = scheduleRow[0][i - 2];
          break;     
        }
      }
      if (previousLocation.type === 'dryland') {
        var availableBlocks = [];
        blocks.forEach(element => {
          const b2d = find(this.props.blocksToDestinations, {blockId: element.id, destinationId: previousLocation.locationId});
          //const b2d = find(element.destinations, {name: previousLocation.name})
          if (b2d) {
            availableBlocks.unshift(element);
          }
        });
        return sortBy(availableBlocks, block => block.name);
      }
      else if (previousLocation.type === 'yard') {
        var availableBlocksAfterYard = [];
        blocks.forEach(element => {
          const l2y = find(this.props.locationsToYards, {locationId: element.id, yardId: previousLocation.locationId});
          if (l2y) {
            availableBlocksAfterYard.unshift(element);
          }
        });
        return sortBy(availableBlocksAfterYard, block => block.name);
      }
  }

  getBlockLoadScenario(blockId) {
    const blockLoadScenario = this.props.blockLoadScenario;
    const validBlockLoadScenario = blockLoadScenario ? blockLoadScenario: {};
    const targetBlock = validBlockLoadScenario[blockId];
    return targetBlock;
  }

  getBlockLoads(blockId) {
    const targetBlock = this.getBlockLoadScenario(blockId);
    const blockLoads = targetBlock ? targetBlock.blockLoads : [];
    return blockLoads;
  }

  saveChanges() {
    const payload = {
      ...this.props.modalProps,
      locationId: this.state.currentLocationId,
      targetLocationId: this.state.targetLocationId,
      currentDestinationIdForBlock: this.state.currentDestinationIdForBlock,
      targetDestinationIdForBlock: this.state.targetDestinationIdForBlock,
      targetLoaderIdForBlock: this.state.targetLoaderIdForBlock

    }
    if (this.state.isModified) {
      this.props.closeSwapScheduleActivityModal();
      this.props.showLoading();
      this.props.swapActivity(payload);
    }
    else {
      this.props.closeSwapScheduleActivityModal();
    }
  }

  disableDoneButton() {
    if ( this.props.modalProps.type === 'dryland') {
      if (this.state.targetLocationId === '') {
        return false;
      }
      else {
        return true;
      }
    } else if (this.props.modalProps.type === 'block') {
      if (this.state.targetLocationId === '' || this.state.targetLocationId === emptyUuid || this.state.targetDestinationIdForBlock === '' || this.state.targetDestinationIdForBlock === emptyUuid || this.state.targetLoaderIdForBlock === '' || this.state.targetLoaderIdForBlock === emptyUuid ) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  render() {
    const closeModal = () => {
      this.saveChanges();
    }

    const cancelAndClose = () => {
      this.props.closeSwapScheduleActivityModal();
    }
    const props = this.props.modalProps;
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          height: '250px',
          width: '350px',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={cancelAndClose}
      >
        <div className='swap-schedule-activity-modal'>
          <div className='display-name'>Current Location: {props.displayName}</div>
          <div>{this.getCurrentDestinationNameForBlockSwap()}</div>
          <br></br>
          <div className='target-location-picker-text'>Select Target Location</div>
          <div className='target-location-picker-section'>
            {this.getTargetLocationPicker()}
            {this.getTargetLoaderPickerForBlock()}
            {this.getTargetDestinationPickerForBlockSwap()}
          </div>
          <div className='swap-modal-buttons'>
            <button className='canel-button' onClick={cancelAndClose}>Cancel</button>
            <button className='done-button' disabled={!this.disableDoneButton()} onClick={closeModal}>Done</button>  
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => {
  const scenarioId = state.scenarioState.selectedScenario.id;
  return {
    destinations: state.locations.destinations,
    blocks: state.locations.blocks,
    scheduleRowState: state.scheduleRowState,
    blockLoadScenario: state.blockLoadState.byId[scenarioId],
    blocksToDestinations: state.locations.blocksToDestinations,
    locationsToYards: state.locations.locationsToYards
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeSwapScheduleActivityModal: () => {
      dispatch(closeSwapScheduleActivityModal());
    },
    swapActivity: (swapPayload) => {
      dispatch(swapActivity(swapPayload));
    },
    showLoading: () => {
      dispatch(showLoading());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwapScheduleActivityModal);