import axios from 'axios';
import store from '../store';
import { setTrucks } from '../reducers/trucks';
import { getAuthService } from '../services/AuthService';

class TruckService {
  static getTrucksApi() {
    return axios.get('/api/trucks', { headers: getAuthService().header });
  }

  static getTrucks() {
    return axios.all([this.getTrucksApi()])
      .then(axios.spread((response) => {
        const trucks = response.data.trucks;
        store.dispatch(setTrucks(trucks));
      }))
      .catch(error => console.log(error));
  }
}

export default TruckService;