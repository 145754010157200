import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import OperatingUnitPicker from '../OperatingUnitPicker';
import { connect } from 'react-redux';
import { emptyUuid } from '../../utils/uuidHelper';

class TruckFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.truckFieldProps? props.truckFieldProps: {};
    const defaultTruckId = fieldProps.truckId? fieldProps.truckId: '';
    const defaultLicensePlate = fieldProps.licensePlate? fieldProps.licensePlate: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultTruckCompanyId = fieldProps.truckCompanyId? fieldProps.truckCompanyId: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultDriverId = fieldProps.defaultDriver? fieldProps.defaultDriver: '';
    const defaultTrailerId = fieldProps.defaultTrailer? fieldProps.defaultTrailer: '';
    const defaultYear = fieldProps.year? fieldProps.year : '';
    const deafultRate = fieldProps.rate? fieldProps.rate : 0.0;
    const defaultIsCentralDispatch = fieldProps.isCentralDispatch? fieldProps.isCentralDispatch : false;

    this.state = {
      truckId: defaultTruckId,
      licensePlate: defaultLicensePlate,
      name: defaultName,
      truckCompanyId: defaultTruckCompanyId,
      isModified: false,
      active: defaultActive,
      comments: defaultComment,
      defaultDriverId: defaultDriverId,
      defaultTrailerId: defaultTrailerId,
      year: defaultYear,
      rate: deafultRate,
      isCentralDispatch: defaultIsCentralDispatch
    }

    this.changeTruckName = this.changeTruckName.bind(this);
    this.changeLicensePlate = this.changeLicensePlate.bind(this);
    this.handleChangeTruckCompany = this.handleChangeTruckCompany.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.handleChangeOperatingUnit = this.handleChangeOperatingUnit.bind(this);
    this.handleChangeDriver = this.handleChangeDriver.bind(this);
    this.handleChangeTrailer = this.handleChangeTrailer.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleChangeRate = this.handleChangeRate.bind(this);
    this.handleChangeIsCentralDispatch = this.handleChangeIsCentralDispatch.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  handleChangeDriver(event) {
    this.setState({defaultDriverId: event.target.value});
    this.setModified();
  }

  handleChangeTrailer(event) {
    this.setState({defaultTrailerId: event.target.value});
    this.setModified();
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeTruckName(event) {
    this.setState({name: event.target.value});
    this.setModified();
  }

  changeLicensePlate(event) {
    this.setState({licensePlate: event.target.value});
    this.setModified();
  }

  handleChangeTruckCompany(event) {
    this.setState({truckCompanyId: event.target.value});
    this.setModified();
  }

  handleChangeYear(event) {
    this.setState({year: event.target.value});
    this.setModified();
  }

  handleChangeRate(event) {
    const validInput = event.target.value === '' ? 0 : event.target.value;
    this.setState({rate: validInput});
    this.setModified();
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  handleChangeOperatingUnit(operatingUnitId, setModified = false) {
    this.setState({operatingUnitId: operatingUnitId});
    if (setModified) {
      this.setModified();
    }
  }

  handleChangeIsCentralDispatch(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.setState({isCentralDispatch: true});
      this.setModified();
    }
    else {
      this.setState({isCentralDispatch: false});
      this.setModified();
    }
  }

  getDriverElements() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeDrivers = this.props.drivers.filter(driver => driver.active && !driver.isDeleted);
    const driverOptions = activeDrivers.map(driver => {
      const id = driver.id;
      return <option key={id} value={id}> {driver.name} </option>;
    });
    driverOptions.unshift(noSelection);
    return driverOptions;
  }

  getDriverPicker() {
    return (
      <select className='driver-picker' value={this.state.defaultDriverId} onChange={this.handleChangeDriver}>
        {this.getDriverElements()}
      </select>
    );
  }

  getTruckCompanyPicker() {
    return (
      <select className='truckCompany-picker' value={this.state.truckCompanyId} onChange={this.handleChangeTruckCompany}>
        {this.getTruckCompanyElements()}
      </select>
    )
  }

  getTruckCompanyElements() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeTruckCompanies = this.props.truckCompanies.filter(x => x.active && !x.isDeleted);
    const options = activeTruckCompanies.map(x => {
      const id = x.id;
      return <option key={id} value={id}> {x.name} </option>;
    });
    options.unshift(noSelection);
    return options;
  }

  getTrailerElements() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const trailerOptions = this.props.trailers.map(trailer => {
      const id = trailer.id;
      return <option key={id} value={id}> {trailer.trailerName} </option>;
    });
    trailerOptions.unshift(noSelection);
    return trailerOptions;
  }

  getTrailerPicker() {
    return (
      <select className='trailer-picker' value={this.state.defaultTrailerId}  onChange={this.handleChangeTrailer}>
        {this.getTrailerElements()}
      </select>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field truck-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeTruckName} />
        </div>
        <div className='license-plate-field truck-field'>
          <div>License Plate</div>
          <input type='text' value={this.state.licensePlate} onChange={this.changeLicensePlate} />
        </div>
        <div className='default-driver truck-field'>
            <div>Truck Company</div>
            {this.getTruckCompanyPicker()}
          </div>
        <OperatingUnitPicker objectId={this.state.truckId}
          onChangeOperatingUnit={this.handleChangeOperatingUnit} />
        <div className='year-filed truck-field'>
          <div>Year</div>
          <input type='text' value={this.state.year} onChange={this.handleChangeYear} />
        </div>
        <div className='rate-field truck-field'>
          <div>Rate</div>  
          <input type='number' value={this.state.rate} onChange={this.handleChangeRate} />
        </div>
        <div className='isCentralDispatch-field truck-field'>
          <div>Central Dispatch</div>
          <input type='checkbox' onChange={(e) => this.handleChangeIsCentralDispatch(e)}
            checked={this.state.isCentralDispatch}
          />
        </div>
        <div className='default-truck-editor'>
          <p>Edit Default Values</p>
          <div className='default-driver truck-field'>
            <div>Default Driver</div>
            {this.getDriverPicker()}
          </div>
          <div className='default-trailer truck-field'>
            <div>Default Trailer</div>
            {this.getTrailerPicker()}
          </div>
        </div>
        <ConfigComment key={this.state.truckId} 
          top={320}
          left={525}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        <div className='truck-field-edit-buttons'>
          <button className='cancel-button' onClick={this.clickCancel}>Cancel</button>
          <button className='done-button' onClick={this.clickDone} disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    drivers: state.drivers.driverList,
    trailers: state.trailers.trailerList,
    truckCompanies: state.truckCompanies.truckCompaniesList
  }
}

export default connect(mapStateToProps)(TruckFieldEditor);