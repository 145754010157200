import axios from 'axios';
import store from '../store';
import { setOperatingUnits } from '../reducers/operatingUnits';
import { getAuthService } from '../services/AuthService';


class OperatingUnitListService {
  static getOperatingUnitListApi(userOperatingUnitIdsList) {
    return axios.get(`/api/operatingUnits/GetUserOperatingUnits?ids=${userOperatingUnitIdsList}`, { headers: getAuthService().header })
  }

  static getUserOperatingUnits(userOperatingUnitIdsList) {
    return this.getOperatingUnitListApi(userOperatingUnitIdsList)
      .then((response) => {
        const data = response.data;
        store.dispatch(setOperatingUnits(data));
      })
      .catch(error => console.log(error));
  }
}

export default OperatingUnitListService;