import find from 'lodash/find';

function getCurrentScenario(storeState) {
  return storeState.scenarioState.selectedScenario.id;
}

function getScheduleRowState(storeState, action) {
  const targetRow = find(storeState.scheduleRowState, {rowId: action.payload.rowId});
  return targetRow;
}

function getDateObjects(scheduleRow, activityId) {
  const targetSchedule = find(scheduleRow.schedule, {id: activityId});
  return targetSchedule.dateObjects;
}

function getLoaderId(action) {
  const loaderId = action.payload.loaderId;
  if (action.payload.loaderId) {
    return loaderId;
  } else {
    return '';
  }
}

export function saveScheduleFormatter(storeState, action) {
  const targetRow = getScheduleRowState(storeState, action);
  const scenarioId = getCurrentScenario(storeState);
  const scenarioTruckId = targetRow.scenarioTruck.scenarioTruckId;
  const locationId = action.payload.locationId;;
  const dateObjects = getDateObjects(targetRow, action.payload.id);
  const startTime = dateObjects.locationStartTime;
  const endTime = dateObjects.locationEndTime;
  const scenarioActivityId = action.payload.scenarioActivityId;
  const loaderId = getLoaderId(action);
  const errorCodeId = action.payload.errorCodeId;
  const payOptions = action.payload.payOptions;
  const secondErrorCodeId = action.payload.secondErrorCodeId;
  const secondPayOption = action.payload.secondPayOption;
  const targetLocationId = action.payload.targetLocationId;
  const currentDestinationForBlock = action.payload.currentDestinationIdForBlock;
  const targetDestinationIdForBlock = action.payload.targetDestinationIdForBlock;
  const targetLoaderIdForBlock = action.payload.targetLoaderIdForBlock;
  const activityType = action.payload.type;
  return {
    model: {
      scenarioActivityId: scenarioActivityId,
      scenarioId: scenarioId,
      scheduleRowId: targetRow.rowId,
      scenarioTruckId: scenarioTruckId,
      locationId: locationId,
      startTime: startTime,
      endTime: endTime,
      comments: action.payload.comments,
      delay: action.payload.delay,
      secondDelay: action.payload.secondDelay,
      loaderId: loaderId,
      hasLoadDelay: action.payload.hasLoadDelay,
      errorCodeId : errorCodeId,
      payOptions : payOptions,
      secondErrorCodeId: secondErrorCodeId,
      secondPayOption: secondPayOption,
      sleeperDestinationId: action.payload.sleeperDestinationId,
      sleeperOriginBlockId: action.payload.sleeperOriginBlockId,
      targetLocationId: targetLocationId,
      currentDestinationForBlock: currentDestinationForBlock,
      targetDestinationIdForBlock: targetDestinationIdForBlock,
      targetLoaderIdForBlock: targetLoaderIdForBlock,
      reasonForAdjustment: action.payload.reasonForAdjustment,
      isThirdParty: action.payload.isThirdParty,
      activityType: activityType
    },
    meta: {
      instanceId: action.payload.id,
      rowId: action.payload.rowId
    }
  }
}