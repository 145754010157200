import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addVendorSuccess, updateVendorSuccess, removeVedorSuccess, ADD_VENDOR, UPDATE_VENDOR, REMOVE_VENDOR, BULK_ACTIVATE_VENDORS, setVendors } from '../../reducers/vendors';
import { getAuthService } from '../AuthService';

const addVendorEpic = action$ => (
  action$.ofType(ADD_VENDOR)
    .mergeMap(action => (
      ajax.post(`/api/vendors/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addVendorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    )
      
    ));

const updateVendorEpic = action$ => (
  action$.ofType(UPDATE_VENDOR)
    .mergeMap(action => (
      ajax.put(`/api/vendors/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateVendorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeVendorEpic = action$ => (
  action$.ofType(REMOVE_VENDOR)
    .mergeMap(action => (
      ajax.delete(`/api/vendors/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeVedorSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const bulkActivateVendorEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_VENDORS)
      .mergeMap(action => (
          ajax.post('/api/vendors/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setVendors(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const VendorEpics = combineEpics(
  addVendorEpic,
  updateVendorEpic,
  removeVendorEpic,
  bulkActivateVendorEpic
);