// calculate how many pixels there are between beginning hour of the schedule until the start time
export function getPixelPosition(beginHourSchedule, startTime) {
  const translatedStartTime = getStartTimeMinutes(beginHourSchedule, startTime);
  const pixelPerMinute = 2;
  return translatedStartTime * pixelPerMinute;
}

// calculate number of minutes from the beginning hour of the schedule until the start time
export function getStartTimeMinutes(beginHourSchedule, startTime) {
  const {startHour, startMinute} = startTime;
  return ((startHour - beginHourSchedule) * 60 + startMinute)
}