import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import ContractorFieldEditor from '../../components/fieldEditors/ContractorFieldEditor';
import uniqid from 'uniqid';
import { addContractor, updateContractor, bulkActivateContractors } from '../../reducers/contractors';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { CONTRACTOR } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class ContractorsConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractorIdSelected: '',
      isAddingNewContractor: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousContractors = previousProps.contractors.map(contractor => contractor.id);
    const currentContractors = this.props.contractors.map(contractor => contractor.id);
    if (currentContractors.length > previousContractors.length) {
      const diff = difference(currentContractors, previousContractors);
      if (diff.length > 0) {
        const newContractor = diff[0];
        this.setState({
          contractorIdSelected: newContractor,
          isAddingNewContractor: false
        });
      }
    }
  }

  addNewContractor() {
    this.setState({
      contractorIdSelected: '',
      isAddingNewContractor: true
    });
  }

  selectContractor(id) {
    this.setState({
      contractorIdSelected: id,
      isAddingNewContractor: false
    });
  }

  getSelectedContractor() {
    const contractor = find(this.props.contractors, {id: this.state.contractorIdSelected});
    return contractor;
  }

  getSelectedContractorName() {
    const contractor = this.getSelectedContractor();
    if (contractor) {
      return contractor.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedContractor() {
    return this.state.contractorIdSelected !== '';
  }

  getContractorsList() {
    const options = this.props.contractors;
    const sortedOptions = sortBy(options, faller => faller.name)
    .filter(contractor => !contractor.isDeleted)
    .map(contractor => {
      return {
        value: contractor.id,
        label: contractor.name
      }
    });

    const handleChange = (selectedOption) => this.selectContractor(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedContractorName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const contractor = this.getSelectedContractor();
    if (contractor) {
      return `Edit contractor ${contractor.name}`;
    } else if (this.state.isAddingNewContractor) {
      return 'Add a new contractor';
    } else {
      return 'Select a contractor to edit';
    }
  }

  checkExistence(state) {
    const contractor = find(this.props.contractors, {name: state.name});
    if (contractor) {
      this.props.openDuplicateObjectNamesModal(contractor);
    }
    else {
      if (this.state.isAddingNewContractor === true) {
        this.props.add(state);
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }


  getContractorEditPanel() {
    if (this.hasSelectedContractor() && !this.state.isAddingNewContractor) {
      return (
        <div>
          <ContractorFieldEditor
            key={uniqid()}
            contractorFieldProps={this.getSelectedContractor()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectContractor('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewContractor) {
      return (
        <div>
          <ContractorFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectContractor('')}
          />
        </div>
      );
    }
  }

  activate() {
    const contractor = this.getSelectedContractor();
    if (contractor) {
      contractor.active = !contractor.active;
      this.props.update(contractor);
    }
  }

  getActivateButton() {
    const contractor = this.getSelectedContractor();
    if (contractor) {
      const buttonName = contractor.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const contractorId = this.state.contractorIdSelected;
    if (contractorId !== '') {
      return (
        <button className='remove-contractor'
          onClick={() => this.props.removeContractorModal(contractorId)}>
          Remove Contractor
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedContractorsWithId = this.props.contractors
      .filter(contractor => !contractor.isDeleted)
      .map(contractor => {
        return {
          ...contractor,
          id: contractor.id,
          name: contractor.name
        }
      });
      return <BulkActivationModal
        type={CONTRACTOR}
        activationItems={updatedContractorsWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='contractor-configuration configuration-forms'>
        <button className='add-new-contractor' onClick={() => this.addNewContractor()}>Add New Contractor</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='contractor-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='contractor-list'>
          {this.getContractorsList()}
        </div>
        <div className='contractor-edit-panel'>
          {this.getContractorEditPanel()}
          <div className='contractor-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectContractor('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    contractors: state.contractors.contractorsList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addContractor(state));
    },
    update: (state) => {
      dispatch(updateContractor(state));
    },
    removeContractorModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateContractors(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ContractorsConfiguration);