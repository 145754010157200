import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addHammerMarkSuccess, updateHammerMarkSuccess, removeHammerMarkSuccess, ADD_HAMMERMARK, UPDATE_HAMMERMARK, REMOVE_HAMMERMARK, BULK_ACTIVATE_HAMMERMARKS, setHammerMarks } from '../../reducers/hammerMarks';
import { getAuthService } from '../AuthService';



const addHammerMarkEpic = action$ => (
  action$.ofType(ADD_HAMMERMARK)
    .mergeMap(action => (
      ajax.post('/api/hammerMarks', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addHammerMarkSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateHammerMarkEpic = action$ => (
  action$.ofType(UPDATE_HAMMERMARK)
    .mergeMap(action => (
      ajax.put(`/api/hammerMarks/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateHammerMarkSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeHammerMarkEpic = action$ => (
  action$.ofType(REMOVE_HAMMERMARK)
    .mergeMap(action => (
      ajax.delete(`/api/hammerMarks/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeHammerMarkSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);


export const bulkActivateHammerMarkEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_HAMMERMARKS)
      .mergeMap(action => (
          ajax.post('/api/hammerMarks/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setHammerMarks(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const HammerMarkEpics = combineEpics(
  addHammerMarkEpic,
  updateHammerMarkEpic,
  removeHammerMarkEpic,
  bulkActivateHammerMarkEpic
);