import { setMarks } from '../reducers/paintMarks';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class PaintMarkService {
  static getMarksApi() {
    return axios.get('/api/paintMarks', { headers: getAuthService().header });
  }

  static getMarks() {
    return this.getMarksApi()
      .then((paintMarks) => {
        store.dispatch(setMarks(paintMarks.data))
      })
      .catch(error => console.log(error));
  }
}

export default PaintMarkService;