import sortBy from 'lodash/sortBy';


const SET_DRIVERS = 'SET_DRIVERS';
export const ADD_DRIVER = 'ADD_DRIVER';
export const REMOVE_DRIVER = 'REMOVE_DRIVER';
export const EDIT_DRIVER = 'EDIT_DRIVER';
export const BULK_ACTIVATE_DRIVERS ='BULK_ACTIVATE_DRIVERS';

export function addDriver(driver) {
  return {
    type: ADD_DRIVER,
    payload: driver
  }
}

export function editDriver(driver) {
  return {
    type: EDIT_DRIVER,
    payload: driver
  }
}

export function removeDriver(driverId) {
  return {
    type: REMOVE_DRIVER,
    payload: driverId
  }
}

export function bulkActivateDrivers(driverIds) {
  return {
    type: BULK_ACTIVATE_DRIVERS,
    payload: driverIds
  }
}
export function setDrivers(drivers) {
  return {
    type: SET_DRIVERS,
    payload: drivers
  }
}



export default function drivers(state = {
  driverList: []  
}, action) {
  switch (action.type) {
    case SET_DRIVERS:
      return _setDrivers(state, action);
    default:
      return state;
  }
}

function _setDrivers(state, action) {
  const sortedDrivers = sortBy(action.payload, driver => driver.name);
  return {
    ...state,
    driverList: sortedDrivers
  }
}

