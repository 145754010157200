import React from 'react';
import { connect } from 'react-redux';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import { startOfWorkDay, getStartTime, getEndTime } from '../../utils/dateTimeHelpers';

class DestinationActivity extends React.Component {

  clickActivity() {
    this.props.setSelectedActivity(this.props.activity.id);
  }

  isSelected() {
    return this.props.activity.id === this.props.selectedActivityId;
  }

  getBackgroundStyle() {
    if (this.isSelected()) {
      return '#ffe970';
    }
  }

  getHeightValue(destination) {
    return destination.unloadTime * 2;
  }

  numberOfMinutesSinceWorkStart(activityStartDate) {
    const startWork = startOfWorkDay(this.props.workHours.beginHour, this.props.scenarioDate);
    const diff = differenceInMinutes(activityStartDate, startWork);
    return diff;
  }

  getActivityDetails(activity) {
    if (this.isSelected()) {
      return (
        <div className='destination-activity-details'>
          <div>{activity.truckName}</div>
          <br/>
          <div>Start of activity: {getStartTime(activity)}</div>
          <div>End of activity: {getEndTime(activity)}</div>
          <br/>
          <div>Delay: {activity.delay}</div>
        </div>
      );
    }
  }

  getTruckName(activity) {
    return (
      <div style={this.blockNameStyle}>{activity.truckName}</div>
    )
  }

  blockNameStyle = {
    fontSize: '10px'
  }

  render() {
    const activity = this.props.activity;
    //const destination = this.props.destination;
    //const heightValue = this.getHeightValue(destination);
    const heightValue = 10;
    const minutesSinceStart = this.numberOfMinutesSinceWorkStart(activity.dateObjects.locationStartTime);
    return <div className='destination-activity' key={activity.id}
      style={{
        border: `2px solid black`,
        marginTop: minutesSinceStart * 2,
        height: heightValue,
        background: this.getBackgroundStyle()
      }}
      onClick={() => this.clickActivity()}>
        {/* {activity.truckName}  */}
        {this.getTruckName(activity)}
        {this.getActivityDetails(activity)}
    </div>
  }
}


const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  return {
    scenarioDate: selectedScenario.date,
    workHours: state.workHoursForSchedule
  }
}

export default connect(mapStateToProps)(DestinationActivity);
