import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { openBlockModal, openSendPdfConfirmationModal } from '../../reducers/modalWindows';
import PdfService from '../../services/pdfService';
import PublishService from '../../services/publishService';
import { BLOCK } from '../../constants/typeConstants';
import { isViewerRole } from '../../utils/userHelper';

class BlockContextMenu extends React.Component {

  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.openBlockPdfPreview = this.openBlockPdfPreview.bind(this);
    this.openConfirmationDialog = this.openConfirmationDialog.bind(this);
    this.openBlockPdfDownload = this.openBlockPdfDownload.bind(this);
  }

  showModal(event, data) {
    event.preventDefault();
    this.props.openBlockModal(this.props.id, this.props.name);
  }

  getContextIds() {
    return 'context-menu' + this.props.id;
  }

  openBlockPdfPreview() {
    PdfService.previewBlockPdf(this.props.scenarioId, this.props.id);
  }

  openBlockPdfDownload() {
    PdfService.downloadBlockPdf(this.props.scenarioId, this.props.id);
  }

  getBlockLoadName() {
    const displayName = this.props.name;
    if (displayName) {
      return <MenuItem attributes={{className:'context-menu-title'}}>{displayName}</MenuItem>;
    } else {
      return null;
    }
  }

  openConfirmationDialog() {
    const sendAction = () => PublishService.sendBlockEmail(this.props.scenarioId, this.props.id, this.props.user);
    const props = {
      scenarioId: this.props.scenarioId,
      id: this.props.id,
      type: BLOCK,
      name: this.props.name,
      sendAction: sendAction,
      user: this.props.user
    }
    this.props.openConfirmation(props);
  }

  render() {
    return (
      <div className="block-context-menu">
        <ContextMenuTrigger id={this.getContextIds()} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>

        <ContextMenu id={this.getContextIds()}>
          {this.getBlockLoadName()}
          <MenuItem onClick={this.showModal}
                    disabled={this.props.isPublished || isViewerRole()}>Load Inventory</MenuItem>
          <MenuItem onClick={this.openBlockPdfPreview}>Preview Block Schedule PDF</MenuItem>
          <MenuItem onClick={this.openBlockPdfDownload}>Download Block Schedule PDF</MenuItem>
          <MenuItem onClick={this.openConfirmationDialog} disabled={isViewerRole()}>Send Block Schedule Email</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    isPublished: state.scenarioState.selectedScenario.published,
    user: state.users.user.profile
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    openBlockModal: (blockId, name) => {
      dispatch(openBlockModal(blockId, name));
    },
    openConfirmation: (props) => {
      dispatch(openSendPdfConfirmationModal(props));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockContextMenu);