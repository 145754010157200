import { fetchScenarioTrucks } from '../reducers/schedule';
import { DELETE_SCENARIO, ASSIGN_NEW_SCENARIO, SELECT_SCENARIO } from '../reducers/scenarios';

export const fetchScheduleOnScenarioChange = store => next => action => {
  next(action);

  if (action.type === DELETE_SCENARIO ||
      action.type === ASSIGN_NEW_SCENARIO ||
      action.type === SELECT_SCENARIO) {
    
    const scenario = store.getState().scenarioState.selectedScenario;
    const scenarioId = scenario? scenario.id: null;
    
    if (scenarioId != null) {
      store.dispatch(fetchScenarioTrucks(scenarioId));
    }
  }
}
