import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { openScenarioTruckModal, openScenarioTruckShareModal } from '../../reducers/modalWindows';
import { isViewerRole } from '../../utils/userHelper';
import { authorizeScenarioTruck } from '../../reducers/schedule';
import PublishService from '../../services/publishService';

class ScenarioTruckContextMenu extends React.Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.showTruckShareModal = this.showTruckShareModal.bind(this);
    this.authorizeScenarioTruck = this.authorizeScenarioTruck.bind(this);
    this.sendTimeCard = this.sendTimeCard.bind(this);
    this.state = {
      isMenuDisplayed: false
    }

    this.blankStyle = {}
  }

  toggleMenuDisplay() {
    this.setState({isMenuDisplayed: !this.state.isMenuDisplayed});
  }

  showModal(event) {
    event.preventDefault();

    const name = this.props.name;
    this.props.openScenarioTruckModal(name);
  }

  showTruckShareModal(event) {
    event.preventDefault();

    const name = this.props.name;
    this.props.openScenarioTruckShareModal(name);
  }

  getZIndexStyle() {
    if (this.state.isMenuDisplayed) {
      return {
        zIndex: 20
      };
    } 
  }

  isInTruckShares(scenarioTruckId) {
    scenarioTruckId = this.props.scenarioTruck.scenarioTruckId;
    const sharedIds = this.props.truckShares.map(share => share.scenarioTruckId);
    return sharedIds.includes(scenarioTruckId);
  }

  authorizeScenarioTruck() {
    this.props.authorizeScenarioTruck(this.props.scenarioTruck.scenarioTruckId);
  }

  sendTimeCard() {
    PublishService.sendTruckDriverTimeCard(this.props.scenarioTruck.scenarioId, this.props.scenarioTruck.scenarioTruckId, this.props.user);
  }
 
  render() {
    const menuId = this.props.isShared? (this.props.id+'shared') : this.props.id;
    return (
      <div className='scenario-truck-context-menu' style={this.getZIndexStyle()}>
        <ContextMenuTrigger id={menuId} holdToDisplay={-1}>
          {this.props.children}
        </ContextMenuTrigger>

        <ContextMenu id={menuId} onShow={() => this.toggleMenuDisplay()} onHide={() => this.toggleMenuDisplay()}>
          <MenuItem attributes={{className:'context-menu-title'}}>{this.props.name}</MenuItem>
          <MenuItem onClick={this.showModal}
                    disabled={(this.props.isPublished || isViewerRole() || this.props.isClosed ) || (this.props.isPublished || (!this.props.isShared && this.isInTruckShares() ))}>Edit Truck</MenuItem>
          <MenuItem onClick={this.showTruckShareModal}
                    disabled={(this.props.isPublished || isViewerRole() || this.props.isClosed ) || (this.props.isPublished || (!this.props.isShared && this.isInTruckShares() ))}>Share Truck</MenuItem>
          <MenuItem onClick={(this.authorizeScenarioTruck)}
                    disabled={!this.props.isClosed || isViewerRole()}>Authorize</MenuItem>
          <MenuItem onClick={this.sendTimeCard} disabled={isViewerRole()}>Send Time Card</MenuItem>
        </ContextMenu>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    truckShares: state.truckShares.truckSharesList,
    isPublished: state.scenarioState.selectedScenario.published || 
                 state.scenarioState.selectedScenario.wasPublished,
    isClosed: state.scenarioState.selectedScenario.isClosed,
    user: state.users.user.profile
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    openScenarioTruckShareModal: (name) => {
      dispatch(openScenarioTruckShareModal(
        props.scenarioTruck.truckId,
        props.scenarioTruck.scenarioTruckId,
        props.scenarioTruck,
        name,
        true));
    },
    openScenarioTruckModal: (name) => {
      dispatch(openScenarioTruckModal(
        props.scenarioTruck.truckId, 
        name, 
        false, 
        props.scenarioTruck, 
        props.startTime,
        props.workDayMinutes,
        props.scenarioTruck.comments));
    },
    authorizeScenarioTruck: (scenarioTruckId) => {
      dispatch(authorizeScenarioTruck(scenarioTruckId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTruckContextMenu);