import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { BLOCK, DESTINATION, YARD, TRUCK, PAINTMARK } from '../../constants/typeConstants';

class BulkActivationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModified: false,
      selectedItems: this.getSelectedItems(props.activationItems)
    }

    ReactModal.setAppElement('#root');

    this.modifyState = this.modifyState.bind(this);
    this.handleCheckItem = this.handleCheckItem.bind(this);
  }

  getSelectedItems(activationItems) {
    return activationItems.filter(item => item.active).map(item => item.id);
  }

  modifyState() {
    this.setState({isModified: true});
  }

  handleCheckItem(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let updatedCheckedItems = [];
    if (isChecked) {
      updatedCheckedItems = [...this.state.selectedItems, id];
    } else {
      updatedCheckedItems = this.state.selectedItems.filter(selectId => selectId !== id);
    }

    this.setState({
      selectedItems: updatedCheckedItems
    });
    this.modifyState();
  }

  getBulkActivationRow(item) {
    return (
      <div className='bulk-activation-row' key={item.id}>
        <input className='activation-item-checkbox' type='checkbox'
          value={item.id}
          checked={this.state.selectedItems.includes(item.id)}
          onChange={this.handleCheckItem} />
  <label>{item.name}{item.code}</label>
      </div>
    );
  }

  getActivationItems() {
    const operatingUnitGroupType = [BLOCK, DESTINATION, YARD, TRUCK, PAINTMARK];
    const isGrouped = operatingUnitGroupType.includes(this.props.type);
    
    if (isGrouped) {
      return this.props.operatingUnits
      .filter(operatingUnit => !operatingUnit.isDeleted)
      .map(opUnit => {
        const targetConfigs = this.props.operatingUnitAssignment
          .filter(assign => assign.operatingUnitId === opUnit.id)
          .map(assign => assign.configObjectId);
        const configObjectSet = new Set(targetConfigs);
        const activationItems = this.props.activationItems.filter(item => configObjectSet.has(item.id));
  
        const checkboxes = activationItems.map(item => this.getBulkActivationRow(item));

        return <div key={opUnit.id}>
          <p>{opUnit.name}</p>
          {checkboxes}
        </div>
      });
    } else {
      return this.props.activationItems.map(item => this.getBulkActivationRow(item));
    }
    
  }

  clickDone() {
    this.props.closeModal();
    if (this.state.isModified) {
      this.props.onClickDone(this.state.selectedItems);
    }
  }

  getModal() {
    const closeModal = () => {
      this.props.closeModal();
    }
    return <ReactModal
        isOpen={true}
        onRequestClose={closeModal}>
        <div className='bulk-activation-modal'>
          <p>Select objects to activate</p>
          <div className='bulk-items-list'>
            {this.getActivationItems()}
          </div>
          <button className='bulk-activation-cancel-button'
            onClick={() => closeModal()}>Cancel</button>
          <button className='bulk-activation-done-button'
            onClick={() => this.clickDone()}>Done</button>
        </div>
    </ReactModal>
  }

  render() {
    return this.getModal();
  }
}

const mapStateToProps = (state) => {
  return {
    operatingUnitAssignment: state.operatingUnits.operatingUnitAssignment,
    operatingUnits: state.operatingUnits.operatingUnitList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkActivationModal);