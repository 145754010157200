import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { PUBLISH_SCENARIO_MODAL } from '../../constants/modalTypes';
import PublishService from '../../services/publishService';

class PublishScenarioModal extends React.Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#root');
  }

  handlePublish(isPublishing, scenarioId, user) {
    this.handleClose();
    if (isPublishing) {
      PublishService.publish(scenarioId, user);
    } else {
      PublishService.unpublish(scenarioId, user);
    }
  }

  handleClose() {
    this.props.closeModal();
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === PUBLISH_SCENARIO_MODAL && modalWindow.isModalOpen) {
      const scenarioId = modalWindow.scenarioId;
      const publishLabel = modalWindow.isPublishing? 'Publish': 'Unpublish';
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '90px',
            width: '290px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.handleClose()}>
            <div className='publish-scenario-modal'>
              <p>Are you sure you want to {publishLabel}?</p>
              <div className='button-container'>
                <button className='cancel-button' onClick={() => this.handleClose()}>Cancel</button>
                <button className='publish-button' onClick={() => this.handlePublish(modalWindow.isPublishing, scenarioId, modalWindow.user)}>{publishLabel}</button>
              </div>
            </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishScenarioModal);