const SET_VENDORS = 'SET_VENDORS';
export const ADD_VENDOR = 'ADD_VENDOR';
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const REMOVE_VENDOR = 'REMOVE_VENDOR';
export const REMOVE_VENDOR_SUCCESS = 'REMOVE_VENDOR_SUCCESS';
export const BULK_ACTIVATE_VENDORS = 'BULK_ACTIVATE_VENDORS';


export function setVendors(vendors) {
  return {
    type: SET_VENDORS,
    payload: vendors
  }
}

export function addVendor(vendor) {
  return {
    type: ADD_VENDOR,
    payload: vendor
  }
}

export function addVendorSuccess(vendor) {
  return {
    type: ADD_VENDOR_SUCCESS,
    payload: vendor
  }
}

export function updateVendor(vendor) {
  return {
    type: UPDATE_VENDOR,
    payload: vendor
  }
}

export function updateVendorSuccess(vendor) {
  return {
    type: UPDATE_VENDOR_SUCCESS,
    payload: vendor
  }
}

export function removeVendor(vendorId) {
  return {
    type: REMOVE_VENDOR,
    payload: vendorId
  }
} 

export function removeVedorSuccess(vendor) {
  return {
    type: REMOVE_VENDOR_SUCCESS,
    payload: vendor
  }
}


export function bulkActivateVendors(vendorIds) {
  return {
    type: BULK_ACTIVATE_VENDORS,
    payload: vendorIds
  }
}

export default function vendors(state = {
  vendorsList: []
}, action) {
  switch(action.type) {
    case SET_VENDORS:
      return _setVendors(state, action);
    case ADD_VENDOR_SUCCESS:
      return _addVendor(state, action);
    case UPDATE_VENDOR_SUCCESS:
      return _updateVendor(state, action);
    case REMOVE_VENDOR_SUCCESS:
      return _removeVendor(state, action);
    default:
      return state;
  }
}

function _setVendors(state, action) {
  return {
    ...state,
    vendorsList: action.payload
  }
}

function _addVendor(state, action) {
  const vendors = [...state.vendorsList, action.payload];
  return {
    ...state,
    vendorsList: vendors
  }
}

function _updateVendor(state, action) {
  const updatedVendor = action.payload;
  const updatedList = state.vendorsList.map(vendor => {
    if (vendor.id === updatedVendor.id) {
      return updatedVendor;
    } else {
      return vendor;
    }
  });
  return {
    ...state,
    vendorsList: updatedList
  }
}


function _removeVendor(state, action) {
  const updatedList = state.vendorsList
  return {
    ...state,
    vendorsList: updatedList
  }
}


