import React from 'react';
import { connect } from 'react-redux';
import { openBulkActivationModal, openRemoveObjectModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import find from 'lodash/find';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import Select from 'react-select';
import OperatingUnitFieldEditor from '../../components/fieldEditors/OperatingUnitFieldEditor';
import uniqid from 'uniqid';
import { addOperatingUnit, updateOperatingUnit } from '../../reducers/operatingUnits';
import { DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class OperatingUnitConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      operatingUnitSelected: '',
      isAddingNewOperatingUnit: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousUnits = previousProps.operatingUnits.map(o => o.id);
    const currentUnits = this.props.operatingUnits.map(o => o.id);
    if (currentUnits.length > previousUnits.length) {
      const diff = difference(currentUnits, previousUnits);
      if (diff.length > 0) {
        const newUnit = diff[0];
        this.setState({
          operatingUnitSelected: newUnit,
          isAddingNewOperatingUnit: false
        });
      }
    }
  }

  addNewOperatingUnit() {
    this.setState({
      operatingUnitSelected: '',
      isAddingNewOperatingUnit: true
    });
  }

  selectOperatingUnit(id) {
    this.setState({
      operatingUnitSelected: id,
      isAddingNewOperatingUnit: false
    });
  }

  getSelectedOperatingUnit() {
    const operatingUnit = find(this.props.operatingUnits, {id: this.state.operatingUnitSelected});
    return operatingUnit;
  }

  getSelectedOperatingUnitName() {
    const operatingUnit = this.getSelectedOperatingUnit();
    if (operatingUnit) {
      return operatingUnit.name;
    } else {
      return 'Select...';
    }
  }

  getOperatingUnitList() {
    const options = this.props.operatingUnits
    .filter(operatingUnit => !operatingUnit.isDeleted)
    .map(operatingUnit => {
      return {
        value: operatingUnit.id,
        label: operatingUnit.name
      }
    });

    const handleChange = (selectedOption) => this.selectOperatingUnit(selectedOption.value);

    return <Select className='configuration-object-selector' 
      value={{label: this.getSelectedOperatingUnitName()}}
      options={options}
      onChange={handleChange}
    />;
  }

  activate() {
    const operatingUnit = this.getSelectedOperatingUnit();
    if (operatingUnit) {
      operatingUnit.active = !operatingUnit.active;
      this.props.update(operatingUnit);
    }
  }

  getActivateButton() {
    const operatingUnit = this.getSelectedOperatingUnit();
    if (operatingUnit) {
      const buttonName = operatingUnit.active? 'Deactivate' : 'Activate';
      return <button className = 'activate-button' onClick={() => this.activate()}>{buttonName}</button>
    }
  }

  getRemoveOperatingUnitButton() {
    const operatingUnitId = this.state.operatingUnitSelected;
    if (operatingUnitId !== '') {
      return (
        <button className='remove-operating-unit'
          onClick={() => this.props.remove(operatingUnitId)}>
          Remove Operating Unit
        </button>
      );
    }
  }

  getPrompt() {
    const operatingUnit = this.getSelectedOperatingUnit();
    if (operatingUnit) {
      return `Edit operating unit ${operatingUnit.name}`;
    } else if (this.state.isAddingNewOperatingUnit) {
      return `Add a new operating unit`;
    } else {
      return `Select an operating unit to edit`;
    }
  }

  hasSelectedOperatingUnit() {
    return this.state.operatingUnitSelected !== '';  
  }

  getOperatingUnitProps() {
    const operatingUnit = this.getSelectedOperatingUnit();
    if (operatingUnit) {
      return operatingUnit;
    } else {
      return {};
    }
  }


  checkExistence(state) {
    if (this.state.isAddingNewOperatingUnit) {
      const operatingUnit = find(this.props.operatingUnits, {name: state.name});
      if (operatingUnit) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewOperatingUnit) {
      if (this.getSelectedOperatingUnitName() !== state.name) {
        const operatingUnit = find(this.props.operatingUnits, {name: state.name});
        if (operatingUnit) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }


  getOperatingUnitEditPanel() {
    if (this.hasSelectedOperatingUnit() && !this.state.isAddingNewOperatingUnit) {
      return (
        <div>
          <OperatingUnitFieldEditor 
            key={uniqid()}
            operatingUnitFieldProps={this.getOperatingUnitProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectOperatingUnit('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewOperatingUnit) {
      return (
        <div>
          <OperatingUnitFieldEditor 
            key={uniqid()} 
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectOperatingUnit('')}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className='operating-unit-configuration configuration-forms'>
        <button className='add-new-operating-unit' onClick={() => this.addNewOperatingUnit()}>Add New Operating Unit</button>
        <div className='operating-unit-editor'>
          <p>{this.getPrompt()}</p>
          <div className='operating-unit-list'>
            {this.getOperatingUnitList()}
          </div>
          <div className='operating-unit-edit-panel'>
            {this.getOperatingUnitEditPanel()}
            <div className='operating-unit-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveOperatingUnitButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectOperatingUnit('')}/>
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    operatingUnits: state.operatingUnits.operatingUnitList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openBulkActivationModal: () => {
      dispatch(openBulkActivationModal())
    },
    add: (state) => {
      dispatch(addOperatingUnit(state));
    },
    update: (state) => {
      dispatch(updateOperatingUnit(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatingUnitConfiguration);
