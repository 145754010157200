import { setReports } from '../reducers/reports';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class ReportService {
  static getReportsApi() {
    return axios.get('/api/reports', { headers: getAuthService().header });
  }

  static getReports() {
    return this.getReportsApi()
      .then((report) => {
        store.dispatch(setReports(report.data))
      })
      .catch(error => console.log(error));
  }
}

export default ReportService;