import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { DELETE_SCENARIO_TRUCK } from '../../constants/modalTypes';
import { removeTruckScenario } from '../../reducers/schedule';
import { deleteTruckShare } from '../../reducers/truckShares';
import { closeModal } from '../../reducers/modalWindows';
import find from 'lodash/find';

class ScenarioTruckDeleteModal extends React.Component {
  constructor(props) {
    super(props);

    this.deleteShare = this.deleteShare.bind(this);
    ReactModal.setAppElement('#root');
    
  }


  getdeleteShareButton() {
    const scenarioTruckId = this.props.modalWindow.scenarioTruckId;
    const shareRow = find(this.props.truckShares, {scenarioTruckId : scenarioTruckId});
    if (shareRow) {
      return (
        <div>
          <button className='delete-share-button' onClick={() => this.deleteShare(shareRow.id)}>Delete Share</button>
        </div>
      );
    }  
  }

  deleteShare(shareId) {  
    this.props.deleteTruckShare(shareId)
  }

  render() {
    const scenarioTruckId = this.props.modalWindow.scenarioTruckId
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DELETE_SCENARIO_TRUCK && modalWindow.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            height: '150px',
            width: '350px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
          <div className='scenario-truck-delete-modal'>
            <p>Are you sure you want to remove this truck from the schedule?</p>
            <div className='delete-modal-buttons'>
              <button className='cancel-button' onClick={() => this.props.closeModal()}>Cancel</button>
              <button className='delete-button' disabled={this.getdeleteShareButton()} onClick={() => this.props.removeTruckScenario(scenarioTruckId)}>Delete</button>
              {this.getdeleteShareButton()}
            </div>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    truckShares: state.truckShares.truckSharesList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    removeTruckScenario: (scenarioTruckId) => {
      dispatch(closeModal());
      dispatch(removeTruckScenario(scenarioTruckId));
    },
    deleteTruckShare: (shareId) => {
      dispatch(closeModal());
      dispatch(deleteTruckShare(shareId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTruckDeleteModal);