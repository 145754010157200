import React from 'react';
import { getActiveDestinationsInOperatingUnit } from '../utils/yardDestinations';
import isEmpty from 'lodash/isEmpty';
import { emptyUuid } from '../utils/uuidHelper';

class YardSleeperEditor extends React.Component {

  constructor(props) {
    super(props);

    const isChecked = !this.hasInvalidId();
    
    this.state = {
      isChecked: isChecked
    }

    this.activeDestinationsInOpUnits = getActiveDestinationsInOperatingUnit(this.props.yardId);
    this.handleCheckSleeper = this.handleCheckSleeper.bind(this);
    this.handleChangeDestination = this.handleChangeDestination.bind(this);
  }

  componentDidUpdate() {
    if (this.hasInvalidId() && this.state.isChecked) {
      const firstDestination = this.activeDestinationsInOpUnits[0];
      const validId = firstDestination? firstDestination.id: '';
      this.props.handleSleeperDestinationChange(validId);
    }
  }

  hasInvalidId() {
    const destinationId = this.props.sleeperDestinationId;
    return destinationId === emptyUuid || isEmpty(destinationId);
  }

  handleCheckSleeper() {
    const checkValue = !this.state.isChecked;
    this.setState({isChecked: checkValue});
    if (!checkValue) {
      this.props.handleSleeperDestinationChange('');
    }
  }

  handleChangeDestination(event) {
    const destinationId = event.target.value;
    this.props.handleSleeperDestinationChange(destinationId);
  }

  getDestinationOptions() {
    const destinationOptions = this.activeDestinationsInOpUnits
      .map(dest => <option key={dest.id} value={dest.id}>{dest.name}</option>);
    return destinationOptions;
  }

  getDestinationSelector() {
    if (this.state.isChecked) {
      return (
        <div>
          <label className='destination-label'>Destination</label>
          <select className='destination-picker' onChange={this.handleChangeDestination} value={this.props.sleeperDestinationId}>
            {this.getDestinationOptions()}
          </select>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='yard-sleeper-editor'>
        <div className='sleeper-checkbox'>
          <input type='checkbox' checked={this.state.isChecked} onChange={this.handleCheckSleeper} />
          <label>Sleeper</label>
        </div>
        {this.getDestinationSelector()}
      </div>
    );
  }
}

export default YardSleeperEditor;