import React from 'react';
import { connect } from 'react-redux';
import { getTruckDisplayName, getDestinationDisplayName } from '../../utils/displayNames';

class DashboardYard extends React.Component {

  getSleeperLoads(yardId) {
    return this.props.sleeperLoads.filter(load => load.yardId === yardId);
  }

  getSleeperLoadInfo(yardId) {
    const sleeperLoadRows = this.getSleeperLoads(yardId).map(load => {
      const truckName = getTruckDisplayName(load.truckId);
      const destinationName = getDestinationDisplayName(load.destinationId);
      const assignedSymbol = load.tripCount > 0? '* ': '';
      return <div className='sleeper-load-dashboard-info' key={load.id}>
        {`${assignedSymbol}${truckName} - ${destinationName}`}
      </div>
    });

    if (sleeperLoadRows.length > 0) {
      return <React.Fragment>
        <div className='sleeper-load-label'><u>Sleeper Loads</u></div>
        {sleeperLoadRows}
      </React.Fragment>
    }
  }

  render() {
    const data = this.props.dashboardInfo;
    const fullName = data.fullName? data.fullName: '';
    const description = data.description? data.description: '';
    return (
      <div className='dashboard-yard'>
        <h4>Yard {data.name} details </h4>
        <div>Full Name: {fullName}</div>
        <div>Description: {description}</div>
        <div className='sleeper-load-section'>
          {this.getSleeperLoadInfo(data.id)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sleeperLoads: state.sleeperLoads.sleeperLoadList
  }
}


export default connect(mapStateToProps)(DashboardYard);