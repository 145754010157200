import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import VendorFieldEditor from '../../components/fieldEditors/VendorFieldEditor';
import uniqid from 'uniqid';
import { addVendor, updateVendor, bulkActivateVendors } from '../../reducers/vendors';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { VENDOR } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class VendorsConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendorIdSelected: '',
      isAddingNewVendor: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousVendors = previousProps.vendors.map(vendor => vendor.id);
    const currentVendors = this.props.vendors.map(vendor => vendor.id);
    if (currentVendors.length > previousVendors.length) {
      const diff = difference(currentVendors, previousVendors);
      if (diff.length > 0) {
        const newVendor = diff[0];
        this.setState({
          vendorIdSelected: newVendor,
          isAddingNewVendor: false
        });
      }
    }
  }

  addNewVendor() {
    this.setState({
      vendorIdSelected: '',
      isAddingNewVendor: true
    });
  }

  selectVendor(id) {
    this.setState({
      vendorIdSelected: id,
      isAddingNewVendor: false
    });
  }

  getSelectedVendor() {
    const vendor = find(this.props.vendors, {id: this.state.vendorIdSelected});
    return vendor;
  }

  getSelectedVendorName() {
    const vendor = this.getSelectedVendor();
    if (vendor) {
      return vendor.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedVendor() {
    return this.state.vendorIdSelected !== '';
  }

  getVendorsList() {
    const options = this.props.vendors;
    const sortedOptions = sortBy(options, vendor => vendor.name)
    .filter(vendor => !vendor.isDeleted)
    .map(vendor => {
      return {
        value: vendor.id,
        label: vendor.name
      }
    });

    const handleChange = (selectedOption) => this.selectVendor(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedVendorName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const vendor = this.getSelectedVendor();
    if (vendor) {
      return `Edit vendor ${vendor.name}`;
    } else if (this.state.isAddingNewVendor) {
      return 'Add a new vendor';
    } else {
      return 'Select a vendor to edit';
    }
  }

  checkExistence(state) {
    const vendor = find(this.props.vendors, {name: state.name});
    if (vendor) {
      this.props.openDuplicateObjectNamesModal(vendor);
    }
    else {
      if (this.state.isAddingNewVendor === true) {
        this.props.add(state);
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getVendorEditPanel() {
    if (this.hasSelectedVendor() && !this.state.isAddingNewVendor) {
      return (
        <div>
          <VendorFieldEditor
            key={uniqid()}
            vendorFieldProps={this.getSelectedVendor()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectVendor('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewVendor) {
      return (
        <div>
          <VendorFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectVendor('')}
          />
        </div>
      );
    }
  }

  activate() {
    const vendor = this.getSelectedVendor();
    if (vendor) {
      vendor.active = !vendor.active;
      this.props.update(vendor);
    }
  }

  getActivateButton() {
    const vendor = this.getSelectedVendor();
    if (vendor) {
      const buttonName = vendor.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const vendorId = this.state.vendorIdSelected;
    if (vendorId !== '') {
      return (
        <button className='remove-vendor'
          onClick={() => this.props.removeVendorModal(vendorId)}>
          Remove Vendor
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedVendorsWithId = this.props.vendors
      .filter(vendor => !vendor.isDeleted)
      .map(vendor => {
        return {
          ...vendor,
          id: vendor.id,
          name: vendor.name
        }
      });
      return <BulkActivationModal
        type={VENDOR}
        activationItems={updatedVendorsWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='vendor-configuration configuration-forms'>
        <button className='add-new-vendor' onClick={() => this.addNewVendor()}>Add New Vendor</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='vendor-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='vendor-list'>
          {this.getVendorsList()}
        </div>
        <div className='vendor-edit-panel'>
          {this.getVendorEditPanel()}
          <div className='vendor-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectVendor('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    vendors: state.vendors.vendorsList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addVendor(state));
    },
    update: (state) => {
      dispatch(updateVendor(state));
    },
    removeVendorModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateVendors(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(VendorsConfiguration);