import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { 
  ADD_NEW_BLOCK_LOCATION, 
  setBlocksToDestinations, 
  setBlockLocations, 
  REMOVE_BLOCK_LOCATION, 
  UPDATE_BLOCK_LOCATION, 
  ADD_NEW_DESTINATION_LOCATION, 
  setDrylandLocations,
  UPDATE_DESTINATION_LOCATION,
  REMOVE_DESTINATION_LOCATION,
  BULK_ACTIVATE_BLOCKS,
  BULK_ACTIVATE_DESTINATIONS,
  updateLocationsToYards
} from '../../reducers/locations';
import { setLoaders } from '../../reducers/loaders';
import { success, failure } from '../notificationMessages';
import { BLOCK, DESTINATION } from '../../constants/typeConstants';
import { setOperatingUnitAssignment } from '../../reducers/operatingUnits';
import { getAuthService } from '../AuthService';
import { setBlockVendors, setBlockContractors, setBlockFallers, setBlockHammerMarks, setBlockProcessors, setBlockYarders } from '../../reducers/blockProperties';



function setUpdatedBlocks(response) {
  const blocksToDestinations = response.blocksToDestinations;
  const loaders = response.loaders;
  const operatingUnitAssignment = response.operatingUnitAssignment;
  const selectedVendors = response.selectedVendors;
  const selectedYarders = response.selectedYarders;
  const selectedProcessors = response.selectedProcessors;
  const selectedFallers = response.selectedFallers;
  const selectedContractors = response.selectedContractors;
  const selectedHammerMarks = response.selectedHammerMarks;
  const updatePayload = {
    locationsToYards: response.locationsToYards,
    type: BLOCK
  }
  
  return Observable.of(
    setOperatingUnitAssignment(operatingUnitAssignment),
    setBlocksToDestinations(blocksToDestinations),
    setLoaders(loaders),
    updateLocationsToYards(updatePayload),
    setBlockVendors(selectedVendors),
    setBlockYarders(selectedYarders),
    setBlockProcessors(selectedProcessors),
    setBlockFallers(selectedFallers),
    setBlockContractors(selectedContractors),
    setBlockHammerMarks(selectedHammerMarks),
    setBlockLocations(response), 
  );
}

function setUpdatedDestinations(response) {
  const updatedPayload = {
    locationsToYards: response.locationsToYards,
    type: DESTINATION
  }
  return Observable.of(
    updateLocationsToYards(updatedPayload),
    setDrylandLocations(response),
    setOperatingUnitAssignment(response.operatingUnitAssignment)
  );
}

export const addNewBlockEpic = action$ => (
  action$.ofType(ADD_NEW_BLOCK_LOCATION)
    .mergeMap(action => (
      ajax.post('/api/blocks', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedBlocks(ajaxResponse.response), 'Successfully added a new block'))
        .catch(fail => Observable.of(failure('Could not add new block')))
    ))
);

export const removeBlockEpic = action$ => (
  action$.ofType(REMOVE_BLOCK_LOCATION)
    .mergeMap(action => (
      ajax.delete(`/api/blocks/${action.payload}`, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedBlocks(ajaxResponse.response), 'Successfully removed block'))
        .catch(fail => Observable.of(failure('Could not remove block')))
    ))
);

export const updateBlockEpic = action$ => (
  action$.ofType(UPDATE_BLOCK_LOCATION)
    .mergeMap(action => (
      ajax.put('/api/blocks', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedBlocks(ajaxResponse.response), 'Successfully updated block'))
        .catch(fail => Observable.of(failure('Could not update block')))
    ))
);

export const addNewDestinationEpic = action$ => (
  action$.ofType(ADD_NEW_DESTINATION_LOCATION)
    .mergeMap(action => (
      ajax.post('/api/destinations', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedDestinations(ajaxResponse.response), 'Successfully added a new destination'))
        .catch(fail => Observable.of(failure('Could not add new destination')))
    ))
);

export const updateDestinationEpic = action$ => (
  action$.ofType(UPDATE_DESTINATION_LOCATION)
    .mergeMap(action => (
      ajax.put('/api/destinations', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedDestinations(ajaxResponse.response), 'Successfully updated destination'))
        .catch(fail => Observable.of(failure('Could not update destination')))
    ))
);

export const removeDestinationEpic = action$ => (
  action$.ofType(REMOVE_DESTINATION_LOCATION)
    .mergeMap(action => (
      ajax.delete(`/api/destinations/${action.payload}`, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedDestinations(ajaxResponse.response), 'Successfully removed destination'))
        .catch(fail => Observable.of(failure('Could not remove destination')))
    ))
);

export const bulkActivateBlocksEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_BLOCKS)
    .mergeMap(action => (
      ajax.post('/api/blocks/bulkActivate', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedBlocks(ajaxResponse.response), 'Successfully updated blocks'))
        .catch(fail => Observable.of(failure('Could not update blocks')))
    ))
);

export const bulkActivateDestinationsEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_DESTINATIONS)
    .mergeMap(action => (
      ajax.post('/api/destinations/bulkActivate', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => setUpdatedDestinations(ajaxResponse.response), 'Successfully updated destinations'))
        .catch(fail => Observable.of(failure('Could not update destinations')))
    ))
);