import React from 'react';
import { connect } from 'react-redux';
import '../styles/loadingScreen.css';
import { RotateSpinLoader } from 'react-css-loaders';

class LoadingScreen extends React.Component {


  getLoadingScreen() {
    if (this.props.isLoading) {
      return (
        <div className='loading-screen'>
          <div className={'loading-screen-rotate-spin-loader'}>
            <RotateSpinLoader />
          </div>  
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return this.getLoadingScreen();
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingScreen.loadCount > 0
  }
}

export default connect(mapStateToProps)(LoadingScreen);