import React from 'react';
import OperatingUnitPicker from '../OperatingUnitPicker';
import { emptyUuid } from '../../utils/uuidHelper';
import { connect } from 'react-redux';

class PaintMarkFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.markFieldProps? props.markFieldProps: {};
    const defaultMarkId = fieldProps.id? fieldProps.id: '';
    const defaultMark = fieldProps.mark? fieldProps.mark: '';
    const defaultDescription = fieldProps.description? fieldProps.description: '';
    const defaultSort = fieldProps.sort? fieldProps.sort: '';
    const defaultPopulation = fieldProps.population? fieldProps.population: '';
    const defaultStratum = fieldProps.stratum? fieldProps.stratum: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const defaultOperatingUnitId = fieldProps.operatingUnitId? fieldProps.operatingUnitId: emptyUuid;
    const defaultDestinationId = fieldProps.destinationId? fieldProps.destinationId: emptyUuid;

    this.state = {
      id: defaultMarkId,
      mark: defaultMark,
      description: defaultDescription,
      sort: defaultSort,
      population: defaultPopulation,
      stratum: defaultStratum,
      active: defaultActive,
      operatingUnitId: defaultOperatingUnitId,
      destinationId: defaultDestinationId,
      isModified: false
    }

    this.changeMark = this.changeMark.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changePopulation = this.changePopulation.bind(this);
    this.changeStratum = this.changeStratum.bind(this);
    this.changeDestination = this.changeDestination.bind(this);
    this.handleChangeOperatingUnit = this.handleChangeOperatingUnit.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeDescription(event) {
    this.setState({description: event.target.value});
    this.setModified();
  }

  changeMark(event) {
    const mark = event.target.value.substring(0, 10);
    this.setState({mark: mark});
    this.setModified();
  }

  changeSort(event) {
    const sort = event.target.value.substring(0, 10);
    this.setState({sort: sort});
    this.setModified();
  }

  changeStratum(event) {
    const stratum = event.target.value.substring(0, 10);
    this.setState({stratum: stratum});
    this.setModified();
  }

  changePopulation(event) {
    const population = event.target.value.substring(0, 10);
    this.setState({population: population});
    this.setModified();
  }

  handleChangeOperatingUnit(operatingUnitId, setModified = false) {
    this.setState({operatingUnitId: operatingUnitId});
    if (setModified) {
      this.setModified();
    }
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeDestination(event) {
    const id = event.target.value;
    this.setState({destinationId: id});
    this.setModified();
  }

  getDestinationOptions() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeDestinations = this.props.destinations.filter(dest => dest.active);
    const options = activeDestinations.map(dest => {
      const id = dest.id;
      return <option key={id} value={id}>{dest.name}</option>;
    });

    return [noSelection, ...options];
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field mark-field'>
          <div>Mark</div>
          <input type='text' value={this.state.mark} onChange={this.changeMark} />
        </div>
        <div className='description-field mark-field'>
          <div>Description</div>
          <textarea rows='4' cols='55' value={this.state.description} onChange={this.changeDescription} />
        </div>
        <div className='sort-field mark-field'>
          <div>Sort</div>
          <input type='text' value={this.state.sort} onChange={this.changeSort} />
        </div>
        <div className='population-field mark-field'>
          <div>Population</div>
          <input type='text' value={this.state.population} onChange={this.changePopulation} />
        </div>
        <div className='stratum-field mark-field'>
          <div>Stratum</div>
          <input type='text' value={this.state.stratum} onChange={this.changeStratum} />
        </div>
        <OperatingUnitPicker objectId={this.state.id}
          onChangeOperatingUnit={this.handleChangeOperatingUnit} />
        <div className='destination-field mark-field'>
          <div>Destination</div>
          <select className='destination-picker'
            value={this.state.destinationId}
            onChange={this.changeDestination}>
              {this.getDestinationOptions()}
          </select>
        </div>
        <div className='mark-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destinations: state.locations.destinations
  }
}

export default connect(mapStateToProps)(PaintMarkFieldEditor);