import find from 'lodash/find';
import isNil from 'lodash/isNil';
import store from '../store';

function getScheduleRows() {
  return store.getState().scheduleRowState;
}

export function isTruckInSchedule(truckId) {
  const schedule = getScheduleRows();
  const targetSchedule = find(schedule, {truckId: truckId});
  return !isNil(targetSchedule);
}