import { Log, User, UserManager } from 'oidc-client';
import { AuthConstants } from '../constants/AuthConstants';

class AuthService {

  private userManager: UserManager;

  public header: object;


  constructor() {

    const settings = {
      authority: AuthConstants.stsAuthority,
      client_id: AuthConstants.clientId,
      redirect_uri: `${AuthConstants.clientRoot}signin-callback.html`,
      silent_redirect_uri: `${AuthConstants.clientRoot}silent-renew.html`,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `${AuthConstants.clientRoot}index.html`,
      response_type: 'code',
      scope: AuthConstants.clientScope
    };
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(() => {
      this.userManager.getUser().then(user => {
        this.setHeader(user);
      })
    });

    this.header = { 'Content-Type': 'application/json' }

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public setHeader(user: User | null): void {
      if (user && user.access_token) {
        this.header = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.access_token
        }
      }
  }

}

let authService: AuthService;

export function getAuthService(){
  if (!authService){
    authService = new AuthService();
  }
  return authService;
}
