import { toast, Zoom } from 'react-toastify';
import { failedAction } from '../reducers/requestActions';

export function success(successFunction, message) {
  if (!message) {
    message = 'Update successful';
  }

  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    transition: Zoom
  });

  return successFunction();
}

export function failure(message) {
  toast.error(`ERROR: ${message}`, {
    position: toast.POSITION.TOP_CENTER,
    transition: Zoom
  });

  return failedAction(message);
}