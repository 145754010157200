import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';
import { DRIVER_HISTORY_MODAL } from '../../constants/modalTypes';
class DriverHistoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModified: false,
      selectedBlocks: this.props.blockIds
    }

    ReactModal.setAppElement('#root');

    this.modifyState = this.modifyState.bind(this);
    this.handleCheckBlocks = this.handleCheckBlocks.bind(this);

  }

  modifyState() {
    this.setState({isModified: true});
  }

  getAllBlocks() {
    return this.props.blocks
    .filter(block => !block.isDeleted)
    .map(item => this.getBlock(item))
  }

  getBlock(block) {
    return (
      <div className='all-blocks-list' key={block.id}>
        <input className='block-select-checkbox' type='checkbox'  
          value={block.id}
          checked={this.state.selectedBlocks.includes(block.id)}
          onChange={this.handleCheckBlocks}/>
        <label>{block.name}</label>
      </div>
    )
  }

  handleCheckBlocks(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let updatedCheckedBlocks = [];
    if (isChecked) {
      updatedCheckedBlocks = [...this.state.selectedBlocks, id];
    } else {
      updatedCheckedBlocks = this.state.selectedBlocks.filter(selectId => selectId !== id);
    }
    this.setState({
      selectedBlocks: updatedCheckedBlocks
    });
    this.modifyState();
  }

  clickDone() {
    this.props.closeModal();
    if (this.state.isModified) {
      this.props.onClickDone(this.state.selectedBlocks);
    }
  }


  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DRIVER_HISTORY_MODAL && modalWindow.isModalOpen) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
          top: '100px',
          left: '800px',
          right: '60px',
          height: '500px',
          width: '700px'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeModal()}>
        <div className='driver-history-modal'>
          <p>Blocks this driver has been to:</p>
          <div className='blocks-for-driver-list'>
            {this.getAllBlocks()}
          </div>
          <div className='driver-history-modal-buttons'>
            <button className='cancel-button'
              onClick={() => this.props.closeModal()}>Cancel</button>
            <button className='done-button'
              onClick={() => this.clickDone()}>Done</button>
          </div>
        </div>
        </ReactModal>
      )
    }
  }
}
    


const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    blocks: state.locations.blocks,
    history: state.driversToBlocksHistory.driversToBlocksHistoryList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverHistoryModal)
