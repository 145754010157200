import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { successfulAction }  from '../../reducers/requestActions';
import { 
  CREATE_SCENARIO_TRUCK, 
  FETCH_SCENARIO_TRUCKS, 
  UPDATE_SCENARIO_TRUCK,
  REMOVE_TRUCK_SCENARIO,
  addTruckToSchedule, 
  fetchSchedule, 
  fetchScheduleByScenarioTruck, 
  setScenarioTrucks, 
  setUpdatedScenarioTruck,
  AUTHORIZE_SCENARIO_TRUCK } from '../../reducers/schedule';
import { ADD_TRUCK, setTrucks, EDIT_TRUCK, REMOVE_TRUCK, BULK_ACTIVATE_TRUCKS } from '../../reducers/trucks';
import { success, failure } from '../notificationMessages';
import { setOperatingUnitAssignment } from '../../reducers/operatingUnits';
import { getAuthService } from '../AuthService';

export const addScenarioTruckEpic = action$ => (
  action$.ofType(CREATE_SCENARIO_TRUCK)
    .mergeMap(action => (
      ajax.post('/api/scenariotrucks', action.payload.scenarioTruckModel, getAuthService().header)
        .map(ajaxResponse => {
          const scenarioTruckInfo = ajaxResponse.response;
          const rowId = action.payload.rowId;
          const truckId = action.payload.truckId;
          const truckName = action.payload.truckName;
          action.payload.highlightTruck(scenarioTruckInfo.scenarioTruckId);
          return addTruckToSchedule(rowId, truckId, truckName, scenarioTruckInfo);
        })
        .catch(fail => Observable.of(failure(fail)))
    ))
);

export const authorizeScenarioTruckEpic = action$ => (
  action$.ofType(AUTHORIZE_SCENARIO_TRUCK)
    .mergeMap(action => {
      const requestUrl = `/api/scenarioTrucks/authorize/${action.payload.scenarioTruckId}`;
      return ajax.post(requestUrl, null, getAuthService().header)
        .map(ajaxResponse => setUpdatedScenarioTruck(ajaxResponse.response))
        .catch(failure => Observable.of(failure));
    })
)


export const fetchScenarioTrucksEpic = action$ => (
  action$.ofType(FETCH_SCENARIO_TRUCKS)
    .mergeMap(action => {
      const scenarioId = action.payload.scenarioId;
      const url = `/api/scenarioTrucks/${scenarioId}`;
      return ajax.get(url, getAuthService().header)
        .flatMap(ajaxResponse => {
          return Observable.of(
            setScenarioTrucks(ajaxResponse.response),
            fetchSchedule(scenarioId)
          )
        })
        .catch(fail => Observable.of(failure(fail)))
    })
);

export const updateScenarioTruckEpic = action$ => (
  action$.ofType(UPDATE_SCENARIO_TRUCK)
    .mergeMap(action => (
      ajax.put(`/api/scenarioTrucks/${action.payload.scenarioTruckId}`, 
        action.payload.scenarioTruckModel, 
        getAuthService().header
      ).flatMap(ajaxResponse => Observable.of(
          setUpdatedScenarioTruck(ajaxResponse.response),
          fetchScheduleByScenarioTruck(action.payload.scenarioTruckId)))
        .catch(fail => Observable.of(failure(fail)))
    ))
);

export const deleteScenarioTruckEpic = action$ => (
  action$.ofType(REMOVE_TRUCK_SCENARIO)
    .mergeMap(action => (
      ajax.delete(`/api/scenarioTrucks/${action.payload.id}`, getAuthService().header)
        .map(ajaxResponse => successfulAction(ajaxResponse.response))
        .catch(fail => Observable.of(failure(fail)))
    ))
);

function handleTruckEditSuccess(response) {
  const trucks = response.trucks;
  const assignment = response.assignment;

  return Observable.of(
    setOperatingUnitAssignment(assignment),
    setTrucks(trucks)
  );

}

export const addTruckEpic = action$ => (
  action$.ofType(ADD_TRUCK)
    .mergeMap(action => (
      ajax.post('/api/trucks', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => handleTruckEditSuccess(ajaxResponse.response), 'Successfully added new truck'))
        .catch(fail => Observable.of(failure(fail)))
    ))
);


export const updateTruckEpic = action$ => (
  action$.ofType(EDIT_TRUCK)
    .mergeMap(action => (
      ajax.put('/api/trucks', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => handleTruckEditSuccess(ajaxResponse.response), 'Successfully edited truck'))
        .catch(fail => Observable.of(failure(fail)))
    ))
);

export const deleteTruckEpic = action$ => (
  action$.ofType(REMOVE_TRUCK)
    .mergeMap(action => (
      ajax.delete(`/api/trucks/${action.payload}`, getAuthService().header)
        .flatMap(ajaxResponse => success(() => handleTruckEditSuccess(ajaxResponse.response), 'Successfully removed truck'))
        .catch(fail => Observable.of(failure(fail)))
    ))
);

export const bulkActivateTrucksEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_TRUCKS)
    .mergeMap(action => (
      ajax.post('/api/trucks/bulkActivate', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => handleTruckEditSuccess(ajaxResponse.response), 'Successfully bulk activated trucks'))
        .catch(fail => Observable.of(failure(fail)))
    ))
);