import React from 'react';
import { BLOCK_DASHBOARD_DETAILS, BLOCK_ACTIVITY_DASHBOARD_DETAILS, DRYLAND_DASHBOARD_DETAILS, DRYLAND_ACTIVITY_DASHBOARD_DETAILS, TRUCK_DASHBOARD_DETAILS, SCENARIO_TRUCK_DASHBOARD_DETAILS, TRANSIT_DASHBOARD_DETAILS, YARD_DASHBOARD_DETAILS } from '../../constants/dashboardTypes';
import { connect } from 'react-redux';
import DashboardBlock from './DashboardBlock';
import DashboardScheduleActivity from './DashboardScheduleActivity';
import DashboardDryland from './DashboardDryland';
import DashboardTruck from './DashboardTruck';
import DashboardScenarioTruck from './DashboardScenarioTruck';
import find from 'lodash/find';
import DashboardYard from './DashboardYard';

class Dashboard extends React.Component {

  getDashboardData() {
    return this.props.currentDashboard.data
  }

  getDashboardForBlock() {
    const dashboardData = this.getDashboardData();
    const timeFromYards = this.getTimeFromYards(dashboardData.id);
    return <DashboardBlock dashboardInfo={dashboardData}
      timeFromYards={timeFromYards}
    />;
  }

  getDashboardActivity(activity) {
    return <DashboardScheduleActivity activity={activity} />;
  }

  getDashboardForDryland() {
    const dashboardData = this.getDashboardData();
    const timeFromYards = this.getTimeFromYards(dashboardData.id);
    return <DashboardDryland dashboardInfo={dashboardData} 
      timeFromYards={timeFromYards}
      schedule={this.props.scheduleRowState}
    />;
  }

  getDashboardForTruck() {
    return <DashboardTruck dashboardInfo={this.getDashboardData()} />;
  }

  getDashboardScenarioTruck() {
    return <DashboardScenarioTruck dashboardInfo={this.getDashboardData()}
      drivers={this.props.drivers} />;
  }

  getDashboardForYard() {
    return <DashboardYard dashboardInfo={this.getDashboardData()} />;
  }

  getDashboardElement() {
    const dashboard = this.props.currentDashboard;
    const dashboardType =  dashboard.dashboardType;
    if (dashboardType === BLOCK_DASHBOARD_DETAILS) {
      return this.getDashboardForBlock();
    } else if (dashboardType === BLOCK_ACTIVITY_DASHBOARD_DETAILS ||
               dashboardType === DRYLAND_ACTIVITY_DASHBOARD_DETAILS ||
               dashboardType === TRANSIT_DASHBOARD_DETAILS) {
      return this.getDashboardActivity(dashboard.activity);
    } else if (dashboardType === DRYLAND_DASHBOARD_DETAILS) {
      return this.getDashboardForDryland();
    } else if (dashboardType === TRUCK_DASHBOARD_DETAILS) {
      return this.getDashboardForTruck();
    } else if (dashboardType === SCENARIO_TRUCK_DASHBOARD_DETAILS) {
      return this.getDashboardScenarioTruck();
    } else if (dashboardType === YARD_DASHBOARD_DETAILS) {
      return this.getDashboardForYard();
    }
  }

  
  getTimeFromYards(locationId) {
    const targetRows = this.props.locationsToYards.filter(row => row.locationId === locationId);
    const timeFromYards = targetRows.map(blockToYard => {
      const yards = this.props.yards;
      const yard = find(yards, {id: blockToYard.yardId});
      if (yard) {
        return <div key={blockToYard.yardId}>Time from {yard.name}: {blockToYard.toYardTime}</div>;
      } else {
        return null;
      }
    });
    return timeFromYards;
  }

  render() {
    return (
      <div className='dashboard'>
        {this.getDashboardElement()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentDashboard: state.dashboard.currentlySelected,
    locationsToYards: state.locations.locationsToYards,
    yards: state.locations.yards,
    drivers: state.drivers.driverList,
    scheduleRowState: state.scheduleRowState
  }
}

export default connect(mapStateToProps)(Dashboard);
