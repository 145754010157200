import { combineEpics } from 'redux-observable';
import { blockLoadEpic, fetchBlockLoads } from './blockLoadEpic';
import {
  fetchScenarioEpic,
  createNewScenarioEpic,
  deleteScenarioEpic,
  updateScenarioEpic,
  copyScenarioEpic,
  activateScenarioEpic,
  completeScenarioEpic,
  reopenScenarioEpic
} from './scenarioEpic';
import {
  saveScheduleEpic,
  fetchScheduleEpic,
  removeFromScheduleEpic,
  updateScheduleEpic,
  fetchScheduleByScenarioTruckEpic,
  swapActivityEpic
} from './scheduleEpic';
import { 
  addScenarioTruckEpic, 
  fetchScenarioTrucksEpic, 
  updateScenarioTruckEpic, 
  deleteScenarioTruckEpic, 
  addTruckEpic,
  updateTruckEpic,
  deleteTruckEpic,
  bulkActivateTrucksEpic,
  authorizeScenarioTruckEpic
} from './truckEpic';
import { 
  addNewBlockEpic, 
  removeBlockEpic, 
  updateBlockEpic, 
  addNewDestinationEpic, 
  removeDestinationEpic, 
  updateDestinationEpic, 
  bulkActivateBlocksEpic,
  bulkActivateDestinationsEpic
} from './editLocationEpic';
import { addDriverEpic, removeDriverEpic, updateDriverEpic, bulkActivateDriversEpic } from './driverEpic';
import { loaderEpics } from './loaderEpic';
import { yardEpics } from './yardEpic';
import { operatorEpics } from './operatorEpic';
import { operatingUnitEpics } from './operatingUnitEpic';
import { addErrorCodeEpic, removeErrorCodeEpic, updateErrorCodeEpic, bulkActivateErrorCodeEpic} from './errorCodeEpic';
import { paintMarkEpics } from './paintMarkEpic';
import { shareTruckEpic, deleteShareTruckEpic } from './truckShareEpic';
import { sleeperLoadEpics } from './sleeperLoadEpic';
import { addDriverToBlockHistoryEpic, removeDriverToBlockHistoryEpic, updateDriverToBlockHistoryEpic, bulkAddDriversToHistoryEpic, bulkAddBlocksToHistoryEpic} from './driverToBlockHistoryEpic';
import { VendorEpics } from './vendorEpic';
import { HammerMarkEpics } from './hammerMarkEpic';
import { ContractorEpics } from './contractorEpic';
import { FallerEpics } from './fallerEpic';
import { YarderEpics } from './yarderEpic';
import { ProcessorEpics } from './processorEpic';
import { updateReportEpic } from './reportEpic';
import { fetchNavistreamDataEpic } from './navistreamEpic';
import { TruckCompanyEpics } from './truckCompanyEpic';

export const rootEpic = combineEpics(
  blockLoadEpic,
  fetchBlockLoads,
  activateScenarioEpic,
  completeScenarioEpic,
  reopenScenarioEpic,
  fetchScenarioEpic,
  createNewScenarioEpic,
  deleteScenarioEpic,
  updateScenarioEpic,
  copyScenarioEpic,
  saveScheduleEpic,
  fetchScheduleEpic,
  removeFromScheduleEpic,
  updateScheduleEpic,
  swapActivityEpic,
  addScenarioTruckEpic,
  fetchScenarioTrucksEpic,
  updateScenarioTruckEpic,
  fetchScheduleByScenarioTruckEpic,
  deleteScenarioTruckEpic,
  authorizeScenarioTruckEpic,
  addNewBlockEpic,
  removeBlockEpic,
  updateBlockEpic,
  addNewDestinationEpic,
  removeDestinationEpic,
  updateDestinationEpic,
  addTruckEpic,
  updateTruckEpic,
  deleteTruckEpic,
  bulkActivateBlocksEpic,
  bulkActivateDestinationsEpic,
  bulkActivateTrucksEpic,
  addDriverEpic,
  removeDriverEpic,
  updateDriverEpic,
  bulkActivateDriversEpic,
  addErrorCodeEpic,
  removeErrorCodeEpic,
  updateErrorCodeEpic,
  bulkActivateErrorCodeEpic,
  loaderEpics,
  yardEpics,
  operatorEpics,
  operatingUnitEpics,
  paintMarkEpics,
  sleeperLoadEpics,
  addDriverToBlockHistoryEpic,
  updateDriverToBlockHistoryEpic,
  removeDriverToBlockHistoryEpic,
  bulkAddDriversToHistoryEpic,
  bulkAddBlocksToHistoryEpic,
  shareTruckEpic,
  deleteShareTruckEpic,
  VendorEpics,
  HammerMarkEpics,
  ContractorEpics,
  FallerEpics,
  YarderEpics,
  ProcessorEpics,
  updateReportEpic,
  fetchNavistreamDataEpic,
  TruckCompanyEpics
);
