import { BLOCK, DRYLAND, YARD } from "../constants/typeConstants";

const HIGHLIGHT_BLOCK = 'HIGHLIGHT_BLOCK';
const HIGHLIGHT_DESTINATION = 'HIGHLIGHT_DESTINATION';
const HIGHLIGHT_YARD = 'HIGHLIGHT_YARD';
const CLEAR_HIGHLIGHTS = 'CLEAR_HIGHLIGHTS';

export function clearHighlights() {
  return {
    type: CLEAR_HIGHLIGHTS,
    payload: initialState
  }
}

export function highlightBlock(blockId) {
  return {
    type: HIGHLIGHT_BLOCK,
    payload: {
      type: BLOCK,
      locationId: blockId
    }
  }
}

export function highlightDestination(destinationId) {
  return {
    type: HIGHLIGHT_DESTINATION,
    payload: {
      type: DRYLAND,
      locationId: destinationId
    }
  }
}

export function highlightYard(yardId) {
  return {
    type: HIGHLIGHT_YARD,
    payload: {
      type: YARD,
      locationId: yardId
    }
  }
}

const initialState = {
  type: '',
  locationId: ''
}

function highlights(state = initialState, action) {
  switch(action.type) {
    case HIGHLIGHT_BLOCK:
    case HIGHLIGHT_DESTINATION:
    case HIGHLIGHT_YARD:
    case CLEAR_HIGHLIGHTS:
      return setHighlight(state, action);
    default:
      return state;
  }
}

export default highlights;

function setHighlight(state, action) {
  if (action.payload.locationId === state.locationId) {
    return initialState;
  } else {
    return {
      ...action.payload
    };
  }
}