import store from '../store';

function getStoreState() {
  return store.getState();
}

function getAssignments() {
  return getStoreState().loaderOperatorAssignment.loaderOperatorAssignmentList;
}

export function getLoadersAssigned(blockId) {
  return getAssignments().filter(x => x.blockId === blockId);
}