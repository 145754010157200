import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { PDF_PREVIEW_MODAL } from '../constants/modalTypes';
import { closeModal } from '../reducers/modalWindows';

class PdfPreview extends React.Component {

  getModalStyle() {
    return {
      width: '1010px',
      height: 'fit-content',
      margin: 'auto'
    }
  }

  isPdfPreviewOpen() {
    return this.props.modalWindow.modalType === PDF_PREVIEW_MODAL;
  }

  render() {
    if (this.isPdfPreviewOpen()) {
      const pdfUrl = this.props.modalWindow.previewUrl;
      return (
       <ReactModal
          isOpen={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}
          style={{content: this.getModalStyle()}}>
          <iframe src={pdfUrl} title='PDF Preview' height='1080' width='1000'>
          </iframe>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfPreview);