import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import PdfService from '../../services/pdfService';
import { openCopyScenarioModal, openScenarioDeleteModal, openPublishModal, openImportSleepersModal, openRollupDayPickerModal, openReopenScenarioPasswordModal, openSendContractorTimeCardsModal, openPublishPartiallyModal} from '../../reducers/modalWindows';
import { toast } from 'react-toastify';
import { getScenariosFromCurrentSelectedDate } from '../../utils/scenarioHelper';
import { isViewerRole } from '../../utils/userHelper';
import { activateScenario, completeScenario } from '../../reducers/scenarios';
import ExportCsvService from '../../services/exportCsvService';

class ScenarioSettingsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.openDeleteScenarioModal = this.openDeleteScenarioModal.bind(this);
    this.publishAction = this.publishAction.bind(this);
    this.openCopyModal = this.openCopyModal.bind(this);
    this.downloadCombinedSchedule = this.downloadCombinedSchedule.bind(this);
    this.downloadStartReport = this.downloadStartReport.bind(this);
    this.activateScenario = this.activateScenario.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.openImportSleepersModal = this.openImportSleepersModal.bind(this);
    this.downloadScheduleDeliveryReportPdf = this.downloadScheduleDeliveryReportPdf.bind(this);
    this.downloadTimeCards = this.downloadTimeCards.bind(this);
    this.timeCardRollup = this.timeCardRollup.bind(this);
    this.completeScenario = this.completeScenario.bind(this);
    this.openReopenScenarioPasswordModal = this.openReopenScenarioPasswordModal.bind(this);
    this.openSendContractorTimeCardsModal = this.openSendContractorTimeCardsModal.bind(this);
    this.openPublishPartiallyModal = this.openPublishPartiallyModal.bind(this);
  }

  openDeleteScenarioModal() {
    this.props.openDeleteScenarioModal(this.props.selectedScenario.id);
  }

  downloadCombinedSchedule() {
    PdfService.downloadCombinedPdf(this.props.selectedScenario.id);
  }

  downloadStartReport() {
    PdfService.downloadStartReportPdf(this.props.selectedScenario.id);
  }

  downloadScheduleDeliveryReportPdf() {
    PdfService.downloadScheduleDeliveryReportPdf(this.props.selectedScenario.id);
  }

  downloadTimeCards() {
    PdfService.downloadTimeCards(this.props.selectedScenario.id);
  }

  timeCardRollup() {
    this.props.openRollupDayPickerModal();
  }

  openReopenScenarioPasswordModal() {
    this.props.openReopenScenarioPasswordModal();
  }

  openSendContractorTimeCardsModal() {
    this.props.openSendContractorTimeCardsModal();
  }

  openPublishPartiallyModal() {
    const scenario = this.props.selectedScenario;
    this.props.openPublishPartiallyModal(scenario.id, this.props.user);
  }

  hasAlreadyPublished() {
    const scenarios = getScenariosFromCurrentSelectedDate();
    const scenariosPublished = scenarios.filter(scenario => scenario.published);
    return scenariosPublished.length > 0;
  }

  activateScenario() {
    const scenario = this.props.selectedScenario;
    this.props.activateScenario(scenario.id, scenario.date);
  }

  exportCsv() {
    const scenario = this.props.selectedScenario;
    const scenarioName = scenario.name.replace(/ /g, '_');
    ExportCsvService.downloadCsv(scenario.id, scenarioName);
  }

  publishAction(isPublishing) {
    const scenarioId = this.props.selectedScenario.id;

    if (isPublishing && this.hasAlreadyPublished()) {
      toast.error('There is already a published scenario for this date');
    } else {
      this.props.openPublishModal(isPublishing, scenarioId, this.props.user);
    }
  }

  getPublishMenuItem() {
    const scenario = this.props.selectedScenario;

    if (!scenario) {
      return null;
    }
    
    if (scenario.published) {
      return <MenuItem onClick={() => this.publishAction(false)} disabled={isViewerRole()}>Unpublish</MenuItem>;
    } else {
      return (
        <MenuItem onClick={() => this.publishAction(true)} disabled={scenario.wasPublished || isViewerRole() || this.props.schedule.length === 0 || scenario.isClosed || scenario.isPartiallyPublished}>
          Publish
        </MenuItem>
      );
    }
  }

  getPublishPartiallyMenuItem() {
    const scenario = this.props.selectedScenario;
    if (!scenario) {
      return null;
    } else {
      return (
        <MenuItem onClick={this.openPublishPartiallyModal} disabled={isViewerRole() || this.props.schedule.length === 0 || scenario.isClosed || scenario.wasPublished || scenario.published || this.isThereAnUnpublibshedScenario()}>Publish By Operating Unit</MenuItem>
      );
    }
  }

  isThereAnUnpublibshedScenario() {
    var todayScenarios = this.props.scenarios;
    if (todayScenarios) {
      var publishedScenario =  Object.keys(todayScenarios)
      .map(id => todayScenarios[id])
      .filter(scenario => scenario.wasPublished);
      if (publishedScenario.length !== 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  getPreviewAllMenuItem() {
    if (this.props.schedule.length > 0) {
      const selectedScenario = this.props.selectedScenario;
      const scenarioId = selectedScenario? selectedScenario.id : "";
      return <MenuItem onClick={() => PdfService.previewAll(scenarioId)} disabled={this.props.schedule.length === 0}>Preview All PDF</MenuItem>;
    }
  }

  openCopyModal() {
    this.props.openCopyModal();
  }

  openImportSleepersModal() {
    this.props.openImportSleepersModal();
  }

  completeScenario() {
    this.props.completeScenario(this.props.selectedScenario.id);
  }

  render() {
    const id = 'scenario-menu';
    const scenario = this.props.selectedScenario;
    if (!scenario) {
      return null;
    }
    return (
      
      <div className='scenario-settings-menu'>
        <ContextMenuTrigger id={id} holdToDisplay={0}>
          <button className='scenario-settings-button'>
              Settings
          </button>
        </ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem onClick={this.activateScenario} 
            disabled={scenario.active || scenario.published || scenario.wasPublished || scenario.isClosed}>Activate</MenuItem>
          <MenuItem onClick={this.openCopyModal} disabled={isViewerRole() || (this.props.schedule.length === 0 ) || scenario.isClosed}>Copy Scenario</MenuItem>
          {this.getPreviewAllMenuItem()}
          <MenuItem onClick={() => PdfService.previewStartReport(this.props.selectedScenario.id)} disabled={this.props.schedule.length === 0}>Preview Start Time Report</MenuItem>
          <MenuItem onClick={() => PdfService.previewScheduleDeliveryPdf(this.props.selectedScenario.id)} disabled={this.props.schedule.length === 0}>Preview Schedule Delivery Report</MenuItem>
          <MenuItem onClick={this.downloadCombinedSchedule} disabled={this.props.schedule.length === 0}>Download All PDF</MenuItem>
          <MenuItem onClick={this.downloadStartReport} disabled={this.props.schedule.length === 0}>Download Start Time Report PDF</MenuItem>
          <MenuItem onClick={this.downloadScheduleDeliveryReportPdf} disabled={this.props.schedule.length === 0}>Download Schedule Delivery Report</MenuItem>
          <MenuItem onClick={this.downloadTimeCards} disabled={this.props.schedule.length === 0 || isViewerRole()}>Download Time Cards</MenuItem>
          <MenuItem onClick={this.timeCardRollup}>Time Card Rollup</MenuItem>
          <MenuItem onClick={this.openSendContractorTimeCardsModal}>Send Contractor Time Cards</MenuItem>
          <MenuItem onClick={this.exportCsv} disabled={this.props.schedule.length === 0}>Export schedule to CSV</MenuItem>
          <MenuItem onClick={this.openImportSleepersModal} disabled={isViewerRole() || (this.props.schedule.length !== 0 )}>Import Sleepers</MenuItem>
          <MenuItem onClick={this.completeScenario} disabled={scenario.isClosed || this.props.schedule.length === 0 || isViewerRole()}>Close Scenario</MenuItem>
          <MenuItem onClick={this.openReopenScenarioPasswordModal} disabled={scenario.isReopened || !scenario.isClosed}>Reopen Scenario</MenuItem>
          {this.getPublishPartiallyMenuItem()}         
          {this.getPublishMenuItem()}
          <MenuItem onClick={this.openDeleteScenarioModal} disabled={isViewerRole()}>Delete</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    schedule: state.scheduleRowState,
    selectedScenario: state.scenarioState.selectedScenario,
    user: state.users.user.profile,
    scenarios: state.scenarioState.byId
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    openCopyModal: () => {
      dispatch(openCopyScenarioModal());
    },
    openDeleteScenarioModal: (scenarioId) => {
      dispatch(openScenarioDeleteModal(scenarioId));
    },
    activateScenario: (scenarioId, date) => {
      dispatch(activateScenario(scenarioId, date));
    },
    openPublishModal: (isPublishing, scenarioId, user) => {
      dispatch(openPublishModal(isPublishing, scenarioId, user));
    },
    openImportSleepersModal: () => {
      dispatch(openImportSleepersModal());
    },
    openRollupDayPickerModal: () => {
      dispatch(openRollupDayPickerModal());
    },
    completeScenario: (scenarioId) => {
      dispatch(completeScenario(scenarioId))
    },
    openReopenScenarioPasswordModal: () => {
      dispatch(openReopenScenarioPasswordModal());
    },
    openSendContractorTimeCardsModal: () => {
      dispatch(openSendContractorTimeCardsModal());
    },
    openPublishPartiallyModal: (scenarioId, user) => {
      dispatch(openPublishPartiallyModal(scenarioId, user));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSettingsMenu);
