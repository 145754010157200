import store from '../store';
import { DESTINATION } from '../constants/typeConstants';
import { matchesSelectedOperatingUnit } from './operatingUnitHelper';

function getStoreState() {
  return store.getState();
}

function getLocationsToYards() {
  return getStoreState().locations.locationsToYards;
}

function getDestinations() {
  return getStoreState().locations.destinations;
}

export function getActiveDestinationsInOperatingUnit(yardId) {
  const locationsToYards = getLocationsToYards();
  const targetDestinationFromYard = locationsToYards
    .filter(row => row.type === DESTINATION && row.yardId === yardId)
    .filter(row => matchesSelectedOperatingUnit(row.locationId))
    .map(row => row.locationId);
  const destinationSet = new Set(targetDestinationFromYard);
  const activeDestinationsInOpUnits = getDestinations().filter(dest => dest.active && destinationSet.has(dest.id));
  return activeDestinationsInOpUnits;
}