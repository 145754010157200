import React from 'react';
import { connect } from 'react-redux';
import flatten from 'lodash/flatten';
import { BLOCK } from '../constants/typeConstants';
import SleeperLoadHelper from '../utils/sleeperLoadHelper';
import { matchesSelectedOperatingUnit } from '../utils/operatingUnitHelper';

class LoadInventoryStatus extends React.Component {
  
  getBlockLoadStates() {
    const scenarioId = this.props.currentScenarioId;
    const blockLoads = this.props.blockLoadState.byId[scenarioId];
    if (blockLoads) {
      const allBlockLoads = Object.keys(blockLoads)
        .filter(blockId => matchesSelectedOperatingUnit(blockId))
        .map(key => blockLoads[key].blockLoads);
      return flatten(allBlockLoads);
    } else {
      return [];
    }
  }
  
  calculateGrandTotalAssignedLoads() {
    const blockLoads = this.getBlockLoadStates();
    const grandTotal = blockLoads.reduce((sum, currentBlockLoad) => sum + currentBlockLoad.assigned, 0);
    return grandTotal;
  }


  calculateGrandTotalRemainingInventory() {
    const blockLoadState = this.getBlockLoadStates();
    const totalRemainingInventory = blockLoadState.reduce((loadsRemaining, currentBlockLoad) => loadsRemaining + (currentBlockLoad.loads - currentBlockLoad.assigned), 0);
    return totalRemainingInventory;
  }

  calculateTotalScheduledBlocks() {
    const scheduleRows = this.props.scheduleRows;
    const total = scheduleRows.reduce((total, scheduleRow) => {
      const blockTotal = scheduleRow.schedule.reduce((blockCount, scheduleItem) => {
        if (scheduleItem.type === BLOCK && matchesSelectedOperatingUnit(scheduleItem.locationId)) {
          return blockCount += 1;
        } else {
          return blockCount;
        }
      }, 0);
      return total + blockTotal;
    }, 0);
    return total;
  }

  getActiveYardsInOpUnitSet() {
    const sleeperLoadHelper = new SleeperLoadHelper(this.props.yards, this.props.selectedOperatingUnitIds, this.props.assignment);
    const active = sleeperLoadHelper.getActiveYardsInSelectedOpUnits();
    const activeYardsInSelectedOpUnitIdSet = new Set(active.map(a => a.id));
    return activeYardsInSelectedOpUnitIdSet;
  }

  getAvailableSleeperLoads() {
    const activeYardsInSelectedOpUnitIdSet = this.getActiveYardsInOpUnitSet();
    const isActiveInOpUnit = (id) => activeYardsInSelectedOpUnitIdSet.has(id);
    const sleeperLoads = this.props.sleeperLoads;
    const availableSleeperLoads = sleeperLoads.filter(load => isActiveInOpUnit(load.yardId));
    return availableSleeperLoads;
  }

  getSleeperInventoryTotal() {
    const availableSleeperLoads = this.getAvailableSleeperLoads();
    const totalSleeperLoads =  availableSleeperLoads.length;
    const assigned = availableSleeperLoads.filter(load => load.tripCount > 0).length;
    const diff = totalSleeperLoads - assigned;
    return diff;
  }

  getScheduledSleepersTotal() {
    const yardsToDestinations = this.props.yardsToDestinations;
    const yardsInOpUnits = this.getActiveYardsInOpUnitSet();
    const matchingYards = yardsToDestinations.filter(trip => yardsInOpUnits.has(trip.yardId));
    return matchingYards.length;
  }

  getTruckCountInOperatingUnit(operatingUnitId) {
    const truckIds = this.props.scheduleRows.map(x => x.truckId);
    const scheduled = this.props.assignment.filter(assignment => assignment.operatingUnitId === operatingUnitId && truckIds.includes(assignment.configObjectId));
    return scheduled.length;
  }

  getTruckCount() {
    const selectedOperatingUnits = this.props.operatingUnits
    .filter(x => this.props.selectedOperatingUnitIds.includes(x.id))
    .map(x => {
      return <div>{x.name}: {this.getTruckCountInOperatingUnit(x.id)}</div>
    });
    return selectedOperatingUnits;
  }

  getLoadInventoryStatus() {
    if (this.props.currentScenarioId !== '') {
      const inventoryCount = this.calculateGrandTotalRemainingInventory();
      const scheduledCount = this.calculateTotalScheduledBlocks();
      const sleepersDeliveredCount = this.getScheduledSleepersTotal();
      const truckCount = this.getTruckCount();
      return (
        <div className='inventory-status-and-count-container'>
          <div className='load-inventory-status'>
            <div>Inventory: {inventoryCount} ({this.getSleeperInventoryTotal()})</div>
            <div>Scheduled: {scheduledCount} ({sleepersDeliveredCount}) </div>
            <div>Delivered: {sleepersDeliveredCount + this.calculateGrandTotalAssignedLoads()} </div>
          </div>
          <div className='truck-count'>
            <div>Truck Count<br></br>
                Total: {this.props.scheduleRows.length}
            </div>
            <div className='truck-count-per-operatingUnit'>
              {truckCount}
            </div>
          </div>
        </div>
      );
    } else {
      return null; 
    }
  }

  render() {
    return this.getLoadInventoryStatus();
  }

}

const mapStateToProps = (state) => {
  const scenario = state.scenarioState.selectedScenario;
  const scenarioId = scenario? scenario.id: '';
  const scheduleRows = state.scheduleRowState;
  const trucks = state.trucks.truckList;
  return {
    yards: state.locations.yards,
    blockLoadState: state.blockLoadState,
    currentScenarioId: scenarioId,
    scheduleRows: scheduleRows,
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    yardsToDestinations: state.sleeperLoads.yardsToDestinations,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    assignment: state.operatingUnits.operatingUnitAssignment,
    operatingUnits: state.operatingUnits.operatingUnitList,
    trucks: trucks
  }
}

export default connect(mapStateToProps)(LoadInventoryStatus);