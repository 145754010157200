import axios from 'axios';
import store from '../store';
import { setTrailers } from '../reducers/trailers';

class TrailerService {
  static getTrailers() {
    return axios.get('/api/trailers')
      .then(trailers => {
        store.dispatch(setTrailers(trailers.data));
      })
      .catch(error => console.log(error));
  }
}

export default TrailerService;