
const SET_TRAILERS = 'SET_TRAILERS';

export function setTrailers(trailerData) {
  return {
    type: SET_TRAILERS,
    payload: trailerData
  }
}

const initialState = {
  trailerList: []
}

export default function trailers(state = initialState, action) {
  switch (action.type) {
    case SET_TRAILERS:
      return _setTrailers(state, action);
    default:
      return state;
  }
}

function _setTrailers(state, action) {
  return {
    ...state,
    trailerList: action.payload
  }
}