import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { REMOVE_OBJECT } from '../../constants/modalTypes';
import { closeModal } from '../../reducers/modalWindows';
import find from 'lodash/find';
import { removeBlockLocation, removeDestinationLocation, removeYard } from '../../reducers/locations';
import { BLOCK, DRYLAND, YARD, ERRORCODE } from '../../constants/typeConstants';
import { removeTruckObject } from '../../reducers/trucks';
import { removeDriver } from '../../reducers/drivers';
import { removeLoader } from '../../reducers/loaders';
import { removeOperator } from '../../reducers/operators';
import { removeOperatingUnit } from '../../reducers/operatingUnits';
import { removeMark } from '../../reducers/paintMarks';
import { removeErrorCode } from '../../reducers/errorCodes';
import { removeVendor } from '../../reducers/vendors';
import { removeHammerMark } from '../../reducers/hammerMarks';
import { removeContractor } from '../../reducers/contractors';
import { removeFaller } from '../../reducers/fallers';
import { removeYarder } from '../../reducers/yarders';
import { removeProcessor } from '../../reducers/processors';
import { removeTruckCompany } from '../../reducers/truckCompanies';

const TRUCK = 'TRUCK';
const DRIVER = 'DRIVER';
const LOADER = 'LOADER';
const OPERATOR = 'OPERATOR';
const OPERATINGUNIT = 'OPERATINGUNIT';
const PAINTMARK = 'PAINTMARK';
const VENDOR = 'VENDOR';
const HAMMERMARK = 'HAMMERMARK';
const CONTRACTOR = 'CONTRACTOR';
const FALLER = 'FALLER';
const YARDER = 'YARDER';
const PROCESSOR = 'PROCESSOR';
const TRUCKCOMPANY = 'TRUCKCOMPANY';

class RemoveObjectPromptModal extends React.Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#root');
  }

  getLocation(id) {
    const combined = [...this.props.blocks, ...this.props.destinations, ...this.props.yards];
    const result = find(combined, {id: id});
    if (result) {
      return result;
    } else {
      return null;
    }
  }

  getTruck(id) {
    const truck = find(this.props.trucks, {id: id});
    if (truck) {
      truck.type = TRUCK;
      return truck;
    } else {
      return null;
    }
  }

  getDriver(id) {
    const driver = find(this.props.drivers, {id: id});
    if (driver) {
      driver.type = DRIVER;
      return driver;
    } else {
      return null;
    }
  }

  getLoader(id) {
    const loader = find(this.props.loaders, {id: id});
    if (loader) {
      loader.type = LOADER;
      return loader;
    } else {
      return null;
    }
  }

  getOperator(id) {
    const operator = find(this.props.operators, {id: id});
    if (operator) {
      operator.type = OPERATOR;
      return operator;
    } else {
      return null;
    }
  }

  getOperatingUnit(id) {
    const operatingUnit = find(this.props.operatingUnits, {id: id});
    if (operatingUnit) {
      operatingUnit.type = OPERATINGUNIT;
      return operatingUnit;
    } else {
      return null;
    }
  }

  getErrorCode(id) {
    const errorCode = find(this.props.errorCodes, {id : id});
    if (errorCode) {
      errorCode.type = ERRORCODE;
      errorCode.name = errorCode.code;
      return errorCode;
    } else {
      return null;
    }
    
  }

  
  getPaintMark(id) {
    const paintMrk = find(this.props.paintMarks, {id: id});
    if (paintMrk) {
      paintMrk.type = PAINTMARK;
      paintMrk.name = paintMrk.mark;
      return paintMrk;
    } else {
      return null;
    }
  }

  getVendor(id) {
    const vendor = find(this.props.vendors, {id: id});
    if (vendor) {
      vendor.type = VENDOR;
      return vendor;
    } else {
      return null;
    }
  }

  getHammerMark(id) {
    const hammerMark = find(this.props.hammerMarks, {id: id});
    if (hammerMark) {
      hammerMark.type = HAMMERMARK;
      return hammerMark;
    } else {
      return null;
    }
  }

  getContractor(id) {
    const contractor = find(this.props.contractors, {id: id});
    if (contractor) {
      contractor.type = CONTRACTOR;
      return contractor;
    } else {
      return null;
    }
  }

  getFaller(id) {
    const faller = find(this.props.fallers, {id: id});
    if (faller) {
      faller.type = FALLER;
      return faller;
    } else {
      return null;
    }
  }

  getYarder(id) {
    const yarder = find(this.props.yarders, {id: id});
    if (yarder) {
      yarder.type = YARDER;
      return yarder;
    } else {
      return null;
    }
  }

  getProcessor(id) {
    const processor = find(this.props.processors, {id: id});
    if (processor) {
      processor.type = PROCESSOR;
      return processor;
    } else {
      return null;
    }
  }

  getTruckCompany(id) {
    const truckCompany = find(this.props.truckCompanies, {id: id});
    if (truckCompany) {
      truckCompany.type = TRUCKCOMPANY;
      return truckCompany;
    } else {
      return null;
    }
  }

  getObject(id) {
    const location = this.getLocation(id);
    const truck = this.getTruck(id);
    const driver = this.getDriver(id);
    const loader = this.getLoader(id);
    const operator = this.getOperator(id);
    const operatingUnit = this.getOperatingUnit(id);
    const paintMark = this.getPaintMark(id);
    const errorCode = this.getErrorCode(id);
    const vendor = this.getVendor(id);
    const hammerMark = this.getHammerMark(id);
    const contractor = this.getContractor(id);
    const faller = this.getFaller(id);
    const yarder = this.getYarder(id);
    const processor = this.getProcessor(id);
    const truckCompany = this.getTruckCompany(id);
    if (location) {
      return location;
    } else if (truck) {
      return truck;
    } else if (driver) {
      return driver;
    } else if (loader) {
      return loader;
    } else if (operator) {
      return operator;
    } else if (operatingUnit) {
      return operatingUnit;
    } else if (errorCode) {
      return errorCode;
    } else if (paintMark) {
      return paintMark;
    } else if (vendor) {
      return vendor;
    } else if (hammerMark) {
      return hammerMark
    } else if (contractor) {
      return contractor
    } else if (faller) {
      return faller
    } else if (yarder) {
      return yarder
    } else if (processor) {
      return processor
    } else if (truckCompany) {
      return truckCompany
    }
  }

  getDeleteMessage(name) {
    return `Are you sure you want to remove ${name}?`;
  }

  remove(object) {
    if (object.type === BLOCK) {
      this.props.removeBlock(object.id);
    } else if (object.type === DRYLAND) {
      this.props.removeDestination(object.id);
    } else if (object.type === YARD) {
      this.props.removeYard(object.id)
    } else if (object.type === TRUCK) {
      this.props.removeTruck(object.id);
    } else if (object.type === DRIVER) {
      this.props.removeDriver(object.id);
    } else if (object.type === LOADER) {
      this.props.removeLoader(object.id);
    } else if (object.type === OPERATOR) {
      this.props.removeOperator(object.id);
    } else if (object.type === OPERATINGUNIT) {
      this.props.removeOperatingUnit(object.id);
    } else if (object.type === PAINTMARK) {
      this.props.removeMark(object.id);
    } else if (object.type === ERRORCODE) {
      this.props.removeErrorCode(object.id);
    } else if (object.type === VENDOR) {
      this.props.removeVendor(object.id);
    } else if (object.type === HAMMERMARK) {
      this.props.removeHammerMark(object.id);
    } else if (object.type === CONTRACTOR) {
      this.props.removeContractor(object.id);
    } else if (object.type === FALLER) {
      this.props.removeFaller(object.id);
    } else if (object.type === YARDER) {
      this.props.removeYarder(object.id);
    } else if (object.type === PROCESSOR) {
      this.props.removeProcessor(object.id);
    } else if (object.type === TRUCKCOMPANY) {
      this.props.removeTruckCompany(object.id);
    }

    if (this.props.onClickDone) {
      this.props.onClickDone();
    }
  }

  render() {
    const modalWindow = this.props.modalWindow;
    const id = modalWindow.targetId;
    if (modalWindow.modalType === REMOVE_OBJECT && modalWindow.isModalOpen) {
      const targetObject = this.getObject(id);
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            top: '140px',
            left: '135px',
            right: '60px',
            height: '300px',
            width: '385px'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
          <div className='remove-prompt-modal'>
            <p>{this.getDeleteMessage(targetObject.name)}</p>
            <div className='delete-modal-buttons'>
              <button className='cancel-button' onClick={() => this.props.closeModal()}>Cancel</button>
              <button className='delete-button' onClick={() => this.remove(targetObject)}>OK</button>
            </div>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    blocks: state.locations.blocks,
    destinations: state.locations.destinations,
    yards: state.locations.yards,
    trucks: state.trucks.truckList,
    drivers: state.drivers.driverList,
    loaders: state.loaders.loaderList,
    operators: state.operators,
    operatingUnits: state.operatingUnits.operatingUnitList,
    paintMarks: state.paintMarks.paintMarkList,
    errorCodes: state.errorCodes.errorCodeList,
    vendors: state.vendors.vendorsList,
    hammerMarks: state.hammerMarks.hammerMarksList,
    contractors: state.contractors.contractorsList,
    fallers: state.fallers.fallersList,
    yarders: state.yarders.yardersList,
    processors: state.processors.processorsList,
    truckCompanies: state.truckCompanies.truckCompaniesList
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    removeBlock: (targetId) => {
      dispatch(closeModal());
      dispatch(removeBlockLocation(targetId));
    },
    removeDestination: (targetId) => {
      dispatch(closeModal());
      dispatch(removeDestinationLocation(targetId));
    },
    removeTruck: (id) => {
      dispatch(closeModal());
      dispatch(removeTruckObject(id));
    },
    removeDriver: (id) => {
      dispatch(closeModal());
      dispatch(removeDriver(id));
    },
    removeLoader: (id) => {
      dispatch(closeModal());
      dispatch(removeLoader(id));
    },
    removeYard: (id) => {
      dispatch(closeModal());
      dispatch(removeYard(id));
    },
    removeOperator: (id) => {
      dispatch(closeModal());
      dispatch(removeOperator(id));
    },
    removeOperatingUnit: (id) => {
      dispatch(closeModal());
      dispatch(removeOperatingUnit(id));
    },
    removeErrorCode: (id) => {
      dispatch(closeModal());
      dispatch(removeErrorCode(id));
    },
    removeMark: (id) => {
      dispatch(closeModal());
      dispatch(removeMark(id));
    },
    removeVendor: (id) => {
      dispatch(closeModal());
      dispatch(removeVendor(id));
    },
    removeHammerMark: (id) => {
      dispatch(closeModal());
      dispatch(removeHammerMark(id));
    },
    removeContractor: (id) => {
      dispatch(closeModal());
      dispatch(removeContractor(id));
    },
    removeFaller: (id) => {
      dispatch(closeModal());
      dispatch(removeFaller(id));
    },
    removeYarder: (id) => {
      dispatch(closeModal());
      dispatch(removeYarder(id));
    },
    removeProcessor: (id) => {
      dispatch(closeModal());
      dispatch(removeProcessor(id));
    },
    removeTruckCompany: (id) => {
      dispatch(closeModal());
      dispatch(removeTruckCompany(id));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveObjectPromptModal);
