import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import ReportFieldEditor from '../../components/fieldEditors/ReportFieldEditor';
import uniqid from 'uniqid';
import { addReport, updateReport, bulkActivateReports } from '../../reducers/reports';
import { openRemoveObjectModal, openBulkActivationModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';

class ReportsConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportIdSelected: '',
      isAddingNewReport: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousReports = previousProps.reports.map(report => report.id);
    const currentReports = this.props.reports.map(report => report.id);
    if (currentReports.length > previousReports.length) {
      const diff = difference(currentReports, previousReports);
      if (diff.length > 0) {
        const newReport = diff[0];
        this.setState({
          reportIdSelected: newReport,
          isAddingNewReport: false
        });
      }
    }
  }

  addNewReport() {
    this.setState({
      reportIdSelected: '',
      isAddingNewReport: true
    });
  }

  selectReport(id) {
    this.setState({
      reportIdSelected: id,
      isAddingNewReport: false
    });
  }

  getSelectedReport() {
    const report = find(this.props.reports, {id: this.state.reportIdSelected});
    return report;
  }

  getSelectedReportName() {
    const report = this.getSelectedReport();
    if (report) {
      return report.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedReport() {
    return this.state.reportIdSelected !== '';
  }

  getReportsList() {
    const options = this.props.reports
    .map(report => {
      return {
        value: report.id,
        label: report.name
      }
    });

    const handleChange = (selectedOption) => this.selectReport(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedReportName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const report = this.getSelectedReport();
    if (report) {
      return `Edit report ${report.name}`;
    } else if (this.state.isAddingNewReport) {
      return 'Add a new report';
    } else {
      return 'Select a report to edit';
    }
  }

  getReportEditPanel() {
    if (this.hasSelectedReport() && !this.state.isAddingNewReport) {
      return (
        <div>
          <ReportFieldEditor
            key={uniqid()}
            reportFieldProps={this.getSelectedReport()}
            clickDone={(state) => this.props.update(state)}
            clickCancel={() => this.selectReport('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewReport) {
      return (
        <div>
          <ReportFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.props.add(state)}
            clickCancel={() => this.selectReport('')}
          />
        </div>
      );
    }
  }


  render() {
    return (
      <div className='report-configuration configuration-forms'>
        
        <div className='report-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='report-list'>
          {this.getReportsList()}
        </div>
        <div className='report-edit-panel'>
          {this.getReportEditPanel()}
          </div>
        </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    reports: state.reports.reportsList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addReport(state));
    },
    update: (state) => {
      dispatch(updateReport(state));
    },
    removeReportModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateReports(ids));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsConfiguration);