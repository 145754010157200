import sortBy from 'lodash/sortBy';

const SET_MARKS = 'SET_MARKS';
export const ADD_MARK = 'ADD_MARK';
export const ADD_MARK_SUCCESS = 'ADD_MARK_SUCCESS';
export const UPDATE_MARK = 'UPDATE_MARK';
export const UPDATE_MARK_SUCCESS = 'UPDATE_MARK_SUCCESS';
export const REMOVE_MARK = 'REMOVE_MARK';
export const REMOVE_MARK_SUCCESS = 'REMOVE_MARK_SUCCESS';
export const BULK_ACTIVATE_MARKS = 'BULK_ACTIVATE_MARKS';


export function setMarks(marks) {
  return {
    type: SET_MARKS,
    payload: marks
  }
}

export function addMark(mark) {
  return {
    type: ADD_MARK,
    payload: mark
  }
}

export function addMarkSuccess(mark) {
  return {
    type: ADD_MARK_SUCCESS,
    payload: mark
  }
}

export function updateMark(mark) {
  return {
    type: UPDATE_MARK,
    payload: mark
  }
}

export function updateMarkSuccess(mark) {
  return {
    type: UPDATE_MARK_SUCCESS,
    payload: mark
  }
}

export function removeMark(markId) {
  return {
    type: REMOVE_MARK,
    payload: markId
  }
}

export function removeMarkSuccess(mark) {
  return {
    type: REMOVE_MARK_SUCCESS,
    payload: mark
  }
}

export function bulkActivateMarks(markIds) {
  return {
    type: BULK_ACTIVATE_MARKS,
    payload: markIds
  }
}

export default function paintMarks(state = {
  paintMarkList: []
}, action) {
  switch (action.type) {
    case SET_MARKS:
      return _setMarks(state, action);
    case ADD_MARK_SUCCESS:
      return _addMark(state, action);
    case UPDATE_MARK_SUCCESS:
      return _updateMark(state, action);
    case REMOVE_MARK_SUCCESS:
      return _removeMark(state, action);
    default:
      return state;
  }
}

function _removeMark(state, action) {
  const updatedList = state.paintMarkList
  return {
    ...state,
    paintMarkList: updatedList
  }
}

function _updateMark(state, action) {
  const updatedMark = action.payload;
  const updatedList = state.paintMarkList.map(mark => {
    if (mark.id === updatedMark.id) {
      return updatedMark;
    } else {
      return mark;
    }
  });

  const sortedMarks = sortBy(updatedList, mark => mark.mark);
  return {
    ...state,
    paintMarkList: sortedMarks
  }
}

function _addMark(state, action) {
  const marks = [...state.paintMarkList, action.payload];
  const sortedMarks = sortBy(marks, mark => mark.mark);
  return {
    ...state,
    paintMarkList: sortedMarks
  }
}

function _setMarks(state, action) {
  return {
    ...state,
    paintMarkList: action.payload
  }
}