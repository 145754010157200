import { rowLocationTransitCalculation } from '../calculations/rowLocationTransitCalculation';
import {
  ADD_LOCATION_TO_SCHEDULE,
  saveSchedule,
  updateSchedule,
  UPDATE_ACTIVITY,
  SWAP_ACTIVITY,
  updateScheduleSwap
} from '../reducers/schedule';
import { saveScheduleFormatter } from './saveScheduleFormatter';
import AddScheduleValidator from './addScheduleValidator';
import { toast } from 'react-toastify';
import { BLOCK } from '../constants/typeConstants';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const addScheduleValidator = new AddScheduleValidator();

function hasLoaderOperatorAssigned(storeState, action) {
  const loaderOperatorAssignments = storeState.loaderOperatorAssignment.loaderOperatorAssignmentList;
  const blockId = action.payload.locationId;
  const loadersAssigned = loaderOperatorAssignments.filter(x => x.blockId === blockId);
  const loadersWithOperators = loadersAssigned.filter(x => {
    const targetAssign = find(loaderOperatorAssignments, {loaderId: x.loaderId});
    return !isNil(targetAssign);
  });

  return !isEmpty(loadersWithOperators);
}

export const addLocationGuard = store => next => action => {

  if (action.type === ADD_LOCATION_TO_SCHEDULE) {
    let updatedAction =  rowLocationTransitCalculation(store.getState(), action);
    let transitValue = updatedAction.payload.transit;

    if (updatedAction.payload.type === BLOCK && !hasLoaderOperatorAssigned(store.getState(), updatedAction)) {
      toast.error('Block does not have any loaders or operators assigned');
    } else {
      if (transitValue !== null) {
        const scenarioDate = store.getState().scenarioState.currentDateSelected;
        const beginWorkHour = store.getState().workHoursForSchedule.beginHour;
        updatedAction.payload.scenarioDate = scenarioDate;
        updatedAction.payload.beginWorkHour = beginWorkHour;
        updatedAction.payload.isAddingToSchedule = true;
  
        const validatorResult = addScheduleValidator.canAddToSchedule(updatedAction);
        if (validatorResult.canAddToSchedule) {
          next(updatedAction);
    
          const saveSchedulePayload = saveScheduleFormatter(store.getState(), updatedAction);
          store.dispatch(saveSchedule(saveSchedulePayload));
        } else {
          const errorMessage = `ERROR: ${validatorResult.errorMessage}`;
          toast.error(errorMessage, {
            autoClose: 10000,
            className: 'load-inventory-error'
          });
        }
      }
    }

  } else {
    next(action);
  }

};

export const updateActivity = store => next => action => {
  if (action.type === UPDATE_ACTIVITY) {
    if (!action.payload.model) {
      const saveFormat = saveScheduleFormatter(store.getState(), action);
      action.payload.model = saveFormat.model;
      store.dispatch(updateSchedule(saveFormat));
    }
  }
  next(action);
}

export const swapActivity = store => next => action => {
  if (action.type === SWAP_ACTIVITY) {
    if (!action.payload.model) {
      const saveFormat = saveScheduleFormatter(store.getState(), action);
      action.payload.model = saveFormat.model;
      store.dispatch(updateScheduleSwap(saveFormat));
    }
  }
  next(action);
}