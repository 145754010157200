import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

class FallerFieldEditor extends Component {
  constructor(props) {
    super(props);

    const fieldProps = props.fallerFieldProps? props.fallerFieldProps: {};
    const defaultFallerId = fieldProps.id? fieldProps.id: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultBlockId = fieldProps.blockId? fieldProps.blockId: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;

    this.state = {
      id: defaultFallerId,
      isModified: false,
      name: defaultName,
      blockId: defaultBlockId,
      active: defaultActive
    }

    this.changeFallerName = this.changeFallerName.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeFallerName(event) {
    const truncatedName = event.target.value.substring(0, 8);
    this.setState({name: truncatedName});
    this.setModified();
  }

  getAssignedBlocks() {
    const blocks = this.getBlocksByFallerId(this.state.id).filter(x => x.active && !x.isDeleted);
    if (isEmpty(blocks)) {
      return (<li key={'none'} style={{color: 'red'}}>N/A</li>);
    }
    else {
      const blockElements = blocks.map(block => {
        return <li key={block.id}>{block.name}</li>
      });
      return blockElements;
    }
  }

  getBlocksByFallerId(id) {
    const blockIds = this.props.blockFallers.filter(x => x.fallerId === id).map(x => x.blockId);
    const blocks = []
    if (blockIds) {
      blockIds.forEach(id => {
        const block = find(this.props.blocks, {id: id});
        if (block) {
          blocks.push(block);
        }
      });
    }
    return blocks;
  }
  
  
  render() {
    return (
      <React.Fragment>
        <div className='name-field faller-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeFallerName} />
        </div>
        <div style={{marginTop: 20}}>
          Assigned Blocks:
        </div>
        <div>
          <ul>
            {this.getAssignedBlocks()}
          </ul>
        </div>
        <div className='faller-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    blockFallers: state.blockProperties.blockFallersList
  }
}

export default connect(mapStateToProps)(FallerFieldEditor);

