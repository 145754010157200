import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import uniqid from 'uniqid';
import Select, { createFilter } from 'react-select';
import { openBulkActivationModal, openRemoveObjectModal, openDuplicateObjectNamesModal  } from '../../reducers/modalWindows';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import YardFieldEditor from '../../components/fieldEditors/YardFieldEditor';
import { addYard, updateYard, bulkActivateYards } from '../../reducers/locations';
import { YARD } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class YardsConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      yardSelected: '',
      isAddingNewYard: false,
    }

    this.addNewYard = this.addNewYard.bind(this);
  }

  componentDidUpdate(previousProps) {
    const previousYards = previousProps.yards.map(y => y.id);
    const currentYards = this.props.yards.map(y =>y.id);

    if (currentYards.length > previousYards.length) {
      const diff = difference(currentYards, previousYards);
      if (diff.length > 0) {
        const newYard = diff[0];
        this.setState({
          yardSelected: newYard,
          isAddingNewYard: false
        })
      }
    }
  }

  addNewYard() {
    this.setState({
      yardSelected: '',
      isAddingNewYard: true
    });
  }

  cancelSelection() {
    this.setState({
      yardSelected: '',
      isAddingNewYard: false
    });
  }

  getSelectedYard() {
    const yard = find(this.props.yards, {id: this.state.yardSelected});
    return yard? yard: {};
  }

  getSelectedYardName() {
    const yard = this.getSelectedYard();
    if (yard.name) {
      return yard.name;
    } else {
      return 'Select...';
    }
  }

  getPrompt() {
    const yard = this.getSelectedYard();
    if (yard.name) {
      return `Edit yard ${yard.name}`;
    } else if (this.state.isAddingNewYard) {
      return 'Add a new yard';
    } else {
      return 'Select a yard to edit';
    }
  }

  hasSelectedYard() {
    return this.state.yardSelected !== '';
  }

  getProps() {
    const yard = this.getSelectedYard();    if (yard) {
      return yard;
    } else {
      return {};
    }
  }

  checkExistence(state) {
    if (this.state.isAddingNewYard) {
      const yard = find(this.props.yards, {name: state.name});
      if (yard) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewYard) {
      if (this.getSelectedYardName() !== state.name) {
        const yard = find(this.props.yards, {name: state.name});
        if (yard) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getYardEditPanel() {
    const cancel = () => this.cancelSelection();
    if (this.hasSelectedYard() && !this.state.isAddingNewYard) {
      const assignedLocations = this.getAssignedLocations();
      return (
        <YardFieldEditor
          key={uniqid()}
          yardFieldProps={this.getProps()}
          clickDone={(state) => this.checkExistence(state)}
          clickCancel={cancel}>
          {assignedLocations}
        </YardFieldEditor>
      );
    } else if (this.state.isAddingNewYard) {
      return (
        <YardFieldEditor
          key={uniqid()}
          clickDone={(state) => this.checkExistence(state)}
          clickCancel={cancel} />
      );
    }
  }

  selectYard(id) {
    this.setState({
      yardSelected: id,
      isAddingNewYard: false
    })
  }

  getYardList() {
    const options = this.props.yards
    .filter(yard => !yard.isDeleted)
    .map(yard => {
      return {
        value: yard.id,
        label: yard.name
      }
    });

    const handleChange = (selectedOption) => this.selectYard(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedYardName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChange}
    />;
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      return <BulkActivationModal
        type={YARD}
        activationItems={this.props.yards.filter(yard => !yard.isDeleted)}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getRemoveYardButton() {
    if (this.hasSelectedYard()) {
      return (
        <button className='remove-yard' 
          onClick={() => this.props.removeYardModal(this.state.yardSelected)}>
          Remove Yard
        </button>
      );
    }
  }

  activate() {
    const yard = this.getProps();
    if (yard) {
      yard.active = !yard.active;
      this.props.update(yard);
    }
  }

  getActiveButton() {
    const yard = this.getSelectedYard();
    if (yard && yard.id) {
      const buttonName = yard.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getAssignedLocations() {
    if (this.hasSelectedYard() && !this.state.isAddingNewYard) {
      const locationsToYards = this.props.locationsToYards.filter(row => row.yardId === this.state.yardSelected);
      const locationIdSet = new Set(locationsToYards.map(row => row.locationId));
      const assignedBlocks = this.props.yards.filter(yard => locationIdSet.has(yard.id));
      const assignedDestinations = this.props.destinations.filter(destination => locationIdSet.has(destination.id));

      const createYardAssignRows = (assignedLocations) => {
        return (
          <div className='yard-assigned-container'>
            {assignedLocations.map(location => {
              const locationToYard = find(locationsToYards, {locationId: location.id});
              return (
                <div className='yard-assigned-row' key={location.id}>
                  <div className='location-name'>{location.name}</div>
                  <div className='yard-to-location-time'>{locationToYard.toYardTime}</div>
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <div className='yard-assignment'>
        {
          isEmpty(assignedBlocks) ? null : 
          <div className='yards-assigned-yard'>
            <div className='yard-assign-label'>Assigned Blocks</div>
            {createYardAssignRows(assignedBlocks)}
          </div>
        }
        {
          isEmpty(assignedDestinations) ? null : 
          <div className='yards-assigned-destination'>
            <div className='yard-assign-label'>Assigned Destinations</div>
            {createYardAssignRows(assignedDestinations)}
          </div>
        }
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='yards-configuration configuration-forms'>
        <button className='add-new-yard' onClick={this.addNewYard}>Add New Yard</button>
        <button className='bulk-activation-button'
          onClick={() => this.props.openBulkActivationModal(this.props.yards)}>
          Bulk Activation
        </button>
        <div className='yard-editor'>
          <p>{this.getPrompt()}</p>
          <div className='yard-list'>
            {this.getYardList()}
          </div>
          <div className='yard-edit-panel'>
            {this.getYardEditPanel()}
            <div className='side-panel'>
              <div className='yard-config-buttons'>
                {this.getActiveButton()}
                {this.getRemoveYardButton()}
              </div>
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.cancelSelection()} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    yards: state.locations.yards,
    modalWindow: state.modalWindows,
    locationsToYards: state.locations.locationsToYards,
    destinations: state.locations.destinations
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openBulkActivationModal: () => {
      dispatch(openBulkActivationModal())
    },
    removeYardModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    },
    add: (state) => dispatch(addYard(state)),
    update: (state) => dispatch(updateYard(state)),
    bulkActivate: (yardIds) => dispatch(bulkActivateYards(yardIds))
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YardsConfiguration);