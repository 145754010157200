import { SET_SLEEPER_LOADS, setYardsToDestination, ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS, IMPORT_SLEEPER_LOADS_SUCCESSFUL, fetchSleeperLoads } from "../reducers/sleeperLoads";
import { emptyUuid } from "../utils/uuidHelper";
import { DRYLAND, YARD } from "../constants/typeConstants";

function getYardsToDestinations(scheduleRows) {
  const yardsToDestinations = scheduleRows.reduce((acc, scheduleRow) => {
    const scheduleNoTransit = scheduleRow.schedule.filter(activity => activity.locationId !== emptyUuid);
    scheduleNoTransit.forEach((activity, index, activityArray) => {
      if (index < activityArray.length - 1) {
        const next = activityArray[index + 1];
        const hasYardToDestination = activity.type === YARD && next.type === DRYLAND;
        if (hasYardToDestination) {
          const info = {
            yardStart: activity.dateObjects.locationStartTime,
            yardId: activity.locationId,
            destinationId: next.locationId,
            truckId: scheduleRow.truckId
          }
          acc.push(info);
        }
      }
    });
    return acc;
  }, []);

  return yardsToDestinations;
}

function mapYardsToDestinations(yardsToDestinations) {
  const map = new Map();

  yardsToDestinations.forEach(trip => {
    if (map.has(trip.yardId)) {
      const target = map.get(trip.yardId);
      target.push(trip);
    } else {
      map.set(trip.yardId, [trip]);
    }
  });

  return map;
}


export const setYardToDestination = store => next => action => {
  if (action.type === SET_SLEEPER_LOADS || action.type === ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS || action.type === IMPORT_SLEEPER_LOADS_SUCCESSFUL) {
    const scheduleRows = store.getState().scheduleRowState;
    const yardsToDestinations = getYardsToDestinations(scheduleRows);
    const mapY2d = mapYardsToDestinations(yardsToDestinations);
    const sleeperLoads = action.payload.map(load => {
      return {
        ...load,
        tripCount: 0
      };
    });

    sleeperLoads.forEach(load => {
      if (mapY2d.has(load.yardId)) {
        const trips = mapY2d.get(load.yardId);
        trips.forEach(trip => {
          if (trip.truckId === load.truckId && trip.destinationId === load.destinationId) {
            load.tripCount += 1;
          } 
          trip.load = load;
        });
      }
    });

    store.dispatch(setYardsToDestination(yardsToDestinations));
    action.payload = sleeperLoads;
    if (action.type === IMPORT_SLEEPER_LOADS_SUCCESSFUL) {
      store.dispatch(fetchSleeperLoads(action.payload[0].scenarioId))
    }
  }

  next(action);
};