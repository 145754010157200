import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { SEND_PDF_EMAIL_MODAL } from '../../constants/modalTypes';

class SendPdfEmailConfirmationModal extends React.Component {

  sendPdf(props) {
    props.sendAction();
    this.props.closeModal();
  }

  render() {
    if (this.props.modalWindow.modalType === SEND_PDF_EMAIL_MODAL) {
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '170px',
            width: '320px',
            margin: 'auto'
          }}}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.props.closeModal()}>
          <h3 className='object-name'>{this.props.modalWindow.name}</h3>
          <div className='confirmation-text'>
            Are you sure you want to send the PDF?
          </div>
          <div className='send-pdf-modal-buttons'>
            <button onClick={() => this.props.closeModal()} className='cancel-button'>Cancel</button>
            <button onClick={() => this.sendPdf(this.props.modalWindow)}className='send-button'>Send</button>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPdfEmailConfirmationModal);