import React from 'react';
import { connect } from 'react-redux';
import { addLoader, updateLoader, bulkActivateLoaders } from '../../reducers/loaders';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal} from '../../reducers/modalWindows';
import LoaderFieldEditor from '../../components/fieldEditors/LoaderFieldEditor';
import find from 'lodash/find';
import uniqid from 'uniqid';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import difference from 'lodash/difference';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import Select, { createFilter } from 'react-select';
import { LOADER } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class LoadersConfiguration extends React.Component {

  static defaultProps = {
    loaderOperatorAssignment: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      loaderSelected: '',
      isAddingNewLoader: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousLoaders = previousProps.loaders.map(l => l.id);
    const currentLoaders = this.props.loaders.map(l => l.id);
    if (currentLoaders.length > previousLoaders.length) {
      const diff = difference(currentLoaders, previousLoaders);
      if (diff.length > 0) {
        const newLoader = diff[0];
        this.setState({
          loaderSelected: newLoader,
          isAddingNewLoader: false
        });
      }
    }
  }

  addNewLoader() {
    this.setState({
      loaderSelected: '',
      isAddingNewLoader: true
    });
  }

  selectLoader(id) {
    this.setState({
      loaderSelected: id,
      isAddingNewLoader: false
    });
  }

  getLoaderButtonStyle(id) {
    if (id === this.state.loaderSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getLoaderList() {
    const options = this.props.loaders
    .filter(loader => !loader.isDeleted)
    .map(loader => {
      return {
        value: loader.id,
        label: loader.name
      }
    });

    const handleChange = (selectedOption) => this.selectLoader(selectedOption.value);

    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedLoaderName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChange}
    />;
  }

  getSelectedLoader() {
    const loader = find(this.props.loaders, {id: this.state.loaderSelected});
    return loader;
  }

  getSelectedLoaderName() {
    const loader = this.getSelectedLoader();
    if (loader) {
      return loader.name;
    } else {
      return 'Select...';
    }
  }

  getLoaderProps() {
    const loader = this.getSelectedLoader();
    if (loader) {
      const operatorBlockPair = this.props.loaderOperatorAssignment
      .filter(assignment => assignment.loaderId === this.state.loaderSelected)
      .map(function(item) {
        return {
          blockId: item.blockId,
          operatorId: item.loaderOperatorId
        }
      });
      
      return {
        operatorBlockPair: operatorBlockPair,
        loaderId: loader.id,
        name: loader.name,
        comments: loader.comments,
        active: loader.active,
      }
    } else {
      return {};
    }
  }

  getLoaderEditPanel() {
    const cancel = () => this.selectLoader('');
    if (this.state.loaderSelected !== '' && !this.state.isAddingNewLoader) {
      return (
        <div>
          <LoaderFieldEditor 
            key={uniqid()}
            loaderFieldProps={this.getLoaderProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={cancel}
          />
        </div>
      );
    } else if (this.state.isAddingNewLoader) {
      return (
        <div>
          <p>Add New Loader</p>
          <LoaderFieldEditor
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={cancel}
          />
        </div>
      );
    }
  }

  activate() {
    const loader = this.getSelectedLoader();
    if (loader) {
      loader.active = !loader.active;
      this.props.update(loader);
    }
  }

  getActivateButton() {
    const loader = this.getSelectedLoader();
    if (loader) {
      const buttonName = loader.active? 'Deactivate' : 'Activate';
      return <button className ='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveLoaderButton() {
    const loaderId = this.state.loaderSelected;
    if (loaderId !== '') {
      return (
        <button className='remove-loader'
          onClick={() => this.props.remove(loaderId)}>
          Remove Loader
        </button>
      );
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const addIdToLoader = this.props.loaders
      .filter(loader => !loader.isDeleted)
      .map(loader => {
        return {
          ...loader,
          id: loader.id
        }
      });
      return <BulkActivationModal
        type={LOADER}
        activationItems={addIdToLoader}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
        />;
    } else {
      return null;
    }
  }

  getPrompt() {
    const loader = this.getSelectedLoader();
    if (loader) {
      return `Edit loader ${loader.name}`;
    } else if (this.state.isAddingNewLoader) {
      return 'Add a new loader';
    } else {
      return 'Select a loader to edit';
    }
  }

  checkExistence(state) {
    if (this.state.isAddingNewLoader) {
      const loader = find(this.props.loaders, {name: state.name});
      if (loader) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewLoader) {
      if (this.getSelectedLoaderName() !== state.name) {
        const loader = find(this.props.loaders, {name: state.name});
        if (loader) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  render() {
    return (
      <div className='loaders-configuration configuration-forms'>
        <button className='add-new-loader' onClick={() => this.addNewLoader()}>Add New Loader</button>
        <button className='bulk-activation-button'
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='loader-editor'>
          <p>{this.getPrompt()}</p>
          <div className='loader-list'>
            {this.getLoaderList()}
          </div>
          <div className='loader-edit-panel'>
            {this.getLoaderEditPanel()}
            <div className='loader-config-buttons'>
              {this.getActivateButton()}
              {this.getRemoveLoaderButton()}
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectLoader('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaders: state.loaders.loaderList,
    modalWindow: state.modalWindows,
    loaderOperatorAssignment: state.loaderOperatorAssignment.loaderOperatorAssignmentList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addLoader(state));
    },
    update: (state) => {
      dispatch(updateLoader(state));
    },
    remove: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems))
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateLoaders(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadersConfiguration);