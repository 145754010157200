import React from 'react';
import { connect } from 'react-redux';
import {ContextMenuTrigger, ContextMenu, MenuItem} from 'react-contextmenu';
import { getAuthService } from '../services/AuthService';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.authService = getAuthService();
  }

  logout() {
    this.authService.logout();
  }

  render() {
    const id='user-menu';
    return (
      <div className='user-settings-menu'>
        <ContextMenuTrigger id={id} holdToDisplay={0}>
          <button className='user-settings-button'>
            User
          </button>
        </ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem attributes={{className: 'context-menu-title'}}>{this.props.username}</MenuItem>
          <MenuItem onClick={this.logout}>Logout</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.users.user.name
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     logout: (sessionId) => {
//       dispatch(logout(sessionId));
//     }
//   }
// }

//export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
export default connect(mapStateToProps)(UserSettings);