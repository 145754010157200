import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { ASSIGN_LOADER_MODAL } from '../../constants/modalTypes';
import { addLocationToSchedule } from '../../reducers/schedule';
import { emptyUuid } from '../../utils/uuidHelper';
import LoaderPicker from '../LoaderPicker';

class AssignLoaderModal extends React.Component {
  constructor(props) {
    super(props);

    this.nullId = emptyUuid;

    ReactModal.setAppElement('#root');

    this.state = {
      loaderId: this.nullId,
      isModified: false
    }

    this.handleChangeLoader = this.handleChangeLoader.bind(this);
  }

  resetState() {
    this.setState({
      loaderId: this.nullId,
      isModified: false
    })
  }

  modifyState() {
    this.setState({isModified: true});
  }

  handleChangeLoader(event) {
    this.modifyState();
    this.setState({
      loaderId: event.target.value
    });
  }

  handleClickDone(modalWindow) {
    const loaderProps = {
      blockId: modalWindow.locationId,
      rowId: modalWindow.rowId,
      activityId: modalWindow.activityId,
      loaderId: this.state.loaderId
    }
    this.resetState();
    this.props.assignLoader(loaderProps);
  }

  handleClose() {
    this.resetState();
    this.props.closeModal();
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === ASSIGN_LOADER_MODAL && modalWindow.isModalOpen) { 
      const blockId = modalWindow.locationId;
      return (
        <ReactModal
        isOpen={true}
        style={{content: {
          right: '60px',
          height: '100px',
          width: '350px',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.handleClose()}>
        <div className='loader-picker-modal'>
          <div className='loader-picker-title'>Choose loader</div>
          <LoaderPicker blockId={blockId} 
            handleChangeLoader={this.handleChangeLoader}
          />
          <div className='loader-modal-buttons'>
            <button className='cancel-button' onClick={() => this.handleClose()}>Cancel</button>
            <button className='done-button' onClick={() => this.handleClickDone(modalWindow)}>Done</button>
          </div>
        </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    assignLoader: ({rowId, blockId, loaderId}) => {
      dispatch(closeModal());
      dispatch(addLocationToSchedule(rowId, blockId, loaderId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignLoaderModal);