import sortBy from 'lodash/sortBy';

const SET_DRYLAND_LOCATIONS = 'SET_DRYLAND_LOCATIONS';
const SET_BLOCK_LOCATIONS = 'SET_BLOCK_LOCATIONS';
const SET_YARD_LOCATIONS = 'SET_YARD_LOCATIONS';
const SET_BLOCKS_TO_DESTINATIONS = 'SET_BLOCKS_TO_DESTINATIONS';
const SET_LOCATIONS_TO_YARDS = 'SET_LOCATIONS_TO_YARDS';
export const ADD_NEW_BLOCK_LOCATION = 'ADD_NEW_BLOCK_LOCATION';
export const REMOVE_BLOCK_LOCATION = 'REMOVE_BLOCK_LOCATION';
export const UPDATE_BLOCK_LOCATION = 'UPDATE_BLOCK_LOCATION';
export const ADD_NEW_DESTINATION_LOCATION = 'ADD_NEW_DESTINATION_LOCATION';
export const UPDATE_DESTINATION_LOCATION = 'UPDATE_DESTINATION_LOCATION';
export const REMOVE_DESTINATION_LOCATION = 'REMOVE_DESTINATION_LOCATION';
export const BULK_ACTIVATE_BLOCKS = 'BULK_ACTIVATE_BLOCKS';
export const BULK_ACTIVATE_DESTINATIONS = 'BULK_ACTIVATE_DESTINATIONS';

export const ADD_NEW_YARD = 'ADD_NEW_YARD';
export const UPDATE_YARD = 'UPDATE_YARD';
export const BULK_ACTIVATE_YARDS = 'BULK_ACTIVATE_YARDS';
export const REMOVE_YARD = 'REMOVE_YARD';
const ADD_NEW_YARD_SUCCESS = 'ADD_NEW_YARD_SUCCESS';
const UPDATE_YARD_SUCCESS = 'UPDATE_YARD_SUCCESS';
const REMOVE_YARD_SUCCESS = 'REMOVE_YARD_SUCCESS';
const UPDATE_LOCATIONS_TO_YARDS = 'UPDATE_LOCATIONS_TO_YARDS';


export function setDrylandLocations(drylands) {
  return {
    type: SET_DRYLAND_LOCATIONS,
    payload: drylands
  }
}

export function setBlockLocations(blocks) {
  return {
    type: SET_BLOCK_LOCATIONS,
    payload: blocks
  }
}

export function setYardLocations(yards) {
  return {
    type: SET_YARD_LOCATIONS,
    payload: yards
  }
}

export function setBlocksToDestinations(blocksToDestinations) {
  return {
    type: SET_BLOCKS_TO_DESTINATIONS,
    payload: blocksToDestinations
  }
}

export function setLocationsToYards(locationsToYards) {
  return {
    type: SET_LOCATIONS_TO_YARDS,
    payload: locationsToYards
  }
}

export function updateLocationsToYards(locationsToYards) {
  return {
    type: UPDATE_LOCATIONS_TO_YARDS,
    payload: locationsToYards
  }
}

export function addNewBlockLocation(block) {
  return {
    type: ADD_NEW_BLOCK_LOCATION,
    payload: block
  }
}

export function removeBlockLocation(blockId) {
  return {
    type: REMOVE_BLOCK_LOCATION,
    payload: blockId
  }
}

export function updateBlockLocation(block) {
  return {
    type: UPDATE_BLOCK_LOCATION,
    payload: block
  }
}

export function addNewDestinationLocation(destination) {
  return {
    type: ADD_NEW_DESTINATION_LOCATION,
    payload: destination
  }
}

export function removeDestinationLocation(destination) {
  return {
    type: REMOVE_DESTINATION_LOCATION,
    payload: destination
  }
}

export function updateDestinationLocation(destination) {
  return {
    type: UPDATE_DESTINATION_LOCATION,
    payload: destination
  }
}

export function bulkActivateBlocks(ids) {
  return {
    type: BULK_ACTIVATE_BLOCKS,
    payload: ids
  }
}

export function bulkActivateDestinations(ids) {
  return {
    type: BULK_ACTIVATE_DESTINATIONS,
    payload: ids
  }
}

export function addYard(yard) {
  return {
    type: ADD_NEW_YARD,
    payload: yard
  }
}

export function addYardSuccess(yard) {
  return {
    type: ADD_NEW_YARD_SUCCESS,
    payload: yard
  }
}

export function removeYard(yardId) {
  return {
    type: REMOVE_YARD,
    payload: yardId
  }
}

export function removeYardSuccess(yard) {
  return {
    type: REMOVE_YARD_SUCCESS,
    payload: yard
  }
}

export function updateYard(yard) {
  return {
    type: UPDATE_YARD,
    payload: yard
  }
}

export function updateYardSuccess(yard) {
  return {
    type: UPDATE_YARD_SUCCESS,
    payload: yard
  }
}

export function bulkActivateYards(yardIds) {
  return {
    type: BULK_ACTIVATE_YARDS,
    payload: yardIds
  }
}

export default function locations(state = {
  destinations: [],
  blocks: [],
  yards: [],
  blocksToDestinations: [],
  locationsToYards: []
}, action) {
  switch (action.type) {
    case SET_DRYLAND_LOCATIONS:
      return setDestinationHelper(state, action, 'destinations');
    case SET_BLOCK_LOCATIONS:
      return setDestinationHelper(state, action, 'blocks');
    case SET_YARD_LOCATIONS:
      return setDestinationHelper(state, action, 'yards');
    case SET_BLOCKS_TO_DESTINATIONS:
      return setBlocksToDestinationsReducer(state, action);
    case SET_LOCATIONS_TO_YARDS:
      return _setLocationsToYards(state, action);
    case UPDATE_LOCATIONS_TO_YARDS:
      return _updateLocationsToYards(state, action);
    case ADD_NEW_YARD_SUCCESS:
      return _addYard(state, action);
    case REMOVE_YARD_SUCCESS:
      return _removeYard(state, action);
    case UPDATE_YARD_SUCCESS:
      return _updateYard(state, action);
    default: 
      return state;
  }
}

function _updateLocationsToYards(state, action) {
  const locationsToYards = state.locationsToYards;
  const filtered = locationsToYards.filter(row => row.type !== action.payload.type);
  const updatedLocationsToYards = [...filtered, ...action.payload.locationsToYards];
  return {
    ...state,
    locationsToYards: updatedLocationsToYards
  };
}

function _setLocationsToYards(state, action) {
  return {
    ...state,
    locationsToYards: action.payload
  }
}

function sortByName(objects) {
  return sortBy(objects, (obj) => obj.name);
}

function _addYard(state, action) {
  const yards = state.yards;
  const newYard = {
    ...action.payload
  }
  const sortedYards = sortByName([...yards, newYard]);
  return {
    ...state,
    yards: sortedYards
  }
}

function _removeYard(state, action) {
  const yards = state.yards;
  const filteredYards = yards.filter(yard => yard.id !== action.payload.id);
  return {
    ...state,
    yards: filteredYards
  }
}

function _updateYard(state, action) {
  const yards = state.yards;
  const updatedYard = action.payload;
  const updatedYards = yards.map(yard => {
    if (yard.id === updatedYard.id) {
      return updatedYard;
    } else {
      return yard;
    }
  });
  const sortedYards = sortByName(updatedYards);
  return {
    ...state,
    yards: sortedYards
  }
}

const getName = (element) => (element.name);

function setDestinationHelper(state, action, locationKey) {
  const destinationsArray = action.payload[locationKey];
  const type = action.payload.type;
  const destinationObjects = destinationsArray
  .map(destination => (
    {
      type: type,
      ...destination
    }
  ));

  const sortedDestinationObjects = sortBy(destinationObjects, getName);
  return {
    ...state,
    [locationKey]: sortedDestinationObjects
  }
}

function setBlocksToDestinationsReducer(state, action) {
  return {
    ...state,
    blocksToDestinations: action.payload
  }
}