import React from 'react';
import { connect } from 'react-redux';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import { startOfWorkDay, getStartTime, getEndTime, getTimeStringOfDate } from '../../utils/dateTimeHelpers';
import isAfter from 'date-fns/is_after';


/**
 * Block activity is a component in the block view
 */
class BlockActivity extends React.Component {

  clickActivity() {
    this.props.setSelectedActivity(this.props.activity.id);
  }

  isSelected() {
    return this.props.activity.id === this.props.selectedActivityId;
  }

  numberOfMinutesSinceWorkStart(activityStartDate) {
    const startWork = startOfWorkDay(this.props.workHours.beginHour, this.props.scenarioDate);
    const diff = differenceInMinutes(activityStartDate, startWork);
    return diff;
  }

  getBackgroundStyle() {
    if (this.isSelected()) {
      return '#ffe970';
    }
  }



  getColor(previous, current) {
    if (previous) {
      const previousEnd = previous.loadEnd;
      const currentStart = current.loadStart;
      if (isAfter(previousEnd, currentStart)) {
        return 'red';
      } else {
        return '#228b22';
      }
    }
    return '#228b22';
  }

  getHeightValue(activity) {
    let height = differenceInMinutes(activity.loadEnd, activity.loadStart);

    // 2 pixels per minute of load time
    return height * 2;
  }

  getActivityDetails(activity) {
    if (this.props.selectedActivityId === activity.id) {

      return (
        <div className='block-activity-details'>
          <div>{activity.truckName}</div>
          <br/>
          <div>Start of activity: {getStartTime(activity)}</div>
          <div>End of activity: {getEndTime(activity)}</div>
          <br/>
          <div>Start of load: {getTimeStringOfDate(activity.loadStart)}</div>
          <div>End of load: {getTimeStringOfDate(activity.loadEnd)}</div>
          <br/>
          <div>Load time: {differenceInMinutes(activity.loadEnd, activity.loadStart)}  min.</div>
          <div>Delay: {activity.delay} min.</div>
        </div>
      );
    }
  }

  render() {
    const activity = this.props.activity;
    const block = this.props.block;
    const heightValue = this.getHeightValue(activity);
    const borderColor = this.getColor(this.props.previousActivity, activity);
    const minutesSinceStart = this.numberOfMinutesSinceWorkStart(activity.dateObjects.locationStartTime);
    return <div className='block-activity' key={activity.id} 
      style={{
        border: `2px solid ${borderColor}`,
        marginTop: 3 + (minutesSinceStart + block.preloadTime) * 2, 
        height: heightValue,
        background: this.getBackgroundStyle()
        }}
      onClick={() => this.clickActivity()}>
        {activity.truckName}
        {this.getActivityDetails(activity)}
  </div>
  }
}

const mapStateToProps = (state) => {
  const selectedScenario = state.scenarioState.selectedScenario;
  return {
    scenarioDate: selectedScenario.date,
    workHours: state.workHoursForSchedule
  }
}

export default connect(mapStateToProps)(BlockActivity);