import React from 'react';
import { connect } from 'react-redux';
import { openScenarioTruckDeleteModal } from '../reducers/modalWindows';
import { isViewerRole } from '../utils/userHelper';

class TruckScenarioDelete extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
  }

  openModal(event) {
    event.stopPropagation();
    const id = this.props.scenarioTruckId;
    this.props.openModal(id);
  }

  // call this function in render when it's fixed
  isScenarioTruckInSelectedOperatingUnit() {
    const operatingUnitId = this.props.operatingUnits.selectedOperatingUnitIds[0];
    const test = this.props.scheduleRowState.filter(x => x.rowId === this.props.scenarioTruckId)
    const test1 = this.props.operatingUnits.operatingUnitAssignment.filter(x => x.configObjectId === test[0].truckId)
    if (test1.length === 0) {
      return false
    }
    if ( test1.operatingUnitId !== operatingUnitId) {
      return true;
    }
  }


  render() {
    if (!this.props.isPublished && !isViewerRole() && !this.props.isClosed) {
      return (
        <div className='delete-item'
             onClick={this.openModal}>
            ✖
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isPublished: state.scenarioState.selectedScenario.published ||
                 state.scenarioState.selectedScenario.wasPublished,
    operatingUnits: state.operatingUnits,
    scheduleRowState: state.scheduleRowState,
    isClosed: state.scenarioState.selectedScenario.isClosed
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (scenarioTruckId) => {
      dispatch(openScenarioTruckDeleteModal(scenarioTruckId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckScenarioDelete);