import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import YardTimeEditor from './YardTimeEditor';
import { toast } from 'react-toastify';
import DestinationOperatingUnitEditor from './DestinationOperatingUnitEditor';
class DestinationFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.destinationFieldProps? props.destinationFieldProps: {};
    const defaultDestinationId = fieldProps.destinationId? fieldProps.destinationId: '';
    const defaultDestinationName = fieldProps.name? fieldProps.name: '';
    const defaultUnloadTime = fieldProps.unloadTime? fieldProps.unloadTime: 0;
    const defaultToYardTime = fieldProps.toYardTime? fieldProps.toYardTime: 0;
    const defaultEmail = fieldProps.email? fieldProps.email: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const defaultFullName = fieldProps.fullName? fieldProps.fullName: '';
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultOperatingUnitIds = fieldProps.selectedOperatingUnitIds? fieldProps.selectedOperatingUnitIds: [];
    const defaultMaxCapacity = fieldProps.maxCapacity? fieldProps.maxCapacity: 0;
    const defaultSiteCode  = fieldProps.siteCode? fieldProps.siteCode: '';
    const defaultScalerEmail = fieldProps.scalerEmail? fieldProps.scalerEmail: '';


    this.state = {
      destinationId: defaultDestinationId,
      name: defaultDestinationName,
      unloadTime: defaultUnloadTime,
      toYardTime: defaultToYardTime,
      email: defaultEmail,
      isModified: false,
      active: defaultActive,
      fullName: defaultFullName,
      comments: defaultComment,
      selectedOperatingUnitIds: defaultOperatingUnitIds,
      maxCapacity: defaultMaxCapacity,
      siteCode: defaultSiteCode,
      scalerEmail: defaultScalerEmail
    }

    this.changeDestinationName = this.changeDestinationName.bind(this);
    this.changeDestinationFullName = this.changeDestinationFullName.bind(this);
    this.changeUnloadTime = this.changeUnloadTime.bind(this);
    this.changeToYardTime = this.changeToYardTime.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.handleYardDataUpdate = this.handleYardDataUpdate.bind(this);
    this.handleOperatingUnitSelect = this.handleOperatingUnitSelect.bind(this);
    this.changeMaxCapacity = this.changeMaxCapacity.bind(this);
    this.changeSiteCode = this.changeSiteCode.bind(this);
    this.changeScalerEmail = this.changeScalerEmail.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeDestinationName(event) {
    const truncatedName = event.target.value.substring(0, 6);
    if (truncatedName !== this.state.name) {
      this.setState({name: truncatedName});
      this.setModified();
    }
  }

  changeDestinationFullName(event) {
    const truncated = event.target.value.substring(0, 50);
    this.setState({fullName: truncated});
    this.setModified();
  }

  changeSiteCode(event) {
    this.setState({siteCode: event.target.value});
    this.setModified();
  }

  changeScalerEmail(event) {
    this.setState({scalerEmail: event.target.value});
    this.setModified();
  }

  getValidTime(timeValue) {
    return timeValue < 0? 0: timeValue;
  }

  changeUnloadTime(event) {
    const validTime = this.getValidTime(event.target.value);
    this.setState({unloadTime: validTime});
    this.setModified();
  }

  changeToYardTime(event) {
    const validTime = this.getValidTime(event.target.value);
    this.setState({toYardTime: validTime});
    this.setModified();
  }

  changeEmail(event) {
    const email = event.target.value.substring(0, 500);
    this.setState({
      email: email
    });
    this.setModified();
  }

  changeMaxCapacity(event) {
    this.setState({maxCapacity: event.target.value});
    this.setModified();
  }

  hasZeroValueOnPicker() {
    let hasZeroValueYard = false;
    const yardTimeData = this.state.yardTimeData;
    if (yardTimeData) {
      hasZeroValueYard = yardTimeData.some(yardTime => yardTime.toYardTime <= 0);
    }

    return hasZeroValueYard;
  }

  clickDone() {
    if (this.hasZeroValueOnPicker()) {
      toast.error('ERROR: Must enter a valid travel time for yards');
    } else {
      this.props.clickDone(this.state);
      this.setNotModified();
    }
  }

  clickCancel() {
    this.props.clickCancel();
  }

  // yardTimeData contains a list of objects holding yard Id and time to yard
  handleYardDataUpdate(yardTimeData) {
    this.setState({yardTimeData: yardTimeData});
  }

  handleOperatingUnitSelect(operatingUnits) {
    this.setState({selectedOperatingUnitIds: operatingUnits});
    this.setModified();
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field destination-field'>
          <div className='destination-input-label'>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeDestinationName} />
        </div>
        <div className='full-name-field destination-field'>
          <div className='destination-input-label'>Full Name</div>
          <input type='text' value={this.state.fullName} onChange={this.changeDestinationFullName} />
        </div>
        <div className='full-name-field destination-field'>
          <div className='destination-input-label'>Site ID</div>
          <input type='text' value={this.state.siteCode} onChange={this.changeSiteCode} />
        </div>
        <div className='unload-time-field destination-field'>
          <div className='destination-input-label'>Unload Time</div>
          <input type='number' value={this.state.unloadTime} onChange={this.changeUnloadTime} />
        </div>
        <div className='max-capacity-field destination-field'>
          <div className='destination-input-label'>Max Capacity</div>
          <input type='number' value={this.state.maxCapacity} onChange={this.changeMaxCapacity} />
        </div>
        <div className='scaler-email-field destination-field'>
          <div className='destination-input-label'>Scaler Email</div>
          <input type='email' value={this.state.scalerEmail} onChange={this.changeScalerEmail} />
        </div>
        <div className='email-field destination-field'>
          <div>Email</div>
          <textarea rows='4' cols='55' value={this.state.email} onChange={this.changeEmail}
            placeholder='Enter each email address separated by a comma'
          />
        </div>
        <YardTimeEditor setModified={() => this.setModified()}
          locationId={this.state.destinationId}
          handleYardDataUpdate={this.handleYardDataUpdate}
        />
        <DestinationOperatingUnitEditor selectedOperatingUnitIds={this.state.selectedOperatingUnitIds} 
          handleOperatingUnitSelect={this.handleOperatingUnitSelect}
        />
        <ConfigComment key={this.state.destinationId}
          top={300}
          left={705}
          comments={this.state.comments} 
          updateComments={this.changeComments}
        />
        <div className='destination-field-edit-buttons'>
          <button className='cancel-button' onClick={this.clickCancel}>Cancel</button>
          <button className='done-button' onClick={this.clickDone} disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

export default DestinationFieldEditor;