import React from 'react';
import { DragSource } from 'react-dnd';
import DraggableTypes from '../../constants/DraggableTypes';
import { isViewerRole } from '../../utils/userHelper';

class LocationDraggable extends React.Component {

  getDashboardHighlightStyle() {
    const style = {};
    if (this.props.currentDashboardId === this.props.id) {
      style.outline = '3px solid yellow';
      style.outlineOffset = '-3px';
      style.padding = '7px';
    }

    if (!this.props.isDraggable) {
      style.opacity = '0.5';

    }

    return style;
  }

  clickHandler() {
    if (this.props.clickHandler) {
      this.props.clickHandler(this.props.id);
    }
  }

  getDraggableElement() {
    const isDraggable = this.props.isDraggable && !isViewerRole();
    const dragClass = isDraggable? '': 'drag-disabled';
    const draggableElement = (
      <div onClick={() => this.clickHandler()}>
        <div className={`draggable ${dragClass} ${this.props.type}`}
          style={this.getDashboardHighlightStyle()}>
          {this.props.name}
        </div>
      </div>
    );

    if (isDraggable) {
      return this.props.connectDragSource(draggableElement);
    } else {
      return draggableElement;
    }
  }

  render () {
    return this.getDraggableElement();
    
  }
}

const locationSource = {
  beginDrag(props) {
    return {
      locationId: props.id,
      type: props.type
    };
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
  }
}

function collect(connect, monitor) {
  return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
  }
}

export default DragSource(DraggableTypes.LOCATION, locationSource, collect) (LocationDraggable);
