import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniqid from 'uniqid';
import LoaderOperatorHelper from '../../utils/loaderOperatorHelper';

class DashboardBlock extends React.Component {

  getOperatingUnit(data) {
    if (data.operatingUnit) {
      return <div>Operating Unit: {data.operatingUnit}</div>;
    } else {
      return null;
    }
  }

  getBlockLoadScenario(blockId) {
    const blockLoadScenario = this.props.blockLoadScenario;
    const validBlockLoadScenario = blockLoadScenario ? blockLoadScenario: {};
    const targetBlock = validBlockLoadScenario[blockId];
    return targetBlock;
  }

  getBlockLoads(blockId) {
    const targetBlock = this.getBlockLoadScenario(blockId);
    const blockLoads = targetBlock ? targetBlock.blockLoads : [];
    return blockLoads;
  }

  getLoadInventoryRows(blockLoads) {
    const rows = blockLoads.map(blockLoad => {
      const destination = find(this.props.destinations, {id: blockLoad.destination});
      const name = destination.name;
      const total = blockLoad.loads;
      const assigned = blockLoad.assigned;
      const remaining = total - assigned;

      return (
        <li key={uniqid()}>
          <div className='dashboard-block-load-row'>
            <div className='total-value'>{total}</div>
            <div className='assigned-value'>{assigned}</div>
            <div className='remaining-value'>{remaining}</div>
            <div className='name-value'>{name}</div>
          </div>
        </li>
      );
    });

    return rows;
  }

  getBlockLoadComment(blockId) {
    const targetBlock = this.getBlockLoadScenario(blockId);
    if (targetBlock && targetBlock.comment) {
      return <div className='comment'><span className='comment-text'>Comment</span>: {targetBlock.comment}</div>;
    } else {
      return null;
    }
  }

  getUnorderedLoads(blockLoads) {
    const unorderedLoads = blockLoads.filter(load => load.loadOrder < 0); 
    if (unorderedLoads.length > 0) {
      return (
        <React.Fragment>
          <p className='unordered-label'>Unordered</p>
          <ul className='dashboard-block-load-rows'>
            {this.getLoadInventoryRows(unorderedLoads)}
          </ul>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }



  getLoadInventory() {
    const blockId = this.props.dashboardInfo.id;
    const blockLoads = this.getBlockLoads(blockId);
    if (blockLoads.length > 0) {
      const orderedLoads = blockLoads.filter(load => load.loadOrder > 0);
      
      return (
        <div className='dashboard-block-loads'>
          {this.getBlockLoadComment(blockId)}
          <div className='headers'>
            <div className='order-header'>Order</div>
            <div className='total-header'>Total</div>
            <div className='assigned-header'>Assigned</div>
            <div className='remaining-header'>Remaining</div>
            <div className='destination-header'>Destination</div>
          </div>
          <ol className='dashboard-block-load-rows'>
            {this.getLoadInventoryRows(orderedLoads)}
          </ol>
          {this.getUnorderedLoads(blockLoads)}
        </div>
      );
    } else {
      return null;
    }
  }

  // getOperatorNames(blockId) {
  //   const loaderOperatorHelper = new LoaderOperatorHelper();
  //   const operators = loaderOperatorHelper.getOperatorsByBlockId(blockId);
  //   if (isEmpty(operators)) {
  //     return (<li key={'none'} style={{color: 'red'}}>N/A</li>);
  //   } else {
  //     const operatorElements = operators.map(operator => {
  //       const loader = loaderOperatorHelper.getLoader(operator.loaderId);
  //       const loaderName = loader? loader.name: 'No loader';
  //       return <li key={operator.id}>{operator.name} - {loaderName}</li>
  //     });
  //     return operatorElements;
  //   }
  // }
  getOperatorNames(blockId) {
    const loaderOperatorHelper = new LoaderOperatorHelper();
    const operators = loaderOperatorHelper.getOperatorsByBlockId(blockId);
    if (isEmpty(operators)) {
      return (<li key={'none'} style={{color: 'red'}}>N/A</li>);
    } else {
      const operatorElements = operators.map(operator => {
        const assignment = find(this.props.assignments, {loaderOperatorId: operator.id});
        const loader = loaderOperatorHelper.getLoader(assignment.loaderId);
        const loaderName = loader? loader.name: 'No loader';
        return <li key={operator.id}>{operator.name} - {loaderName}</li>
      });
      return operatorElements;
    }
  }

  getBlockRanking() {
    const data = this.props.dashboardInfo;
    if (data.ranking === null) {
      return ' N/A';
    }else {
      return ('Level ' + data.ranking);
    }
  }

  render() {
    const data = this.props.dashboardInfo;
    return (
      <React.Fragment>
        <div className='dashboard-block'>
          <h4>Block {data.name} Details </h4>
          <div>Ranking: {this.getBlockRanking()}</div>
          <div>Channel: {data.channel}</div>
          <div>Road Name: {data.roadName} </div>
          {this.props.timeFromYards}
          <div>Loader Operators:</div>
          <ul className='loader-operator-list'>
            {this.getOperatorNames(data.id)}
          </ul>
          {this.getOperatingUnit(data)}
        </div>
        {this.getLoadInventory()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const scenarioId = state.scenarioState.selectedScenario.id;
  return {
    blockLoadScenario: state.blockLoadState.byId[scenarioId],
    destinations: state.locations.destinations,
    assignments: state.loaderOperatorAssignment.loaderOperatorAssignmentList
  }
}

export default connect(mapStateToProps)(DashboardBlock);
