import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { closeModal } from '../../reducers/modalWindows';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import PublishService from '../../services/publishService';
import sortBy from 'lodash/sortBy';

class SendContractorTimeCardsModal extends React.Component {
  constructor(props) {
    super(props);
    this.isoFormat = 'YYYY-MM-DD';
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = this.getInitialState();
    this.closeModal = this.closeModal.bind(this);
    this.sendTimeCards = this.sendTimeCards.bind(this);
    this.handleChangeContractor = this.handleChangeContractor.bind(this);
  }

  getInitialState() {
    return {
      date: undefined,
      selectedContractor: undefined
    };
  }

  getNoneOption() {
    return <option key={'none'} value={"undefined"}>None</option>;
  }

  getCompanyList() {
    // const scheduledTruckIds = this.props.scenarioState.map(x => x.truckId);
    // const scheduledTruckCompanyIds = [...new Set(this.props.trucks.filter(x => scheduledTruckIds.includes(x.id)).map(x => x.truckCompanyId))]; 
    const companyList = sortBy(this.props.truckCompanies
      .filter(x => !x.isDeleted && x.active), truckCompany => truckCompany.name)
    const uniqueOptions = companyList.map(x => <option key={x.name} value={x.id}>{x.name}</option>)
    uniqueOptions.unshift(this.getNoneOption());
    return uniqueOptions;
  }

  handleChangeContractor(event) {
    const contractor = event.target.value;
    this.setState({
      selectedContractor: contractor,
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  handleDayClick(day) {
    this.setState({date: day});
  }

  sendTimeCards() {
    const formattedDate = format(this.state.date, this.isoFormat);
    PublishService.sendTruckCompanyTimeCards(formattedDate, this.state.selectedContractor, this.props.user);
  }

  render() {
    return (
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.closeModal}
        style={{content: {
          height: '370px',
          width: '280px',
          margin: 'auto'
        }}}
      >
      <div className="RangeExample">
        <DayPicker
          selectedDays={this.state.date}
          className="Selectable"
          formatDate={format}
          onDayClick={this.handleDayClick}
        />
      </div>
      
      <div className=''>
        <span>Truck Company </span>
        <select style={{width: 155}} className='contractor-list'onChange={this.handleChangeContractor} value={undefined}>
          {this.getCompanyList()}
        </select>
      </div>
      <div style={{marginTop: 20}}>
        <button style={{width: 130}} className='rollup-modal-cancel-button' onClick={this.closeModal}>Cancel</button>
        <button style={{width: 125}} className='rollup-modal-done-button' disabled={this.state.date === undefined || this.state.selectedContractor === undefined} onClick={this.sendTimeCards}>Done</button>
      </div>
      </ReactModal>
    );
  }   
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows,
    truckCompanies: state.truckCompanies.truckCompaniesList,
    user: state.users.user.profile,
    // scenarioState: state.scheduleRowState,
    // trucks: state.trucks.truckList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendContractorTimeCardsModal);