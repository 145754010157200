import axios from 'axios';
import store from '../store';
import { setDriversToBlocksHistory } from '../reducers/driversToBlocksHistory';
import { getAuthService } from '../services/AuthService';

class DriverToBlockHistoryService {
  static getDriversToBlocksHistoryApi() {
    return axios.get('/api/driversToBlocksHistory', { headers: getAuthService().header });
  }


  static getDriversToBlocksHistory() {
    return this.getDriversToBlocksHistoryApi()
      .then((driversToBlocksHistory) => {
        store.dispatch(setDriversToBlocksHistory(driversToBlocksHistory.data))
      })
      .catch(error => console.log(error));
  }
}

export default DriverToBlockHistoryService;

