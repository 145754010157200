import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addTruckCompanySuccess, updateTruckCompanySuccess, ADD_TRUCKCOMPANY, UPDATE_TRUCKCOMPANY, REMOVE_TRUCKCOMPANY, BULK_ACTIVATE_TRUCKCOMPANIES, setTruckCompanies } from '../../reducers/truckCompanies';
import { getAuthService } from '../AuthService';


const addTruckCompanyEpic = action$ => (
  action$.ofType(ADD_TRUCKCOMPANY)
    .mergeMap(action => (
      ajax.post('/api/truckCompanies', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addTruckCompanySuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateTruckCompanyEpic = action$ => (
  action$.ofType(UPDATE_TRUCKCOMPANY)
    .mergeMap(action => (
      ajax.put(`/api/truckCompanies/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateTruckCompanySuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeTruckCompanyEpic = action$ => (
  action$.ofType(REMOVE_TRUCKCOMPANY)
    .mergeMap(action => (
      ajax.delete(`/api/truckCompanies/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => setTruckCompanies(ajaxResponse.response), 'Successfully Removed Truck Company'))
        .catch(fail => Observable.of(failure()))
    ))
);

export const bulkActivateTruckCompaniesEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_TRUCKCOMPANIES)
      .mergeMap(action => (
          ajax.post('/api/truckCompanies/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setTruckCompanies(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const TruckCompanyEpics = combineEpics(
  addTruckCompanyEpic,
  updateTruckCompanyEpic,
  removeTruckCompanyEpic,
  bulkActivateTruckCompaniesEpic
);