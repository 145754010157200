import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';

class OperatingUnitFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.operatingUnitFieldProps? props.operatingUnitFieldProps: {};
    const defaultId = fieldProps.id? fieldProps.id: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultDescription = fieldProps.description? fieldProps.description: '';
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;

    this.state = {
      id: defaultId,
      name: defaultName,
      description: defaultDescription,
      isModified: false,
      comments: defaultComment,
      active: defaultActive,
    }

    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeName(event) {
    const nameValue = event.target.value;
    const name = nameValue.substring(0, 50);
    this.setState({name: name});
    this.setModified();
  }

  changeDescription(event) {
    this.setState({description: event.target.value});
    this.setModified();
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field operating-unit-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeName} />
        </div>
        <div className='description-field operating-unit-field'>
          <div>Description</div>
          <textarea rows='3' cols='40' value={this.state.description} onChange={this.changeDescription}/>
        </div>
        <ConfigComment key={this.state.operatingUnitId} 
          top={300}
          left={645}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        <div className='operating-unit-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

export default OperatingUnitFieldEditor;