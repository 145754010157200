import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { SCENARIO_DELETE_MODAL } from '../../constants/modalTypes';
import { deleteScenario } from '../../reducers/scenarios';

class ScenarioDeleteModal extends React.Component {

  deleteAndCloseModal(scenarioId) {
    this.props.deleteScenario(scenarioId);
    this.props.closeModal();
  }

  getModal() {
    const scenarioId = this.props.modalWindow.scenarioId;
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          right: '60px',
          height: '150px',
          width: '310px',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeModal()}>
          <p>Are you sure you want to delete this scenario?</p>
          <div className='scenario-delete-modal-buttons'>
            <button onClick={() => this.props.closeModal()} className='cancel-button'>Cancel</button>
            <button onClick={() => this.deleteAndCloseModal(scenarioId)} className='delete-button'>Delete</button>
          </div>
      </ReactModal>
    );
  }

  render() {
    if (this.props.modalWindow.modalType === SCENARIO_DELETE_MODAL) {
      return this.getModal();
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    deleteScenario: (scenarioId) => {
      dispatch(deleteScenario(scenarioId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioDeleteModal);