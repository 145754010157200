import React from 'react';
import { connect } from 'react-redux';
import { isInactiveStyle } from '../../utils/inactiveStyleHelper';
import includes from 'lodash/includes';

class DestinationOperatingUnitEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOperatingUnitIds: props.selectedOperatingUnitIds
    }
  }

  handleSelect(event) {
    const isChecked = event.target.checked;
    const id = event.target.value;
    let selectedOperatingUnitIds = [];
    if (isChecked) {
      selectedOperatingUnitIds = [...this.state.selectedOperatingUnitIds, id];
    } else {
      selectedOperatingUnitIds = this.state.selectedOperatingUnitIds.filter(opUnitId => opUnitId !== id);
    }

    this.setState({
      selectedOperatingUnitIds: selectedOperatingUnitIds
    });

    this.props.handleOperatingUnitSelect(selectedOperatingUnitIds);
  }

  isOperatingUnitSelected(id) {
    return includes(this.state.selectedOperatingUnitIds, id);
  }

  getAssignedOperatingUnits() {
    const operatingUnitRows = this.props.operatingUnits
    .filter(operatingUnit => !operatingUnit.isDeleted)
    .map(operatingUnit => {
      const id = operatingUnit.id;
      return (
        <div className='operating-unit-row' key={id}>
          <div style={isInactiveStyle(operatingUnit)} className='operating-unit-label'>{operatingUnit.name}</div>
          <input type='checkbox' className='operating-unit-select-checkbox' value={id} 
            checked={this.isOperatingUnitSelected(id)}
            onChange={(e) => this.handleSelect(e)}
          />
        </div>
      );
    });
    return operatingUnitRows;
  }

  render() {
    return (
      <div className='destination-operating-unit-editor'>
        <div className='assigned-operating-unit-label'>Assigned Operating Units</div>
        {this.getAssignedOperatingUnits()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    operatingUnits: state.operatingUnits.operatingUnitList
  }
}

export default connect(mapStateToProps)(DestinationOperatingUnitEditor);