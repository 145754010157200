import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';
import { COPY_SCENARIO_MODAL } from '../../constants/modalTypes';
import { copyScenario } from '../../reducers/scenarios';
import ReactModal from 'react-modal';
import format from 'date-fns/format';
import DayPicker from 'react-day-picker';
import isSameDay from 'date-fns/is_same_day';
import { getCurrentDate } from '../../utils/dateTimeHelpers';

class CopyScenarioModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: getCurrentDate()
    }

    this.isoFormat = 'YYYY-MM-DD';
    this.closeModal = this.closeModal.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.copyScenario = this.copyScenario.bind(this);
  }

  closeModal() {
    this.props.closeModal();
  }

  handleDayClick(day) {
    this.setState({selectedDate: day});
  }

  copyScenario() {
    const isTargetCurrentDate = isSameDay(this.props.currentDate, this.state.selectedDate);
    const payload = {
      scenarioId: this.props.currentScenarioId,
      dateTarget: this.state.selectedDate,
      isTargetCurrentDate: isTargetCurrentDate
    }
    this.props.copyScenario(payload);
    this.closeModal();
  }

  getModal() {
    const modalWindow = this.props.modalWindow;

    if (modalWindow.isModalOpen && modalWindow.modalType === COPY_SCENARIO_MODAL) {
      return (
        <ReactModal
          isOpen={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.closeModal}
          style={{content: {
            height: '410px',
            width: '300px',
            margin: 'auto'
          }}}
        >
          <div className='copy-scenario-modal'>
            <div className='copy-prompt'>Copy a scenario to a specified date </div>
            <DayPicker 
              onDayClick={this.handleDayClick}
              selectedDays={this.state.selectedDate}
              dayPickerProps={{
                todayButton: 'Today',
              }}/>
              <p className='copy-chosen-date-label'>Chosen Date: {format(this.state.selectedDate, this.isoFormat)}</p>
              <button className='copy-cancel' onClick={this.closeModal}>Cancel</button>
              <button className='copy-done' onClick={this.copyScenario}>Done</button>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }

  render() {
    return this.getModal();
  }
}

const mapStateToProps = (state) => {
  const scenarioId = state.scenarioState.selectedScenario.id;
  return {
    currentScenarioId: scenarioId,
    modalWindow: state.modalWindows,
    currentDate: state.scenarioState.currentDateSelected
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    copyScenario: (scenarioPayload) => {
      dispatch(copyScenario(scenarioPayload));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyScenarioModal);