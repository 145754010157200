import React from 'react';
import ReactDOM from 'react-dom';
import { DropTarget } from 'react-dnd';
import DraggableTypes from '../../constants/DraggableTypes';

class TruckDropTarget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true
    }
  }

  scrollIntoView(canDrop) { 
    if (canDrop && this.props.rowCount > 11) {
      ReactDOM.findDOMNode(this).scrollIntoView(); 
    }
  }

  editRow() {
    this.setState({
      isEditing: true
    });
  }

  finishEdit() {
    this.setState({
      isEditing: false
    });
  }

  getStyle(isOver) {
    if(isOver) {
      return {
        paddingLeft: '5px',
        paddingTop: '5px',
        fontSize: '14px',
      }
    }
  }

  isEditing() {
    return this.state.isEditing;
  }

  setTruckId(newTruckId) {
    this.setState({truckId: newTruckId});
    this.finishEdit();
  }

  getDisplayName(isOver) {
    if (isOver && this.isEditing()) {
      return 'Release to drop';
    } else {
      return '+';
    } 
  }

  render () {
    const { isOver, connectDropTarget, canDrop } = this.props;
    this.scrollIntoView(canDrop);

    return connectDropTarget(
      <div className="add-truck-prompt"
        onClick={() => this.editRow()}
        style={this.getStyle(isOver)}>
        {this.getDisplayName(isOver)} 
      </div>
    );
  }
}

const rowTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.openScenarioTruckModal(item.id, item.name, true);
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
	  isOver: monitor.isOver(),
	  canDrop: monitor.canDrop()
  }
}

export default DropTarget(DraggableTypes.TRUCK, rowTarget, collect)(TruckDropTarget);