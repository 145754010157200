const SET_CONTRACTORS = 'SET_CONTRACTORS';
export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';
export const ADD_CONTRACTOR_SUCCESS = 'ADD_CONTRACTOR_SUCCESS';
export const UPDATE_CONTRACTOR = 'UPDATE_CONTRACTOR';
export const UPDATE_CONTRACTOR_SUCCESS = 'UPDATE_CONTRACTOR_SUCCESS';
export const REMOVE_CONTRACTOR = 'REMOVE_CONTRACTOR';
export const REMOVE_CONTRACTOR_SUCCESS = 'REMOVE_CONTRACTOR_SUCCESS';
export const BULK_ACTIVATE_CONTRACTORS = 'BULK_ACTIVATE_CONTRACTORS';

export function setContractors(contractors) {
  return {
    type: SET_CONTRACTORS,
    payload: contractors
  }
}

export function addContractor(contractor) {
  return {
    type: ADD_CONTRACTOR,
    payload: contractor
  }
}

export function addContractorSuccess(contractor) {
  return {
    type: ADD_CONTRACTOR_SUCCESS,
    payload: contractor
  }
}

export function updateContractor(contractor) {
  return {
    type: UPDATE_CONTRACTOR,
    payload: contractor
  }
}

export function updateContractorSuccess(contractor) {
  return {
    type: UPDATE_CONTRACTOR_SUCCESS,
    payload: contractor
  }
}

export function removeContractor(contractorId) {
  return {
    type: REMOVE_CONTRACTOR,
    payload: contractorId
  }
}

export function removeContractorSuccess(contractor) {
  return {
    type: REMOVE_CONTRACTOR_SUCCESS,
    payload: contractor
  }
}

export function bulkActivateContractors(contractorIds) {
  return {
    type: BULK_ACTIVATE_CONTRACTORS,
    payload: contractorIds
  }
}

export default function contractors(state = {
  contractorsList: []
}, action) {
  switch(action.type) {
    case SET_CONTRACTORS:
      return _setContractors(state, action);
    case ADD_CONTRACTOR_SUCCESS:
      return _AddContractor(state, action);
    case UPDATE_CONTRACTOR_SUCCESS:
      return _updateContractor(state, action);
    case REMOVE_CONTRACTOR_SUCCESS:
      return _removeContractor(state, action);
    default:
      return state;
  }
}


function _setContractors(state, action) {
  return {
    ...state,
    contractorsList: action.payload
  }
}

function _AddContractor(state, action) {
  const contractors = [...state.contractorsList, action.payload];
  return {
    ...state,
    contractorsList: contractors
  }
}

function _updateContractor(state, action) {
  const updatedContractor = action.payload;
  const updatedList = state.contractorsList.map(contractor => {
    if(contractor.id === updatedContractor.id) {
      return updatedContractor;
    } else {
      return contractor;
    }
  });
  return {
    ...state,
    contractorsList: updatedList
  }
}

function _removeContractor(state, action) {
  const updatedList = state.contractorsList
  return {
    ...state,
    contractorsList: updatedList
  }
}
