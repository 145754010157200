import store from '../store';
import { VIEWER } from '../constants/userRole';

export function isViewerRole() {
  const userState = getUserState();
  return userState.role === VIEWER;
}

function getStoreState() {
  return store.getState();
}

function getUserState() {
  return getStoreState().users.user;
}