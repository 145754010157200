import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import ProcessorFieldEditor from '../../components/fieldEditors/ProcessorFieldEditor';
import uniqid from 'uniqid';
import { addProcessor, updateProcessor, bulkActivateProcessors } from '../../reducers/processors';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { PROCESSOR } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class ProcessorsConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processorIdSelected: '',
      isAddingNewProcessor: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousProcessors = previousProps.processors.map(processor => processor.id);
    const currentProcessors = this.props.processors.map(processor => processor.id);
    if (currentProcessors.length > previousProcessors.length) {
      const diff = difference(currentProcessors, previousProcessors);
      if (diff.length > 0) {
        const newProcessor = diff[0];
        this.setState({
          processorIdSelected: newProcessor,
          isAddingNewProcessor: false
        });
      }
    }
  }

  addNewProcessor() {
    this.setState({
      processorIdSelected: '',
      isAddingNewProcessor: true
    });
  }

  selectProcessor(id) {
    this.setState({
      processorIdSelected: id,
      isAddingNewProcessor: false
    });
  }

  getSelectedProcessor() {
    const processor = find(this.props.processors, {id: this.state.processorIdSelected});
    return processor;
  }

  getSelectedProcessorName() {
    const processor = this.getSelectedProcessor();
    if (processor) {
      return processor.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedProcessor() {
    return this.state.processorIdSelected !== '';
  }

  getProcessorsList() {
    const options = this.props.processors;
    const sortedOptions = sortBy(options, processor => processor.name)
    .filter(processor => !processor.isDeleted)
    .map(processor => {
      return {
        value: processor.id,
        label: processor.name
      }
    });

    const handleChange = (selectedOption) => this.selectProcessor(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedProcessorName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const processor = this.getSelectedProcessor();
    if (processor) {
      return `Edit processor ${processor.name}`;
    } else if (this.state.isAddingNewProcessor) {
      return 'Add a new processor';
    } else {
      return 'Select a processor to edit';
    }
  }

  checkExistence(state) {
    const processor = find(this.props.processors, {name: state.name});
    if (processor) {
      this.props.openDuplicateObjectNamesModal(processor);
    }
    else {
      if (this.state.isAddingNewProcessor === true) {
        this.props.add(state);
      }
      else {
        this.props.update(state);
      }
    }
  }

  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getProcessorEditPanel() {
    if (this.hasSelectedProcessor() && !this.state.isAddingNewProcessor) {
      return (
        <div>
          <ProcessorFieldEditor
            key={uniqid()}
            processorFieldProps={this.getSelectedProcessor()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectProcessor('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewProcessor) {
      return (
        <div>
          <ProcessorFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectProcessor('')}
          />
        </div>
      );
    }
  }

  activate() {
    const processor = this.getSelectedProcessor();
    if (processor) {
      processor.active = !processor.active;
      this.props.update(processor);
    }
  }

  getActivateButton() {
    const processor = this.getSelectedProcessor();
    if (processor) {
      const buttonName = processor.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const processorId = this.state.processorIdSelected;
    if (processorId !== '') {
      return (
        <button className='remove-processor'
          onClick={() => this.props.removeProcessorModal(processorId)}>
          Remove Processor
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedProcessorsWithId = this.props.processors
      .filter(processor => !processor.isDeleted)
      .map(processor => {
        return {
          ...processor,
          id: processor.id,
          name: processor.name
        }
      });
      return <BulkActivationModal
        type={PROCESSOR}
        activationItems={updatedProcessorsWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='processor-configuration configuration-forms'>
        <button className='add-new-processor' onClick={() => this.addNewProcessor()}>Add New Processor</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='processor-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='processor-list'>
          {this.getProcessorsList()}
        </div>
        <div className='processor-edit-panel'>
          {this.getProcessorEditPanel()}
          <div className='processor-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectProcessor('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    processors: state.processors.processorsList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addProcessor(state));
    },
    update: (state) => {
      dispatch(updateProcessor(state));
    },
    removeProcessorModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateProcessors(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProcessorsConfiguration);