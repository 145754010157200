export const SUCCESSFUL_ACTION = 'SUCCESSFUL_ACTION';
export const FAILED_ACTION = 'FAILED_ACTION';

export function successfulAction(response) {
  return {
    type: SUCCESSFUL_ACTION,
    payload: response
  }
}

export function failedAction(response) {
  return {
    type: FAILED_ACTION,
    payload: response
  }
}