import axios from 'axios';
import store from '../store';
import { 
  setDrylandLocations, 
  setBlockLocations ,
  setYardLocations,
  setBlocksToDestinations,
  setLocationsToYards
} from '../reducers/locations';
import { getAuthService } from '../services/AuthService';

class LocationService {
  static getDestinations() {
    return axios.get('/api/destinations', { headers: getAuthService().header });
  }
  
  static getBlocks() {
    return axios.get('/api/blocks', { headers: getAuthService().header });
  }
  
  static getYards() {
    return axios.get('/api/yards', { headers: getAuthService().header });
  }
  
  static getBlocksToDestinations() {
    return axios.get('/api/blocksToDestinations', { headers: getAuthService().header });
  }

  static getLocationsToYards() {
    return axios.get('/api/yards/locationsToYards', { headers: getAuthService().header });
  }

  static getLocations() {
    return axios.all(
      [
        this.getDestinations(),
        this.getBlocks(),
        this.getYards(),
        this.getBlocksToDestinations(),
        this.getLocationsToYards()
      ])
      .then(axios.spread( 
        (destinations, blocks, yards, blocksToDestinations, locationsToYards) => {
          store.dispatch(setDrylandLocations(destinations.data));
          store.dispatch(setBlockLocations(blocks.data));
          store.dispatch(setYardLocations(yards.data));
          store.dispatch(setBlocksToDestinations(blocksToDestinations.data));
          store.dispatch(setLocationsToYards(locationsToYards.data));
      }))
      .catch((error) => {
        console.log(error);
      });
  }
}

export default LocationService; 