const SET_LOADER_OPERATORS = 'SET_LOADER_OPERATORS';
export const ADD_LOADER_OPERATOR = 'ADD_LOADER_OPERATOR';
export const EDIT_LOADER_OPERATOR = 'EDIT_LOADER_OPERATOR';
export const BULK_ADD_LOADER_OPERATORS = 'BULK_ADD_LOADER_OPERATORS';


export function setLoaderOperators (operatorBlockPairs){
  return {
    type: SET_LOADER_OPERATORS,
    payload: operatorBlockPairs
  }
}

export function editLoaderOperators (loaderOperators) {
  return {
    type: EDIT_LOADER_OPERATOR,
    payload: loaderOperators
  }
}

export function bulkAddLoaderOperators (loaderId, operatorIds, blockIds) {
  return {
    type: BULK_ADD_LOADER_OPERATORS,
    payload: {
      loaderId: loaderId,
      operatorIds: operatorIds,
      blockIds: blockIds
    }
  }
}

export default function loaderOperatorAssignment (state = {
  loaderOperatorAssignmentList: []
}, action){
  switch (action.type) {
    case SET_LOADER_OPERATORS:
      return _setLoaderOperators(state, action);
    case BULK_ADD_LOADER_OPERATORS:
      return _bulkAddLoaderOperators(state, action);
    default:
      return state;
  }
}

export function _setLoaderOperators(state, action) {
  return {
    ...state,
    loaderOperatorAssignmentList: action.payload
  }
}

function _bulkAddLoaderOperators(state, action) {
  return {
    loaderId: action.payload.loaderId,
    operatorIds: action.payload.operatorIds,
    blockIds: action.payload.blockIds
  }
}

