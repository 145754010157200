import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import { ASSIGN_SLEEPER_YARD_MODAL } from '../../constants/modalTypes';
import YardSleeperEditor from '../YardSleeperEditor';
import { addYardWithSleeper } from '../../reducers/schedule';

class AssignSleeperModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sleeperDestinationId: ''
    }

    this.handleSleeperDestinationChange = this.handleSleeperDestinationChange.bind(this);

  }

  resetState() {
    this.setState({
      sleeperDestinationId: ''
    });
  }

  handleClose() {
    this.resetState();
    this.props.closeModal();
  }

  handleClickDone(rowId, yardId, blockId) {
    const sleeperDestinationId = this.state.sleeperDestinationId;
    this.props.addYardWithSleeper(rowId, yardId, sleeperDestinationId, blockId);
    this.resetState();
  }

  handleSleeperDestinationChange(destinationId) {
    this.setState({sleeperDestinationId: destinationId});
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === ASSIGN_SLEEPER_YARD_MODAL) {
      const yardId = modalWindow.yardId;
      const rowId = modalWindow.rowId;
      const blockId = modalWindow.blockId
      return (
        <ReactModal
          isOpen={true}
          style={{content: {
            right: '60px',
            height: '130px',
            width: '350px',
            margin: 'auto'
          }}}>
          <div className='assign-sleeper-modal'>
            <p>Is this a sleeper load?</p>
            <YardSleeperEditor yardId={yardId}
              sleeperDestinationId={this.state.sleeperDestinationId}
              handleSleeperDestinationChange={this.handleSleeperDestinationChange}
            />
            <div className='assign-sleeper-modal-buttons'>
              <button className='cancel-button' onClick={() => this.handleClose()}>Cancel</button>
              <button className='done-button' onClick={() => this.handleClickDone(rowId, yardId, blockId)}>Done</button>
            </div>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    addYardWithSleeper: (rowId, yardId, sleeperDestinationId, sleeperOriginBlockId) => {
      dispatch(closeModal());
      dispatch(addYardWithSleeper(rowId, yardId, sleeperDestinationId, sleeperOriginBlockId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignSleeperModal);