import { ADD_NEW_BLOCK, SET_BLOCK_LOAD_INFO, fetchBlockLoadInfo } from '../reducers/blockLoads';
import { BLOCK, DRYLAND } from '../constants/typeConstants';
import { SET_SCHEDULE, REMOVE_TRUCK_SCENARIO } from '../reducers/schedule';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { fetchSleeperLoads } from '../reducers/sleeperLoads';

export const addBlockLoadGuard = (store) => (next) => (action) => {
  if (action.type === ADD_NEW_BLOCK) {
    const targetBlockLoad = store.getState().blockLoadState.byId[action.payload.scenarioId];
    const blockLoadState = targetBlockLoad? targetBlockLoad: {};
    const hasBlockLoad = blockLoadState.hasOwnProperty(action.payload.blockId);
    if (!hasBlockLoad) {
      next(action);
    }
  } else {
    next(action);
  }
}

export const assignDestinationAny = store => next => action => {
  if (action.type === SET_BLOCK_LOAD_INFO) {
    action.payload.forEach(blockLoadInfo => {
      blockLoadInfo.blockLoads.forEach(blockLoad => {
        blockLoad.assigned = 0;
        blockLoad.scheduleIdList = [];
      })
    });
  }
  next(action);
}

export const callFetchBlockLoads = store => next => action => {
  next(action);
  if (action.type === SET_SCHEDULE || 
      action.type === REMOVE_TRUCK_SCENARIO
    ) {
    const scenarioId = store.getState().scenarioState.selectedScenario.id;
    store.dispatch(fetchBlockLoadInfo(scenarioId, false));
    store.dispatch(fetchSleeperLoads(scenarioId));
  }
}

function getBlocksToDryland(scheduleRows) {
  const blocksToDryland = scheduleRows.reduce((acc, scheduleRow) => {
    const scheduleNoTransit = scheduleRow.schedule.filter(scheduleItem => scheduleItem.locationId !== "00000000-0000-0000-0000-000000000000");
    scheduleNoTransit.forEach((schedule, index, schedArray) => {
      if (index < schedArray.length - 1) {
        const next = schedArray[index + 1];
        const hasBlockToDryland = schedule.type === BLOCK && next.type === DRYLAND;
        if (hasBlockToDryland) {
          const idObject = {
            blockStart: schedule.dateObjects.locationStartTime,
            id: next.id,
            rowId: scheduleRow.rowId,
            blockId: schedule.locationId,
            drylandId: next.locationId
          }
          acc.push(idObject);
        }
      }
    });
    return acc;
  }, []);

  const sortedBlocksToDryland = sortBy(blocksToDryland, (b2d) => b2d.blockStart.valueOf());
  return sortedBlocksToDryland;
}

export const setAssignedBlockLoads = store => next => action => {
  if (action.type === SET_BLOCK_LOAD_INFO) {
    const scheduleRows = store.getState().scheduleRowState;
    const blocksToDrylands = getBlocksToDryland(scheduleRows);

    blocksToDrylands.forEach(blockToDryland => {
      const matchingBlock = find(action.payload, {blockId: blockToDryland.blockId});
      if (matchingBlock) {
        const blockLoads = matchingBlock.blockLoads;
        for (let i = 0; i < blockLoads.length; i++) {
          const blockLoad = blockLoads[i];
          const sameDestination = blockLoad.destination === blockToDryland.drylandId;
          if (sameDestination) {
            if (blockLoad.assigned < blockLoad.loads) {
              blockLoad.assigned++;
              blockLoad.scheduleIdList.push(blockToDryland.id);
              break;
            }
          }
        }
      }
    });
  }
  next(action);
}
