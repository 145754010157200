import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';
import SleeperLoadRow from '../../containers/SleeperLoadRow';
import uniqid from 'uniqid';
import { addOrUpdateSleeperLoads, deleteSleeperLoads } from '../../reducers/sleeperLoads';
import { emptyUuid } from '../../utils/uuidHelper';
import some from 'lodash/some';

class SleeperLoadModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();

    this.closeModal = this.closeModal.bind(this);
    this.handleAddLoad = this.handleAddLoad.bind(this);
    this.handleChangeSelection = this.handleChangeSelection.bind(this);
    this.handleRemoveLoad = this.handleRemoveLoad.bind(this);

    ReactModal.setAppElement('#root');
  }

  defaultState() {
    return {
      isModified: false,
      sleeperLoadMap: new Map()
    }
  }

  componentDidMount() {
    const loadsFromYard = this.props.sleeperLoads
      .filter(load => load.yardId === this.props.id)
      .map(load => ({...load, isNew: false}));

    const sleeperLoadMap = this.state.sleeperLoadMap;
    loadsFromYard.forEach(load => {
      sleeperLoadMap.set(load.id, load);
    });

    this.setState({sleeperLoadMap: sleeperLoadMap});
  }

  handleChangeSelection(id, selectedTruckId, selectedOriginBlockId, selectedDestinationId) {
    const sleeperLoadMap = this.state.sleeperLoadMap;
    const sleeperLoad = sleeperLoadMap.get(id);
    const updatedSleeperLoad = {
      ...sleeperLoad,
      truckId: selectedTruckId,
      originBlockId: selectedOriginBlockId,
      destinationId: selectedDestinationId
    }
    sleeperLoadMap.set(id, updatedSleeperLoad);
    this.setState({sleeperLoadMap: sleeperLoadMap});
    this.modifyState();
  }

  modifyState() {
    this.setState({
      isModified: true
    });
  }

  hasSleeperLoads() {
    return this.state.sleeperLoadMap.size > 0;
  }

  getSelectedTruckIdSet() {
    const mapValues = this.state.sleeperLoadMap.values();
    const allSelectedTruckIds = this.props.sleeperLoads.map(load => load.truckId);
    const truckIds = Array.from(mapValues).map(value => value.truckId);
    const combined = truckIds.concat(allSelectedTruckIds);
    return new Set(combined); 
  }

  getMapEntries() {
    const sleeperLoads = [];
    for (let sleeperLoadRow of this.state.sleeperLoadMap.entries()) {
      const id = sleeperLoadRow[0];
      const loadInfo = sleeperLoadRow[1];
      const isNew = loadInfo.isNew? true: false;
      sleeperLoads.push({
        isNew: isNew,
        id: id,
        truckId: loadInfo.truckId,
        destinationId: loadInfo.destinationId,
        originBlockId: loadInfo.originBlockId,
        yardId: this.props.id,
        scenarioId: this.props.scenarioId
      });
    }
    return sleeperLoads;
  }

  getSleeperLoads() {
    if (this.hasSleeperLoads()) {
      const truckIdSet = this.getSelectedTruckIdSet();
      const sleeperLoadElements = this.getMapEntries().map(load => {
        return this.createSleeperLoadRow(load.id, load.truckId, load.originBlockId, load.destinationId, truckIdSet);
      });
      return sleeperLoadElements;
    } else {
      return null;
    }
  }

  saveChanges() {
    if (this.state.isModified) {
      const loads = this.getMapEntries()
        .filter(row => row.truckId !== '' && row.destinationId !== '')
        .map(row => {
          if (row.isNew) {
            return {
              ...row,
              id: emptyUuid
            }
          } else {
            return row;
          }
        });

      if (loads.length > 0) {
        this.props.addOrUpdate(loads);
      } else {
        const deleteInfo = {
          scenarioId: this.props.scenarioId,
          yardId: this.props.id
        };
        this.props.delete(deleteInfo);
      }
    }
  }

  closeModal() {
    this.props.closeModal();
    this.saveChanges();
    this.setState(this.defaultState());
  }

  createSleeperLoadRow(id, truckId, originBlockId, destinationId, selectedTruckIdSet) {
    return <SleeperLoadRow 
      yardId={this.props.id}
      key={id}
      id={id}
      selectedTruckIdSet={selectedTruckIdSet}
      selectedTruckId={truckId}
      selectedDestinationId={destinationId}
      selectedOriginBlockId={originBlockId} 
      handleChangeSelection={this.handleChangeSelection}
      handleRemoveLoad={this.handleRemoveLoad}
    />;
  }

  handleAddLoad() {
    const newId = uniqid();
    const newSleeperValues = {
      isNew: true,
      id: newId,
      truckId: '',
      originBlockId: '',
      destinationId: ''
    }
    this.state.sleeperLoadMap.set(newId, newSleeperValues);
    this.setState({sleeperLoadMap: this.state.sleeperLoadMap})
  }

  handleRemoveLoad(id) {
    this.state.sleeperLoadMap.delete(id);
    this.modifyState();
  }

  getLabels() {
    if (this.state.sleeperLoadMap.size > 0) {
      return (
        <div className='labels'>
          <span className='truck-label'>Truck</span>
          <span className='originBlock-label'>OriginBlock</span>
          <span className='destination-label'>Destination</span>
        </div>
      );
    }
  }

  hasUnassignedLoad() {
    const sleeperLoads = this.getMapEntries();
    const hasUnassigned = (load) => load.truckId === '' || load.destinationId === '' || load.originBlockId === '';
    return some(sleeperLoads, hasUnassigned);
  }

  render() {
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          width: '500px',
          margin: 'auto'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeModal()}
      >
        <div className='sleeper-load-editor'>
          <p><strong>{this.props.name}</strong> sleeper loads</p>
          {this.getLabels()}
          {this.getSleeperLoads()}
          <div className='sleeper-load-buttons'>
            <button className='add-sleeper-load' onClick={this.handleAddLoad}>Add Sleeper Load</button>
            <button className='done' onClick={this.closeModal} disabled={this.hasUnassignedLoad()}>Done</button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    scenarioId: state.scenarioState.selectedScenario.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    addOrUpdate: (loads) => dispatch(addOrUpdateSleeperLoads(loads)),
    delete: (info) => dispatch(deleteSleeperLoads(info))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SleeperLoadModal);