export const FETCH_SLEEPER_LOADS = 'FETCH_SLEEPER_LOADS';
export const SET_SLEEPER_LOADS = 'SET_SLEEPER_LOADS';
export const ADD_OR_UPDATE_SLEEPER_LOADS = 'ADD_OR_UPDATE_SLEEPER_LOADS';
export const ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS = 'ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS';
export const DELETE_SLEEPER_LOADS = 'DELETE_SLEEPER_LOADS';
export const DELETE_SLEEPER_LOADS_SUCCESS = 'DELETE_SLEEPER_LOADS_SUCCESS';
export const SHOW_SCENARIOS_FOR_SLEEPER_LOADS = 'SHOW_SCENARIOS_FOR_SLEEPER_LOADS';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const IMPORT_SLEEPER_LOADS = 'IMPORT_SLEEPER_LOADS';
export const IMPORT_SLEEPER_LOADS_SUCCESSFUL = 'IMPORT_SLEEPER_LOADS_SUCCESSFUL';
const SET_YARDS_TO_DESTINATIONS = 'SET_YARDS_TO_DESTINATIONS';

export function fetchSleeperLoads(scenarioId) {
  return {
    type: FETCH_SLEEPER_LOADS,
    payload: scenarioId
  }
}

export function setSleeperLoads(loads) {
  return {
    type: SET_SLEEPER_LOADS,
    payload: loads
  }
}

export function addOrUpdateSleeperLoads(loads) {
  return {
    type: ADD_OR_UPDATE_SLEEPER_LOADS,
    payload: loads
  }
}

export function addOrUpdateSleeperLoadSuccess(loads) {
  return {
    type: ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS,
    payload: loads
  }
}

export function deleteSleeperLoads(info) {
  return {
    type: DELETE_SLEEPER_LOADS,
    payload: info
  }
}

export function deleteSleeperLoadSuccess(info) {
  return {
    type: DELETE_SLEEPER_LOADS_SUCCESS,
    payload: info
  }
}

export function setYardsToDestination(yardsToDestinations) {
  return {
    type: SET_YARDS_TO_DESTINATIONS,
    payload: yardsToDestinations
  }
}

export function showScenariosForSleeperLoads(date) {
  return {
    type: SHOW_SCENARIOS_FOR_SLEEPER_LOADS,
    payload: date
  }
}

export function showScenariosSuccess(scenarios) {
  return {
    type: SHOW_SUCCESS,
    payload: scenarios
  }
}

export function importSleeperLoads(scenarioId, currentScenarioId) {
  return {
    type: IMPORT_SLEEPER_LOADS,
    payload: {
      scenarioId: scenarioId,
      currentScenarioId: currentScenarioId
    }
  }
}

export function importSuccessful(loads) {
  return {
    type: IMPORT_SLEEPER_LOADS_SUCCESSFUL,
    payload: loads
  }
}


const initialState = {
  sleeperLoadList: [],
  yardsToDestinations: [],
  scenariosToImport: []
}

function sleeperLoads(state = initialState, action) {
  switch (action.type) {
    case SET_SLEEPER_LOADS:
      return _setSleeperLoads(state, action);
    case ADD_OR_UPDATE_SLEEPER_LOADS_SUCCESS:
      return _updateSuccess(state, action);
    case DELETE_SLEEPER_LOADS_SUCCESS:
      return _deleteSuccess(state, action);
    case SET_YARDS_TO_DESTINATIONS:
      return _setYardsToDestination(state, action);
    case SHOW_SUCCESS:
      return _showSuccess(state, action);
    case IMPORT_SLEEPER_LOADS_SUCCESSFUL:
      return _importSuccessful(state, action)
    
    default:
      return state;
  }
}

export default sleeperLoads;

function _importSuccessful(state, action) {
  return {
    ...state,
    sleeperLoadList: action.payload
  }
}

function _showSuccess(state, action) {
  return {
    ...state,
    scenariosToImport: action.payload
  }
}


function _setYardsToDestination(state, action) {
  return {
    ...state,
    yardsToDestinations: action.payload
  }
}

function _setSleeperLoads(state, action) {
  return {
    ...state,
    sleeperLoadList: action.payload
  }
}

function _updateSuccess(state, action) {
  const yardId = action.payload[0].yardId;
  const removedCurrentYardSleepers = state.sleeperLoadList.filter(row => row.yardId !== yardId);
  const updatedList = [...removedCurrentYardSleepers, ...action.payload];
  return {
    ...state,
    sleeperLoadList: updatedList
  }
}

function _deleteSuccess(state, action) {
  const yardId = action.payload.yardId;
  const scenarioId = action.payload.scenarioId;
  const filtered = state.sleeperLoadList.filter(row => row.yardId !== yardId && row.scenarioId === scenarioId);
  return {
    ...state,
    sleeperLoadList: filtered
  }
}
