import React from 'react';
import range from 'lodash/range';

export function displayFormat(hour) {
  let currentHour = hour;
  let period = 'am'
  if (hour > 11) {
    currentHour = (hour === 12)? 12: (hour - 12);
    period = 'pm'
  }

  let hourDisplay = currentHour + ':00' + period;
  return hourDisplay;
}

const TimeMarker = (hour) => (
  <div className='time-marker' key={hour}>
      {displayFormat(hour)}
  </div>
);

export const TimeMarkers = (begin, end) => {
  let hours = range(begin, end+1);
  return hours.map(hour => TimeMarker(hour))
};


export default TimeMarkers;