import React from 'react';
import { connect } from 'react-redux';
import LocationDraggable from './LocationDraggable';
import BlockContextMenu from '../../components/contextMenus/BlockContextMenu';
import BlockLoadEditorModal from '../../components/modals/BlockLoadEditorModal';
import { BLOCK } from '../../constants/typeConstants';
import { showBlockDashboard, clearDashboard, showYardDashboard } from '../../reducers/dashboard';
import find from 'lodash/find';
import { BLOCK_DASHBOARD_DETAILS, YARD_DASHBOARD_DETAILS } from '../../constants/dashboardTypes';
import DraggableDestination from './DraggableDestination';
import { highlightBlock, highlightYard, clearHighlights } from '../../reducers/highlights';
import { matchesSelectedOperatingUnit } from '../../utils/operatingUnitHelper';
import DraggableYard from './DraggableYard';

class LocationDraggableSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedItemId: '',
      doNotShowBlocksWithoutLoads: false
    }

    this.showBlockInDashboard = this.showBlockInDashboard.bind(this);
    this.showYardInDashboard = this.showYardInDashboard.bind(this);
    this.handleNotShowChange = this.handleNotShowChange.bind(this);
  }

  showBlockLoadEditorModal() {
    const modalWindows = this.props.modalWindows;
    if (modalWindows.isModalOpen && modalWindows.modalType === BLOCK) {
      return <BlockLoadEditorModal id={modalWindows.modalId} name={modalWindows.name} />
    } else {
      return null;
    }
  }

  setClickedId(id) {
    this.setState({clickedItemId: id});
  }

  clearDashboard() {
    this.props.clearDashboard();
    this.setState({clickedItemId: ''});
  }

  showYardInDashboard(id) {
    this.props.highlightYard(id);
    if (id !== this.state.clickedItemId || this.props.dashboard.dashboardType !== YARD_DASHBOARD_DETAILS) {
      const targetYard = find(this.props.yards, {id: id});
      this.props.showYardDashboard(id, targetYard);
      this.setClickedId(id);
    } else {
      this.clearDashboard();
    }
  }

  showBlockInDashboard(id) {
    this.props.highlight(id);
    if (id !== this.state.clickedItemId || this.props.dashboard.dashboardType !== BLOCK_DASHBOARD_DETAILS) {
      const targetBlock = find(this.props.blocks, {id: id});
      this.props.showBlockDashboard(id, targetBlock);
      this.setClickedId(id);
    } else {
      this.clearDashboard();
    }
  }



  getBlockLoad(blockId) {
    const scenario = this.props.blockLoadState.byId[this.props.currentScenarioId];
    const block = scenario? scenario[blockId]: null;
    const validBlockLoad = block? block.blockLoads: {};
    return validBlockLoad;
  }

  getTotalLoadsRemaining(blockId) {
    const blockLoads = this.getBlockLoad(blockId);
    if (blockLoads && blockLoads.length > 0) {
      return blockLoads.reduce((acc, blockLoad) => acc + (blockLoad.loads - blockLoad.assigned), 0);
    } else {
      return null;
    }
  }

  formatBlockNameWithRemainingLoads(blockId, blockName) {
    const totalLoads = this.getTotalLoadsRemaining(blockId);
    if (totalLoads != null) {
      return `${blockName} (${totalLoads})`;
    } else {
      return blockName;
    }
  }

  isBlockDraggable(blockId) {
    const scenarioId = this.props.currentScenarioId;
    const blockLoadObject = this.props.blockLoadState.byId[scenarioId];
    if (blockLoadObject && !this.hasBeenPublished()) {
      return (blockId in blockLoadObject);
    } else {
      return false;
    }
  }

  getScrollableBlockHeight(blocksToRender) {
    const blockLength = blocksToRender.length;
    if (blocksToRender.length > 24) {
      return {
        height: '16.8em'
      }
    }

    // for every 4 blocks in a row a height must be set to 3em
    const blockEmValue = 3;
    const maxBlocksInRow = 4;
    const rowCount = Math.ceil(blockLength / maxBlocksInRow);
    const heightValue = `${rowCount * blockEmValue}em`;
    return {
      height: heightValue
    }
  }

  hasBeenPublished() {
    return this.props.isPublished || this.props.wasPublished;
  }

  handleNotShow() {
    return this.state.doNotShowBlocksWithoutLoads;
  }

  handleNotShowChange() {
    const isChecked = this.state.doNotShowBlocksWithoutLoads;
    if (isChecked){
      this.setState({ doNotShowBlocksWithoutLoads: false});
    }
    else {
      this.setState({ doNotShowBlocksWithoutLoads: true});
    }
  }

  getBlocksToRender() {
    var blocksToRender;
    if (!this.state.doNotShowBlocksWithoutLoads) {
      blocksToRender = this.props.blocks
        .filter(block => block.active && matchesSelectedOperatingUnit(block.id) && !block.isDeleted)
        .map(block =>
          <BlockContextMenu
            key={'context'+block.id}
            id={block.id}
            name={block.name}
            scenarioId={this.props.currentScenarioId}>
            <LocationDraggable
              key={block.id}
              id={block.id}
              name={this.formatBlockNameWithRemainingLoads(block.id, block.name)}
              type={block.type}
              currentDashboardId={this.props.dashboard.id}
              clickHandler={this.showBlockInDashboard}
              isDraggable={this.isBlockDraggable(block.id)}
            />
          </BlockContextMenu>
      );
    }
    else {
      blocksToRender = this.props.blocks
        .filter(block => block.active && matchesSelectedOperatingUnit(block.id) && !block.isDeleted && this.isBlockDraggable(block.id))
        .map(block =>
          <BlockContextMenu
            key={'context'+block.id}
            id={block.id}
            name={block.name}
            scenarioId={this.props.currentScenarioId}>
            <LocationDraggable
              key={block.id}
              id={block.id}
              name={this.formatBlockNameWithRemainingLoads(block.id, block.name)}
              type={block.type}
              currentDashboardId={this.props.dashboard.id}
              clickHandler={this.showBlockInDashboard}
              isDraggable={this.isBlockDraggable(block.id)}
            />
          </BlockContextMenu>
      );
    }
    return blocksToRender;
  }

  render() {

    const blocksToRender = this.getBlocksToRender();
    
    return (
      <div className="location-draggable-section">
        <div className="location-draggable-list" style={{width: '460px'}}>
          <div>
            <input className='notShow-checkbox' type='checkbox' 
              onChange={this.handleNotShowChange}
              checked={this.handleNotShow()} />
              Do not show blocks without loads
            </div>
          <div className='block-draggable-list' style={this.getScrollableBlockHeight(blocksToRender)}>
            {blocksToRender}
          </div>
        </div>
        <div className="location-draggable-list draggable-drylands">
          <DraggableDestination hasBeenPublished={this.hasBeenPublished()}/>
        </div>
        <div className="location-draggable-list">
          <DraggableYard hasBeenPublished={this.hasBeenPublished()} 
            showYardInDashboard={this.showYardInDashboard} />
        </div>
        {this.showBlockLoadEditorModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destinations: state.locations.destinations,
    blocks: state.locations.blocks,
    yards: state.locations.yards,
    modalWindows: state.modalWindows,
    blockLoadState: state.blockLoadState,
    currentScenarioId: state.scenarioState.selectedScenario.id,
    dashboard: state.dashboard.currentlySelected,
    isPublished: state.scenarioState.selectedScenario.published,
    wasPublished: state.scenarioState.selectedScenario.wasPublished,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showBlockDashboard: (blockId, data) => {
      dispatch(showBlockDashboard(blockId, data));
    },
    showYardDashboard: (yardId, data) => {
      dispatch(showYardDashboard(yardId, data));
    },
    highlightYard: (id) => {
      dispatch(highlightYard(id));
    },
    clearDashboard: () => {
      dispatch(clearHighlights());
      dispatch(clearDashboard());
    },
    highlight: (id) => {
      dispatch(highlightBlock(id));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationDraggableSection);
