import axios from 'axios';
import store from '../store';
import { setOperatingUnitAssignment} from '../reducers/operatingUnits';
import { getAuthService } from '../services/AuthService';


class OperatingUnitAssignmentService {
  static getOperatingUnitsApi() {
    return axios.get('/api/operatingUnits/GetOperatingUnitAssignments/', { headers: getAuthService().header });
  }

  static getOperatingUnits() {
    return this.getOperatingUnitsApi()
      .then((response) => {
        const data = response.data;
        //const operatingUnits = data.operatingUnits;
        const assignment = data.assignment;
        store.dispatch(setOperatingUnitAssignment(assignment));
      })
      .catch(error => console.log(error));
  }
}

export default OperatingUnitAssignmentService;