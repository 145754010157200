const SET_WORK_HOURS = 'SET_WORK_HOURS';

export function setWorkHours(workHours) {
  return {
    type: "",
    payload: workHours
  }
}

function setWorkHoursReducer(state, action) {
  
  if (action.payload) {
    return {
      beginHour: action.payload.beginHour,
      endHour: action.payload.endHour
    }
  }

  return state;
}

const workHoursForSchedule = (state = {
  beginHour: 2,
  endHour: 19,
  maxScheduledMinutes: 600
}, action) => {
  switch (action.type) {
    case SET_WORK_HOURS:
      return setWorkHoursReducer(state, action);
    default:
      return state;
  }
};

export default workHoursForSchedule;
