import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import {
  setBlockLoadInfo,
  SAVE_BLOCK_LOAD_CHANGES,
  FETCH_BLOCK_LOAD_INFO
} from '../../reducers/blockLoads';
import { failedAction } from '../../reducers/requestActions';
import { getAuthService } from '../AuthService';


export const blockLoadEpic = action$ => (
  action$.ofType(SAVE_BLOCK_LOAD_CHANGES)
    .mergeMap(action =>
      ajax.post(
        '/api/blockloads', 
        action.payload,
        getAuthService().header
      )
      .map(ajaxResponse => setBlockLoadInfo(ajaxResponse.response))
      .catch(failure => Observable.of(failedAction(failure)))
    )
  );

export const fetchBlockLoads = action$ => (
  action$.ofType(FETCH_BLOCK_LOAD_INFO)
    .mergeMap(action => {
      const scenarioId = action.payload.scenarioId;
      const blockLoadUrl = '/api/blockloads/' + scenarioId;
      return ajax.get(blockLoadUrl, getAuthService().header)
      .map(ajaxResponse => setBlockLoadInfo(ajaxResponse.response))
      .catch(failure => Observable.of(failedAction(failure)))
    })
);