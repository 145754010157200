import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { TRAINEE_DRIVER_MODAL } from '../../constants/modalTypes';
import { connect } from 'react-redux';
import { closeModal } from '../../reducers/modalWindows';

class TraineeDriverModal extends Component {
  constructor(props) {
    super(props);
    ReactModal.setAppElement('#root');
  }

  render() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === TRAINEE_DRIVER_MODAL && modalWindow.isModalOpen) {
      const trainingDate = this.props.modalWindow.traineeDriver.trainingStartDate.split('T')[0];
      return (
        <ReactModal
        isOpen={true}
          style={{content: {
            right: '60px',
            height: '220px',
            width: '385px',
            margin: 'auto'
          }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeModal()}>
          <div className='trainee-driver-modal'>
            <p>{this.props.modalWindow.traineeDriver.name} is a trainee.</p>
            <p>Training start date: {trainingDate}</p>
            <p>Mentor driver: {this.props.modalWindow.mentorDriver.name}</p>
            <div className='ranking-modal-buttons'>
              <button className='done-button' onClick={() => this.props.closeModal()}>Done</button>
            </div>
          </div>
        </ReactModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeDriverModal);