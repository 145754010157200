import TransitTimeCalculations from './transitTimeCalculations';
import { last, find, concat } from 'lodash';
import { BLOCK, DRYLAND } from '../constants/typeConstants';
import uniqid from 'uniqid';

const combinedLocations = (state) => concat(
  state.locations.destinations,
  state.locations.blocks,
  state.locations.yards
);

function findTransitDefinition(state, locationId) {
  const targetLocation = find(combinedLocations(state), {id: locationId});
  return targetLocation? targetLocation: {};
}

function augmentPayload(state, action) {
  let locationId = action.payload.locationId;
  let transitDef = findTransitDefinition(state, locationId);
  return {
    ...action,
    payload: {
      ...action.payload,
      name: transitDef.name,
      type: transitDef.type
    }
  };
}

function addIdToPayload (action) {
  let locationId = uniqid('location');
  return {
    ...action,
    payload: {
      ...action.payload,
      id: locationId
    }
  }
}

function hasBlockToDryland(origin, destination) {
  return origin.type === BLOCK && destination.type === DRYLAND;
}

function getCurrentScenario(state) {
  return state.scenarioState.selectedScenario.id;
}

export function rowLocationTransitCalculation(state, action) {
  const rowState = find(state.scheduleRowState, {rowId: action.payload.rowId});
  let updatedAction = action;
  if (rowState) {
    const lastItemScheduled = last(rowState.schedule);
    if (lastItemScheduled) {
      const locationId = action.payload.locationId;
      const origin = findTransitDefinition(state, lastItemScheduled.locationId);
      const destination = findTransitDefinition(state, locationId);
      const transitCalculation = new TransitTimeCalculations(
        state.locations.blocksToDestinations,
        state.locations.locationsToYards
      );
      const result = transitCalculation.getTransitTime(origin, destination);
      const scenarioId = getCurrentScenario(state);
      updatedAction = {
        ...action, 
        payload: {
          ...action.payload,
          name: destination.name, 
          transit: result, 
          type: destination.type
        },
        meta: {
          scenarioId: scenarioId,
          origin: origin,
          destination: destination,
          hasBlockToDryland: hasBlockToDryland(origin, destination)
        }
      };
    } else {
      updatedAction = augmentPayload(state, action);
    }
  }
    
  updatedAction = addIdToPayload(updatedAction);

  return updatedAction;
}
