import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { closeModal } from '../../reducers/modalWindows';


class DuplicateObjectNamesModal extends Component {
  constructor(props) {
    super(props);

    ReactModal.setAppElement('#root');
  }

  clickActivate(object) {
    this.props.closeModal();
    this.props.activate(object);
  }

  clickRecover(object) {
    this.props.closeModal();
    this.props.recover(object);
  }

  closeModal() {
    this.props.closeModal();
  }
  //TODO : fix the object names.
  // some objects like error codes have different property than name (like errorCode.code instead of name)
  getText(object) {
    if (!object.active) {
      return (
        <div>
          <div className='duplicate-object-name-modal-text'>
            <p>{object.name}{object.code} already exists but it is deactivated.</p>
            
            <p>Would you like to activate it ?</p>
          </div>
          <div className='duplicate-object-name-modal-buttons'>
            <button onClick={() => this.closeModal()}>Canel</button>
            <button className='activate-button' onClick={() => this.clickActivate(object)}>Activate</button>
          </div>
        </div>
      )
    }
    if (object.isDeleted) {
      return (
        <div>
          <div className='duplicate-object-name-modal-text'>
            <p>{object.name}{object.code} already exists but was removed.
            This will recreate {object.name}{object.code}.</p>
          
            <p>Would you like to recreate it ?</p>
          </div>
          <div className='duplicate-object-name-modal-buttons'>
            <button onClick={() => this.closeModal()}>Canel</button>
            <button className='recreate-button' onClick={() => this.clickRecover(object)}>Recreate</button>
          </div>
        </div>
      )
    }
    if (object.active && !object.isDeleted) {
      return (
        <div>
          <div className='duplicate-object-name-modal-text'>
            <p>{object.name}{object.code} already exists.</p>
          </div>
          <div className='duplicate-object-name-modal-buttons'>
            <button className='done-button' onClick={() => this.closeModal()}>Done</button>
          </div>
        </div>
      )
    }
  }
 
  render() {
    const object = this.props.modalWindow.object;
    return (
      <ReactModal
        isOpen={true}
        style={{content: {
          top: '140px',
          left: '135px',
          right: '60px',
          height: '150px',
          width: '330px'
        }}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.closeModal()}>
      <div className='duplicate-object-name-modal'>
        {this.getText(object)}
      </div>
      </ReactModal>
    ); 
  }  
}

const mapStateToProps = (state) => {
  return {
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateObjectNamesModal);
