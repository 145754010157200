import store from '../store';

function getStoreState() {
  return store.getState();
}

function getLocationsToYards() {
  return getStoreState().locations.locationsToYards;
}

export function getYardTimes(locationId) {
  const locationsToYards = getLocationsToYards();
  const targetYards = locationsToYards.filter(row => row.locationId === locationId);
  const yardTimes = targetYards.map(row => {
    return {
      yardId: row.yardId,
      toYardTime: row.toYardTime
    }
  });
  return yardTimes;
}