
const ADD_NEW_BLOCK_LOAD = 'ADD_NEW_BLOCK_LOAD';
const UPDATE_BLOCK_LOAD = 'UPDATE_BLOCK_LOAD';
const REMOVE_BLOCK_LOAD = 'REMOVE_BLOCK_LOAD';
export const ADD_NEW_BLOCK = 'ADD_NEW_BLOCK';
export const SAVE_BLOCK_LOAD_CHANGES = 'SAVE_BLOCK_LOAD_CHANGES';
export const FETCH_BLOCK_LOAD_INFO = 'FETCH_BLOCK_LOAD_INFO';
export const SET_BLOCK_LOAD_INFO = 'SET_BLOCK_LOAD_INFO';


export function fetchBlockLoadInfo(scenarioId, showLoading) {
  const shouldShowLoading = (showLoading === true || showLoading === false)? showLoading: true;
  return {
    type: FETCH_BLOCK_LOAD_INFO,
    shouldShowLoading: shouldShowLoading,
    payload: {
      scenarioId: scenarioId
    }
  }
}

export function setBlockLoadInfo(blockLoadInfo) {
  return {
    type: SET_BLOCK_LOAD_INFO,
    payload: blockLoadInfo
  }
}

export function saveBlockLoadChanges(blockLoad) {
  return {
    type: SAVE_BLOCK_LOAD_CHANGES,
    payload: blockLoad
  }
}

export function addNewBlockLoad(blockLoad) {
  return {
    type: ADD_NEW_BLOCK_LOAD,
    payload: blockLoad
  }
}

export function updateBlockLoad(blockLoad) {
  return {
    type: UPDATE_BLOCK_LOAD,
    payload: blockLoad
  }
}

export function addNewBlock(blockInfo) {
  return {
    type: ADD_NEW_BLOCK,
    payload: blockInfo
  }
}

export function removeBlockLoad(blockLoad) {
  return {
    type: REMOVE_BLOCK_LOAD,
    payload: blockLoad
  }
}

function blockLoadState(state = {byId: {}}, action) {
  switch (action.type) {
    case ADD_NEW_BLOCK:
      return _addNewBlock(state, action);
    case ADD_NEW_BLOCK_LOAD:
      return matcher(state, action, _addNewBlockLoad);
    case UPDATE_BLOCK_LOAD:
      return matcher(state, action, _updateBlockLoad);
    case REMOVE_BLOCK_LOAD:
      return matcher(state, action, _removeBlockLoad);
    case SET_BLOCK_LOAD_INFO:
      return setBlockLoadInfoState(state, action);
    default:
      return state;
  }
}

function setBlockLoadInfoState(state, action) {
  const newState = action.payload.reduce((stateAcc, blockLoadInfo) => {
    const scenarioId = blockLoadInfo.scenarioId;
    const blockId = blockLoadInfo.blockId;
    return {
      [scenarioId] : {
        ...stateAcc[scenarioId],
        [blockId]: {
          blockLoads: blockLoadInfo.blockLoads,
          comment: blockLoadInfo.comment
        }
      }
    }
  }, {});

  return {byId: newState};
}

function _addNewBlock(state, action) {
  const scenarioId = action.payload.scenarioId;
  const blockId = action.payload.blockId;
  const updatedState = {
    byId: {
      ...state.byId,
      [scenarioId] : {
        ...state.byId[scenarioId],
        [blockId]: {
          blockLoads: []
        }
      }
    }
  }
  return updatedState;
}

function _addNewBlockLoad(currentBlock, action) {
  const targetBlockLoads = currentBlock.blockLoads;
  const updatedBlockLoads = [...targetBlockLoads, action.payload.blockLoad];
  return updatedBlockLoads;
}

function _updateBlockLoad(currentBlock, action) {
  const blockLoads = currentBlock.blockLoads;
  const updated = blockLoads.map(blockLoad => {
    if (blockLoad.blockLoadId === action.payload.blockLoadId) {
      return {
        ...blockLoad,
        ...action.payload.blockLoad,
        inOrder: action.payload.blockLoad.loadOrder > 0
      }
    } else {
      return blockLoad;
    }
  });
  return updated;
}

function _removeBlockLoad(currentBlock, action) {
  const blockLoads = currentBlock.blockLoads;
  const filteredBlockLoad = blockLoads.filter(blockLoad => (blockLoad.blockLoadId !== action.payload.blockLoadId));
  return filteredBlockLoad;
}

function getOrCreateBlock(state, scenarioId, blockId) {
  const scenario = state.byId[scenarioId];
  const emptyBlockLoads = {
    blockLoads: []
  };

  if (scenario) {
    const block = scenario[blockId];
    if (block) {
      return block;
    } else {
      return emptyBlockLoads;
    }
  } else {
    return emptyBlockLoads;
  }
}

function matcher(state, action, processor) {
  const scenarioId = action.payload.scenarioId;
  const blockId = action.payload.blockId;
  const blockLoadState = Object.keys(state.byId).reduce((stateAcc, stateScenarioId) => {
    if (stateScenarioId === scenarioId) {
      const currentBlock = getOrCreateBlock(state, scenarioId, blockId);
      const updatedBlockLoads = processor(currentBlock, action);
      return {
        ...stateAcc,
        [scenarioId]: {
          ...state.byId[scenarioId],
          [blockId]: {
            blockLoads: updatedBlockLoads
          }
        }
      };
    } else {
      return stateAcc[stateScenarioId] = state.byId[stateScenarioId];
    }
  }, {});
  return {byId: blockLoadState};
}

export default blockLoadState;
