import React, { Component } from 'react';

class TruckCompanyFieldEditor extends Component {
  constructor(props) {
    super(props);

    const fieldProps = props.truckCompanyFieldProps? props.truckCompanyFieldProps: {};
    const defaultTruckCompanyId = fieldProps.id? fieldProps.id: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultEmail = fieldProps.email? fieldProps.email: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;

    this.state = {
      id: defaultTruckCompanyId,
      isModified: false,
      name: defaultName,
      email: defaultEmail,
      active: defaultActive
    }

    this.changeName = this.changeName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeName(event) {
    this.setState({name: event.target.value});
    this.setModified();
  }

  changeEmail(event) {
    this.setState({email: event.target.value});
    this.setModified();
  }
  
  
  render() {
    return (
      <React.Fragment>
        <div className='name-field truckCompany-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeName} />
        </div>
        <div className='email-field truckCompany-field'>
          <div>Email</div>
          <input type='email' value={this.state.email} onChange={this.changeEmail} />
        </div>
        <div className='truckCompany-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

export default TruckCompanyFieldEditor;

