import React from 'react';
import ConfigComment from '../../containers/configuration/ConfigComment';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { emptyUuid } from '../../utils/uuidHelper';
import sortBy from 'lodash/sortBy';

class LoaderFieldEditor extends React.Component {
  constructor(props) {
    super(props);

    const fieldProps = props.loaderFieldProps? props.loaderFieldProps: {};
    const defaultLoaderId = fieldProps.loaderId? fieldProps.loaderId: '';
    const defaultName = fieldProps.name? fieldProps.name: '';
    const defaultComment = fieldProps.comments? fieldProps.comments: '';
    const defaultActive = fieldProps.active? fieldProps.active: false;
    const operatorBlockPair = fieldProps.operatorBlockPair? fieldProps.operatorBlockPair: []
    const emptyOperatorBlockPair = {
      blockId: emptyUuid,
      operatorId: emptyUuid
    }
    operatorBlockPair.push(emptyOperatorBlockPair);

    this.state = {
      id: defaultLoaderId,
      isModified: false,
      name: defaultName,
      comments: defaultComment,
      active: defaultActive,
      operatorBlockPair: operatorBlockPair
    }

    this.changeLoaderName = this.changeLoaderName.bind(this);
    this.changeOperatorId = this.changeOperatorId.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.changeBlockId = this.changeBlockId.bind(this);
  }

  setModified() {
    this.setState({isModified: true});
  }

  setNotModified() {
    this.setState({isModified: false});
  }

  clickDone() {
    this.props.clickDone(this.state);
    this.setNotModified();
  }

  clickCancel() {
    this.props.clickCancel();
  }

  changeComments(updatedComment) {
    this.setState({comments: updatedComment});
    this.setModified();
  }

  changeLoaderName(event) {
    const truncatedName = event.target.value.substring(0, 8);
    this.setState({name: truncatedName});
    this.setModified();
  }

  changeOperatorId(event, pair) {
    const newOperatorId = event.target.value;
    let stateOperatorBlockPairCopy = [...this.state.operatorBlockPair];
    const specificPair = find(this.state.operatorBlockPair, {blockId: pair.blockId, operatorId: pair.operatorId});
    const index = stateOperatorBlockPairCopy.indexOf(specificPair);
    let item = {...stateOperatorBlockPairCopy[index]};
    item.operatorId = newOperatorId;
    stateOperatorBlockPairCopy[index] = item;
    this.setState({operatorBlockPair: stateOperatorBlockPairCopy});
    this.setModified();
  }

  changeBlockId(event, pair) {
    const newBlockId = event.target.value;
    let stateOperatorBlockPairCopy = [...this.state.operatorBlockPair];
    const specificPair = find(this.state.operatorBlockPair, {blockId: pair.blockId, operatorId: pair.operatorId});
    const index = stateOperatorBlockPairCopy.indexOf(specificPair);
    let item = {...stateOperatorBlockPairCopy[index]};
    item.blockId = newBlockId;
    stateOperatorBlockPairCopy[index] = item;
    this.setState({operatorBlockPair: stateOperatorBlockPairCopy});
    this.setModified();
  }

  deletePair(event, pair){
    if (pair.blockId !== emptyUuid || pair.operatorId !== emptyUuid){
      let stateOperatorBlockPairCopy = [...this.state.operatorBlockPair];
      const specificPair = find(this.state.operatorBlockPair, {blockId: pair.blockId, operatorId: pair.operatorId});
      const index = stateOperatorBlockPairCopy.indexOf(specificPair);
      stateOperatorBlockPairCopy.splice(index, 1);
      this.setState({operatorBlockPair: stateOperatorBlockPairCopy});
      this.setModified();
    }
  }

  getOperatorOptions() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const activeOperators = this.props.operators.filter(op => op.active && !op.isDeleted);
    //const assignments = this.props.assignments? this.props.assignments: [];
    //const allSelectedOperatorIds = assignments.map(assign => assign.loaderOperatorId);
    const operatorOptions = activeOperators.map(operator => {
      const id = operator.id;
      //const disabled = allSelectedOperatorIds.includes(id);
      return <option key={id} value={id}>{operator.name}</option>;
    });
    operatorOptions.unshift(noSelection);
    return operatorOptions;
  }

  getOperatorPicker() {
    return (
      <select className='loader-operator-picker' value={this.state.operator} onChange={this.changeOperatorId}>
        {this.getOperatorOptions()}
      </select>
    );
  }

  getBlockOptions() {
    const noSelection = <option key='blank' value={emptyUuid}>None</option>;
    const allBlocksForthisLoader = this.state.operatorBlockPair.map(x => x.blockId);
    const activeBlocks = sortBy(this.props.blocks, block => block.name)
      .filter(block => block.active && !block.isDeleted)
      .map(block => {
        const id = block.id;
        const disabled = allBlocksForthisLoader.includes(id);
        return <option key={id} value={id} disabled={disabled}>{block.name}</option>;
      });
      activeBlocks.unshift(noSelection);
      return activeBlocks;
  }

  getBlockPicker() {
    return (
      <select className='loader-operator-picker' value={this.state.blockId} onChange={this.changeBlockId}>
        {this.getBlockOptions()}
      </select>
    )
  }

  isDeleteDisabled(pair) {
    if ((pair.blockId === emptyUuid && pair.operatorId === emptyUuid) || this.state.operatorBlockPair.length === 1) {
      return true;
    }
  }

  getPairPicker() {
      return (<div>
      {this.state.operatorBlockPair.map((pair) => (
        <React.Fragment key={pair.blockId}>
          <div>
            <select style={{marginTop: 15}} value={pair.blockId} onChange={(e) => this.changeBlockId(e, pair)}>{this.getBlockOptions()}</select>
            <select style={{marginLeft: 15, marginTop:15}} value={pair.operatorId} onChange={(e) => this.changeOperatorId(e, pair)}>{this.getOperatorOptions()}</select>
            <button style={{marginLeft: 15, marginTop:15}} onClick={(e) => this.deletePair(e, pair)} disabled={this.isDeleteDisabled(pair)}>Delete</button>
          </div>
          </React.Fragment>
      ))}
      </div>);
  }

  render() {
    return (
      <React.Fragment>
        <div className='name-field loader-field'>
          <div>Name</div>
          <input type='text' value={this.state.name} onChange={this.changeLoaderName} />
        </div>
        <ConfigComment key={this.state.id} 
          top={300}
          left={575}
          comments={this.state.comments}
          updateComments={this.changeComments}
        />
        <div>
          <div style={{marginTop: 45}}>Block-Operator Pairs</div>
            {this.getPairPicker()}
        </div>
        <div className='loader-field-edit-buttons'>
          <button onClick={this.clickCancel} className='cancel-button'>Cancel</button>
          <button onClick={this.clickDone} className='done-button' disabled={!this.state.isModified}>Done</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.locations.blocks,
    operators: state.operators,
    assignments: state.loaderOperatorAssignment.loaderOperatorAssignmentList,
    loaders: state.loaders.loaderList
  }
}

export default connect(mapStateToProps)(LoaderFieldEditor);