import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import find from 'lodash/find';
import TruckCompanyFieldEditor from '../../components/fieldEditors/TruckCompanyFieldEditor';
import uniqid from 'uniqid';
import { addTruckCompany, updateTruckCompany, bulkActivateTruckCompanies } from '../../reducers/truckCompanies';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import difference from 'lodash/difference';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import { TRUCKCOMPANY } from '../../constants/typeConstants';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';
import sortBy from 'lodash/sortBy';

class TruckCompanyConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      truckCompanyIdSelected: '',
      isAddingNewTruckCompany: false
    }
  }

  componentDidUpdate(previousProps) {
    const previousTruckCompanies = previousProps.truckCompanies.map(truckComapny => truckComapny.id);
    const currentTruckCompanies = this.props.truckCompanies.map(truckComapny => truckComapny.id);
    if (currentTruckCompanies.length > previousTruckCompanies.length) {
      const diff = difference(currentTruckCompanies, previousTruckCompanies);
      if (diff.length > 0) {
        const newTruckCompany = diff[0];
        this.setState({
          truckCompanyIdSelected: newTruckCompany,
          isAddingNewTruckCompany: false
        });
      }
    }
  }

  addNewTruckCompany() {
    this.setState({
      truckCompanyIdSelected: '',
      isAddingNewTruckCompany: true
    });
  }

  selectTruckCompany(id) {
    this.setState({
      truckCompanyIdSelected: id,
      isAddingNewTruckCompany: false
    });
  }

  getSelectedTruckCompany() {
    const truckCompany = find(this.props.truckCompanies, {id: this.state.truckCompanyIdSelected});
    return truckCompany;
  }

  getSelectedTruckCompanyName() {
    const truckCompany = this.getSelectedTruckCompany();
    if (truckCompany) {
      return truckCompany.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedTruckCompany() {
    return this.state.truckCompanyIdSelected !== '';
  }

  getTruckCompaniesList() {
    const options = this.props.truckCompanies;
    const sortedOptions = sortBy(options, truckCompany => truckCompany.name)
    .filter(truckCompany => !truckCompany.isDeleted)
    .map(truckCompany => {
      return {
        value: truckCompany.id,
        label: truckCompany.name
      }
    });

    const handleChange = (selectedOption) => this.selectTruckCompany(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedTruckCompanyName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={sortedOptions}
      onChange={handleChange}
    />;
  }

  getPrompt() {
    const truckCompany = this.getSelectedTruckCompany();
    if (truckCompany) {
      return `Edit Truck Company ${truckCompany.name}`;
    } else if (this.state.isAddingNewTruckCompany) {
      return 'Add a new Truck Company';
    } else {
      return 'Select a Truck Company to edit';
    }
  }

  checkExistence(state) {
    if (this.state.isAddingNewTruckCompany) {
      const truckCompany = find(this.props.truckCompanies, {name: state.name});
      if (truckCompany) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewTruckCompany) {
      if (this.getSelectedTruckCompanyName() !== state.name) {
        const truckCompany = find(this.props.truckCompanies, {name: state.name});
        if (truckCompany) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }

  getTruckCompanyEditPanel() {
    if (this.hasSelectedTruckCompany() && !this.state.isAddingNewTruckCompany) {
      return (
        <div>
          <TruckCompanyFieldEditor
            key={uniqid()}
            truckCompanyFieldProps={this.getSelectedTruckCompany()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectTruckCompany('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewTruckCompany) {
      return (
        <div>
          <TruckCompanyFieldEditor 
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectTruckCompany('')}
          />
        </div>
      );
    }
  }

  activate() {
    const truckCompany = this.getSelectedTruckCompany();
    if (truckCompany) {
      truckCompany.active = !truckCompany.active;
      this.props.update(truckCompany);
    }
  }

  getActivateButton() {
    const truckCompany = this.getSelectedTruckCompany();
    if (truckCompany) {
      const buttonName = truckCompany.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getRemoveButton() {
    const truckCompanyId = this.state.truckCompanyIdSelected;
    if (truckCompanyId !== '') {
      return (
        <button className='remove-truckCompany'
          onClick={() => this.props.removeTruckCompanyModal(truckCompanyId)}>
          Remove Truck Company
        </button>
      )
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      const updatedTruckCompaniesWithId = this.props.truckCompanies
      .filter(truckCompany => !truckCompany.isDeleted)
      .map(truckCompany => {
        return {
          ...truckCompany,
          id: truckCompany.id,
          name: truckCompany.name
        }
      });
      return <BulkActivationModal
        type={TRUCKCOMPANY}
        activationItems={updatedTruckCompaniesWithId}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='truckCompany-configuration configuration-forms'>
        <button className='add-new-truckCompany' onClick={() => this.addNewTruckCompany()}>Add New Truck Company</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='truckCompany-editor'>
          <p>{this.getPrompt()}</p>
        </div>
        <div className='truckCompany-list'>
          {this.getTruckCompaniesList()}
        </div>
        <div className='truckCompany-edit-panel'>
          {this.getTruckCompanyEditPanel()}
          <div className='truckCompany-config-buttons'>
            {this.getActivateButton()}
            {this.getRemoveButton()}
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectTruckCompany('')} />
        {this.getBulkActivationModal()}
        {this.getDuplicateObjectNameModal()}
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    truckCompanies: state.truckCompanies.truckCompaniesList,
    modalWindow: state.modalWindows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addTruckCompany(state));
    },
    update: (state) => {
      dispatch(updateTruckCompany(state));
    },
    removeTruckCompanyModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems));
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateTruckCompanies(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TruckCompanyConfiguration);