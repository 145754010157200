import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ADD_DRIVER, setDrivers, REMOVE_DRIVER, EDIT_DRIVER, BULK_ACTIVATE_DRIVERS } from '../../reducers/drivers';
import { success, failure } from '../notificationMessages';
import { getAuthService } from '../AuthService';
import { setTrucks } from '../../reducers/trucks';


function updateDriversAndTrucks(response) {
  // const updatePayload = {
  //   trucks: response.trucks,
  //   type: SET_TRUCKS
  // }
  const trucks = response.trucks.value;
  const drivers = response.drivers.value;
  return Observable.of(
    setDrivers(drivers),
    setTrucks(trucks)
  );
}

export const addDriverEpic = action$ => (
  action$.ofType(ADD_DRIVER)
    .mergeMap(action => (
      ajax.post('/api/drivers', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => updateDriversAndTrucks(ajaxResponse.response), 'Successfully added new driver'))
        .catch(fail => Observable.of(failure('Could not add new driver')))
    ))
);

export const removeDriverEpic = action$ => (
  action$.ofType(REMOVE_DRIVER)
    .mergeMap(action => (
      ajax.delete(`/api/drivers/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => setDrivers(ajaxResponse.response), 'Successfully removed driver'))
        .catch(fail => Observable.of(failure('Could not remove driver')))
    ))
);

export const updateDriverEpic = action$ => (
  action$.ofType(EDIT_DRIVER)
    .mergeMap(action => (
      ajax.put('/api/drivers', action.payload, getAuthService().header)
        .flatMap(ajaxResponse => success(() => updateDriversAndTrucks(ajaxResponse.response), 'Successfully updated driver'))
        .catch(fail => Observable.of(failure('Could not update driver')))
    ))
);

export const bulkActivateDriversEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_DRIVERS)
    .mergeMap(action => (
      ajax.post('/api/drivers/bulkActivate', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => setDrivers(ajaxResponse.response), 'Successfully updated drivers'))
        .catch(fail => Observable.of(failure('Could not update drivers')))
    ))
);