import 'rxjs';
import {Observable} from 'rxjs/Observable';
import {ajax} from 'rxjs/observable/dom/ajax';
import {success, failure} from '../notificationMessages';
import {ADD_ERROR_CODE, setErrorCodes, REMOVE_ERROR_CODE, EDIT_ERROR_CODE, BULK_ACTIVATE_ERROR_CODES} from '../../reducers/errorCodes';
import { getAuthService } from '../AuthService';


export const addErrorCodeEpic = action$ => (
    action$.ofType(ADD_ERROR_CODE)
        .mergeMap(action => (
            ajax.post(`/api/errorCodes`, action.payload, getAuthService().header)
                .map(ajaxResponse => success(() => setErrorCodes(ajaxResponse.response), 'Successfully added new Error Code'))
                .catch(fail => Observable.of(failure('Could not add new Error Code')))
        ))
);

export const removeErrorCodeEpic = action$ => (
    action$.ofType(REMOVE_ERROR_CODE)
        .mergeMap(action => (
            ajax.delete(`/api/errorCodes/${action.payload}`, getAuthService().header)
                .map(ajaxResponse => success(() => setErrorCodes(ajaxResponse.response), 'Successfully remove Error Code'))
                .catch(fail => Observable.of(failure('Could not remove Error Code')))
                ))
);

export const updateErrorCodeEpic = action$ => (
    action$.ofType(EDIT_ERROR_CODE)
    .mergeMap(action => (
        ajax.put('/api/errorCodes',action.payload, getAuthService().header)
            .map(ajaxResponse => success(() => setErrorCodes(ajaxResponse.response), 'Succesully updated Error Code'))
            .catch(fail => Observable.of(failure('Could not update Error Code')))
    ))
);

export const bulkActivateErrorCodeEpic = action$ => (
    action$.ofType(BULK_ACTIVATE_ERROR_CODES)
        .mergeMap(action => (
            ajax.post('/api/errorCodes/bulkActivate',action.payload, getAuthService().header)
                .map(ajaxResponse => success(() => setErrorCodes(ajaxResponse.response), 'Successfully updated Error Codes'))
                .catch(fail => Observable.of(failure('Could not update Error Codes')))
        ))
);