const SET_HAMMERMARKS = 'SET_HAMMERMARKS';
export const ADD_HAMMERMARK = 'ADD_HAMMERMARK';
export const ADD_HAMMERMARK_SUCCESS = 'ADD_HAMMERMARK_SUCCESS';
export const UPDATE_HAMMERMARK = 'UPDATE_HAMMERMARK';
export const UPDATE_HAMMERMARK_SUCCESS = 'UPDATE_HAMMERMARK_SUCCESS';
export const REMOVE_HAMMERMARK = 'REMOVE_HAMMERMARK';
export const REMOVE_HAMMERMARK_SUCCESS = 'REMOVE_HAMMERMARK_SUCCESS';
export const BULK_ACTIVATE_HAMMERMARKS = 'BULK_ACTIVATE_HAMMERMARKS';


export function setHammerMarks(hammerMarks) {
  return {
    type: SET_HAMMERMARKS,
    payload: hammerMarks
  }
}

export function addHammerMark(hammerMark) {
  return {
    type: ADD_HAMMERMARK,
    payload: hammerMark
  }
}

export function addHammerMarkSuccess(hammerMark) {
  return {
    type: ADD_HAMMERMARK_SUCCESS,
    payload: hammerMark
  }
}

export function updateHammerMark(hammerMark) {
  return {
    type: UPDATE_HAMMERMARK,
    payload: hammerMark
  }
}

export function updateHammerMarkSuccess(hammerMark) {
  return {
    type: UPDATE_HAMMERMARK_SUCCESS,
    payload: hammerMark
  }
}

export function removeHammerMark(hammerMarkId) {
  return {
    type: REMOVE_HAMMERMARK,
    payload: hammerMarkId
  }
}

export function removeHammerMarkSuccess(hammerMark) {
  return {
    type: REMOVE_HAMMERMARK_SUCCESS,
    payload: hammerMark
  }
}

export function bulkActivateHammerMarks(hammerMarkIds) {
  return {
    type: BULK_ACTIVATE_HAMMERMARKS,
    payload: hammerMarkIds
  }
}

export default function hammerMarks(state = {
  hammerMarksList: []
}, action) {
  switch(action.type) {
    case SET_HAMMERMARKS:
      return _setHammerMarks(state, action);
    case ADD_HAMMERMARK_SUCCESS:
      return _AddHammerMark(state, action);
    case UPDATE_HAMMERMARK_SUCCESS:
      return _updateHammerMark(state, action);
    case REMOVE_HAMMERMARK_SUCCESS:
      return _removeHammerMark(state, action);
    default:
      return state;
  }
}

function _setHammerMarks(state, action) {
  return {
    ...state,
    hammerMarksList: action.payload
  }
}

function _AddHammerMark(state, action) {
  const hammerMarks = [...state.hammerMarksList, action.payload];
  return {
    ...state,
    hammerMarksList: hammerMarks
  }
}

function _updateHammerMark(state, action) {
  const updatedHammerMark = action.payload;
  const updatedList = state.hammerMarksList.map(hammerMark => {
    if(hammerMark.id === updatedHammerMark.id) {
      return updatedHammerMark;
    } else {
      return hammerMark;
    }
  });
  return {
    ...state,
    hammerMarksList: updatedList
  }
}

function _removeHammerMark(state, action) {
  const updatedList = state.hammerMarksList
  return {
    ...state,
    hammerMarksList: updatedList
  }
}
