import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { combineEpics } from 'redux-observable';
import { success, failure } from '../notificationMessages';
import { addFallerSuccess, updateFallerSuccess, removeFallerSuccess, ADD_FALLER, UPDATE_FALLER, REMOVE_FALLER, BULK_ACTIVATE_FALLERS, setFallers } from '../../reducers/fallers';
import { getAuthService } from '../AuthService';


const addFallerEpic = action$ => (
  action$.ofType(ADD_FALLER)
    .mergeMap(action => (
      ajax.post('/api/fallers', action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => addFallerSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

const updateFallerEpic = action$ => (
  action$.ofType(UPDATE_FALLER)
    .mergeMap(action => (
      ajax.put(`/api/fallers/${action.payload.id}`, action.payload, getAuthService().header)
        .map(ajaxResponse => success(() => updateFallerSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const removeFallerEpic = action$ => (
  action$.ofType(REMOVE_FALLER)
    .mergeMap(action => (
      ajax.delete(`/api/fallers/${action.payload}`, getAuthService().header)
        .map(ajaxResponse => success(() => removeFallerSuccess(ajaxResponse.response)))
        .catch(fail => Observable.of(failure()))
    ))
);

export const bulkActivateFallerEpic = action$ => (
  action$.ofType(BULK_ACTIVATE_FALLERS)
      .mergeMap(action => (
          ajax.post('/api/fallers/bulkActivate',action.payload, getAuthService().header)
              .map(ajaxResponse => success(() => setFallers(ajaxResponse.response)))
              .catch(fail => Observable.of(failure()))
      ))
);


export const FallerEpics = combineEpics(
  addFallerEpic,
  updateFallerEpic,
  removeFallerEpic,
  bulkActivateFallerEpic
);