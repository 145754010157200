import React from 'react';
import { connect } from 'react-redux';
import { clearDashboard, showDrylandDashboard } from '../../reducers/dashboard';
import { DRYLAND_DASHBOARD_DETAILS } from '../../constants/dashboardTypes';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import DestinationContextMenu from '../../components/contextMenus/DestinationContextMenu';
import LocationDraggable from './LocationDraggable';
import { highlightDestination } from '../../reducers/highlights';

class DraggableDestination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedItemId: ''
    }

    this.showDrylandDashboard = this.showDrylandDashboard.bind(this);
  }

  setClickedId(id) {
    this.setState({clickedItemId: id});
  }

  clearDashboard() {
    this.props.clearDashboard();
    this.setState({clickedItemId: ''});
  }

  showDrylandDashboard(id) {
    this.props.highlight(id);
    if (id !== this.state.clickedItemId || this.props.dashboard.dashboardType !== DRYLAND_DASHBOARD_DETAILS) {
      const targetDryland = find(this.props.destinations, {id: id});
      this.props.showDrylandDashboard(id, targetDryland);
      this.setClickedId(id);
    } else {
      this.clearDashboard();
    }
  }

  getBlockLoadStates() {
    const scenarioId = this.props.currentScenarioId;
    const blockLoads = this.props.blockLoadState.byId[scenarioId];
    if (blockLoads) {
      const allBlockLoads = Object.keys(blockLoads).map(key => blockLoads[key].blockLoads);
      return flatten(allBlockLoads);
    } else {
      return [];
    }
  }

  calculateRemainingLoadsToDryland(destinationId) {
    const blockLoads = this.getBlockLoadStates();
    const sumRemaining = blockLoads.reduce((remaining, currentBlockLoad) => {
      if (currentBlockLoad.destination === destinationId) {
        const remainValue = currentBlockLoad.loads - currentBlockLoad.assigned;
        return remaining + remainValue;
      } else {
        return remaining;
      }
    }, 0);
    const unassignedSleepersCount = this.props.sleeperLoads
      .filter(load => load.destinationId === destinationId && load.tripCount === 0).length;
    
    return sumRemaining + unassignedSleepersCount;
  }

  formatDrylandNameWithRemainingLoads(destinationId, name) {
    const hasLoadForDestination = find(this.getBlockLoadStates(), {destination: destinationId}) !== undefined;
    const hasSleeperLoad = this.hasSleeperLoadAvailable(destinationId);
    if (hasLoadForDestination || hasSleeperLoad) {
      const totalRemaining = this.calculateRemainingLoadsToDryland(destinationId);
      return `${name} (${totalRemaining})`;
    } else {
      return name;
    }
  }

  hasSleeperLoadAvailable(destinationId) {
    const trips = this.props.sleeperLoads
      .filter(load => load.destinationId === destinationId);
    return trips.length > 0;
  }

  isDestinationDraggable(destinationId) {
    if (this.hasSleeperLoadAvailable(destinationId)) {
      return true;
    }
    const scenarioId = this.props.currentScenarioId;
    const blockLoadObject = this.props.blockLoadState.byId[scenarioId];
    if (blockLoadObject && !this.props.hasBeenPublished) {
      return Object.keys(blockLoadObject).reduce((hasDestination, blockId) => {
        const blockLoads = blockLoadObject[blockId].blockLoads;
        const hasDestinationAssigned = blockLoads.reduce((isAssigned, blockLoad) => isAssigned || blockLoad.destination === destinationId, false);
        return hasDestination || hasDestinationAssigned;
      }, false);
    } else {
      return false;
    }
  }

  matchesSelectedOperatingUnit(operatingUnitIds) {
    const selectedIdSet = new Set(this.props.selectedOperatingUnitIds);
    const matchesFilter = operatingUnitIds.filter(id => selectedIdSet.has(id));
    return matchesFilter.length > 0;
  }

  getDrylandsToRender() {
    return this.props.destinations
      .filter(dryland => dryland.active && this.matchesSelectedOperatingUnit(dryland.operatingUnitIds) && !dryland.isDeleted)
      .map(dryland =>
        <DestinationContextMenu
          key={'context'+dryland.id}
          id={dryland.id}
          name={dryland.name}
          scenarioId={this.props.currentScenarioId}
          user={this.props.user}
        >
          <LocationDraggable 
            key={dryland.id}
            id={dryland.id}
            name={this.formatDrylandNameWithRemainingLoads(dryland.id, dryland.name)}
            type={dryland.type}
            currentDashboardId={this.props.dashboard.id}
            clickHandler={this.showDrylandDashboard}
            isDraggable={this.isDestinationDraggable(dryland.id)}
          />
        </DestinationContextMenu>
      );
  } 

  getStyle(destinations) {
    const numRows = Math.ceil(destinations.length / 2);
    const heightPerRow = 3;
    const heightValue = heightPerRow * Math.min(numRows, 6);
    return {
      height: `${heightValue}em`
    }
  }

  render() {
    const destinations = this.getDrylandsToRender();
    return <div className='destination-draggable-list' style={this.getStyle(destinations)}>
      {destinations}
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    destinations: state.locations.destinations,
    blockLoadState: state.blockLoadState,
    currentScenarioId: state.scenarioState.selectedScenario.id,
    dashboard: state.dashboard.currentlySelected,
    isPublished: state.scenarioState.selectedScenario.published,
    selectedOperatingUnitIds: state.operatingUnits.selectedOperatingUnitIds,
    sleeperLoads: state.sleeperLoads.sleeperLoadList,
    user: state.users.user.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showDrylandDashboard: (destinationId, data) => {
      dispatch(showDrylandDashboard(destinationId, data));
    },
    clearDashboard: () => {
      dispatch(clearDashboard());
    },
    highlight: (id) => {
      dispatch(highlightDestination(id));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableDestination);