import axios from 'axios';
import store from '../store';
import { setLoaders } from '../reducers/loaders';
import { setLoaderOperators } from '../reducers/loaderOperatorAssignment';
import { setOperators } from '../reducers/operators';
import { getAuthService } from '../services/AuthService';

class LoaderService {

  static getLoaderOperatorAssignmentApi() {
    return axios.get('/api/loaderOperatorAssignment/', { headers: getAuthService().header})
  }
  
  static getLoadersApi() {
    return axios.get('/api/loaders', { headers: getAuthService().header });
  }

  static getOperatorsApi() {
    return axios.get('/api/operators', { headers: getAuthService().header });
  }

  static getLoaderOperatorAssignments() {
    return this.getLoaderOperatorAssignmentApi()
      .then((assignments) => {
        store.dispatch(setLoaderOperators(assignments.data))
      })
      .catch(error => console.log(error));
  }

  static getLoaders() {
    return this.getLoadersApi()
      .then((loaders) => {
        store.dispatch(setLoaders(loaders.data))
      })
      .catch(error => console.log(error));
  }

  static getOperators() {
    return this.getOperatorsApi()
      .then((operators) => {
        store.dispatch(setOperators(operators.data))
      })
      .catch(error => console.log(error));
  }
}

export default LoaderService;