import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store';
import { assignNewScenario, updateScenarioSuccess } from '../reducers/scenarios';
import { showLoading, hideLoading } from '../reducers/loadingScreen';
import { closeModal } from '../reducers/modalWindows';

class PublishService {
  static baseUrl() {
    return window.location.origin + '/';
  }

  // static getSessionId() {
  //   return sessionStorage.getItem('token');
  // }

  static publish(scenarioId, user) {
    store.dispatch(showLoading());

    const url = this.baseUrl() + `api/publishSchedule/publish/${scenarioId}?userEmail=${user.email}`;
    axios.post(url)
      .then(response => {
        toast.success(response.data.message);
        store.dispatch(updateScenarioSuccess(response.data.scenario));
        
      })
      .catch(error => console.log(error))
      .then(() => store.dispatch(hideLoading()));
    
  }

  static unpublish(scenarioId) {
    store.dispatch(showLoading());
    const url = `api/publishSchedule/unpublish/${scenarioId}`;
    axios.post(url)
      .then(response => {
        toast.success('Successfully unpublished schedule');
        store.dispatch(updateScenarioSuccess(response.data.currentScenario));
        store.dispatch(assignNewScenario(response.data.newScenario));
      })
      .catch(error => console.log(error))
      .then(() => store.dispatch(hideLoading()));
  }

  static publishPartially(scenarioId, operatingUnitId, user) {
    store.dispatch(showLoading());
    const data = {scenarioId: scenarioId, operatingUnitId: operatingUnitId};
    const url = this.baseUrl() + `api/publishSchedule/publishPartially?userEmail=${user.email}`;
    axios.post(url, data)
      .then(response => {
        toast.success(response.data.message);
        store.dispatch(updateScenarioSuccess(response.data.scenario));
        
      })
      .catch(error => console.log(error))
      .then(() => store.dispatch(hideLoading()));
  }

  static sendTruckDriverEmail(scenarioId, truckId, user) {
    const url = this.baseUrl() + `api/publishSchedule/truck?userEmail=${user.email}`;
    const data = {scenarioId: scenarioId, truckId: truckId};
    axios.post(url, data)
      .then(success => {
        toast.success('Successfully sent schedule to truck driver');
      })
      .catch(error => toast.error('Could not send schedule'));
  }

  static sendBlockEmail(scenarioId, blockId, user) {
    const url = this.baseUrl() + `api/publishSchedule/block?userEmail=${user.email}`;
    const data = {scenarioId: scenarioId, blockId: blockId};
    axios.post(url, data)
      .then(success => toast.success('Successfully sent schedule to loader'))
      .catch(error => toast.error('Could not send schedule'));
  }

  static sendDestinationEmail(scenarioId, destinationId, user) {
    const url = this.baseUrl() + `api/publishSchedule/destination?userEmail=${user.email}`;
    const data = {scenarioId: scenarioId, destinationId: destinationId};
    axios.post(url, data)
      .then(success => toast.success('Successfully sent schedule to destination'))
      .catch(error => toast.error('Could not send schedule'));
  }

  static sendTruckDriverTimeCard(scenarioId, scenarioTruckId, user){
    const url = this.baseUrl() + `api/publishSchedule/truckDriverTimeCard?userEmail=${user.email}`;
    const data = {scenarioId: scenarioId, scenarioTruckId: scenarioTruckId};
    axios.post(url, data)
      .then(success => {
        toast.success('Successfully sent time card to truck driver');
      })
      .then(() => store.dispatch(closeModal()))
      .catch(error => toast.error('Could not send time card'));
  }

  static sendTruckCompanyTimeCards(date, contractorId, user){
    store.dispatch(closeModal());
    store.dispatch(showLoading());
    const url = this.baseUrl() + `api/publishSchedule/sendTruckCompanyTimeCards?userEmail=${user.email}`;
    const data = {date: date, contractorId: contractorId};
    axios.post(url, data)
      .then(success => {
        toast.success('Successfully sent time cards to truck company');
        store.dispatch(hideLoading());
      })
      .catch(error =>  {
        store.dispatch(hideLoading());
        toast.error('Could not send time cards');
      })
  }
}

export default PublishService;