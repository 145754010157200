import { sortBy } from 'lodash';

export const ADD_NEW_LOADER = 'ADD_NEW_LOADER';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';
export const FETCH_LOADERS = 'FETCH_LOADERS';
export const BULK_ACTIVATE_LOADERS = 'BULK_ACTIVATE_LOADERS';
const ADD_NEW_LOADER_SUCCESS = 'ADD_NEW_LOADER_SUCCESS';
const UPDATE_LOADER_SUCCESS = 'UPDATE_LOADER_SUCCESS';
const REMOVE_LOADER_SUCCESS = 'REMOVE_LOADER_SUCCESS';
const SET_LOADERS = 'SET_LOADERS';

export function fetchLoaders() {
  return {
    type: FETCH_LOADERS
  }
}

export function addLoader(loader) {
  return {
    type: ADD_NEW_LOADER,
    payload: loader
  }
}

export function addLoaderSuccess(loader) {
  return {
    type: ADD_NEW_LOADER_SUCCESS,
    loader: loader
  }
}

export function updateLoader(loader) {
  return {
    type: UPDATE_LOADER,
    payload: loader
  }
}

export function updateLoaderSuccess(loader) {
  return {
    type: UPDATE_LOADER_SUCCESS,
    loader: loader
  }
}

export function removeLoader(loaderId) {
  return {
    type: REMOVE_LOADER,
    payload: loaderId
  }
}

export function removeLoaderSuccess(loaderId) {
  return {
    type: REMOVE_LOADER_SUCCESS,
    loaderId: loaderId
  }
}

export function setLoaders(loaders) {
  return {
    type: SET_LOADERS,
    loaders: loaders,
  }
}

export function bulkActivateLoaders(loaderIds) {
  return {
    type: BULK_ACTIVATE_LOADERS,
    payload: loaderIds
  }
}

const initialState = {
  loaderList: []
}

function loaders(state = initialState, action) {
  switch(action.type) {
    case SET_LOADERS:
      return _setLoaders(state, action);
    case ADD_NEW_LOADER_SUCCESS:
      return _addLoader(state, action);
    case UPDATE_LOADER_SUCCESS:
      return _updateLoader(state, action);
    case REMOVE_LOADER_SUCCESS:
      return _removeLoader(state, action);
    default:
      return state;
  }
}

export default loaders;

function sortLoaders(loaders) {
  return sortBy(loaders, (loader) => loader.name);
}

function _setLoaders(state, action) {
  return {
    ...state,
    loaderList: sortLoaders(action.loaders)
  }
}

function _addLoader(state, action) {
  const sortedLoaders = sortLoaders([...state.loaderList, action.loader]);
  return {
    ...state,
    loaderList: sortedLoaders 
  }
}

function _removeLoader(state, action) {
  const updatedList = state.loaderList
  return {
    ...state,
    loaderList: updatedList
  }
}

function _updateLoader(state, action) {
  const updatedLoader = action.loader;
  const updatedList = state.loaderList.map(loader => {
    if (loader.id === updatedLoader.id) {
      return updatedLoader;
    } else {
      return loader;
    }
  });
  const sortedLoaders = sortLoaders(updatedList);
  return {
    ...state,
    loaderList: sortedLoaders
  }
}