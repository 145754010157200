import React from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { fetchScenarios } from '../reducers/scenarios';
import { fetchNavistreamData } from '../reducers/navistream';
import subDays from 'date-fns/sub_days';
import addDays from 'date-fns/add_days';
import isValid from 'date-fns/is_valid';

class ScenarioDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.displayFormat =  'MMMM D, YYYY';
    this.isoFormat = 'YYYY-MM-DD';

    this.handleDayClick = this.handleDayClick.bind(this);
    this.goBackOneDay = this.goBackOneDay.bind(this);
    this.goForwardOneDay = this.goForwardOneDay.bind(this);
  }

  handleDayClick(day) {
    const parsedDate = parse(day);
    const fin = isFinite(parsedDate.valueOf());
    if (fin && isValid(parsedDate)) {
      const isoDate = format(day, this.isoFormat);
      this.props.fetchScenarios(isoDate);
      //this.props.fetchNavistreamData(isoDate);
    }
  }

  goBackOneDay() {
    const subtractedDay = subDays(this.props.currentDate, 1);
    this.handleDayClick(subtractedDay);
  }

  goForwardOneDay() {
    const addedDay = addDays(this.props.currentDate, 1);
    this.handleDayClick(addedDay);
  }

  render() {
    return (
      <div className='scenario-date-picker'>
        <button className='back-date-arrow' onClick={this.goBackOneDay}> {'<'} </button>
        <DayPickerInput
          onDayChange={this.handleDayClick}
          dayPickerProps={{
            todayButton: 'Today',
          }}
          formatDate={format}
          format={this.isoFormat}
          placeholder={this.isoFormat}
          value={`${format(this.props.currentDate, this.displayFormat)}`}
        />
        <button className='forward-date-arrow' onClick={this.goForwardOneDay}> {'>'} </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentDate: state.scenarioState.currentDateSelected
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchScenarios: (date) => {
      dispatch(fetchScenarios(date));
    },
    fetchNavistreamData: (scenarioDate) => {
      dispatch(fetchNavistreamData(scenarioDate));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioDatePicker);
