import React from 'react';
import { connect } from 'react-redux';
import isToday from 'date-fns/is_today'

class CurrentTimeLineTracker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentMinute: this.getElapsedMinutesSinceStartingHour()
    };
  }

  getTimeValue(hour) {
    return new Date().setHours(hour, 0, 0, 0);
  }

  getTimeValueMidnight() {
    return this.getTimeValue(0);
  }

  currentTimeValue() {
    return new Date().valueOf();
  }

  getCurrentHourSinceStart() {
    let hoursSinceStart = this.state.currentMinute / 60;
    return this.props.beginHour + hoursSinceStart;
  }

  // startValue and endValue expect Unix time in milliseconds
  getElapsedMinutes(startValue, endValue) {
    let ellapsedTimeUnixMs = endValue - startValue;
    let ellapsedTimeMinutes = ellapsedTimeUnixMs / 1000 / 60;
    return ellapsedTimeMinutes;
  }

  getElapsedMinutesSinceStartingHour() {
    let started = this.getTimeValue(this.props.beginHour);
    let now = this.currentTimeValue();
    let elapsedMinutes = this.getElapsedMinutes(started, now);
    return elapsedMinutes;
  }

  tick = () => {
    let timeElapsed =  this.getElapsedMinutesSinceStartingHour();
    this.setState({currentMinute: timeElapsed});
  }

  componentDidMount() {
    const intervalId = setInterval(this.tick, 5000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getStyle() {
    let now = this.getCurrentHourSinceStart();
    let beginHour = this.props.beginHour;
    let endHour = this.props.endHour + 1;
    if(beginHour <= now && endHour >= now) {
      // multiply by 2 since 2px is one minute
      let leftTranslation = this.state.currentMinute * 2;

      return {
        left: (leftTranslation + 'px')
      };
    }
  }

  render() {
    if (isToday(this.props.currentDateSelected)) {
      return (
        <div className="current-time-tracker-container">
          <div className="current-time-tracker" style={this.getStyle()}/>
        </div>
      ); 
    } else {
      return null;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    currentDateSelected: state.scenarioState.currentDateSelected
  }
}

export default connect(mapStateToProps)(CurrentTimeLineTracker);