class SleeperLoadHelper {

  constructor(yards, selectedOperatingUnitIds, assignment) {
    this.yards = yards;
    this.selectedOperatingUnitIds = selectedOperatingUnitIds;
    this.yardOpUnits = assignment.filter(assign => assign.objectType === 'yard');
  }

  getActiveYards() {
    const activeYards = this.yards.filter(yard => yard.active);
    return activeYards;
  }

  getActiveYardIdSet() {
    const ids = this.getActiveYards().map(y => y.id);
    return new Set(ids);
  }

  getCurrentlySelectedOpUnitIdSet() {
    return new Set(this.selectedOperatingUnitIds);
  }

  getYardOpUnitIdSet() {
    const yardOpUnits = this.yardOpUnits;
    return new Set(yardOpUnits);
  }

  getActiveYardsInSelectedOpUnits() {
    const selectedOpUnitIdSet = this.getCurrentlySelectedOpUnitIdSet();
    const activeYardIdSet = this.getActiveYardIdSet();
    const activeAndSelectedYardIds = this.yardOpUnits.filter(assign => {
      return activeYardIdSet.has(assign.configObjectId) && selectedOpUnitIdSet.has(assign.operatingUnitId); 
    }).map(assign => assign.configObjectId);
    
    const activeYards = this.getActiveYards();
    const activeAndSelectedIdSet = new Set(activeAndSelectedYardIds);
    return activeYards.filter(yard => activeAndSelectedIdSet.has(yard.id));

  }
}

export default SleeperLoadHelper;