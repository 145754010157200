import React from 'react';
import { connect } from 'react-redux';
import DestinationFieldEditor from '../../components/fieldEditors/DestinationFieldEditor';
import { addNewDestinationLocation, updateDestinationLocation, bulkActivateDestinations } from '../../reducers/locations';
import { openRemoveObjectModal, openBulkActivationModal, openDuplicateObjectNamesModal } from '../../reducers/modalWindows';
import find from 'lodash/find';
import uniqid from 'uniqid';
import RemoveObjectPromptModal from '../../components/modals/RemoveObjectPromptModal';
import difference from 'lodash/difference';
import { BULK_ACTIVATION_MODAL, DUPLICATE_OBJECT_NAMES_MODAL } from '../../constants/modalTypes';
import BulkActivationModal from '../../components/modals/BulkActivationModal';
import Select, { createFilter } from 'react-select';
import { getYardTimes } from '../../utils/yardTimeHelper';
import { DESTINATION } from '../../constants/typeConstants';
import DuplicateObjectNamesModal from '../../components/modals/DuplicateObjectNamesModal';

class DestinationsConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      destinationSelected: '',
      isAddingNewDestination: false
    }

    this.addNewDestination = this.addNewDestination.bind(this);
    this.removeDestination = this.removeDestination.bind(this);
  }

  componentDidUpdate(previousProps) {
    const previousDestinations = previousProps.destinations.map(d => d.id);
    const currentDestinations = this.props.destinations.map(d => d.id);
    if (currentDestinations.length > previousDestinations.length) {
      const diff = difference(currentDestinations, previousDestinations);
      if (diff.length > 0) {
        const newDest = diff[0];
        this.setState({
          destinationSelected: newDest,
          isAddingNewDestination: false
        });
      }
    }
  }

  addNewDestination() {
    this.setState({
      destinationSelected: '',
      isAddingNewDestination: true
    });
  }

  selectDestination(id) {
    this.setState({
      destinationSelected: id,
      isAddingNewDestination: false
    });
  }

  getSelectedDestination() {
    const destination = find(this.props.destinations, {id: this.state.destinationSelected});
    return destination;
  }

  getSelectedDestinationName() {
    const destination = this.getSelectedDestination();
    if (destination) {
      return destination.name;
    } else {
      return 'Select...';
    }
  }

  hasSelectedDestination() {
    return this.state.destinationSelected !== '';
  }

  getDestinationProps() {
    const destination = this.getSelectedDestination();
    if (destination) {
      const yardTimes = getYardTimes(destination.id);
      const selectedOperatingUnitIds = destination.operatingUnitIds;
      return {
        destinationId: destination.id,
        name: destination.name,
        unloadTime: destination.unloadTime,
        email: destination.email,
        active: destination.active,
        fullName: destination.fullName,
        comments: destination.comments,
        yardTimeData: yardTimes,
        selectedOperatingUnitIds: selectedOperatingUnitIds,
        maxCapacity: destination.maxCapacity,
        siteCode: destination.siteCode,
        scalerEmail: destination.scalerEmail
      }
    } else {
      return {};
    }
  }

  removeDestination() {
    this.props.removeDestinationModal(this.state.destinationSelected);
  }

  getDestinationButtonStyle(id) {
    if (id === this.state.destinationSelected) {
      return {
        background: '#cae6ff',
        border: '1px solid #bfbfbf'
      }
    }
  }

  getDestinationList() {
    const options = this.props.destinations
    .filter(destination => !destination.isDeleted)
    .map(dest => {
      return {
        value: dest.id,
        label: dest.name
      }
    });

    const handleChanage = (selectedOption) => this.selectDestination(selectedOption.value);
    return <Select className='configuration-object-selector'
      value={{label: this.getSelectedDestinationName()}}
      filterOption={createFilter({matchFrom: 'start'})}
      options={options}
      onChange={handleChanage}
    />;
  }

  getRemoveDestinationButton() {
    if (this.state.destinationSelected !== '') {
      return <button className='remove-destination' onClick={this.removeDestination}>Remove Destination</button>;
    }
  }

  checkExistence(state) {
    if (this.state.isAddingNewDestination) {
      const destination = find(this.props.destinations, {name: state.name});
      if (destination) {
        this.props.openDuplicateObjectNamesModal(state);
      }
      else {
        this.props.add(state);
      }
    }
    if (!this.state.isAddingNewDestination) {
      if (this.getSelectedDestinationName() !== state.name) {
        const destination = find(this.props.destinations, {name: state.name});
        if (destination) {
          this.props.openDuplicateObjectNamesModal(state);
        }
        else {
          this.props.update(state);
        }
      }
      else {
        this.props.update(state);
      }
    }
  }


  getDuplicateObjectNameModal() {
    const modalWindow = this.props.modalWindow;
    if (modalWindow.modalType === DUPLICATE_OBJECT_NAMES_MODAL && modalWindow.isModalOpen) {
      return (
        <DuplicateObjectNamesModal 
          activate={(object) => this.activateObjectFromDuplicateNameModal(object)}
          recover={(object) => this.recoverObjectFromDuplicateNameModal(object)}
        />
      );
    }
  }

  recoverObjectFromDuplicateNameModal(object) {
    object.isDeleted = !object.isDeleted;
    this.props.update(object);
  }

  activateObjectFromDuplicateNameModal(object) {
    object.active = !object.active;
    this.props.update(object);
  }


  getDestinationEditPanel() {
    if (this.hasSelectedDestination() && !this.state.isAddingNewDestination) {
      const destinationId = this.state.destinationSelected;
      return (
        <div>
          <DestinationFieldEditor
            key={destinationId}
            destinationFieldProps={this.getDestinationProps()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectDestination('')}
          />
        </div>
      );
    } else if (this.state.isAddingNewDestination) {
      return (
        <div>
          <DestinationFieldEditor
            key={uniqid()}
            clickDone={(state) => this.checkExistence(state)}
            clickCancel={() => this.selectDestination('')}
          />
        </div>
      );
    }
  }

  activate() {
    const destinationState = this.getDestinationProps();
    if (destinationState) {
      destinationState.active = !destinationState.active;
      this.props.update(destinationState);
    }
  }

  getActivateButton() {
    const destination = this.getSelectedDestination();
    if (destination) {
      const buttonName = destination.active? 'Deactivate': 'Activate';
      return <button className='activate-button' onClick={() => this.activate()}>{buttonName}</button>;
    }
  }

  getBulkActivationModal() {
    const modal = this.props.modalWindow;
    if (modal.isModalOpen && modal.modalType === BULK_ACTIVATION_MODAL) {
      return <BulkActivationModal
        type={DESTINATION}
        activationItems={this.props.destinations.filter(destination => !destination.isDeleted)}
        onClickDone={(selectedItems) => this.props.bulkActivate(selectedItems)}
      />
    } else {
      return null;
    }
  }

  getPrompt() {
    const destination = this.getSelectedDestination();
    if (destination) {
      return `Edit destination ${destination.name}`;
    } else if (this.state.isAddingNewDestination) {
      return 'Add a new destination';
    } else {
      return 'Select a destination to edit';
    }
  }

  render() {
    return (
      <div className='destinations-configuration configuration-forms'>
        <button className='add-new-destination' onClick={this.addNewDestination}>Add New Destination</button>
        <button className='bulk-activation-button' 
          onClick={() => this.props.openBulkActivationModal()}>
          Bulk Activation
        </button>
        <div className='destination-editor'>
          <p>{this.getPrompt()}</p>
          <div className='destination-list'>
            {this.getDestinationList()}
          </div>
          <div className='destination-edit-panel'>
            {this.getDestinationEditPanel()}
            <div className='side-panel'>
              <div className='destination-config-buttons'>
                {this.getActivateButton()}
                {this.getRemoveDestinationButton()}
                {this.getDuplicateObjectNameModal()}
              </div>
            </div>
          </div>
        </div>
        <RemoveObjectPromptModal onClickDone={() => this.selectDestination('')}/>
        {this.getBulkActivationModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destinations: state.locations.destinations,
    modalWindow: state.modalWindows,
    locationsToYards: state.locations.locationsToYards
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (state) => {
      dispatch(addNewDestinationLocation(state));
    },
    update: (state) => {
      dispatch(updateDestinationLocation(state));
    },
    removeDestinationModal: (id) => {
      dispatch(openRemoveObjectModal(id));
    },
    openBulkActivationModal: (activationItems) => {
      dispatch(openBulkActivationModal(activationItems))
    },
    bulkActivate: (ids) => {
      dispatch(bulkActivateDestinations(ids));
    },
    openDuplicateObjectNamesModal:(object) => {
      dispatch(openDuplicateObjectNamesModal(object));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationsConfiguration);
