import { SHOW_BLOCK_DASHBOARD, clearDashboard } from '../reducers/dashboard';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { DELETE_SCENARIO, CREATE_NEW_SCENARIO, FETCH_SCENARIOS } from '../reducers/scenarios';

export const addDetailsToDashboardData = store => next => action => {
  if (action.type === SHOW_BLOCK_DASHBOARD) {
    const locations = store.getState().locations;
    const blocksToDestinations = locations.blocksToDestinations;
    const matchingBlocksToDestinations = blocksToDestinations.filter(b2d => b2d.blockId === action.payload.id);
    const destinationIds = matchingBlocksToDestinations.map(m => m.destinationId);
    const drylands = locations.destinations
      .filter(dryland => includes(destinationIds, dryland.id))
      .map(destination => {
        const b2d = find(matchingBlocksToDestinations, {destinationId: destination.id});
        return {
          ...destination,
          toDestinationTime: b2d.toDestinationTime
        }
      });
    action.payload.data.destinations = drylands;
  }

  next(action);
}

export const clearDashboardDispatcher = store => next => action => {

  if (action.type === DELETE_SCENARIO ||
      action.type === CREATE_NEW_SCENARIO ||
      action.type === FETCH_SCENARIOS) {
    store.dispatch(clearDashboard());
  }

  next(action);
}
