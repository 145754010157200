import React from 'react';
import { DragSource } from 'react-dnd';
import DraggableTypes from '../../constants/DraggableTypes';
import { isViewerRole } from '../../utils/userHelper';

class TruckDraggable extends React.Component {
  
  getDashboardHighlightStyle() {
    if (this.props.currentDashboardId === this.props.id) {
      return {
        border: '3px solid yellow',
        padding: '7px'
      }
    }
  }

  getFadedStyle() {
    if (this.props.isTruckInSchedule) {
      return {
        backgroundColor: '#e6e6e6',
        color: '#8c8c8c'
      }
    }
  }

  getStyle() {
    const highlight = this.getDashboardHighlightStyle();
    const faded = this.getFadedStyle();
    return {
      ...highlight,
      ...faded
    }
  }

  clickHandler() {
    if (this.props.clickHandler) {
      this.props.clickHandler(this.props.id);
    }
  }

  render() {
    const { connectDragSource } = this.props;

    const isDraggable = this.props.isDraggable && !isViewerRole();
    const dragClass = isDraggable? '': 'drag-disabled';
    const draggableElement = (
      <div onClick={() => this.clickHandler()}>
        <div className={`draggable ${dragClass}`}
             style={this.getStyle()}>
          {this.props.name}
        </div>
      </div>
    );
    if (isDraggable) {
      return connectDragSource(draggableElement);
    } else {
      return draggableElement;
    }
  }
}

const truckSource = {
  beginDrag(props) {
    return {
      id: props.id,
      name: props.name
    };
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource()
  }
}

export default DragSource(DraggableTypes.TRUCK, truckSource, collect) (TruckDraggable);