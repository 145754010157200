import getTime from 'date-fns/get_time';
import startOfToday from 'date-fns/start_of_today';
import startOfDay from 'date-fns/start_of_day';
import format from 'date-fns/format';
import addHours from 'date-fns/add_hours';
import addMinutes from 'date-fns/add_minutes';
import setHours from 'date-fns/set_hours';
import setMinutes from 'date-fns/set_minutes';
import isDate from 'date-fns/is_date';
import parse from 'date-fns/parse';

export function getCurrentDateTime() {
  return getTime(new Date());
}

export function getCurrentDate() {
  return startOfToday();
}

const isoFormat = 'YYYY-MM-DD';
const timeFormat = 'h:mm a';

export function getDateIsoFormat() {
  return format(startOfToday(), isoFormat);
}

export function getTimeStringOfDate(dateObject) {
  return format(dateObject, timeFormat);
}

export function formatDateStringIso(dateString) {
  return format(dateString, isoFormat);
}

export function startOfWorkDay(beginWorkHour, scenarioDate) {
  return addHours(startOfDay(scenarioDate), beginWorkHour);
}

export function startTimeOfScheduledItem(startTime, scenarioDate) {
  const startWork = startOfWorkDay(startTime.startHour, scenarioDate);
  return addMinutes(startWork, startTime.startMinute);
}

// expect beginWorkHour as an integer and travelTime as integer in minutes
export function transitEndTime(beginWorkHour, travelTime) {
  const transitStart = startOfWorkDay(beginWorkHour);
  return addMinutes(transitStart, travelTime);
}

export function getTransitTimeDates(beginWorkHour, travelTime) {
  const transitStart = startOfWorkDay(beginWorkHour);
  const transitEnd = addMinutes(transitStart, travelTime);
  return {
    transitStartTime: transitStart,
    transitEndTime: transitEnd
  }
}

export function setTimeToDate(hour, minute, date) {
  const updatedHour = setHours(date, hour);
  const updatedTime = setMinutes(updatedHour, minute);
  return updatedTime;
}

function getStartDate(activity) {
  return activity.dateObjects.locationStartTime;
}

export function getEndDate(activity) {
  return addMinutes(activity.dateObjects.locationEndTime, activity.delay + activity.secondDelay);
}

export function getStartTime(activity) {
  const start = getStartDate(activity);
  const formatted = format(start, timeFormat);
  return formatted;
}

export function getEndTime(activity) {
  const end = getEndDate(activity);
  const formatted = format(end, timeFormat);
  return formatted;
}

export function isProperDate(dateString) {
  if (dateString) {
    return isDate(parse(dateString));
  } else {
    return false;
  }
}