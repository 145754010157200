import { setHammerMarks } from '../reducers/hammerMarks';
import axios from 'axios';
import store from '../store';
import { getAuthService } from '../services/AuthService';

class HammerMarkService {
  static getHammerMarksApi() {
    return axios.get('/api/hammerMarks', { headers: getAuthService().header });
  }

  static getHammerMarks() {
    return this.getHammerMarksApi()
      .then((hammerMarks) => {
        store.dispatch(setHammerMarks(hammerMarks.data))
      })
      .catch(error => console.log(error));
  }
}

export default HammerMarkService;