const SET_USER = 'SET_USER';

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  }
}

const initialState = {
  user: {}
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return _setUser(state, action);
    default:
      return state;
  }
}

function _setUser(state, action) {
  return {
    ...state,
    user: action.payload
  }
}